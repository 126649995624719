@import '~common/scss/shared/shared';

.one-and-two-marketing {
    width: 100%;
    display: flex;

    _:-ms-lang(x),
    .slide-content {
        display: inline-block !important;
    }

    .children {
        //@include clearfix;
    }

    .left-col {
        width: 67%;
        display: block;
    }

    .right-col {
        width: 38%;
        padding-left: 1%;
        display: block; // pre-flex fallback
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .component {
        display: block;
        width: 100%;
    }

    .title-text-cta {
        // override title-text-cta defaults, but why are they even defaults?...
        background: none;
        padding: 0;

        .container {
            margin: 0;
            padding: 0;
            //@include clearfix;
        }

        .image {
            width: 100%;

            img,
            picture {
                width: 100%;
            }
        }

        .copy {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 4%;
        }

        .title,
        .subtitle {
            margin: 0;
        }

        .cta {
            position: relative;
            flex-grow: 10;

            &.text-align-center {
                .btn {
                    // since btn is position absolute
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &.text-align-right {
                .btn {
                    // since btn is position absolute
                    right: 0;
                }
            }
        }

        .btn {
            position: absolute;
            bottom: 0;
        }
    }

    .right-col .title-text-cta {
        .title {
            font-size: 2vw;
        }

        .subtitle {
            font-size: 1.4vw;
        }
    }

    .left-col .title-text-cta {
        .copy {
            width: 100%;
            padding: 5%;
        }

        .title {
            font-size: 4vw;
        }

        .subtitle {
            font-size: 2vw;
        }
    }

    @include media(bp-lt-sm) {
        flex-direction: column;

        .title-text-cta {
            .title {
                font-size: 6vw;
            }

            .subtitle {
                font-size: 4vw;
            }

            .btn {
                width: 100%;
            }
        }

        .left-col {
            width: 100%;

            .title-text-cta {
                .copy {
                    padding: 6% 2.5%;
                }

                .title {
                    font-size: 8vw;
                }

                .subtitle {
                    font-size: 4vw;
                }
            }
        }

        .right-col {
            width: 100%;
            flex-direction: row;
            margin-top: 2%;
            padding-left: 0;

            .component {
                width: 49%;
            }

            .title-text-cta {
                .copy {
                    padding: 8% 5%;
                }

                .title {
                    font-size: 5vw;
                }

                .subtitle {
                    font-size: 4vw;
                }
            }
        }
    }
}
