@use "sass:math";
.overview-price-item {
    display: table;

    .item-img {
        display: table-cell;
        width: percentage(math.div(225, 690));
        padding-right: percentage(math.div(30, 690));
        vertical-align: top;
    }

    .item-copy {
        display: table-cell;
        vertical-align: top;
    }

    .item-title {
        margin: 0 0 .2em;
        line-height: 1.1em;
    }

    .details {
        display: table;
    }

    .description {
        display: table-cell;
        width: percentage(math.div(240, 465));
        padding-right: percentage(math.div(30, 465));
    }

    .internet-price {
        display: table-cell;
        padding-left: percentage(math.div(15, 465));
        text-align: center;

        .label {
            text-transform: uppercase;
            font-weight: bold;
            line-height: 1.2em;
        }

        .price {
            margin-top: 8px;
            line-height: .9em;

            span {
                line-height: 1em;
            }
        }
    }

    p {
        margin: 0;
        line-height: 1.2em;
    }

    .reg-price {
        margin-bottom: .8em;
        font-weight: bold;
    }

    @include media(bp-lt-md) {
        display: block;

        .folder {
            margin: 0 -15px;
        }

        .item-img {
            display: block;
            width: 100%;
            text-align: center;
        }

        .item-copy {
            display: block;
        }

        .item-title {
            display: none;
        }

        .details {
            display: block;
        }

        .description {
            display: block;
            width: 100%;
            padding: 20px 0 15px;
            text-align: center;
        }

        .reg-price {
            margin-bottom: .5em;
        }

        .internet-price {
            display: block;
            margin-bottom: 10px;
            padding: 20px 0;
        }

        // .btn-title {
        //     display: none;
        // }
    }
}
