@use 'sass:math';

body {
    // overflow-x moved into #wrapper
    margin: 0;
    padding: 0;

    &.results-grid-filter-active {
        cursor: pointer;
    }

    &.no-scroll {
        overflow: hidden;
    }
}

#wrapper {
    width: 100%;
    // max-width: $wrapper-max-width;
    margin: 0 auto;

    // @include media(bp-lt-sm) {
    // this is so the mobile nav doesn't cause L/R scroll (mobile ignores body:overflow)
    /* turning off for now to allow sticky elements in sav2 */
    /* overflow-x: hidden; */
    // }

    &.no-scroll {
        @include media(bp-lt-sm) {
            height: 100vh;
        }
    }

    //text-align: center;
}

#main {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    clear: both;
}

.container {
    position: relative;
    max-width: $container-max-width;
    margin: 0 percentage(math.div(70px, $wrapper-max-width));
    text-align: left;
}

.col {
    float: left;
}

.container-component {
    &.gradient-top,
    &.gradient-bottom,
    &.add-padding {
        padding: 40px 0;
    }
}
