@use "sass:math";
@import '~common/scss/shared/shared';

$basic-fifty-fifty-border-color: #dcdcdb !default;

.basic-fifty-fifty {
    .container {
        overflow: hidden;
        padding: 20px 0;
    }

    .col {
        width: 50%;

        @include media(bp-lt-sm) {
            float: none;
            width: 100%;
        }

        &:first-child {
            padding-right: percentage(math.div(30px, 650px));
            border-right: solid 1px $basic-fifty-fifty-border-color;

            @include media(bp-lt-sm) {
                padding: 0;
                border: none;
            }
        }

        &:last-child {
            padding-left: percentage(math.div(30px, 650px));

            @include media(bp-lt-sm) {
                padding: 0;
            }
        }
    }

    .title {
        margin: 0;
        font-size: 22px;

        @include media(bp-lt-sm) {
            font-size: 18px;
        }
    }

    p {
        &:nth-of-type(1) {
            @include media(bp-lt-sm) {
                margin-top: 5px;
            }
        }
    }
}
