.rm-open-body .rm-modal {
    .wc-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 350px;
        justify-content: space-between;

        h2 {
            text-transform: uppercase;
            font-size: 36px;
            /* margin-bottom: -20px; */
        }

        .btn {
            min-width: 230px;
            font-size: 17px;
        }

        .wc-lnk {
            color: $dark-gray;
            font-size: 18px;
            margin-top: 15px;
            text-decoration: underline;
        }

        .map-bloc {
            display: flex;
            margin-right: 10px;
            padding: 2%;
            @include media(bp-lt-md) {
                flex-direction: column;
            }
            &.bg-grey {
                padding: 3%;
            }
        }

        .store-copy {
            margin-left: 10px;

            span {
                font-size: 22px;
            }
        }

        &.store-bloc {
            h3 {
                margin: 0;
            }

            > p {
                margin: 0px 0px 20px;
                font-size: 16px;
                width: 80%;
            }

            .btn {
                margin-top: 50px;
            }
        }
    }
}
