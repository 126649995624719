/* accessibility.styl */

.u-coral-screenReaderOnly,
.sr-only {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    width: 1px !important;
    height: 1px !important;
    margin: 0 -1px -1px 0 !important;
}

/* FROM AEM CORAL IMPORT */
/* layout.styl */

[hidden] {
    display: none !important;
}

/* link.styl */

.coral-Link {
    color: #326ec8;
    text-decoration: none;
}

.coral-Link:hover {
    text-decoration: underline;
}

.coral-Link:focus {
    outline: none;
    outline-offset: 0;
    text-decoration: underline;
}

.coral-Link.is-disabled {
    opacity: 0.15;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=15)';
    filter: alpha(opacity=15);
}

/* heading.styl */

.coral-Heading {
    margin: 0;
    color: #3c3c3c;
}

.coral-Heading--1 {
    @include font-body;
    font-weight: 300;
    font-style: normal;
    font-size: 1.375rem;
    line-height: 1.625rem;
    margin-bottom: 0.5rem;
}

.coral-Heading--1:not(:first-child) {
    margin-top: 1.25rem;
}

.coral-Heading--2 {
    @include font-body;
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.125rem;
}

.coral-Heading--2:not(:first-child) {
    margin-top: 0.625rem;
}

.coral-Heading--3,
.coral-Heading--4,
.coral-Heading--5,
.coral-Heading--6 {
    @include font-body;
    font-weight: 700;
    font-style: normal;
}

.coral-Heading--3 {
    font-size: 1.125rem;
    line-height: 1.25rem;
}

.coral-Heading--4 {
    font-size: 0.8125rem;
    line-height: 1rem;
}

.coral-Heading--5 {
    font-size: 0.8125rem;
    line-height: 1rem;
}

.coral-Heading--6 {
    text-transform: uppercase;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: #969696;
}

/* icon.styl */

.coral-Icon {
    display: inline-block;
    font-family: AdobeIcons;
    font-style: normal;
    font-weight: normal;
    line-height: inherit;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.125rem;
}

.coral-Icon--sizeXS {
    font-size: 0.75rem;
}

.coral-Icon--sizeS {
    font-size: 1.125rem;
}

.coral-Icon--sizeM {
    font-size: 1.5rem;
}

.coral-Icon--sizeL {
    font-size: 2.25rem;
}

@font-face {
    font-family: 'AdobeIcons';
    src: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/icon/AdobeIcons.eot?24f48ec74c6d8d1fda760acb929c99f5');
    src:
        url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/icon/AdobeIcons.eot?#iefix')
            format('embedded-opentype'),
        url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/icon/AdobeIcons.woff?24f48ec74c6d8d1fda760acb929c99f5')
            format('woff'),
        url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/icon/AdobeIcons.ttf?24f48ec74c6d8d1fda760acb929c99f5')
            format('truetype');
}

.coral-Icon--close:before {
    content: '\f16e';
}

.coral-Icon--closeCircle:before {
    content: '\f16f';
}

/* button.styl */

.coral-Button {
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 6.875rem;
    height: 2.375rem;
    margin: 0;
    padding: 0.625rem 0.9375rem;
    vertical-align: top;
    border: 0.0625rem solid;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
    border-color: #d0d0d0;
    background-color: #fafafa;
    @include font-body;
    font-weight: 700;
    font-style: normal;
    color: #4b4b4b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    font-size: 0.9375rem;
    text-decoration: none;
    text-align: center;
    line-height: 1rem;
    cursor: pointer;
    white-space: nowrap;
}

.coral-Button:disabled,
.coral-Button.is-disabled {
    border-color: #e6e6e6;
    background-color: #f0f0f0;
    color: #b4b4b4;
    text-shadow: none;
    cursor: default;
}

.coral-Button:not(:disabled):not(.is-disabled):focus {
    outline: none;
    -webkit-box-shadow: 0 0 0.1875rem #326ec8;
    box-shadow: 0 0 0.1875rem #326ec8;
    border: 0.0625rem solid #326ec8;
    background-color: $white;
    text-decoration: none;
}

.coral-Button:not(:disabled):not(.is-disabled):hover {
    background-color: $white;
    text-decoration: none;
}

.coral-Button:not(:disabled):not(.is-disabled).is-selected,
.coral-Button:not(:disabled):not(.is-disabled):active {
    background-color: #ebebeb;
}

.coral-Button--primary {
    border-color: #1e5fbe;
    background-color: #326ec8;
    color: $white;
    text-shadow: 0 -0.0625rem 0 #1e5fbe;
}

.coral-Button--primary:not(:disabled):not(.is-disabled):focus {
    background-color: #4178cd;
}

.coral-Button--primary:not(:disabled):not(.is-disabled):hover {
    background-color: #4178cd;
}

.coral-Button--primary:not(:disabled):not(.is-disabled).is-selected,
.coral-Button--primary:not(:disabled):not(.is-disabled):active {
    background-color: #1e5fbe;
}

.coral-Button .coral-Icon {
    vertical-align: middle;
    margin-right: 0.3125rem;
    position: relative;
    top: -0.0625rem;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .coral-Button .coral-Icon {
        position: initial;
        top: auto;
    }
}

/* minimal-button.styl */

.coral-MinimalButton {
    cursor: pointer;
    background: none;
    padding: 0;
    border-width: 0.0625rem;
    border-color: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.coral-MinimalButton:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0.1875rem #326ec8;
    box-shadow: 0 0 0.1875rem #326ec8;
    border: 0.0625rem solid #326ec8;
}

.coral-MinimalButton:not(:disabled):not(.is-disabled):active .coral-MinimalButton-icon {
    color: #326ec8;
}

.coral-MinimalButton:not(:disabled):not(.is-disabled):hover .coral-MinimalButton-icon {
    color: rgba(0, 0, 0, 0.8);
}

.coral-MinimalButton:disabled,
.coral-MinimalButton.is-disabled {
    color: #b4b4b4;
    text-shadow: none;
    cursor: default;
}

.coral-MinimalButton:disabled > .coral-MinimalButton-icon,
.coral-MinimalButton.is-disabled > .coral-MinimalButton-icon {
    color: rgba(0, 0, 0, 0.15);
}

.coral-MinimalButton-icon {
    color: rgba(0, 0, 0, 0.6);
}

/* wait.styl */

.coral-Wait {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait_16.gif')
        no-repeat 0 0;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    -webkit-animation: wait 1.6s step-end infinite;
    -moz-animation: wait 1.6s step-end infinite;
    -o-animation: wait 1.6s step-end infinite;
    -ms-animation: wait 1.6s step-end infinite;
    animation: wait 1.6s step-end infinite;
}

.coral-Wait--center,
.coral-Wait--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
}

.coral-Wait--large {
    width: 2rem;
    height: 2rem;
    background-image: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait_32.gif');
}

.coral-Wait--large.coral-Wait--center,
.coral-Wait--large.coral-Wait--centered {
    margin-top: -1rem;
    margin-left: -1rem;
}

@-moz-keyframes wait {
    0%,
    100% {
        background-position: 0 0;
        background-image: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait.svg');
    }

    12.5% {
        background-position: 14.28571% 0;
    }

    25% {
        background-position: 28.57143% 0;
    }

    37.5% {
        background-position: 42.85714% 0;
    }

    50% {
        background-position: 57.14286% 0;
    }

    62.5% {
        background-position: 71.42857% 0;
    }

    75% {
        background-position: 85.71429% 0;
    }

    87.5% {
        background-position: 100% 0;
    }
}

@-webkit-keyframes wait {
    0%,
    100% {
        background-position: 0 0;
        background-image: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait.svg');
    }

    12.5% {
        background-position: 14.28571% 0;
    }

    25% {
        background-position: 28.57143% 0;
    }

    37.5% {
        background-position: 42.85714% 0;
    }

    50% {
        background-position: 57.14286% 0;
    }

    62.5% {
        background-position: 71.42857% 0;
    }

    75% {
        background-position: 85.71429% 0;
    }

    87.5% {
        background-position: 100% 0;
    }
}

@-o-keyframes wait {
    0%,
    100% {
        background-position: 0 0;
        background-image: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait.svg');
    }

    12.5% {
        background-position: 14.28571% 0;
    }

    25% {
        background-position: 28.57143% 0;
    }

    37.5% {
        background-position: 42.85714% 0;
    }

    50% {
        background-position: 57.14286% 0;
    }

    62.5% {
        background-position: 71.42857% 0;
    }

    75% {
        background-position: 85.71429% 0;
    }

    87.5% {
        background-position: 100% 0;
    }
}

@-ms-keyframes wait {
    0%,
    100% {
        background-position: 0 0;
        background-image: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait.svg');
    }

    12.5% {
        background-position: 14.28571% 0;
    }

    25% {
        background-position: 28.57143% 0;
    }

    37.5% {
        background-position: 42.85714% 0;
    }

    50% {
        background-position: 57.14286% 0;
    }

    62.5% {
        background-position: 71.42857% 0;
    }

    75% {
        background-position: 85.71429% 0;
    }

    87.5% {
        background-position: 100% 0;
    }
}

@keyframes wait {
    0%,
    100% {
        background-position: 0 0;
        background-image: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-resources/v1/resources/components/wait/wait.svg');
    }

    12.5% {
        background-position: 14.28571% 0;
    }

    25% {
        background-position: 28.57143% 0;
    }

    37.5% {
        background-position: 42.85714% 0;
    }

    50% {
        background-position: 57.14286% 0;
    }

    62.5% {
        background-position: 71.42857% 0;
    }

    75% {
        background-position: 85.71429% 0;
    }

    87.5% {
        background-position: 100% 0;
    }
}

.wait {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;

    .coral-Wait--center {
        margin-top: -2rem;
    }

    .coral-Wait {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100%;
        vertical-align: middle;

        animation: alternate 1s fadeInOut infinite;

        @keyframes fadeInOut {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    }

    .message {
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;

        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        color: #666;
        /* $brand-color-red; */
        margin: 5px 0;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: bold;

        .load-price {
            display: inline-block;
        }

        .load-quote {
            display: none;
        }
    }
}

/* popover.styl */

.coral-Popover {
    position: absolute;
    display: none;
    z-index: 1480;
    top: -9999px;
    left: -9999px;
    background-color: #c8c8c8;
    border: 0.125rem solid #c8c8c8;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
    padding: 0;
    min-width: 9.375rem;
    max-width: 40.875rem;
    outline: none;
}

.coral-Popover.is-focused {
    z-index: 1481;
}

.coral-Popover-arrow {
    display: none;
}

/* modal.styl */

.coral-Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1440;
    display: none;
    min-width: 20rem;
    max-width: 90%;
    outline: none;
    border: 0.125rem solid #c8c8c8;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
    background-color: $white;

    &.wait {
        position: fixed;
    }
}

.icon-navigation:before {
    content: '\f29a';
}

.icon-check:before {
    content: '\f160';
}

.icon-close:before {
    content: '\f16e';
}

.coral-Tooltip.is-hidden {
    display: none;
}

#duplicate-appointment-warning-modal,
#past-appointment-warning-modal,
#general-appointment-warning-modal,
#system-error-modal {
    width: 90%;
    max-width: 500px;
    text-align: center;

    .btn {
        width: 100%;
    }

    .coral-Modal-body {
        width: 100%;
        padding: 0 20px 10px;
    }

    .coral-Modal-footer {
        padding: 10px 20px 20px;
    }
}

// overrides coral modal during feature flag testing
.bsro-ui-chatbot-v1 {
    .contact-us {
        &[class~='coral-Modal.is-open'] {
            overflow: scroll;
        }
        .coral-Modal,
        .coral-Modal-backdrop {
            display: none !important;
        }
    }
}
