@import '~common/scss/shared/shared';

.coral-Popover {
    .horizontal-vehicle-filter {
        height: auto;
    }
}

.horizontal-vehicle-filter {
    @include clearfix;
    @include gradient-top();

    padding: 50px 0;

    input,
    select,
    button {
        width: 100%;
    }

    input {
        line-height: 1.45;
    }

    .heading,
    .subhead {
        text-align: center;
    }

    .heading {
        margin: 0 10px;
    }

    .subhead {
        margin: 0 0 12px;
    }

    .filters-wrap {
        margin-top: 15px;
        background-color: $dark-gray;
    }

    .filters-wrap-inner {
        @include clearfix;
        width: 96%;
        margin: 0 auto;
        padding: 25px 0 0;

        @include media(bp-lt-sm) {
            padding-bottom: 10px;
        }
    }

    .col {
        width: 16.6666%;
        padding: 0 6px 20px;

        @include media(bp-gt-lg) {
            width: 33.3333%;
        }

        @include media(bp-lt-sm) {
            width: 100%;
            padding: 0 6px 15px;
        }
    }

    .btn {
        padding: 6px 0;

        @include media(bp-lt-sm) {
            padding: 10px 0;
            font-size: 20px;
        }
    }

    .disable {
        opacity: 0.65;
        pointer-events: none;
    }

    &.seven-col {
        .col {
            width: 14.2857143%;

            @include media(bp-gt-lg) {
                width: 33.3333%;
            }

            @include media(bp-lt-sm) {
                width: 100%;
            }
        }
    }

    .zipcodeError {
        color: $brand-color-red;
    }
}
