@use 'sass:math';
@import '~common/scss/shared/shared';

.product-results-columns {
    max-width: 1300px;
    margin: 30px auto;
    padding: 0 15px;

    &.batteries {
        .label,
        .price {
            opacity: 0;
            transition: opacity 0.5s ease-out;
        }
    }

    .column-container {
        @include outer-container;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        &.three-column {
            .column {
                width: percentage(math.div(414, 1300));

                &:nth-child(3n + 1) {
                    margin: 0 percentage(math.div(29, 1300)) 20px 0;
                }

                &:nth-child(3n + 2) {
                    margin: 0 percentage(math.div(29, 1300)) 20px 0;
                }

                &:nth-child(3n) {
                    margin: 0 0 20px;
                }
            }
        }

        &.two-column {
            .column {
                margin: 0 0 0 percentage(math.div(30, 1300));
                width: percentage(math.div(635, 1300));

                &:first-child {
                    margin: 0;
                }
            }
        }
    }

    .column {
        .disclaimer {
            margin: 20px 25px 40px;
        }
    }

    .title {
        text-align: center;
    }

    .img-container {
        margin-bottom: 25px;
        padding: 0 15px;
        text-align: center;
    }

    .price-container {
        padding: 20px 25px;
        text-align: center;
    }

    .label {
        margin: 0 0 5px;
    }

    .price {
        line-height: 0.9em;

        span {
            line-height: 1em;
            vertical-align: top;
        }
    }

    .regular-price {
        margin: 10px 0;
    }

    .copy-container {
        margin: 40px 25px 20px;

        ul {
            margin: 0 0 0 1.2em;
            padding: 0;
            list-style-position: outside;
        }
    }

    > .disclaimer {
        margin: 40px 0;
    }

    .disclaimer-controls {
        display: none;
    }

    .mobile-only {
        display: none;
    }

    @include media(bp-lt-md) {
        padding: 0;

        .column-container {
            &.three-column,
            &.two-column {
                .column {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        .column {
            .disclaimer {
                display: none;
            }
        }

        .price-container {
            padding: 20px 15px;
        }

        .copy-container {
            margin-right: 15px;
            margin-left: 15px;
        }

        > .disclaimer {
            padding: 0 15px;
        }

        .disclaimer-controls {
            display: block;

            a {
                display: none;

                &.active {
                    display: block;
                }
            }
        }

        .disclaimer-content {
            display: none;

            &.active {
                display: block;
            }
        }

        .mobile-only {
            display: block;
        }
    }
}
