.rm-open-body .rm-modal,
.my-account {
    .remove-vehicle {
        text-align: center;
        @include media(bp-gt-sm) {
            height: 150px;
            min-height: 150px;
            min-width: 400px;
            width: 400px;
        }
    }
}

