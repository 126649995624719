@import '~common/scss/shared/shared';
@import '~fcac/scss/shared/fonts';

.accordion {
    &.mobile-only {
        @include media(bp-gt-sm) {
            .folder {
                cursor: default;

                &:after {
                    display: none;
                }

                &.mobile-only {
                    display: none;
                }
            }

            .item {
                overflow: visible;
                max-height: 1000px;
                opacity: 1;
            }
        }
    }

    .folder {
        display: block;
        position: relative;
        cursor: pointer;

        &:after {
            position: absolute;
            display: block;
            top: 50%;
            right: 15px;
            content: '+';
            line-height: 0;
        }

        &.active {
            &:after {
                content: '–';
            }
        }
    }

    .item {
        overflow: hidden;
        max-height: 0;
        opacity: 0;

        &.active {
            max-height: 1000px;
            opacity: 1;
        }
    }
}
