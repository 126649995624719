.rm-open-body .rm-modal,
.my-account {
    .vehicle-add {
        .desktop-form {
            @include media(bp-gt-sm) {
                min-width: 730px;
            }
        }
    }
    // .vehicle-add-model {
    //     .model-select-segment {
    //         background-color: $white;
    //         border: solid thin black;
    //         h3 {
    //             margin-top: 10px;
    //             margin-bottom: 5px;
    //         }
    //         > .fl-input-container.form-wrap {
    //             margin-top: 0;
    //         }
    //     }
    //     .next {
    //         float: right;
    //         margin-top: 15px;
    //         width: 150px;
    //     }
    //     select.multi-line {
    //         border: none;
    //         color: $white;
    //         padding-top: 0;
    //         padding-left: 0;
    //         padding-right: 0;
    //         --webkit-user-select: none;
    //         overflow-x: hidden;
    //         overflow-y: auto;
    //         option {
    //             font-size: 14px;
    //             height: 2em;
    //             line-height: 2em;
    //             padding-top: 6px;
    //             &:checked, &:checked:not(:focus) {
    //                 background: #0079c2 linear-gradient(0deg, #0079c2 0%, #0079c2 100%);
    //                 color: $white !important;
    //             }
    //             &:not(:checked) { color: $black; }
    //         }
    //         &:not(:focus) {
    //             option:checked {
    //                 background: #A5D8F8 linear-gradient(0deg, #A5D8F8 0%, #A5D8F8 100%);
    //             }
    //         }
    //     }
    //     input[type=radio] {
    //         display:none;
    //         & ~ label {
    //             display: block;
    //             line-height: 2em;
    //             padding: 0;
    //             width: 100%;
    //         }
    //         &:checked ~ label {
    //             background-color: #0079c2;
    //             color: $white;
    //         }
    //     }
    //     .mobile-form {
    //         margin-bottom: 35px;
    //         @include media(bp-lt-md) {
    //             height: calc(100% - 150px);
    //             overflow-y: auto;
    //             -webkit-overflow-scrolling: touch;
    //         }
    //         .model-select-segment {
    //             text-align: center;
    //             @include media(bp-lt-md) {
    //                 /* height: 375px; */
    //                 overflow-y: auto;
    //                 -webkit-overflow-scrolling: touch;
    //             }
    //             h3 {
    //                 margin-top: 0;
    //                 padding-top: 10px;
    //             }
    //             > div {
    //                 max-height: 330px;
    //                 overflow-y: scroll;
    //                 -webkit-overflow-scrolling: touch;
    //             }
    //             label {
    //                 display: block;
    //                 line-height: 2em;
    //                 padding: 0;
    //                 width: 100%;
    //                 &:focus {
    //                     background-color: #0079c2;
    //                     color: $white;
    //                 }
    //             }
    //             &.selected {
    //                 border-bottom-color: $white;
    //                 h4 {
    //                     background-color: #0079c2;
    //                     color: $white;
    //                     margin: 0;
    //                     padding-bottom: 1em;
    //                     padding-top: 1em;
    //                 }
    //             }
    //             &:not(:first-child) {
    //                 border-top: none;
    //             }
    //         }
    //     }
    //     .tablet-form {
    //         min-width: 630px;
    //         .model-select-segment {
    //             > div {
    //                 max-height: 350px;
    //                 overflow: scroll;
    //                 -webkit-overflow-scrolling: touch;
    //             }
    //             padding: 0;
    //             h3, label {
    //                 padding-left: 15px;
    //             }
    //         }
    //     }
    //     .desktop-form {
    //         .model-select-segment {
    //             padding: 0 10px 0 15px;
    //         }
    //     }
    //     .desktop-form,
    //     .tablet-form {
    //         display: flex;
    //         flex-direction: row;
    //         max-width: 800px;
    //         .model-select-segment {
    //             background-color: $white;
    //             border: solid thin black;
    //             flex: 1 25%;
    //             width: 25%;
    //             h3 {
    //                 margin-top: 10px;
    //                 margin-bottom: 5px;
    //             }
    //             &.year, &.make, &.model {
    //                 border-right: solid thin #ccc;
    //             }
    //             &.make, &.model, &.trim {
    //                 border-left: none;
    //             }
    //             > .fl-input-container.form-wrap {
    //                 margin-top: 0;
    //             }
    //         }
    //     }
    //     .fl-input-bar { display: none; }
    // }
}
