input,
textarea {
    border: solid 1px $form-field-border-color;
}

input {
    padding: 5px 8px;
}

textarea {
    padding: 8px;
}

.form-field {
    position: relative;

    label {
        @include media(bp-lt-sm) {
            position: absolute;
            top: 6px;
            left: 10px;
            -webkit-transition:
                top 0.5s,
                left 0.5s,
                font-size 0.5s,
                -webkit-transform 0.5s;
            transition:
                top 0.5s,
                left 0.5s,
                font-size 0.5s,
                transform 0.5s;
            color: #666;
        }
    }

    input,
    textarea {
        &:focus {
            @include media(bp-lt-sm) {
                padding-top: 25px;
            }
        }
    }

    &.expand {
        label {
            font-size: 10px;
        }
    }
}

.custom-select {
    padding: 0 0 1px;
    margin: 0;
    border: 1px solid $form-field-border-color;
    width: auto;
    border-radius: 0;
    overflow: hidden;
    background-color: $white;
    background-position: 95% 50%;
    background-repeat: no-repeat;
    @include icon('select-arrow', red, 16px);
    // background: $white url("http://www.scottgood.com/jsg/blog.nsf/images/arrowdown.gif") no-repeat 95% 50%;

    select {
        padding: 5px 8px;
        width: 110%;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            outline: none;
        }
    }
}

.custom-checkbox {
    input[type='checkbox'] {
        display: none;

        &:checked + label:before {
            @include icon('checkmark', $black, auto);
        }
    }

    label {
        cursor: pointer;

        &.active::before {
            @include icon('checkmark', $black, auto);
        }

        &:before {
            display: inline-block;
            width: 0;
            height: 0;
            margin-right: 8px;
            padding: 8px;
            content: '';
            vertical-align: top;
        }

        &:hover,
        &:active {
            &::before {
                background-color: #ebebeb;
            }
        }
    }
}

.custom-radio {
    input[type='radio'] {
        display: none;

        &:checked + label:before {
            @include icon('radio-checked', $black, 16px);
        }
    }

    label {
        cursor: pointer;

        &:before {
            display: inline-block;
            width: 0;
            height: 0;
            margin: 1px 12px 0 0;
            padding: 6px;
            border-radius: 8px;
            content: '';
            vertical-align: top;
        }

        &:hover,
        &:active {
            &::before {
                background: #ebebeb;
            }
        }
    }
}
