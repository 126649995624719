/* popover.styl */

.coral-Popover-content {
    position: relative;
    background-color: $white;
    -webkit-border-radius: 0.0625rem;
    border-radius: 0.0625rem;
}

.coral-Popover-content + .coral-Popover-content {
    margin-top: 0.125rem;
}

/* tooltip.styl */

.coral-Tooltip {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    z-index: 1500;
    min-width: 2rem;
    min-height: 2rem;
    max-width: 15.625rem;
    max-height: 15.625rem;
    margin: 0.5rem;
    padding: 0.5rem 0.625rem;
    background-position: 0.5rem 0.5rem;
    background-repeat: no-repeat;
    -webkit-background-size: 1rem 1rem;
    -moz-background-size: 1rem 1rem;
    background-size: 1rem 1rem;
    color: #4b4b4b;
    vertical-align: middle;
    border: 0.0625rem solid;

    > ul {
        list-style-type: disc;
        padding-left: 1.5em;

        > li {
            padding: 4px 0;
        }
    }
}

.coral-Tooltip:before,
.coral-Tooltip:after {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.coral-Tooltip--positionRight:before,
.coral-Tooltip--positionRight:after {
    top: 50%;
    right: 100%;
}

.coral-Tooltip--positionRight:before {
    border-width: 0.53125rem;
    margin-top: -0.53125rem;
}

.coral-Tooltip--positionRight:after {
    border-width: 0.4375rem;
    margin-top: -0.4375rem;
}

.coral-Tooltip--positionLeft:before,
.coral-Tooltip--positionLeft:after {
    top: 50%;
    left: 100%;
}

.coral-Tooltip--positionLeft:before {
    border-width: 0.53125rem;
    margin-top: -0.53125rem;
}

.coral-Tooltip--positionLeft:after {
    border-width: 0.4375rem;
    margin-top: -0.4375rem;
}

.coral-Tooltip--positionBelow:before,
.coral-Tooltip--positionBelow:after {
    left: 50%;
    bottom: 100%;
}

.coral-Tooltip--positionBelow:before {
    border-width: 0.53125rem;
    margin-left: -0.53125rem;
}

.coral-Tooltip--positionBelow:after {
    border-width: 0.4375rem;
    margin-left: -0.4375rem;
}

.coral-Tooltip--positionAbove:before,
.coral-Tooltip--positionAbove:after {
    left: 50%;
    top: 100%;
}

.coral-Tooltip--positionAbove:before {
    border-width: 0.53125rem;
    margin-left: -0.53125rem;
}

.coral-Tooltip--positionAbove:after {
    border-width: 0.4375rem;
    margin-left: -0.4375rem;
}

.coral-Tooltip--info {
    background-color: #87beff;
    border-color: #78afff;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
}

.coral-Tooltip--info.coral-Tooltip--positionBelow:before {
    border-bottom-color: #78afff;
}

.coral-Tooltip--info.coral-Tooltip--positionBelow:after {
    border-bottom-color: #87beff;
}

.coral-Tooltip--info.coral-Tooltip--positionAbove:before {
    border-top-color: #78afff;
}

.coral-Tooltip--info.coral-Tooltip--positionAbove:after {
    border-top-color: #87beff;
}

.coral-Tooltip--info.coral-Tooltip--positionRight:before {
    border-right-color: #78afff;
}

.coral-Tooltip--info.coral-Tooltip--positionRight:after {
    border-right-color: #87beff;
}

.coral-Tooltip--info.coral-Tooltip--positionLeft:before {
    border-left-color: #78afff;
}

.coral-Tooltip--info.coral-Tooltip--positionLeft:after {
    border-left-color: #87beff;
}

.coral-Tooltip--error {
    background-color: #fa7369;
    border-color: #fa695f;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
}

.coral-Tooltip--error.coral-Tooltip--positionBelow:before {
    border-bottom-color: #fa695f;
}

.coral-Tooltip--error.coral-Tooltip--positionBelow:after {
    border-bottom-color: #fa7369;
}

.coral-Tooltip--error.coral-Tooltip--positionAbove:before {
    border-top-color: #fa695f;
}

.coral-Tooltip--error.coral-Tooltip--positionAbove:after {
    border-top-color: #fa7369;
}

.coral-Tooltip--error.coral-Tooltip--positionRight:before {
    border-right-color: #fa695f;
}

.coral-Tooltip--error.coral-Tooltip--positionRight:after {
    border-right-color: #fa7369;
}

.coral-Tooltip--error.coral-Tooltip--positionLeft:before {
    border-left-color: #fa695f;
}

.coral-Tooltip--error.coral-Tooltip--positionLeft:after {
    border-left-color: #fa7369;
}

.coral-Tooltip--notice {
    background-color: #fad269;
    border-color: #f5c346;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
}

.coral-Tooltip--notice.coral-Tooltip--positionBelow:before {
    border-bottom-color: #f5c346;
}

.coral-Tooltip--notice.coral-Tooltip--positionBelow:after {
    border-bottom-color: #fad269;
}

.coral-Tooltip--notice.coral-Tooltip--positionAbove:before {
    border-top-color: #f5c346;
}

.coral-Tooltip--notice.coral-Tooltip--positionAbove:after {
    border-top-color: #fad269;
}

.coral-Tooltip--notice.coral-Tooltip--positionRight:before {
    border-right-color: #f5c346;
}

.coral-Tooltip--notice.coral-Tooltip--positionRight:after {
    border-right-color: #fad269;
}

.coral-Tooltip--notice.coral-Tooltip--positionLeft:before {
    border-left-color: #f5c346;
}

.coral-Tooltip--notice.coral-Tooltip--positionLeft:after {
    border-left-color: #fad269;
}

.coral-Tooltip--success {
    background-color: #a5d273;
    border-color: #8cc35f;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
}

.coral-Tooltip--success.coral-Tooltip--positionBelow:before {
    border-bottom-color: #8cc35f;
}

.coral-Tooltip--success.coral-Tooltip--positionBelow:after {
    border-bottom-color: #a5d273;
}

.coral-Tooltip--success.coral-Tooltip--positionAbove:before {
    border-top-color: #8cc35f;
}

.coral-Tooltip--success.coral-Tooltip--positionAbove:after {
    border-top-color: #a5d273;
}

.coral-Tooltip--success.coral-Tooltip--positionRight:before {
    border-right-color: #8cc35f;
}

.coral-Tooltip--success.coral-Tooltip--positionRight:after {
    border-right-color: #a5d273;
}

.coral-Tooltip--success.coral-Tooltip--positionLeft:before {
    border-left-color: #8cc35f;
}

.coral-Tooltip--success.coral-Tooltip--positionLeft:after {
    border-left-color: #a5d273;
}

.coral-Tooltip--inspect {
    color: $white;
    background-color: #5a5a5a;
    border-color: #414141;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
}

.coral-Tooltip--inspect.coral-Tooltip--positionBelow:before {
    border-bottom-color: #414141;
}

.coral-Tooltip--inspect.coral-Tooltip--positionBelow:after {
    border-bottom-color: #5a5a5a;
}

.coral-Tooltip--inspect.coral-Tooltip--positionAbove:before {
    border-top-color: #414141;
}

.coral-Tooltip--inspect.coral-Tooltip--positionAbove:after {
    border-top-color: #5a5a5a;
}

.coral-Tooltip--inspect.coral-Tooltip--positionRight:before {
    border-right-color: #414141;
}

.coral-Tooltip--inspect.coral-Tooltip--positionRight:after {
    border-right-color: #5a5a5a;
}

.coral-Tooltip--inspect.coral-Tooltip--positionLeft:before {
    border-left-color: #414141;
}

.coral-Tooltip--inspect.coral-Tooltip--positionLeft:after {
    border-left-color: #5a5a5a;
}

[data-init~='quicktip']:focus {
    outline: none;
}

/* modal.styl */

.coral-Modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1420;
    background-color: rgba(0, 0, 0, 0.2);
}

.coral-Modal-header {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 2.875rem;
    background: #fafafa;
    border-bottom: 0.0625rem solid #e9e9e9;
    -webkit-border-radius: 0.0625rem 0.0625rem 0 0;
    border-radius: 0.0625rem 0.0625rem 0 0;
    outline: none;
}

.coral-Modal-title {
    position: absolute;
    left: 0.9375rem;
    right: 2.375rem;
    line-height: 2.875rem !important;
    outline: none;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.coral-Modal-title,
.coral-Modal-title:not(:first-child) {
    margin: 0;
}

.coral-Modal-typeIcon {
    position: absolute;
    top: 0.8125rem;
    left: 0.9375rem;
}

.coral-Modal-closeButton {
    padding: 0.875rem;
    float: right;

    &.icon-close-button {
        @include icon('close-button');
    }
}

.coral-Modal-closeButton .coral-Icon {
    color: rgba(0, 0, 0, 0.6);
}

.coral-Modal-closeButton:hover .coral-Icon {
    color: rgba(0, 0, 0, 0.8);
}

.coral-Modal-closeButton:focus {
    border: 0.0625rem solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.coral-Modal-closeButton:focus .coral-Icon {
    color: #326ec8;
    text-shadow: 0 0 0.1875rem #326ec8;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Glow(color=#326ec8,strength=3)';
}

.coral-Modal-body {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 1px;
    outline: none;
}

.coral-Modal-footer {
    zoom: 1;
    padding: 1rem;
    -webkit-border-radius: 0 0 0.0625rem 0.0625rem;
    border-radius: 0 0 0.0625rem 0.0625rem;
    outline: none;
    text-align: right;
}

.coral-Modal-footer:before,
.coral-Modal-footer:after {
    content: '';
    display: table;
}

.coral-Modal-footer:after {
    clear: both;
}

.coral-Modal-footer .coral-Button + .coral-Button {
    margin-left: 0.625rem;
}

.coral-Modal--fullscreen {
    max-width: none;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    border: none;
}

.coral-Modal--fullscreen .coral-Modal-header {
    width: 100%;
}

.coral-Modal--fullscreen .coral-Modal-body {
    position: absolute;
    top: 2.875rem;
    bottom: 0;
    width: 100%;
}

.coral-Modal--fullscreen .coral-Modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.coral-Modal--error .coral-Modal-header,
.coral-Modal--notice .coral-Modal-header,
.coral-Modal--success .coral-Modal-header,
.coral-Modal--help .coral-Modal-header,
.coral-Modal--info .coral-Modal-header {
    border-bottom: 0.125rem solid #e9e9e9;
}

.coral-Modal--error .coral-Modal-header .coral-Modal-title,
.coral-Modal--notice .coral-Modal-header .coral-Modal-title,
.coral-Modal--success .coral-Modal-header .coral-Modal-title,
.coral-Modal--help .coral-Modal-header .coral-Modal-title,
.coral-Modal--info .coral-Modal-header .coral-Modal-title {
    left: 2.5rem;
}

.coral-Modal--error .coral-Modal-header {
    border-bottom-color: #fa7369;
}

.coral-Modal--error .coral-Modal-typeIcon {
    color: #fa695f;
}

.coral-Modal--notice .coral-Modal-header {
    border-bottom-color: #fad269;
}

.coral-Modal--notice .coral-Modal-typeIcon {
    color: #f5c346;
}

.coral-Modal--success .coral-Modal-header {
    border-bottom-color: #a5d273;
}

.coral-Modal--success .coral-Modal-typeIcon {
    color: #8cc35f;
}

.coral-Modal--help .coral-Modal-header,
.coral-Modal--info .coral-Modal-header {
    border-bottom-color: #87beff;
}

.coral-Modal--help .coral-Modal-typeIcon,
.coral-Modal--info .coral-Modal-typeIcon {
    color: #78afff;
}

/* START OVERRIDES */

// MODAL WINDOW
body[class~='coral-Modal.is-open'] {
    overflow: hidden;
}

div.coral-Modal {
    z-index: 9100; // top-nav is 9000
    margin: 0 !important;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    max-width: none;
    overflow: hidden;

    &.coral-Modal--warning {
        width: inherit;
        height: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .coral-Modal-header {
            border-bottom: 0px;
        }

        .coral-Modal-footer {
            .coral-Button.btn {
                @include media(bp-lt-md) {
                    width: 100%;
                    margin: 0 auto 10px;
                }

                &[data-cancel] {
                    text-transform: inherit;
                }
            }
        }
    }
}

.coral-Modal-header {
    width: 100%;
    background-color: $white;
    border-bottom: 0;
    z-index: 1;
}

.coral-Modal-body {
    height: 100%;
}

.coral-Modal-backdrop {
    z-index: 9001;
}

div.coral-Tooltip {
    font-size: 10px !important;
    line-height: 1.1em;
}

/* POPOVER */
.coral-Popover {
    // display: block
    position: relative;
    min-width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    border: 0 !important;
    background: transparent;
    z-index: 998 !important;
}

.coral-Popover-content {
    background-color: transparent;
}

/* BUTTONS */
.coral-Button {
    &:hover,
    &:active {
        background-color: #a61704;
        color: white;
    }

    &:not(:disabled) {
        &:not(.is-disabled) {
            &:hover {
                background-color: #a61704;
                color: white;
            }
        }
    }
}

#get-vehicle-modal,
#will-this-fit {
    &.coral-Modal {
        @media only screen and (max-width: 768px) and (orientation: landscape) {
            width: 85% !important;
            max-width: 520px !important;

            .tire-service-widget {
                width: 100% !important;
                height: auto !important;

                .form {
                    .col-6 {
                        width: 49%;
                        margin-right: 4px;
                    }
                }

                .form_left {
                    width: 100%;
                    float: left;
                }
                .form_right {
                    width: 49%;
                    float: right;
                }
            }
        }
    }
}
#get-vehicle-modal .refresh-modal__close {
    top: 10px;
    right: 10px;
}
