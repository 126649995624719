@use 'sass:math';
@import '~common/scss/shared/shared';

.local-store {
    background-color: #ebebeb;

    @include media(bp-lt-sm) {
        background-color: transparent;
    }

    .inner {
        @include clearfix;
        position: relative;

        @include media(bp-lt-sm) {
            max-width: $container-max-width;
            padding: 0 4% 4%;
        }
    }

    .schedule-cta-phone {
        display: none;
        clear: both;
        padding: 20px 0 40px;
        @include media(bp-lt-sm) {
            display: block;
        }
    }

    &.reskin {
        .cta-store-bar {
            display: block;
            width: 100%;
            height: 57px;
            text-align: center;
            font-size: 16px;

            .icon-map-marker {
                width: 18px;
                height: 18px;
                margin-top: -5px;
            }
        }

        .find-store-cta,
        .close-store-cta,
        .store-info {
            margin: 0 auto;
            padding: 0;
            height: 100%;

            a,
            .btn,
            > span {
                display: table-cell;
                vertical-align: middle;
            }

            a {
                text-decoration: none;
                color: $dark-gray;
            }

            .btn {
                background-color: transparent;
                color: $dark-gray;
            }
        }

        .close-store-cta,
        .store-info {
            display: none;
        }

        .find-store-cta {
            display: table;
            a {
                span:not(.icon-map-marker) {
                    &:after {
                        content: '\203A';
                        font-size: 42px;
                        position: relative;
                        top: 6px;
                        right: -3px;
                    }
                }
            }
        }

        .store-info {
            float: none;

            .store-info-toggle {
                .close {
                    display: none;
                }

                .store {
                    position: relative;

                    strong {
                        text-transform: uppercase;

                        @include media(bp-lt-sm) {
                            display: block;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 3px;
                        right: -25px;
                        border-width: 8px 8px 8px 8px;
                        border-style: solid;
                        border-color: $brand-color-red transparent transparent;
                    }
                }

                &.is-selected {
                    .close {
                        display: inline;
                    }

                    .store {
                        display: none;
                    }
                }
            }
        }

        .close {
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: -1px;
                right: -25px;
                border-width: 8px 8px 8px 8px;
                border-style: solid;
                border-color: transparent transparent $brand-color-red;
            }
        }

        .info {
            a {
                &:first-child {
                    padding-right: 20px;
                }
            }
        }

        .schedule-cta,
        .schedule-cta-phone {
            float: left;
            max-width: 50%;

            @include media(bp-lt-md) {
                display: block;
                width: 100%;
                max-width: none;
            }

            a {
                background-color: #1c7abd;
                border: 2px solid #0b5389;
                width: 100%;
            }
        }

        .open {
            font-size: 16px;
            margin-bottom: 10px;
        }

        .hours {
            dt {
                width: 30%;
                white-space: nowrap;
            }
        }

        .inner {
            display: none;
        }

        .heading {
            margin: 0 0 22px 0;
            line-height: 1;

            @include media(bp-lt-sm) {
                margin: 0;
                font-size: 24px;
            }
        }

        .info-col {
            @include clearfix;

            .phone {
                margin-bottom: 22px;
            }
        }

        .hours-col {
            padding-bottom: 10px;
            margin-bottom: 10px;

            @include media(bp-lt-sm) {
                padding: 30px 0;
            }
        }

        .local-store-content {
            position: relative;
            padding-bottom: 30px;

            .info {
                width: 100%;
            }
        }

        .store-ctas {
            float: left;
            width: 100%;

            a {
                text-decoration: underline;

                &:first-child {
                    margin-right: 4%;
                }

                @include media(bp-lt-md) {
                    text-decoration: none;
                    font-size: 18px;
                    text-transform: uppercase;
                    width: 48%;
                    display: inline-block;
                    float: left;
                    text-align: center;
                    border: 1px solid;
                    padding: 6px;
                }
            }
        }

        .mobile-ctas {
            @include clearfix;

            display: none;

            @include media(bp-lt-sm) {
                display: block;
            }

            a {
                text-decoration: none;
                font-size: 18px;
                text-transform: uppercase;

                display: inline-block;
                width: 49%;

                text-align: right;

                &:first-child {
                    text-align: left;
                }
            }

            .cta-call {
                text-align: left;
            }
            .cta-call:before {
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 10px;
            }
        }

        .directions-cta {
            float: right;
            position: static;
            width: 50%;
            padding-left: 20px;

            @include media(bp-lt-md) {
                display: none;
            }

            .directions {
                font-size: 18px;
                text-transform: uppercase;
                white-space: nowrap;

                @include media(bp-lt-md) {
                    font-size: 16px;
                }
                &:after {
                    content: '\203A';
                    color: $brand-color-red;
                    font-size: 42px;
                    position: relative;
                    top: 6px;
                }
            }
        }

        .local-store-map {
            padding: 0 40px 10px 20px;

            @include media(bp-lt-md) {
                display: none;
            }
        }
    }

    /* body class injected by js */
    .has-local-store & {
        &.reskin {
            .cta-store-bar {
                .store-info {
                    display: table;
                }

                .find-store-cta {
                    display: none;
                }
            }
        }
    }

    .no-local-store & {
        &.reskin {
            display: block;
            .cta-store-bar {
                .find-store-cta {
                    display: table;

                    a {
                        &:after {
                            position: relative;
                            color: $dark-gray;
                            content: '\203A';
                            top: -4px;
                            line-height: 0;
                            font-size: 30px;
                            vertical-align: middle;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    @media print {
        img {
            max-width: none !important;
        }

        @page {
            margin: 0cm 0cm 0cm 0cm;
            padding: 0cm 0cm 0cm 0cm;
        }

        .store-locator .wrap-inner {
            font-size: 18px !important;

            .custom-select {
                background-image: none !important;
            }
        }

        .map-col {
            height: 390px !important;
        }

        .btn.get-direction,
        .inner a,
        .inline,
        .MicrosoftNav {
            display: none !important;
        }

        .fields {
            div {
                padding: 0;
            }

            label,
            .get-direction,
            .inner a {
                display: none !important;
            }

            input,
            select {
                border: 0;
                padding: 0;
            }
        }
    }
}

.local-store-content,
.local-store-map {
    float: left;

    .map {
        overflow: hidden;
        > img {
            position: relative;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            max-width: none;
            height: 100%;
        }
    }
}

.local-store-content {
    width: percentage(math.div(780px, 1440px));
    padding-left: percentage(math.div(70px, 1440px));

    a {
        text-decoration: none;
    }

    @include media(bp-lt-sm) {
        width: 100%;
        padding: 0;
    }

    .heading {
        margin-bottom: 10px;
        font-size: 30px;
    }

    .info-col {
        width: 50%;
        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .phone {
        font-size: 30px;
        line-height: 1;

        @include media(bp-lt-sm) {
            display: none;
        }
    }

    .store {
        @include clearfix;
        position: relative;
        width: 90%;
        margin: 15px 0;

        @include media(bp-lt-sm) {
            width: 100%;
        }

        .store-zip {
            color: $text-color;
            text-decoration: none;
            display: inline-block;
            cursor: text;
        }
    }

    .info {
        a {
            text-decoration: underline;
        }
    }

    .info,
    .cta-dir,
    .cta-call {
        float: left;
    }

    .info {
        width: 70%;

        @include media(bp-lt-sm) {
            width: 75%;
        }
    }

    .ctas {
        position: absolute;
        left: 65%;

        @include media(bp-lt-sm) {
            top: 0;
            right: 0;
            left: auto;
        }

        div {
            @include media(bp-lt-sm) {
                margin-bottom: 10px;
            }
        }
    }

    .cta-dir,
    .cta-call {
        text-align: center;

        @include media(bp-lt-sm) {
            float: none;
        }
    }

    .cta-dir {
        margin-left: 10px;

        &:before {
            @include icon('map-signs', $black);
            display: block;
            margin: 0 auto;
        }

        @include media(bp-lt-sm) {
            margin-left: 0;
        }
    }

    .cta-call {
        display: none;

        &:before {
            @include icon('phone', $black);
            display: block;
            margin: 0 auto;
        }

        @include media(bp-lt-sm) {
            display: block;
        }
    }

    .hours-col {
        width: 50%;
        border-left: 1px solid #ccc;
        padding-left: 5%;

        @include media(bp-lt-sm) {
            width: 100%;
            border-left: 0;
            padding-left: 0;
        }
    }

    .open {
        margin-bottom: 20px;
    }

    dl {
        overflow: auto;
        margin: 0;
    }

    dt,
    dd {
        float: left;
    }

    dt {
        clear: both;
        width: 25%;

        @include media(bp-lt-lg) {
            width: 32%;
        }

        @include media(bp-lt-sm) {
            width: 25%;
        }
    }

    dd {
        margin: 0;
    }

    .schedule-cta {
        margin-top: 10px;

        @include media(bp-lt-sm) {
            display: none;
        }
    }

    .holiday {
        color: $brand-color-red;
    }
}

.local-store-map {
    width: percentage(math.div(660px, 1440px));

    .map {
        border: solid 1px #aaa;
    }

    @include media(bp-lt-sm) {
        width: 100%;
        margin-top: 10px;
    }
}

.no-local-store {
    .local-store {
        display: none;
    }
}

.has-local-store {
    .local-store {
        display: block;
    }
}
