.declined-services-overview {
    width: 100%;
    position: relative;
    margin-top: 20px;

    &__counter {
        position: absolute;
        top: -4px;
        left: -2px;
        background: #d21a09;
        height: 17px;
        width: 17px;
        border-radius: 50%;
        color: $white;
        text-align: center;
        font-size: 13px;
        z-index: 1;

        @include media(bp-lt-md) {
            height: 22px;
            width: 22px;
            font-size: 16px;
            left: -5px;
        }
    }

    &__panel {
        display: flex;
        align-items: center;
        border: 2px solid #d21a09;

        @include media(bp-lt-md) {
            flex-direction: column;
            position: relative;
            align-items: flex-start;
        }
    }

    &__icon {
        background: $black;
        padding: 10px 13px;
        transform: scale(1.05);

        svg {
            fill: $white;
            width: 22px;

            @include media(bp-lt-md) {
                width: 25px;
            }
        }

        @include media(bp-lt-md) {
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            transform: scale(1.03);
            top: 0;
            left: -1px;
        }
    }

    &__message {
        margin-left: 15px;
        color: $black;
        width: 47%;
        line-height: 15px;

        @include media(bp-lt-lg) {
            width: 55%;
        }

        @include media(bp-lt-md) {
            margin-left: 63px;
            width: 75%;
            margin-top: 25px;
            margin-bottom: 23px;
            font-size: 15px;
            line-height: 18px;
        }
    }

    &__cta {
        margin-left: auto;
        margin-right: 20px;

        @include media(bp-lt-md) {
            margin-left: 63px;
            margin-bottom: 15px;
        }
    }
}

.declined-services-details {
    &__notification {
        color: $black;
        font-size: 16px !important;
        padding: 0 100px 0 0;
        text-align: left !important;
        margin-bottom: 0px;

        @include media(bp-lt-md) {
            padding-right: 20px;
        }
    }

    &__schedule-appointment {
        text-align: center;

        a {
            text-align: center;
            color: #a41204;
            font-size: 16px;
            margin-top: 25px;
            display: inline-block;
            margin-bottom: 0;
            padding: 0;

            &:hover {
                color: darken(#a41204, 10%);
            }

            @include media(bp-lt-md) {
                font-size: 19px;
                margin-bottom: 20px;
            }
        }
    }

    h3 {
        display: block;
        color: #4a4a4a;
        font-size: 20px !important;
        text-align: center;
        text-transform: uppercase !important;
        margin-bottom: 10px !important;
    }

    .vehicle-details-display {
        margin-bottom: 40px !important;

        .vehicle-image-wrap {
            flex: 1 10%;
        }

        .description-wrap {
            margin-left: 0;
        }
    }
}

.declined-services-list {
    font-size: 16px;

    .table-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        margin: 10px 0;

        // &.flex-wrap-mobile {
        //     flex-wrap: wrap;

        //     > :nth-child(odd) {
        //         flex: 1 50%;
        //     }

        //     > div {
        //         margin: 5px 0;
        //         white-space: nowrap;
        //     }
        // }
    }

    .wrapper {
        display: flex;
        flex-direction: row;
    }

    .column {
        flex-grow: 0;
        flex-shrink: 0;
        vertical-align: top;
    }

    .date {
        width: 25%;
    }

    .service-type {
        width: 50%;
        flex-grow: 1;
        overflow: hidden;
        padding-right: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .cost {
        width: 25%;
        text-align: right;
    }

    .header {
        font-weight: bold;
    }

    .declined-services-list__items {
        text-transform: capitalize;
    }
}
