.product-results-grid--v1 {
    .container {
        .results-container {
            .tire-sizing-options {
                button {
                    @include font-title;
                    font-weight: normal;
                }
            }
        }
    }
}
