@import '~common/scss/shared/shared';

// special case interrupt
.interrupt[data-interrupt-id='interrupt-add-to-cart'],
.interrupt[data-interrupt-id='interrupt-appt-lsp'] {
    &.show {
        .interrupt-box {
            animation-name: showme;
            animation-duration: 0.5s;
            animation-direction: normal;
            animation-fill-mode: forwards;
        }
        .interrupt-anchored-overlay.bottom::after {
            animation-name: showmeafter;
            animation-duration: 0.5s;
            animation-direction: normal;
            animation-fill-mode: forwards;
        }
    }

    &:not(.show) {
        .interrupt-box {
            margin-bottom: -200px;
        }

        .interrupt-anchored-overlay.bottom::after {
            bottom: -200px;
        }
    }
}

@keyframes showme {
    from {
        margin-bottom: -200px;
    }

    to {
        margin-bottom: 0;
    }
}

@keyframes showmeafter {
    from {
        bottom: -200px;
    }

    to {
        bottom: 0;
    }
}

.interrupt {
    position: relative;
    z-index: 9980;
    min-width: 320px;
    background: transparent;

    @media print {
        display: none !important;
    }
}

.interrupt-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.65);
    z-index: 9970;
    display: flex;

    @media print {
        display: none !important;
    }
}

.interrupt-modal,
.interrupt-anchored-strip,
.interrupt-anchored-overlay {
    z-index: 9980;
    display: flex;
    min-height: 70px;
    color: $dark-gray; // light mode - default
    background-color: $white; // light mode - default

    .heading,
    .subheading {
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 1.2em;
    }

    .heading {
        @include font-title;
        font-size: 24px;
        text-transform: uppercase;
        color: $black; // light mode - default
    }

    .subheading {
        font-size: 18px;
        color: #656565; // light mode - default
    }

    .copy {
        margin-bottom: 10px;
    }

    .interrupt-content {
        display: flex;
        flex: 1 100%;
        flex-direction: column;
        text-align: left;
    }

    .ctas {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

        @include media(bp-lt-sm) {
            flex-direction: column;
        }

        > a {
            line-height: 1.2em;

            &.btn {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: auto;
                min-width: 195px;
                font-size: 16px;

                @include media(bp-lt-sm) {
                    height: 45px;
                }

                + .btn {
                    margin-left: 12px;
                }
            }
        }
    }

    .flexible-messaging-area {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 30px;
        padding: 8px 20px;
        background-color: #f5f5f5; // light mode - default
        color: #656565; // light mode - default
        font-size: 14px;
        line-height: 1.1em;

        @include media(bp-lt-sm) {
            min-height: 25px;
            padding: 5px 20px;
            font-size: 12px;
        }
    }

    .close > a {
        position: relative;
        display: block;
        overflow: hidden;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        font-size: 0;
        text-indent: -9999px;
        appearance: none;
        box-shadow: none;
        border-radius: none;
        border: none;
        cursor: pointer;

        @include media(bp-lt-sm) {
            width: 16px;
            height: 16px;
        }

        span {
            position: absolute;
            display: block;
            top: 8px;
            left: 0;
            right: 0;
            height: 2px;

            &:before,
            &:after {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #8d8d8d; // light mode - default
            }

            &:before {
                top: 0;
                transform: rotate(45deg);
            }

            &:after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
    }

    // assuming dark-mode is same for all versions of component...
    &.dark-mode {
        color: $white;
        background-color: #007ac2; // this is site specific color

        .heading,
        .subheading {
            color: $white;
        }

        .close a span {
            &:before,
            &:after {
                background-color: $white;
            }
        }

        .ctas > a {
            color: $white;

            &.btn.secondary {
                border-color: white;
                color: $link-color;
            }
        }
    }
}

.interrupt-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-flow: column nowrap;
    width: calc(100% - 30px);
    max-width: 470px;
    transform: translate(-50%, -50%);

    // this element isn't part of this variation so explicitly hiding it in case it is present
    .interrupt-image {
        display: none;
    }

    .custom-module {
        margin-bottom: 12px;
    }

    .ctas {
        &::before {
            content: '';
            width: calc(100% + 20px);
            height: 2px;
            margin: 0 -10px 16px;
            background: #f5f5f5;
        }

        > a:only-child {
            width: 100%;
        }

        @include media(bp-gt-xs) {
            flex-wrap: wrap;
        }

        @include media(bp-lt-sm) {
            margin-top: -8px;

            &::before {
                content: '';
                width: calc(100% + 10px);
                margin: 0 -5px 8px;
            }

            > a.btn {
                margin-top: 8px;

                &:first-child {
                    order: 2;
                }

                + .btn {
                    margin-left: 0;
                }
            }
        }
    }

    &::after {
        content: '';
        position: fixed;
        z-index: -1;
        left: 0;
        width: 100vw;
        height: 120px;
        pointer-events: none;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 18px;

        @include media(bp-lt-sm) {
            padding: 11px 13px;
        }
    }

    h2,
    h3,
    h4,
    p {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .interrupt-content {
        @include media(bp-lt-sm) {
            padding: 30px 15px 8px;
        }

        @include media(bp-gt-xs) {
            padding: 16px 21px;
        }
    }

    .flex-message {
        padding: 5px 10px;
        background-color: #ebebeb;
        color: #666;
    }
}

.interrupt-anchored-overlay {
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    box-shadow:
        0 0 6px 0 rgba(black, 0.35),
        0 0 20px 0 rgba(black, 0.35);

    @include media(bp-gt-sm) {
        width: 470px;
    }

    @include media(bp-lt-md) {
        width: 100%;
    }

    // this element isn't part of this variation so explicitly hiding it in case it is present
    .interrupt-image {
        display: none;
    }

    .custom-module {
        margin-bottom: 12px;
    }

    .ctas {
        @include media(bp-gt-sm) {
            justify-content: flex-start;
        }

        @include media(bp-lt-sm) {
            margin-top: -8px;

            &::before {
                content: '';
                width: calc(100% + 10px);
                height: 2px;
                margin: 0 -5px 8px;
                background: #f5f5f5;
            }

            > a.btn {
                margin-top: 8px;

                &:first-child {
                    order: 2;
                }

                + .btn {
                    margin-left: 0;
                }
            }
        }
    }

    &::after {
        content: '';
        position: fixed;
        z-index: -1;
        left: 0;
        width: 100vw;
        height: 120px;
        pointer-events: none;
    }

    &.top {
        top: 0;
        bottom: auto;

        &::after {
            top: 0;
            bottom: auto;
            background-image: linear-gradient(rgba($white, 0.8), rgba($white, 0));
        }
    }

    &.bottom {
        top: auto;
        bottom: 0;

        &::after {
            top: auto;
            bottom: 0;
            background-image: linear-gradient(rgba($white, 0), rgba($white, 0.8));
        }
    }

    &.right {
        left: auto;

        @include media(bp-lt-md) {
            right: 0;
        }

        @include media(bp-gt-sm) {
            right: 125px;
        }
    }

    &.left {
        right: auto;

        @include media(bp-lt-md) {
            left: 0;
        }

        @include media(bp-gt-sm) {
            left: 125px;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 18px;

        @include media(bp-lt-sm) {
            padding: 11px 13px;
        }
    }

    h2,
    h3,
    h4,
    p {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .interrupt-content {
        @include media(bp-lt-md) {
            padding: 30px 15px 8px;
        }

        @include media(bp-gt-sm) {
            padding: 16px 21px;
        }
    }

    .flex-message {
        padding: 5px 10px;
        background-color: #ebebeb;
        color: #666;
    }
}

.interrupt-anchored-strip {
    position: fixed;
    width: 100%;
    padding: 10px;

    .close {
        display: flex;
    }

    .interrupt-image {
        margin: 0 15px 0 0;

        img {
            max-height: 40px;
            max-width: 40px;
        }

        @include media(bp-gt-sm) {
            margin-top: 0;
        }
    }

    .custom-module {
        flex: 1 100%;
    }

    .ctas {
        flex-direction: row;

        @include media(bp-lt-md) {
            justify-content: flex-start;
        }

        > a {
            @include font-title;
            margin: 0 10px 0 0;
            font-size: 15px;
            text-decoration: underline;
            text-transform: uppercase;
        }
    }

    .flexible-messaging-area {
        display: none;
    }

    &.top {
        top: 0;
    }

    &.bottom {
        bottom: 0;
    }

    @include media(bp-gt-sm) {
        align-items: center;

        .interrupt-content {
            flex-direction: row;
            align-items: center;

            .heading,
            .subheading,
            .custom-module,
            .ctas {
                margin: 0 12px 0 0;
            }

            .heading,
            .custom-module,
            .ctas {
                white-space: nowrap;
            }

            .subheading {
                flex: 1 100%;
            }
        }
    }
}

// custom modules

.interrupt-email-signup {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    input {
        width: 240px;
    }
}

.recently-viewed-tires {
    .tire {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-end;
        margin: 10px 0 0;
        @include media(bp-gt-sm) {
            margin: 20px 0;
            min-height: 160px;
        }

        &__image {
            position: relative;
            width: 40%;
            text-align: center;
            img {
                width: 90%;
                max-width: 90px;
            }
        }
        &__brand {
            img {
                height: 12px;
            }
            @include media(bp-gt-sm) {
                img {
                    height: 14px;
                }
            }
        }
        &__info {
            width: 60%;
        }
        &__name {
            @include font-title;
            font-size: 18px;
            text-transform: uppercase;
            color: $black;
            margin: 5px 0;
            @include media(bp-gt-sm) {
                font-size: 20px;
            }
        }
        &__price {
            font-size: 16px;
            color: $black;
            padding-right: 10px;
            @include media(bp-gt-sm) {
                font-size: 18px;
            }
        }
        &__old-price {
            font-size: 16px;
            color: $brand-color-red;
            text-decoration: line-through;
            @include media(bp-gt-sm) {
                font-size: 18px;
            }
        }
        &__cta {
            width: 100%;
            margin-top: 20px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            a,
            button {
                width: 100%;
                font-size: 14px;
                @include media(bp-gt-sm) {
                    font-size: 16px;
                }
            }
        }
    }
}
.interrupt--recently-viewed-tires {
    .ctas {
        display: none;
    }
    &.interrupt-modal {
        max-width: 565px;
        .tire {
            @include media(bp-gt-sm) {
                margin: 20px 30px 20px 0;
            }
            &__image {
                @include media(bp-gt-sm) {
                    display: block;
                    img {
                        max-width: 130px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }
            &__cta {
                @include media(bp-gt-sm) {
                    width: 60%;
                    margin-top: 0;
                }
            }
        }
    }
}
