@import '~common/scss/shared/shared';

.fun-fact {
    padding: 50px 20px 60px;
    text-align: center;

    .title,
    p {
        text-transform: uppercase;
    }

    .title {
        margin: 0;
    }

    i,
    img,
    p {
        display: inline-block;
    }

    i,
    img {
        vertical-align: middle;
        margin: 0 5px 4px;
        max-height: 30px;
    }

    p {
        margin: 0;
    }

    strong,
    span {
        margin: 0 5px;
        font-size: 80px;
        vertical-align: middle;
    }

    @include media(bp-lt-md) {
        padding: 30px 15%;

        i,
        img {
            display: none;
        }

        strong,
        span {
            display: block;
            margin: 6px 3px 0;
        }
    }
}
