@import '~common/scss/shared/shared';
$desktop-body-width-lg: 1200px; // the width of the body in desktop. Extra Large

.local-seo-theme {
    .appt-step {
        margin-bottom: 0;
    }
    .container {
        display: block;

        &.local-geolocation-search {
            margin: 0 auto;
            max-width: 100%;
        }
    }
    .container h2 {
        color: $black;
        text-align: center;
        @include font-title;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .search-block {
        width: 98%;
        padding: 0 20px;
        margin: auto;
        @include media(bp-gt-md) {
            padding-left: 0;
            padding-right: 0;
        }

        input {
            width: 100%;
        }

        .btn.find-store.mobile,
        .experiencefragment button {
            display: block;
            width: 100%;
            border-radius: 5px;
        }

        .default-block {
            display: block;
            position: relative;

            .btn.find-store.mobile-secondary {
                display: block;
                position: absolute;
                top: 0px;
                right: 5px;
                width: 35px;
                height: 35px;
                text-indent: -150px;
                overflow: hidden;
                color: red;
                background-color: transparent;
                border: none;
                background-image: url('https://s7d1.scene7.com/is/content/bridgestone/search-icon-web-global-bsro');
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: center;
            }
        }
    }

    .appt-step .wrap {
        background: none;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        margin: auto;

        &.has-results {
            .search-block {
                @include media(bp-gt-md) {
                    width: 408px;
                    height: auto;
                    background-image: none;
                }
                form {
                    @include media(bp-gt-md) {
                        position: relative;
                        width: auto;
                        height: auto;
                        border-radius: 0;
                        text-align: right;
                        padding-right: 0;
                        padding-bottom: 0;
                        padding-left: 0;
                        margin-bottom: 10px;
                    }
                    .geolocation-header {
                        display: none;
                        @include media(bp-gt-md) {
                            display: none;
                        }
                    }
                    input {
                        margin-bottom: 20px;
                        @include media(bp-gt-md) {
                            width: 100% !important;
                            margin-bottom: 0;
                        }
                    }
                    .btn {
                        &.find-store {
                            @include media(bp-gt-md) {
                                right: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
        &.local,
        &.state {
            .search-block {
                form {
                    .zipcodeError {
                        margin-top: 10px;
                        margin-bottom: 10px;
                        @include media(bp-gt-md) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .wrap-inner {
        display: none;
        flex-direction: column;
        width: 100%;
        &:not(.local):not(.state) {
            display: flex;
        }

        @include media(bp-gt-md) {
            flex-direction: row;
        }

        .col.results-col {
            @include media(bp-gt-md) {
                display: flex;
                order: 2;

                width: 472px;
                height: 810px;
            }
        }

        .map-col {
            display: flex;
            order: 2;
            height: 181px;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            padding: 0 20px;
            @include media(bp-gt-md) {
                position: relative;
                top: -52px;
                width: 427px;
                height: 817px;
                order: 3;
                padding: 0;
            }
            iframe {
                height: 100%;
                @include media(bp-gt-md) {
                    height: 100%;
                }
            }
        }

        .results-col {
            display: flex;
            order: 3;
        }
        &.has-results {
            display: flex;
            padding-bottom: 40px;
            @include media(bp-gt-md) {
                padding-bottom: 0;
            }
            .results-col {
                .appt-step-1-results {
                    margin-top: 0;
                }
            }
        }
        &.has-errors {
            .col {
                &.map-col {
                    @include media(bp-gt-md) {
                        top: -72px;
                    }
                }
            }
        }
    }

    .appt-step-1 {
        #show-more-toggle:checked ~ div {
            width: 100%;
        }
    }

    .appt-step-1-results {
        border: none;
        width: 100%;

        .result {
            background: none !important;
            border-bottom: 1px solid #ccc;
            &:first-child {
                border-top: 1px solid #ccc;
                margin-top: 15px;
                @include media(bp-gt-sm) {
                    margin-top: 0;
                }
            }
            .inner {
                background: $white;

                .info {
                    width: 100%;
                    padding: 0;

                    .cta a,
                    .cta-mobile a,
                    .cta button {
                        border-radius: 4px;
                        width: 100%;
                    }

                    .address {
                        margin-bottom: 12px;
                        color: #5c5f5f;
                    }

                    .store-name,
                    .address a,
                    .phone a,
                    .cta-mobile.mobile-phone a {
                        @include font-body;
                        font-style: normal;
                        color: var(--color-base-Black, $black);
                    }

                    .store-name {
                        font-size: 20px;
                        font-weight: 900;
                        line-height: 24px;
                    }

                    .phone a {
                        text-align: right;
                        font-size: 14px;
                        font-weight: 350;
                        line-height: 16px;
                        text-decoration-line: underline;
                    }

                    .map-mobile {
                        margin-top: 30px;
                    }
                }
            }
        }

        .pin {
            float: none !important;
            margin-bottom: 12px;

            figure {
                width: fit-content;

                figcaption {
                    margin-top: -22px;
                    color: $white;
                }
            }

            .store-number {
                width: 24px;
                display: flex;
                height: 24px;
                padding: 10px;
                background: $dark-gray;
                color: $white;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
            }
        }

        &::-webkit-scrollbar {
            background: #e1e3e3;
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $brand-color-red;
            height: 180px;
        }

        .find-store-show-more-toggle.btn {
            width: 74%;
            background-color: transparent;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $brand-color-red;
        }

        .find-store-show-more-toggle.btn img {
            margin-left: 8px;
        }
    }

    .search-block {
        input {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }

        label {
            display: none;
        }
    }

    .appt-step-1-results {
        @include media(bp-gt-md) {
            padding: 0 15px;
            height: 750px;
        }

        .result {
            @include media(bp-gt-md) {
                border: 1px solid #ccc;
                padding: 20px;
                margin-bottom: 15px;
            }
        }
    }

    .container:not(.local-geolocation-search) {
        @include media(bp-gt-md) {
            display: flex;
        }
    }

    .search-block {
        @include media(bp-gt-md) {
            width: 425px;
            margin-left: 15px;
        }

        form {
            position: relative;
        }

        label {
            @include media(bp-gt-md) {
                display: block;
                float: left;
                text-align: left;
            }
        }

        button.btn {
            @include media(bp-gt-md) {
                display: none;
            }
        }

        .default-block {
            @include media(bp-gt-md) {
                position: relative;
                display: inline-block;
                width: 100%;
            }

            .btn.find-store.mobile {
                @include media(bp-gt-md) {
                    display: none;
                }
            }
        }
    }

    .btn.find-store.mobile,
    .experiencefragment button {
        @include media(bp-gt-md) {
            text-indent: -9999px;
            border: none;
            width: 20px !important;
            height: 29px;
            position: absolute;
            right: 5px;
            top: 5px;
            background: transparent;

            &:hover {
                background-color: transparent;
                border: none;
            }
        }
    }

    .hide-form {
        display: none;
    }

    .show-form {
        display: block;
    }

    .wrap-inner.show-form {
        display: flex;
    }
}

.local-geolocation-search {
    .search-block .geolocation-header {
        display: inline;
    }

    .search-block {
        height: auto;
        align-content: center;
        padding: 40px 30px;

        @include media(bp-gt-md) {
            height: 400px;
            padding: 0;
        }

        .default-block {
            input {
                top: -3px;
            }

            button {
                top: -3px;
            }
        }

        form {
            text-align: center;
            button {
                border-radius: 5px;
                &.geo-locate-me {
                    display: inline-block;
                    width: 180px;
                    margin-bottom: 20px;
                    @include media(bp-gt-md) {
                        margin-bottom: 0;
                    }
                    img {
                        filter: brightness(0) invert(1);
                        margin-right: 20px;
                    }
                }
            }
            input {
                width: 100%;
                margin: 0 auto;
                margin-bottom: 10px;
                @include media(bp-gt-md) {
                    margin-bottom: 0;
                }
            }
            span {
                &.heading {
                    display: inline-block;
                    width: 90%;
                    padding: 0;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: bold;
                    padding-bottom: 20px;
                    text-transform: none;
                }
            }
        }
    }

    .search-block {
        @include media(bp-gt-md) {
            background-image: url('https://s7d1.scene7.com/is/image/bridgestone/locate-me-map-background-local-pages-global-web-bsro?scl=1&fmt=webp');
            width: 100%;
            margin: auto;

            .default-block {
                display: inline;
            }
        }
    }

    .search-block form {
        @include media(bp-gt-md) {
            padding: 20px;
            background: $white;
            align-content: center;
            align-items: center;
            width: 535px;
            margin: auto;
            text-align: center;
            border-radius: 8px;
            position: relative;
        }
    }

    .search-block form button.geo-locate-me {
        @include media(bp-gt-md) {
            vertical-align: middle;
            position: relative;
            margin-right: 30px;
        }
    }

    .search-block form button.geo-locate-me:after {
        @include media(bp-gt-md) {
            background: #ccc;
            content: '';
            display: block;
            height: 35px;
            position: absolute;
            right: -20px;
            top: 2px;
            width: 2px;
        }
    }

    .btn.find-store.mobile-secondary {
        @include media(bp-gt-md) {
            top: -8px !important;
        }
    }

    .search-block input {
        @include media(bp-gt-md) {
            vertical-align: middle;
            display: inline-block;
            width: 260px !important;
            border: 1px solid #ccc;
            line-height: 18px;
        }
    }

    .results-col {
        @include media(bp-gt-md) {
            width: 100%;
        }
    }

    .btn.find-store.mobile {
        @include media(bp-gt-md) {
            display: none;
        }
    }
}
