/* SCAFFOLDING */
* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
button,
input,
select,
textarea {
    color: $text-color;
    -webkit-font-smoothing: antialiased;
}

body {
    //max-width: 1440px; // not limiting width at this time
    background: $body-bg;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

::-moz-selection {
    background: $text-selection-bg-color;
    text-shadow: none;
}

::selection {
    background: $text-selection-bg-color;
    text-shadow: none;
}

// Links
a {
    color: $link-color;

    &:hover:not(.btn),
    &:focus:not(.btn) {
        color: $link-hover-color;
        text-decoration: underline;
    }
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
    margin: 0;
}

// Images
// Responsive images (ensure images don't scale beyond their parents)
img {
    //display: block;
    max-width: 100%;
    // Part 1: Set a maximum relative to the parent
    height: auto;
    // Part 2: Scale the height according to the width, otherwise you get stretching
    vertical-align: middle;
}

.img-fixed {
    max-width: none;
}

.icon {
    display: inline-block;
}