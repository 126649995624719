// UI Breakpoints

// usage example:
// @include media(bp-lt-md) { my styles }

// legacy example:
// @include media($lt-tablet) { my styles }

/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/

// bp-xs: screen and (max-width: 539px);
// bp-sm: screen and (min-width: 540px) and (max-width: 767px);
// bp-md: screen and (min-width: 768px) and (max-width: 1024px);
// bp-lg: screen and (min-width: 1025px) and (max-width: 1279px);
// bp-xl: screen and (min-width: 1280px) and (max-width: 1500px);
// bp-lt-sm: screen and (max-width: 540px);
// bp-lt-nav: screen and (max-width: 701px);
// bp-lt-md: screen and (max-width: 768px);
// bp-lt-lg: screen and (max-width: 1025px);
// bp-lt-xl: screen and (max-width: 1280px);
// bp-lt-xxl: screen and (max-width: 1500px);
// bp-gt-xs: screen and (min-width: 540px);
// bp-gt-nav: screen and (min-width: 701px);
// bp-gt-sm: screen and (min-width: 768px);
// bp-gt-md: screen and (min-width: 1025px);
// bp-gt-lg: screen and (min-width: 1280px);
// bp-gt-xl: screen and (min-width: 1500px);

// rules to be used for the media mixin.
$bp-rules: (
    'bp-xs',
    'bp-sm',
    'bp-md',
    'bp-lg',
    'bp-xl',
    'bp-lt-nav',
    'bp-lt-sm',
    'bp-lt-md',
    'bp-lt-lg',
    'bp-lt-xl',
    'bp-lt-xxl',
    'bp-gt-xs',
    'bp-gt-nav',
    'bp-gt-sm',
    'bp-gt-md',
    'bp-gt-lg',
    'bp-gt-xl'
);

// Breakpoint Values
$bpv-a: 540px;
$bpv-nav: 701px;
$bpv-b: 768px;
$bpv-c: 1025px;
$bpv-d: 1280px;
$bpv-e: 1500px;

// map legacy variables to rules:
$tablet-nav: bp-gt-nav;
$tablet: bp-gt-sm;
$desktop: bp-gt-md;
$lg-desktop: bp-gt-lg;
$max: bp-gt-xl;
$phone: bp-lt-sm;
$lt-tablet-nav: bp-lt-nav;
$lt-tablet: bp-lt-md;
$lt-desktop: bp-lt-lg;
$lt-lg-desktop: bp-lt-xl;
$lt-max: bp-lt-xxl;
$tablet-only: bp-md;

@mixin media($rule) {
    @if index($bp-rules, $rule) {
        @if $rule ==bp-xs {
            @media only screen and (max-width: ($bpv-a - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-sm {
            @media only screen and (min-width: $bpv-a) and (max-width: ($bpv-b - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-md {
            @media only screen and (min-width: $bpv-b) and (max-width: ($bpv-c - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lg {
            @media only screen and (min-width: $bpv-c) and (max-width: ($bpv-d - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-xl {
            @media only screen and (min-width: $bpv-d) and (max-width: ($bpv-e - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lt-nav {
            @media only screen and (max-width: ($bpv-nav - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lt-sm {
            @media only screen and (max-width: ($bpv-a - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lt-md {
            @media only screen and (max-width: ($bpv-b - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lt-lg {
            @media only screen and (max-width: ($bpv-c - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lt-xl {
            @media only screen and (max-width: ($bpv-d - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-lt-xxl {
            @media only screen and (max-width: ($bpv-e - 1)) {
                @content;
            }
        }

        @else if $rule ==bp-gt-xs {
            @media only screen and (min-width: $bpv-a) {
                @content;
            }
        }

        @else if $rule ==bp-gt-nav {
            @media only screen and (min-width: $bpv-nav) {
                @content;
            }
        }

        @else if $rule ==bp-gt-sm {
            @media only screen and (min-width: $bpv-b) {
                @content;
            }
        }

        @else if $rule ==bp-gt-md {
            @media only screen and (min-width: $bpv-c) {
                @content;
            }
        }

        @else if $rule ==bp-gt-lg {
            @media only screen and (min-width: $bpv-d) {
                @content;
            }
        }

        @else if $rule ==bp-gt-xl {
            @media only screen and (min-width: $bpv-e) {
                @content;
            }
        }
    }

    @else {
        @warn 'Unknown `#{$rule}` in $bp-rules when calling mixin media.';
    }
}

// Deprecated breakpoint variable names:

// $mobile-breakpoint: 320px;
// $tablet-nav-breakpoint: 701px;
// $tablet-breakpoint: 768px;
// $desktop-breakpoint: 1025px;
// $lg-desktop-breakpoint: 1280px;
// $max-breakpoint: 1500px;

// $phone: new-breakpoint(max-width em($tablet-breakpoint - 1) 6); // Styles in this mixin apply to phone only
// $tablet-nav: new-breakpoint(min-width em($tablet-nav-breakpoint) 8);
// $lt-tablet-nav: new-breakpoint(max-width em($tablet-nav-breakpoint - 1) 8);
// $tablet: new-breakpoint(min-width em($tablet-breakpoint) 8);
// $lt-tablet: new-breakpoint(max-width em($tablet-breakpoint - 1) 6);
// $tablet-only: new-breakpoint(min-width em($tablet-breakpoint) max-width em($desktop-breakpoint - 1) 8);
// $desktop: new-breakpoint(min-width em($desktop-breakpoint), 12);
// $lt-desktop: new-breakpoint(max-width em($desktop-breakpoint - 1) 12);
// $lg-desktop: new-breakpoint(max-width em($lg-desktop-breakpoint), 12);
// $max: new-breakpoint(min-width em($max-breakpoint), 12);