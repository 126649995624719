.offers-signup {
    &.inline {
        > div {
            display: inline-block;
        }
    }

    .unsubscribe {
        display: block;
        line-height: 1;
    }
    .social-links {
        .social-link {
            text-decoration: none;
        }

    }
}
