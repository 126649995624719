.rm-open-body .rm-modal,
.my-account {
    #accountApp {
        display: flex;
        flex-direction: column;
    }
    .vehicle-wrap {
        margin: 30px 0;

        hr {
            margin: 15px 0;
        }
        @media only screen and (min-width: 680px) {
            .btn {
                width: 250px;
            }
        }

        .actions-wrap {
            padding-top: 15px;
        }
    }
    .vehicle-card {
        margin-bottom: 40px;
        @include media(bp-gt-sm) {
            .vehicle-details-display {
                flex: 1 55%;
            }
        }

        .actions-wrap > * {
            margin-bottom: 15px;
        }

        .actions-wrap {
            padding-top: 0px;
        }
    }

    .vehicle-summary-card {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include media(bp-gt-sm) {
            flex-direction: row;
        }

        .actions-wrap {
            flex: 1 25%;
            margin-bottom: 12px;
            text-align: right;
        }

        &__messaging {
            &--declined-services {
                display: none;
                margin: 0;
                margin-top: 10px;
                font-size: 16px;
                color: $black;

                @include media(bp-lt-md) {
                    display: block;
                }
            }
        }

        &__heading-group {
            margin-bottom: 35px;
            padding: 0 5%;

            button {
                margin-left: 15px;
                font-size: 14px;

                @include media(bp-lt-md) {
                    position: relative;
                    top: -3px;
                    font-size: 16px;
                }
            }

            @include media(bp-lt-md) {
                margin-bottom: 0;
            }
        }
    }

    .vehicle-details-display {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        @include media(bp-gt-sm) {
            flex: 1 50%;
            flex-direction: row;
            align-items: flex-start;
        }
    }

    .vehicle-image-wrap {
        flex: 1 25%;
        img {
            max-height: 105px;
        }
    }

    .dropzone-wrap {
        text-align: center;
        > p {
            margin-top: 1px;
            margin-bottom: 0;
            font-size: 0.75em;
        }
        .error {
            color: $brand-color-red;
            margin-top: -10px;
        }
    }

    .description-wrap {
        @include media(bp-gt-sm) {
            flex: 1 70%;
            margin-left: 25px;
        }

        h4 {
            margin: 15px 0 10px;
        }

        p {
            font-size: 16px;
            margin: 5px 0;
            text-align: left !important;
        }
    }

    .vehicle-service-card {
        .secondary {
            margin-left: 0;
            margin-top: 15px;

            @include media(bp-gt-sm) {
                margin-left: 15px;
            }
        }
    }

    .vehicle-service-inactive {
        margin: 30px 0;

        h5 {
            text-transform: capitalize;
            margin-bottom: 5px;
        }

        p {
            margin-top: 0;
        }
    }

    .rmv-dect {
        text-decoration: none;
    }

    .cta-import {
        font-size: 20px;
        text-align: center;
        display: block;
        color: $dark-gray;
    }

    .mobile-invoice-overview {
        display: flex;
        flex-wrap: wrap;
        > div {
            flex: 1 0 50%;
            &:nth-child(even) {
                padding-right: 10px;
                p {
                    text-align: right;
                }
            }
            &:nth-child(odd) {
                padding-left: 10px;
                p {
                    text-align: left;
                }
            }
        }
    }

    .add-vehicle-wrapper {
        @include media(bp-lt-lg) {
            padding: 0 20px;
        }
    }
}
