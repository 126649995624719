@use "sass:math";
@import '~common/scss/shared/shared';

$email-signup-border-color-light: #dcdcdb !default;

.email-signup {
    margin-bottom: 50px;

    @include media(bp-lt-sm) {
        margin-bottom: 10px;
    }

    .heading {
        margin-bottom: 0;
    }

    .desc {
        &.mobile {
            display: none;
        }

        @include media(bp-lt-sm) {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
            }
        }
    }

    .inner-wrap {
        overflow: hidden;
        padding: 60px 0;
        border: solid 1px $email-signup-border-color-light;

        @include media(bp-lt-sm) {
            padding: 10px 0;
            border: none;
        }
    }

    .form-col,
    .img-col {
        float: left;
    }

    .form-col {
        width: percentage(math.div(735px, 1300px));

        @include media(bp-lt-sm) {
            width: 100%;
        }

        .inner {
            width: percentage(math.div(600px, 735px));
            margin-left: percentage(math.div(50px, 735px));

            @include media(bp-lt-sm) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .img-col {
        width: percentage(math.div(565px, 1300px));
        padding-left: percentage(math.div(85px, 1300px));
        border-left: solid 1px $email-signup-border-color-light;

        @include media(bp-lt-sm) {
            display: none;
        }
    }

    .image-wrap {
        padding-right: 6%;
    }
}

.email-signup-form {
    label {
        display: block;
    }

    input {
        width: 100%;
    }

    [placeholder]::-webkit-input-placeholder {
        color: transparent;

        @include media(bp-lt-sm) {
            color: #666;
        }
    }

    > div {
        overflow: hidden;
    }

    .form-field {
        float: left;
        width: 48%;
        margin: 0 0 15px;

        @include media(bp-lt-sm) {
            width: 100%;
            margin: 0 0 10px;
        }

        &:nth-child(even) {
            float: right;
        }

        &.state {
            label {
                @include media(bp-lt-sm) {
                    display: none;
                }
            }

            option:first-child::after {
                content: ' (optional)';
                color: #666;
            }
        }
    }

    .instruction {
        margin: 15px 0 5px;
    }

    .cta {
        margin-top: 10px;
    }

    .privacy {
        overflow: hidden;
        margin-top: 30px;
        font-size: 12px;

        .icn {
            width: 70px;
            float: left;

            &:before {
                @include icon('lock-circled', $brand-color-red, 64px);

                display: block;
                margin: -4px 0 0 -4px;
            }
        }

        p {
            width: 75%;
            margin-top: 0;
            float: left;

            @include media(bp-lt-sm) {
                width: 70%;
            }
        }
    }
}

.signup-optin {
	label {
		display: flex;
	}
	input[type='checkbox'] {
		min-width: 25px;
		flex: 0 0 25px;
        margin-top: 0.25em;
        height: 15px;
	}
	p {
		margin: 0;
	}
}
// specific to product-quote-reskin
.email-quote-form .signup-optin {
    clear: both;
    margin-bottom: 20px;
}
.email-signup-form .signup-optin {
	margin-bottom: 26px;
}
