$product-results-bg-color: #ebebeb;
$product-results-border-color: #dcdcdc;

@include media(bp-lt-md) {
    .top-result-container {
        .mobile-only-filter {
            .filter-btn,
            .sorting-btn {
                a {
                    @include font-body;

                    &:after {
                        top: 1px;
                    }
                }
            }

            .sorting-btn {
                margin: 0 20px 0 0;
            }
        }
    }
}
