.overview-basic-item {
    .item-supertitle {
        margin: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    .item-title {
        margin: 0.1em 0 0.25em;
        line-height: 1em;
    }

    @include media(bp-lt-md) {
        li {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}
