// outer most wrapper

// .isIE {
//     .my-account {
//         .logged-disp-wrap {
//             width: 50%;
//         }
//     }
// }

.my-account h3 {
    display: inline-block;
    color: #4a4a4a;
    font-size: 25px !important;
}

.action-cta {
    background: none;
    border: none;
    text-decoration: underline;
    text-underline-offset: 1px;
    padding: 0;
    cursor: pointer;
    font-size: 15px;

    &--view-all-light {
        color: #d21b0a;

        &:hover {
            color: darken(#d21b0a, 10%);
        }
    }
}
