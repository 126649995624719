@import '~common/scss/shared/shared';
.about-us {
    position: relative;

    .image-wrap {
        position: relative;
        overflow: hidden;
        max-height: 557px;

        img {
            max-width: none;
            width: auto;
            min-width: 1440px;
        }
    }

    .copy {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        color: white;
        background: rgba(216, 30, 5, 0.8);

        .headline,
        .subhead {
            color: white;
            margin: 30px 0;
        }

        .headline {
            font-size: 102px;
        }

        .subhead {
            font-size: 48px;

            @include media(bp-lt-sm) {
                margin: 15px 0 10px;
            }
        }

        .description {
            font-size: 18px;
            // padding-bottom: 41px;
            text-align: left;
            width: 75%;
            margin: 0 auto;
        }

        p {
            @include media(bp-lt-sm) {
                margin: 15px 0;
                line-height: 1.1em;
            }

            @include media(bp-gt-sm) {
                line-height: 1.2em;
            }
        }

        .logo {
            position: absolute;
            bottom: -41px;

            border: 1px solid #666; //tmp. logo placeholder hard to see.
        }
    }

    &.reskin {
        background: #ebebeb;

        .quote {
            height: auto;
            width: 100%;

            .bubble {
                position: relative;
                display: table;
                background: $white;
                width: 60%;
                margin: 0 auto;
                text-align: center;
                top: -74px;

                > p {
                    display: table-cell;
                    vertical-align: middle;

                    small {
                        display: block;
                        width: 100%;
                    }
                }

                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    bottom: -52px;
                    left: 26px;
                    border-width: 52px 56px 0 0;
                    border-style: solid;
                    border-color: $white transparent;
                    width: 0;
                }
            }
        }

        .image-wrap {
            display: none;
        }

        .copy {
            position: relative;
            color: $dark-gray;
            background: #ebebeb;
            width: 75%;
            margin: 0 auto;
            padding-bottom: 50px;

            .heading {
                display: inline-block;
                width: auto;

                hr {
                    width: 105%;
                    margin: 0 0 0 -2.5%;
                }
            }
        }
    }
}
