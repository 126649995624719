@mixin menuDownArrow {
    span {
        &.arrow-down {
            display: flex;
        }
    }
}

// Mixin to open the navigation.
@mixin openNav {
    color: $colorBlack;
    outline: none;

    @include media(bp-gt-sm) {
        + .sub-nav {
            top: $nav-main-height + $nav-secondary-height;
            opacity: 1;
        }
    }

    @include menuDownArrow();
}

// Mixing for the focus state. This mixin will add the focus state to the element with the dashed border.
// The mixin accepts 4 parameters, which are the top, right, bottom and left offsets for the focus state.
// top, right, bottom and left offsets for focus state.
@mixin focusState($top: 0, $right: 0, $bottom: 0, $left: 0, $hasDownArrow: false) {
    position: relative;

    &:focus-visible {
        outline: none;
        text-decoration: underline !important;

        // Forcing underlined text for all children.
        * {
            text-decoration: underline !important;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            border-radius: 10px;
            border: 0.125rem dashed $black;

            top: $top;
            right: $right;
            bottom: $bottom;
            left: $left;
        }

        // If $hasDownArrow is true, then add the down arrow to the focus state.
        @if $hasDownArrow {
            @include menuDownArrow();
        }
    }
}
