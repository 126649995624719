.rm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.65);
    z-index: 10010; // 9001; wasn't high enough for cart.
    display: flex;
}

.rm-container {
    height: 100%;
    background-color: #fafafa;
    outline: none;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;

    @include media(bp-lt-md) {
        min-width: 100vw;
    }

    @include media(bp-gt-sm) {
        margin-top: 1%;
        width: auto;
        height: auto;
        max-height: calc(100vh - 2%);
        padding: 35px;
    }

    @include media(bp-gt-md) {
        margin-top: 2%;
        max-height: calc(100vh - 4%);
    }

    @include media(bp-gt-lg) {
        margin-top: 3%;
        max-height: calc(100vh - 10%);
    }
}

.rm-content {
    /* @include media(bp-lt-md) {
        height: calc(100% - 25px);
        margin-top: 25px;
        overflow-y: auto;
    } */

    h2 {
        text-transform: uppercase;
        font-size: 20px;
        margin: 10px 0;
        text-align: center;

        @include media(bp-gt-sm) {
            font-size: 30px;
        }
    }

    p {
        text-align: center;
        font-size: 16px;
    }

    .store-copy {
        p {
            text-align: left;
        }
    }

    .radio {
        font-size: 17px;

        label {
            padding-left: 10px;
        }
    }

    .fl-input-container {
        margin: 14px 0 0;

        &.margin-acct {
            margin: 50px 0 30px;
        }
    }

    .time-slot-error {
        &__heading {
            text-align: center;
        }
    }
}

.edit-btn-cont {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 15px;

    :first-child {
        margin-right: 5px;
    }

    :nth-child(2) {
        margin-left: 5px;
    }

    .btn {
        width: 100%;

        /* @include media(bp-gt-sm) {
            width: 38%;
        } */

        &.secondary {
            margin-top: 0;
            margin-left: 0;
        }
    }
}

.form-footer-link {
    border: none;
    background-color: transparent;
    color: $text-color;
    text-decoration: underline;
    padding-left: 0;
    font-weight: 600;
    cursor: pointer;
}

.rm-header {
    line-height: 20px;
    height: 20px;
    > * {
        cursor: pointer;
    }

    p {
        color: transparent;
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 17px;
        margin: 0;
        line-height: 0;
        font-size: 0; //25px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 7px;
            right: 0;
            width: 20px;
            height: 2.6px;
            background-color: #4a4a4a;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }
}

.date-picker-input {
    padding-top: 20px;

    .react-date-picker__wrapper {
        padding: 8px 10px;
        border: none;
    }
}

.acct-sign-in {
    text-align: center;
    font-size: 14px;
}

.btn-sign-in {
    margin-top: 19px;
    margin-bottom: -7px;
}

.fl-input-container:not(.form-wrap) {
    .btn:first-child {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    @include media(bp-gt-sm) {
        .btn-group {
            margin-top: 20px;
            margin-bottom: 10px;

            .btn:first-child {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

.remove-underline {
    text-decoration: none;
}

#editDetails {
    select {
        -webkit-appearance: none;
        background-color: transparent;
        padding: 13px 8px;
        border-radius: 0;
    }
}

.service-record-import {
    > p {
        font-size: 15px;
        margin: 0;
        color: #ccc;

        @include media(bp-gt-sm) {
            padding: 0 5%;
        }
    }

    #phoneSearchForm {
        @include media(bp-gt-sm) {
            padding: 0 4%;
            margin-top: -5px;
        }

        + .radio {
            margin-top: 15px;

            @include media(bp-gt-sm) {
                margin-top: 48px;
            }
        }
    }

    .btn {
        margin-top: 45px;

        /* @include media(bp-gt-sm) {
            font-size: 16px;
        } */
    }
}

.rm-content {
    .create-account .fl-input-container.margin-acct {
        margin-top: 15px;
    }
    .appt-step-1 {
        @include media(bp-gt-sm) {
            .search-block {
                .btn {
                    width: auto;
                }
            }
            .result {
                .cta {
                    .btn {
                        width: auto;
                    }
                }
            }
        }
    }
}

.rm-open-body .rm-modal {
    .sign-in,
    .create-account,
    .forgot-password,
    .service-record-import,
    .vehicle-details,
    .add-custom-invoice,
    .update-login-email,
    .change-password,
    .reset-password,
    .wc-wrapper {
        @include media(bp-gt-sm) {
            min-width: 400px;
        }
    }

    .forgot-password,
    .update-login-email,
    .change-password,
    .reset-password {
        @include media(bp-gt-sm) {
            max-width: 400px;
        }
        .form-actions {
            .secondary-action .btn.secondary {
                margin-left: initial;
            }
        }
    }

    .create-account {
        @include media(bp-gt-sm) {
            max-width: 450px;
        }
    }
    .wc-wrapper {
        @include media(bp-gt-sm) {
            min-width: 350px;
        }
    }
    .appt-step {
        margin-bottom: 10px;
    }
    .appt-step-1 {
        @include media(bp-gt-sm) {
            width: 80vw;
        }
        @include media(bp-gt-md) {
            width: 90vw;
        }
        .container {
            margin-left: 1%;
            margin-right: 1%;
            .search-block {
                @include media(bp-sm) {
                    button {
                        margin-top: 5px;
                    }
                }
                @include media(bp-gt-sm) {
                    margin-bottom: 0;
                    button {
                        margin-left: 15px;
                    }
                }
            }
            .appt-step-1-results {
                height: 473px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }
}

.my-account,
.rm-open-body .rm-modal {
    max-width: 830px;
    margin: 0 auto;
    min-height: 300px;

    h1 {
        text-transform: capitalize;
        font-size: 32px;
        margin: 40px 0;
    }

    h3 {
        text-transform: capitalize;
        font-size: 30px;
        margin: 0;
    }

    p {
        font-size: 1.422rem;
    }
    .lnk {
        border: none;
        background-color: transparent;
        color: $brand-color-blue;
    }

    hr {
        border: 0;
        height: 0;
        border-top: 1px solid #ccc;
    }

    .account-nav {
        /* list styling */
        list-style-type: none;
        padding-inline-start: 0;
        /* /end list styling */

        /* layout styling */
        align-items: baseline;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        margin-top: 5vh;
        font-size: 20px;
        @include font-title;
        /* /end layout styling */

        /* fix for IE layout padding issue */
        padding-left: 0;

        @include media(bp-gt-sm) {
            li:nth-child(n) {
                flex: 1 33.3%;
            }
        }

        @include media(bp-xs) {
            font-size: 16px;
        }

        /* list item styling */
        li {
            flex: 1 33.3%;
            text-align: center;
            white-space: nowrap;
        }
        .active {
            color: $brand-color-blue;
            border-bottom-color: $brand-color-blue;
        }

        li:nth-child(odd) {
            flex: 2 1 20%;
        }

        li:nth-child(even) {
            flex: 3 2 40%;
        }

        li:first-child {
            margin-right: 5px;
        }

        li:last-child {
            margin-left: 5px;
        }

        a {
            color: $dark-gray;
            border-bottom: 4px solid #ccc;
            display: inline-block;
            width: 100%;
            padding-bottom: 12px;
            text-decoration: none;

            @include media(bp-xs) {
                border-bottom-width: 2px;
            }
        }
        /* /end list item styling */
    }

    .btn {
        width: 100%;
        padding: 15px 15px;
        border: 2px solid #d81d05;
        &:hover {
            border: 2px solid #9e1504;
        }
    }

    .btn-white-form {
        background-color: $white;
        color: $red;
        font-size: 14px;
        max-width: 136px;
        padding: 8px;

        @include media(bp-gt-sm) {
            font-size: 20px;
            max-width: 180px;
        }

        &:hover,
        &:active {
            background-color: darken($white, 10%);
            color: $black;
        }
    }
    @media only screen and (min-width: 680px) {
        .btn.add-vehicle-btn {
            width: 250px;
        }
    }
}

.rm-open-body .rm-modal,
.my-account {
    .account-details-form {
        margin: 30px 0;
    }

    .acct-form-wrap {
        display: flex;
        flex-direction: column;

        @include media(bp-gt-sm) {
            flex-direction: row;
            flex-wrap: wrap;

            .form-wrap:nth-child(odd) {
                padding-right: 25px;
            }
        }
    }

    .form-wrap {
        position: relative;

        @include media(bp-gt-sm) {
            flex: 1 35%;
            margin-top: 14px;
        }

        input,
        select {
            width: 100%;
            outline: 0;
            padding: 10px 8px;
        }

        img {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }

        select {
            background-color: $white;
        }

        ~ .btn.right-float {
            float: right;
            margin-right: 20%;
            margin-top: 10px;
            width: 25%;
        }

        ~ .btn.secondary {
            margin-left: 20%;
            margin-top: 10px;
        }
    }

    #accountDetailsUpdateForm .bottom-cta {
        text-align: center;
        margin-top: 25px;
        flex: 1 100%;
        .btn {
            display: block;
            width: 250px;
            &:first-child {
                margin-bottom: 10px;
            }
        }
    }

    .security-details {
        margin-bottom: 40px;
    }
    .acct-top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(bp-lt-md) {
            flex-direction: column;
        }
    }

    .logged-disp-wrap {
        display: flex;
        flex-direction: column;
        p {
            font-size: 16px;
        }
        .btn-micro {
            width: 100%;
            cursor: pointer;
        }
    }

    .logged-disp-email {
        @include media(bp-lt-md) {
            margin: 10px 0;
        }
    }

    .logged-disp-email,
    .logged-disp-pass {
        display: flex;

        > * {
            white-space: nowrap;
            @include media(bp-lt-md) {
                font-size: 10px;
            }
        }
    }

    .logged-disp-email > *,
    .logged-disp-pass .btn-micro {
        flex: 1;
        outline: none;
        text-align: right;
    }

    .logged-disp-pass p {
        flex: 1 27%;
    }

    .logged-disp-wrap {
        @include media(bp-lt-md) {
            margin-top: 10px;

            .btn-micro {
                padding: 0 10px;
            }
        }

        p {
            margin: 5px;

            @include media(bp-lt-md) {
                margin: 0;
                flex: 1 16%;
            }
        }
    }
}

@media print {
    body.rm-open-body {
        overflow: visible !important;
        #wrapper > header,
        #wrapper > footer.site-footer,
        #wrapper > .coral-modal,
        #wrapper > #main {
            display: none;
            height: 0;
            width: 0;
        }
        .rm-modal,
        .rm-modal > .rm-overlay,
        .rm-modal > .rm-overlay > .rm-container {
            display: block;
            height: auto;
            max-height: auto;
            overflow: visible;
            overflow-y: visible;
            position: relative;
        }
        .rm-content {
            display: block;
            width: 100%;
            height: auto;
            max-height: auto;
            overflow: visible;
            overflow-y: visible;
            position: relative;
        }
    }
}
