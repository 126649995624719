.product-results-grid--v2 {
    .container {
        @include media(bp-lt-md) {
            margin: 0;
        }

        .results-container {
            width: 100%;
            clear: both;
            float: none;
            // padding-top: 1.92%;

            // @include media(bp-lt-md) {
            // padding-top: 0;
            // }
        }
    }

    .disclaimer {
        display: inline-block;
        float: none;
        padding: 22px;
        margin: 0;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
            }
        }
    }

    .seo-footer-content-container {
        margin-left: 20px;
        margin-right: 20px;
        padding: 52px 0;

        // @media screen and (max-width: 768px) {
        //     background: #ebebeb;
        // }

        .seo-footer-content {
            color: #666;
            margin: 0 auto;
            text-align: center;

            h3 {
                color: #666;
                margin-top: 0;
            }

            hr {
                width: 50%;
                border-style: solid;
                border-top: 0;
                color: $brand-color-red;
            }
        }
    }
}
