@import '~common/scss/shared/shared';

$nav-bg-color: #2d2d2d;
$nav-height: 131px;

$nav-main-height: 90px;
$nav-main-vr-bg-color: #ebebeb;

$nav-main-sub-nav-div-bg-color: #fdfdfd;
$nav-main-sub-nav-div-width: 100%;
$nav-main-sub-nav-div-height: 370px;
$nav-main-sub-nav-store-info-height: 278px;

$nav-secondary-height: 40px;

$nav-action-bg-color: #717171;
$nav-action-height: 31px;
$z-nav: 9000;

$colorYellow: #fdf200;
$colorDarkYellow: #eb8f2a;
$colorPaleYellow: #fbfad6; // lighter than light yellow // used in tables
$colorPaleDirtyYellow: #f6f5d2; // slightly greyer than pale yellow // used in tables
$colorLightYellow: #fcf8af; // used in tables

$colorOrange: #f38f1d;
$colorLightOrange: #f5a716;
$colorRed: #ee3d42;

$colorGrey: #565656; //#4e4e4e;        // default text color;
$colorDarkGrey: #747474;

$colorDivider: #bcbcbc; // this color is used for single line dividers/borders
$colorLightDivider: #d5d5d5; // there are some instances where the divider line is designed to be lighter.
$colorDarkDivider: #6a6a6a; // used in some tables with dark backgrounds.
$colorLinkDefault: $colorRed;
$colorLinkDefault_hover: $colorOrange;
/* End Clean Up/Remove */

$nav-link-color: $dark-gray;
$nav-link-color-hover: $brand-color-red;

// ** functional css for nav rollover ** //
header,
.experience-fragment {
    &:has(.author) {
        display: block;
    }
    & nav.top-nav {
        height: $nav-height;
        width: 100%;
        z-index: $z-nav;
        position: relative;
        border-bottom: 1px solid #ebebeb;

        -webkit-box-shadow: 0px 4px 3px -2px rgba(50, 50, 50, 0.1);
        -moz-box-shadow: 0px 4px 3px -2px rgba(50, 50, 50, 0.1);
        box-shadow: 0px 4px 3px -2px rgba(50, 50, 50, 0.1);
        .bsro-ui-global-nav-v2 & {
            display: none;
        }

        // default color for nav elements only in nav elements
        h2,
        h3,
        h4,
        p {
            color: $colorGrey;
        }

        h4 {
            font-size: 14px;
            margin-bottom: 0;
        }

        ul,
        li,
        a {
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none !important;
            color: $nav-link-color;

            span.label {
                display: none;
            }
        }

        li {
            list-style-type: none;
            vertical-align: top;

            .sub-nav {
                -webkit-box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);
                -moz-box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);
                box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);

                position: absolute;
                top: -999em; // position offscreen
                z-index: $z-nav + 1;
                overflow: hidden;
                text-align: left;
                cursor: default;

                hr {
                    margin: 0;
                    height: 14px;
                }

                &.overflow-visible {
                    overflow: visible;
                }
            }

            &:hover {
                .sub-nav {
                    top: auto;
                }
            }
        }

        .mobile-only {
            display: none !important;

            &.find-a-store {
                margin-bottom: 17px;
            }
        }

        .cart {
            display: none;
            a {
                &:hover,
                &:focus {
                    small {
                        display: none;
                    }
                }
            }

            &.mobile-only {
                position: absolute;
                top: 14px;
                right: 70px;
            }

            .cart-container {
                display: block;
                position: relative;
                width: 38px;
                height: 29px;

                small {
                    position: absolute;
                    top: 1px;
                    right: 5px;
                    color: white;
                    font-size: 65%;
                    font-weight: bold;
                }
            }
        }

        @media only screen and (max-width: 680px) {
            .desktop-only {
                display: none !important;
            }

            .mobile-only {
                display: block !important;
            }
        }
        .checkout-page-heading {
            color: $black;
            float: left;
            margin-left: 5%;
            @include media(bp-gt-md) {
                margin-left: 3%;
            }
            @include media(bp-gt-lg) {
                margin-left: 2%;
            }
            @include media(bp-lt-md) {
                display: none;
            }
        }
        .checkout-phone-header-wrap {
            color: $black;
            float: right;
            text-align: right;
            margin-top: 3px;
            white-space: nowrap;
            @include media(bp-lt-md) {
                display: none;
            }
            @include media(bp-gt-sm) {
                width: 27%;
            }
            @include media(bp-gt-md) {
                width: 20%;
            }
            h4 {
                color: $black;
                float: left;
                font-family: $font-family-base !important;
                font-size: 1.2rem;
                letter-spacing: 0.01em;
                line-height: 1.422em;
                .h3 {
                    font-family: $font-family-base !important;
                    font-size: 2.4rem;
                    font-weight: bold;
                }
            }
        }
    }
}
// ** END functional css for main nav rollover ** //

.top-nav > .primary-nav {
    position: relative;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    background-color: $white;
}

.top-nav .main-nav-wrapper-container {
    @media only screen and (max-width: 680px) {
        position: absolute;
        top: 60px;
        width: 0;
        height: 100vh;
        overflow: hidden;
        transition: width 0.5s;
        right: 0;
    }

    &.is-active {
        width: 100%;
        transition: width 0.5s;
    }
}

.primary-nav {
    @extend .tab-nav;

    height: $nav-main-height !important;
    width: 100%;
    padding: 0;
    font-size: 16px;

    > ul {
        > li {
            border-left: 1px solid $nav-main-vr-bg-color;
            vertical-align: middle;
            height: 64px;

            > a {
                span:not(.arrow-down) {
                    padding-top: 16px;
                    padding-bottom: 15px;

                    @include media(bp-lt-sm) {
                        padding-top: 0 !important;
                    }
                }

                span.arrow-down {
                    display: none;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                }
            }

            .sub-nav {
                background: $nav-main-sub-nav-div-bg-color;
                height: $nav-main-sub-nav-div-height;
                width: $nav-main-sub-nav-div-width;
                left: 0;
                right: 0;
                // to show border on IE8
                .no-boxshadow & {
                    border: 1px solid $colorLightDivider;
                    border-top: 0;
                }
            }

            &.last-child {
                .sub-nav {
                    width: 680px;
                }

                border-left: 0px;
            }

            @include media(bp-gt-sm) {
                &:hover {
                    background-color: transparent;
                    cursor: default;

                    > a {
                        position: relative;
                        background-color: transparent;
                        color: $brand-color-red;
                        z-index: $z-nav + 2;

                        span.arrow-down {
                            display: block;
                        }
                    }
                }
            }

            &.active {
                &:hover {
                    > a {
                        // when border-bottom is 0 the <a> shifts vertically causing a blip due to the change in height
                        // so changing the border from 0 to 5px white fixes this issue.
                        ////border-bottom: 5px solid white;
                    }
                }

                > a {
                    ////border-bottom: 5px solid black; //tmp
                    span {
                        ////padding-top: 16px !important;
                        ////padding-bottom: 15px;
                    }
                }
            }
        }

        li {
            > a {
                span {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .logo {
        border: 0;
    }

    .no-border-left {
        border: 0px;
    }

    #tab-my-store-container {
        &.seo-page {
            #SetZipForm {
                display: block;
            }
            .store-wrapper-mobile {
                display: none;
            }
            .store-wrapper {
                display: none;
            }
        }
    }

    .sub-nav-close {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1199;
        cursor: pointer;
    }

    .zipcodeError {
        display: block;
        color: #ee3d42;
        font-size: 14px;
    }

    .f-shield-mobile {
        position: absolute;
        top: 17px;
        right: 127px;
        width: 21px;
        height: 27px;

        img {
            width: 20px;
        }
    }
}

.secondary-nav {
    position: relative;
    height: $nav-secondary-height;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    background-color: #ebebeb;
    color: $dark-gray;

    .links {
        max-width: 1440px;
        height: 100%;
        margin: 0 auto;
        padding: 10px 15px 0;
        color: $dark-gray;
    }

    .links a {
        border-left: 1px solid $dark-gray;
        padding: 0 6px;

        &.f-shield-desktop {
            border-left: 0;
            padding-left: 3px;

            img {
                width: 20px;
            }
        }

        &:first-child {
            border-left: 0;
        }

        &:hover {
            text-decoration: underline !important;
        }
    }
}

.util-nav,
.action-nav {
    color: $black;
    font-size: 12px;
    display: inline-block;
    height: 100%;
}

.util-nav {
    > ul {
        position: relative;
        top: 13px;
        display: table;

        li {
            border-right: 1px solid black;
            border-left: 1px solid #424242;
            display: table-cell;
            vertical-align: middle;
            padding: 0 22px;

            > a {
                display: table;
                width: 100%;
                height: 100%;
                text-align: center;

                span {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            &:first-child {
                border-left: 0;
                padding-left: 0;
            }

            &:last-child,
            &.last-child {
                border-right: 0;
            }
        }

        strong {
            color: $colorYellow;
            margin-right: 25px;
            font-size: 22px;
            line-height: 22px;
        }
    }

    .icon-login {
        margin-left: 8px;
    }
}

.search {
    position: absolute;
    top: 44px;
    right: 10%;
    height: $nav-main-height;

    input[type='text'] {
        height: 32px;
        width: 227px; //90%;
        padding: 2px 16px 0 8px;
        border: 1px solid #bbb300;
        margin: 16px 18px 0 0; //16px -23px 0 0;
        color: $black;
        font-size: 13px;

        .lt-ie9 & {
            padding: 7px 16px 0 8px;
        }
    }

    input[type='image'] {
        position: absolute;
        top: 26px;
        right: 24px;
    }

    .icon-search-arrow {
        cursor: pointer;
        margin-right: 32px;
    }
}

/* OPTIMIZE */
.sub-nav-content {
    text-align: left;
    width: 100%;
    max-width: $wrapper-max-width;

    > img {
        float: left;
    }

    h3 {
        margin-top: 0;
    }

    .services,
    .service-items,
    .schedule-cta {
        float: left;
        height: 100%;
    }

    .services {
        width: 77%;
        padding: 55px 0 55px 31px;
    }

    .service-items {
        width: 49.72%;
        border-right: 1px solid #ccc;

        > a {
            display: inline-block;
            width: 32%;
            height: auto;
        }
    }

    .schedule-cta {
        width: 28.05%;
        padding-left: 40px;

        h3 {
            margin-bottom: 12px;
            font-size: 16px;
        }
    }

    h2 {
        font-size: 24px;
    }

    h4 {
        font-size: 14px;
    }

    p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    > ul {
        display: table;
        position: relative;
        margin: 0 0 15px 0;
        padding: 0;
        width: 100%;
    }

    > ul > li {
        height: 100%;
        display: table-cell;
        width: 12%;
        text-align: center;
        vertical-align: top;
        border-right: 1px solid #d4d4d4 !important;
        border-left: 0;

        > a {
            font-size: 14px;
            display: block;
            text-align: center;
            padding: 0 5px;

            span {
                display: block;
                padding: 0;
                margin-bottom: 5px;
            }

            &:hover {
                color: $colorOrange !important;
            }

            &:active {
                color: $colorRed !important;
            }
        }
    }

    li:first-child {
        border-left: 0px !important;
    }

    li:last-child {
        border-right: 0px !important;
    }

    li:hover {
        &:last-child {
            border-right: 0px !important;
        }
    }

    .col1,
    .col2 {
        font-size: 11px;
        padding-top: 8px;
        padding-bottom: 6px;
        border-color: #e3e3e3;

        i:first-child {
            position: absolute;
        }

        div {
            margin-left: 17.35%;
            padding-top: 5px;

            h4 {
                font-size: 14px;
            }

            p {
                font-size: 11px;
                margin-bottom: 2px;
                line-height: 1.25em;
            }

            a {
                font-weight: bold;
                &:hover {
                    color: $colorOrange;
                }
            }
        }
    }

    &.offers-and-coupons {
        hr {
        }

        .col1 {
            position: relative;
            div {
                margin-left: 24%;

                h4 {
                    font-size: 16px;
                }

                p,
                a {
                    font-size: 13px;
                }
            }
        }
    }

    .col {
        height: 170px;
        width: 195px;
        margin-right: 30px;
        margin-top: 0px;

        > a {
            display: table;
            float: left;
            height: 78px;
            width: 100%;
            border-bottom: 1px solid #ccc;
            font-size: 18px;

            span {
                display: table-cell;
                vertical-align: middle;
            }

            span.icon {
                width: 60px;
                height: 60px;
            }

            background: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2OUE4RTk2RTk1MDQxMUU1OEUwNURGQUFDNjAyRDY1MiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2OUE4RTk2Rjk1MDQxMUU1OEUwNURGQUFDNjAyRDY1MiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY5QThFOTZDOTUwNDExRTU4RTA1REZBQUM2MDJENjUyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY5QThFOTZEOTUwNDExRTU4RTA1REZBQUM2MDJENjUyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+oIGkCwAAAGpJREFUeNpiuCHHKgjEM0H0////GZAxEwMEGAPx7pvybIIMSIBJ/eGv90DaFcpHUQDWiUsBI8hsGIAK7oZyXZmQ7YCacBbqBhcU10Fd/R+I08Am4pKAS2KTAEsCBYyxSSDrVEKXAGGAAAMAO/p7GVs+ppgAAAAASUVORK5CYII=)
                transparent no-repeat right center;

            &:first-child {
                border-bottom: 1px solid #ccc !important;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
    &.has-chatbot-link {
        & > .col.details {
            & > a.chatbot-header-link {
                align-items: center;
                background: none;
                border: none !important;
                color: $black;
                display: flex;
                @include font-body;
                font-size: 16px;
                height: auto;
                margin-bottom: 7px;
                text-decoration: underline !important;
                text-transform: uppercase;
                &::before {
                    background: url('https://s7d1.scene7.com/is/content/bridgestone/chat-bubble-icon-global-web-bsro')
                        no-repeat;
                    background-size: contain;
                    content: '';
                    display: inline-block;
                    height: 25px;
                    margin-right: 5px;
                    width: 25px;
                    vertical-align: middle;
                }
            }
            & > h3.phone-with-chatbot {
                align-items: center;
                color: $black;
                display: inline-flex;
                font-family: $font-family-base !important;
                font-size: 16px;
                &:before {
                    background: url('https://s7d1.scene7.com/is/content/bridgestone/PHONE-ICON-GLOBAL-WEB-BSRO')
                        no-repeat;
                    background-size: contain;
                    content: '';
                    display: inline-block;
                    height: 20px;
                    margin-right: 5px;
                    width: 20px;
                    vertical-align: middle;
                }
            }
        }
    }
}

/* CONTENT SPECIFIC CSS */

/*
 * SHOP FOR TIRES
 * Tab Contents
*/

.tabs.shop-for-tires {
    padding: 20px 0 20px 20px;
    position: absolute;
    left: 23%;
    top: 0;
    width: 77%;

    h3 {
        font-size: 20px;
    }

    .tab-nav {
        width: 23.17%;
        vertical-align: top;
        border-right: 1px solid #ccc;
        padding-right: 30px;

        > ul > li {
            height: 36px;
            background: transparent !important;

            > a {
                color: $nav-link-color;
                padding-left: 0;
                cursor: pointer !important;
                text-transform: none;
                font-size: 14px;
                line-height: 0.9em;

                @include media(bp-gt-sm) {
                    font-size: 16px;
                }

                @include media(bp-gt-md) {
                    font-size: 18px;
                }

                span {
                    padding-left: 0;
                    padding-right: 18px;
                }
            }

            span.indicator {
                display: block;
                margin: 0 0 0 -8px;
                background: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2OUE4RTk2RTk1MDQxMUU1OEUwNURGQUFDNjAyRDY1MiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2OUE4RTk2Rjk1MDQxMUU1OEUwNURGQUFDNjAyRDY1MiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY5QThFOTZDOTUwNDExRTU4RTA1REZBQUM2MDJENjUyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY5QThFOTZEOTUwNDExRTU4RTA1REZBQUM2MDJENjUyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+oIGkCwAAAGpJREFUeNpiuCHHKgjEM0H0////GZAxEwMEGAPx7pvybIIMSIBJ/eGv90DaFcpHUQDWiUsBI8hsGIAK7oZyXZmQ7YCacBbqBhcU10Fd/R+I08Am4pKAS2KTAEsCBYyxSSDrVEKXAGGAAAMAO/p7GVs+ppgAAAAASUVORK5CYII=)
                    transparent no-repeat right center;
            }

            > a:hover {
                color: $nav-link-color-hover;
            }

            &.active {
                span.indicator {
                    margin: 0 0 0 -8px;
                }

                > a {
                    color: $nav-link-color;
                }

                a:hover {
                    color: $nav-link-color-hover;
                }

                background: transparent !important;
                .ie & {
                    span.indicator {
                        width: 6px;
                        right: -5px;
                        margin: 0 0 0 0;
                        background-position: center center;
                    }
                }
            }
        }
    }

    .tab-content {
        width: 76.5%;
        padding-left: 35px;
    }
}

.primary-nav .store-info {
    border-left: 0;
    .sub-nav {
        width: 680px;
    }

    .sub-nav .col {
        margin-right: 0;
        height: 100%;

        a.directions {
            display: inline-block;
            float: right;
            margin-top: 15px;
            text-align: center;
            text-decoration: underline !important;

            img {
                display: block;
            }
        }

        &.map {
            width: 45%;
            > img {
                border: 1px solid #cccccc;
            }
        }

        &.details {
            width: 54.7%;
        }

        table td:first-child {
            min-width: 100px;
        }
    }

    .directions {
        text-align: center;
        text-transform: uppercase;

        &:before {
            @include icon('map-signs', $black);
            display: block;
            margin: 0 auto;
        }
    }

    .holiday {
        color: $brand-color-red;
    }
}

@media print {
    #SetZipForm {
        display: none !important;
    }
}

@import '~common/components/content/tabs/v1/scss/tabs';
