@use 'sass:math';
@import '~common/scss/shared/shared';

$product-results-mobile-padding: 15px;
$quote-container-margin-left: percentage(math.div(1, 7));
$quote-container-margin-right: percentage(math.div(1, 14));
$product-results-bg-color: #ebebeb;
$product-results-border-color: #dcdcdc;
$grey-text-color: $dark-gray;
$error-text-color: $brand-color-red;

.product-results {
    @include outer-container;
    margin: 10px auto 0;

    @include media(bp-lt-md) {
        position: relative;
        padding: 0;
        clear: left;

        .desktop-only {
            display: none !important;
        }

        > .container {
            margin: 0;

            .error-message {
                margin: 0 4.86111%;
            }
        }
    }

    .btn {
        padding-right: 50px;
        padding-left: 50px;
    }

    // FILTER
    aside {
        width: percentage(math.div(3, 13));
        margin-right: percentage(math.div(5, 130));
        float: left;

        @include media(bp-lt-md) {
            @include gradient-top(30px, #ebebeb, $white);
            border: none;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
        }

        .title {
            margin: 0;
            padding: 20px;
            background-color: $product-results-bg-color;
            font-size: 20px;
            font-weight: normal;

            @include media(bp-lt-md) {
                display: none;
            }
        }

        ul {
            margin: 0;
            padding: 0;

            list-style-type: none;
        }

        li {
            display: block;
            padding-bottom: 10px;

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        @include media(bp-lt-md) {
            display: none;
            position: absolute;
            top: 120px; // this has to match the height of .toolbar-top
            width: 100%;
            margin-right: 0;
            float: none;
            z-index: 100;

            &.active {
                display: block;
            }

            .label {
                margin-bottom: 0;
            }

            li {
                padding-bottom: 15px;

                &:last-of-type {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .filter {
        border: 1px solid $product-results-border-color;

        @include media(bp-lt-md) {
            padding-top: 25px;
        }

        &.mobile-only {
            display: block !important;
        }
    }

    .filter-toolbar {
        @include outer-container;

        display: none;
        margin: 0 15px 20px;

        @include media(bp-lt-md) {
            display: block;

            .btn {
                width: auto;
                padding-right: 35px;
                padding-left: 35px;
                float: left;
            }

            .close-btn {
                float: right;
                cursor: pointer;
            }
        }

        .close-btn {
            position: relative;
            margin: 15px 32px 0 0;
            font-size: 15px;
            text-transform: uppercase;
            text-decoration: none;

            &:after {
                display: inline-block;
                position: absolute;
                top: -2px;
                right: -33px;
                width: 0;
                height: 0;
                padding: 11px 14px 11px 8px;
                border-radius: 15px;
                font-size: 11px;
                font-weight: bold;
                line-height: 0;
                content: 'X';
            }
        }
    }

    .filter-toolbar-bottom {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .category {
        margin: 0 20px;
        padding: 20px 0;
        border-bottom: 1px solid $product-results-border-color;

        &:last-child {
            border-bottom: none;
        }

        @include media(bp-lt-md) {
            margin: 0 15px;
            padding: 0;
        }
    }

    .accordion {
        .label,
        .folder {
            margin: 0 0 8px;
            padding: 0;
            text-decoration: underline !important;
            border-bottom: 0;
            background-color: transparent;
            font-size: 16px;
            font-weight: normal;
            text-transform: none;

            &:after {
                //@include font-body;
                right: 0;
                font-size: 24px;
            }

            @include media(bp-lt-md) {
                margin: 0;
                padding: 15px 0;
            }
        }
        .show_full_disclaimer {
            color: $brand-color-red !important;
            text-decoration: underline !important;
            font-size: 14px !important;
        }
    }

    // RESULTS COLUMN
    .results-container {
        width: percentage(math.div(95, 130));
        float: left;

        @include media(bp-lt-md) {
            width: 100%;
            float: none;
        }
    }

    // RESULTS TOOLBAR
    .toolbar {
        display: table;
        width: 100%;

        @include media(bp-lt-md) {
            display: block;
        }
    }

    // .compare {
    //     display: table-cell;
    // }

    .toolbar-top {
        padding: 35px 0 30px;
        border-top: 1px solid $product-results-border-color;

        @include media(bp-lt-md) {
            @include outer-container;
            height: 120px;
            padding: 0 $product-results-mobile-padding;
            border-top: none;
        }
    }

    .toolbar-bottom {
        padding: 30px 0 40px;
        border-bottom: 1px solid $product-results-border-color;

        @include media(bp-lt-md) {
            display: none;
        }
    }

    .number-of-results {
        display: table-cell;
        vertical-align: middle;
        font-size: 18px;
        text-transform: uppercase;

        @include media(bp-lt-md) {
            display: block;
            width: 100%;
            text-align: center;
            padding-bottom: 16px;
            font-size: 30px;
        }

        @include media(bp-lt-sm) {
            padding-bottom: 16px;
        }
    }

    .sort {
        display: table-cell;
        text-align: center;
        vertical-align: middle;

        label {
            margin-right: 4px;
            font-weight: bold;
            vertical-align: middle;
            font-size: 14px;
        }

        .custom-select {
            display: inline-block;
            width: 150px; //160px;
            vertical-align: middle;
        }

        @include media(bp-lt-md) {
            display: block;
            float: left;
        }
    }

    .compare-btn {
        text-align: right;
        vertical-align: middle;

        @include media(bp-lt-md) {
            display: none;
        }
    }

    .filter-btn {
        display: none;

        @include media(bp-lt-md) {
            display: block;
            float: right;
            cursor: pointer;
            margin-top: 5px;
        }

        a {
            position: relative;
            margin-right: 21px;
            font-size: 17px;
            text-transform: uppercase;
            text-decoration: none;

            &:after {
                position: absolute;
                display: inline-block;
            }
        }
    }

    // PRODUCT
    .product {
        display: table;
        position: relative;
        width: 100%;
        margin-top: 45px;
        border: 1px solid $product-results-border-color;

        &:first-of-type {
            margin-top: 0;
        }

        @include media(bp-lt-md) {
            display: block;
            margin-top: 0;
            border-top: none;
            border-right: none;
            border-left: none;

            &:first-of-type {
                border-top: 1px solid $product-results-border-color;
            }
        }
    }

    // IMAGE CONTAINER
    .img-container {
        display: table-cell;
        position: relative;
        width: percentage(math.div(260, 950));
        padding: 0 10px 30px;
        text-align: center;

        @include media(bp-lt-md) {
            display: block;
            position: initial;
            width: 40%;
            padding: 0 25px 25px;
            float: left;
        }
    }

    .rating {
        position: absolute;
        top: 0;
        left: 0;
        height: 26px;
        padding: 3px 25px 4px 12px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            content: '';
        }

        @include media(bp-lt-md) {
            right: 15px;
            left: auto;
            width: 60px;
            height: auto;
            padding: 6px 4px 2px;
            line-height: 1em;
            text-align: center;
            font-size: 11px;

            &:before {
                top: auto;
                bottom: -16px;
                border-right: 30px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 30px solid transparent;
            }
        }
    }

    .product-img {
        padding-top: 45px;

        @include media(bp-lt-md) {
            padding-top: 40px;
        }
    }

    .tsr-card {
        &:hover {
            .compare-button {
                background-color: #c11b03;

                &:hover {
                    background-color: $brand-color-red;
                }
            }

            .container {
                color: $white;

                input:checked ~ .checkmark {
                    background-color: white;
                    border-color: #c11b03;

                    &:after {
                        border-color: black;
                    }
                }
            }
        }

        & .compare-button {
            height: 44px;

            &.compare-checkbox {
                &.show {
                    background-color: #c11b03;
                    &:hover {
                        background-color: $brand-color-red;
                    }

                    & .container {
                        color: $white;

                        input:checked ~ .checkmark {
                            background-color: white;
                            border-color: $brand-color-red;

                            &:after {
                                border-color: black;
                            }
                        }
                    }
                }
            }
        }
    }

    // COPY CONTAINER
    .copy-container {
        display: table-cell;
        width: percentage(math.div(390, 950));
        padding: 44px 10px 10px 0;
        vertical-align: top;

        ul {
            margin: 0;
            padding: 0;

            list-style-type: none;
        }

        li {
            display: block;
        }

        .coupon,
        .rebate {
            display: none;

            a {
                position: relative;

                &:before {
                    position: absolute;
                }
            }
        }

        .price {
            display: none;
            font-weight: normal;

            span {
                font-size: 32px;
            }
        }

        @include media(bp-lt-md) {
            display: block;
            width: 60%;
            padding: 40px 15px 20px 0;
            float: left;

            ul.product-specs {
                margin: 8px 0;
                list-style: disc inside;
            }

            li {
                display: list-item;
            }

            .coupon,
            .rebate {
                display: block;
            }

            .price {
                display: block;
            }
        }
    }

    .product-name {
        margin: 0.75em 0 0.25em;
        text-transform: uppercase;
        font-size: 20px;

        @include media(bp-lt-md) {
            font-size: 18px;
            line-height: 1.4 !important;
        }
    }

    .product-id {
        margin: 0.25em 0;
    }

    // QUOTE CONTAINER
    .quote-container {
        display: table-cell;
        width: percentage(math.div(280, 950));
        padding: 10px 35px; // padding: 35px percentage(35/950); the percentage width was breaking in IE because of mix of table-cell, padding %, and link hover.
        vertical-align: middle;
        background-color: $product-results-bg-color;

        @include media(bp-lt-md) {
            background-color: transparent;
        }

        .coupon,
        .rebate {
            min-height: 60px;
            line-height: 1.2em;
            padding-top: 20px;
            box-sizing: border-box;

            > span {
                display: block;
                position: relative;
                margin-left: 40px;

                &:before {
                    position: absolute;
                    left: -40px;
                }
            }
        }

        .price {
            line-height: 3em;
            white-space: nowrap;

            @include media(bp-lt-lg) {
                span {
                    font-size: 36px;
                }
            }

            .retail-price {
                font-size: 30px;
            }
            .front-rear {
                font-size: 14px !important;
                color: $grey-text-color !important;
                line-height: 1.5em;
                text-align: center;
            }
        }

        .small {
            font-size: 20px;
            top: -1.2em;
        }

        .btn {
            width: 100%;
            max-width: 200px;
            padding-right: 5px;
            padding-left: 5px;
        }

        @include media(bp-lt-lg) {
            padding: 20px 15px;

            .small {
                top: -0.6em;
            }

            .coupon,
            .rebate {
                min-height: 75px;
            }

            .price {
                line-height: 2em;

                .retail-price {
                    font-size: 24px !important;
                }

                span {
                    font-size: 30px !important;
                }
            }
        }

        @include media(bp-lt-md) {
            display: block;
            width: 100%;
            padding: 25px 15px 40px;

            .coupon,
            .rebate {
                display: none;
            }

            .price {
                display: none;
            }

            .btn {
                max-width: none;
            }
        }
    }

    .front-rear {
        display: block;
        font-size: 14px !important;
        color: $grey-text-color !important;
        text-align: center;
    }

    @include media(bp-lt-sm) {
        .retail-price {
            font-size: 22px !important;
        }
    }

    .details {
        line-height: 1em;

        @include media(bp-lt-md) {
            display: none;
        }
    }

    .availability {
        margin-bottom: 1.5em;
        font-weight: bold;

        @include media(bp-lt-md) {
            display: none;
        }
    }

    .no-result-error {
        font-size: 20px;
        color: $error-text-color;
        margin: 1.92%;
    }

    // FOOTER
    .disclaimer {
        margin: 40px 0;

        a {
            display: inline-block;
            margin-top: 1em;
        }

        @include media(bp-lt-md) {
            margin: 20px 0;
            padding: 0 15px;
        }
    }

    // CREDIT PROMOTION
    .credit-promotion {
        margin-top: 30px;

        p {
            margin: 0;

            a {
                display: block;
            }
        }

        .footer-tool {
            width: 100% !important;
            float: none !important;
        }

        // @include media(bp-lt-lg) {
        //     display: none;
        // }

        @include media(bp-gt-sm) {
            @include outer-container;

            margin-top: 30px;

            p {
                // width: percentage(21/30);
                margin: 0;
                //float: right;
            }

            .promotion-img {
                width: percentage(math.div(9, 30));
                padding-right: 15px;
                float: left;
            }

            &.mobile-only {
                display: none;
            }
        }

        @include media(bp-lt-md) {
            padding: 35px 15px 25px;
            text-align: center;

            .credit-promotion-title {
                margin: 0;
            }
        }
    }

    // GUARANTEE
    .guarantee-carousel {
        @include media(bp-gt-sm) {
            @include outer-container;
            margin-bottom: 20px;

            .title {
                margin: 0 15px 0 0;
                float: left;

                span {
                    display: block;
                }

                & + p {
                    clear: left;
                }
            }

            .carousel {
                display: table;
                width: 530px;
                height: 60px;
                margin-bottom: 10px;
                float: right;
            }

            .slide {
                display: table-cell;
                text-align: right;
                vertical-align: bottom;

                &:first-child {
                    padding-left: 0;
                }
            }

            &.mobile-only,
            .mobile-tooltip-alt {
                display: none;
            }
        }

        @include media(bp-lt-md) {
            padding: 35px 15px 25px;
            text-align: center;

            .title {
                margin: 0 0 0.5em;
            }

            img {
                margin: 0 auto;
            }

            .coral-Tooltip {
                display: none !important;
            }

            .mobile-tooltip-alt {
                display: block;
            }
        }
    }

    .hide-price,
    .no-price {
        display: none;
    }

    .no-strike {
        text-decoration: none;
    }

    .price-no-discount,
    .price-front-discount,
    .price-rear-discount {
        display: none;
        .retail-price,
        .discounted-price,
        .total-price {
            &:before {
                content: '$';
            }
        }
    }

    .price-no-discount {
        .price {
            .retail-price {
                font-size: 46px;
                text-decoration: none;
            }
        }
    }

    .results.ecom,
    .gbb {
        .coupon,
        .rebate {
            display: none;
        }

        .with-rebate {
            .copy-container {
                @include media(bp-lt-md) {
                    .rebate {
                        display: block;

                        &.rear {
                            display: none;
                        }
                    }
                }
            }

            .quote-container {
                @include media(bp-gt-sm) {
                    > .rebate,
                    > .price-front-tire > .rebate {
                        display: block;
                    }
                }

                @include media(bp-gt-md) {
                    > .rebate {
                        display: block;
                    }
                }
            }
        }

        .with-rebate-rear {
            .quote-container {
                @include media(bp-gt-sm) {
                    > .rebate,
                    > .price-rear-tire > .rebate {
                        display: block;
                    }
                }

                @include media(bp-gt-md) {
                    > .rebate,
                    > .price-rear-tire > .rebate {
                        display: block;
                    }
                }
            }
        }

        .with-coupon {
            .price-front-discount {
                display: block;
            }

            .copy-container {
                .price-no-discount {
                    display: none;
                }

                @include media(bp-lt-md) {
                    .coupon {
                        display: block;

                        &.rear {
                            display: none;
                        }
                    }
                }
            }

            .quote-container {
                @include media(bp-gt-sm) {
                    > .coupon,
                    > .price-front-tire > .coupon {
                        display: block;
                    }

                    > .price-rear-tire > .price-no-discount {
                        display: none;
                    }
                }

                @include media(bp-gt-md) {
                    > .coupon,
                    > .price-front-tire > .coupon {
                        display: block;
                    }

                    > .price-rear-tire > .price-no-discount {
                        display: none;
                    }
                }
            }
        }

        .with-price {
            .price-no-discount {
                display: block;
            }
        }

        .with-coupon-rear {
            .price-rear-discount {
                display: block;
            }

            .quote-container {
                @include media(bp-gt-sm) {
                    > .coupon,
                    > .price-front-tire > .coupon {
                        display: block;
                    }

                    > .price-rear-tire > .price-no-discount {
                        display: none;
                    }
                }

                @include media(bp-gt-md) {
                    > .coupon,
                    > .price-front-tire > .coupon {
                        display: block;
                    }

                    > .price-rear-tire > .price-no-discount {
                        display: none;
                    }
                }
            }
        }

        .without-price {
            .no-price {
                display: block;

                @include media(bp-lt-md) {
                    margin-left: 40%;
                }
            }

            .tire-quote-btn {
                display: none;
            }
        }

        .with-hidden-price {
            .price-front-discount,
            .price-rear-discount,
            .price-no-discount {
                display: none !important;
            }

            .hide-price {
                display: block;
                font-size: 16px;
                font-weight: bold;

                > p {
                    margin: 20px 0;
                }
            }
        }
    }

    .accordion .folder {
        padding: 12px 0;
        border-bottom: 0;
        background-color: transparent;
        text-transform: uppercase;
        text-decoration: underline !important;

        &:after {
            display: none;
        }

        a {
            font-size: 14px;
            text-transform: none;
        }
    }

    .coral-Tooltip {
        max-height: 500px;
        font-size: 10px;
        line-height: 1.1em;
        text-align: left;
    }

    .discount-show {
        display: block;
    }

    .discount-hide {
        display: none;
    }

    .error-display {
        width: 29%;
        background: #dbdbdb !important;
        margin: 0 auto;
        padding: 25px;
        box-sizing: border-box;

        h4 {
            text-transform: none;
            font-size: 19px;
        }

        .btn {
            display: block;
            margin: 0 auto;
            width: 80px;
            padding: 10px 0;
        }
    }
}
