@use "sass:math";
@import '~common/scss/shared/shared';

// base selector is more specific because needs to override styles from base tabs.scss
.product-detail-tabs.tabs.tab-top {
    position: relative;

    .container {
        @include media(bp-lt-md) {
            margin: 0;
        }
    }
	
	&.reskin {
		.container {
			.product-detail-tread-info {	
				padding-left:25%;
				
				.inner1 .wrap{
					line-height: 3em;
				}
				
				.inner {
					padding: 15%;
					padding-left: 2%
				}
				
				.accordion .folder {
					line-height: 1.8em;
					left: 0%;
				}
				
				.wrap div {
					font-size:14px;
				}
				
				.wrap > div span {
					color: $brand-color-blue;
					display: block;
					line-height: 0.8em;
					text-align: center;
				}
			}
			.tab-content{
				h2 {
					text-align:center;
				}
				section{
					&.active{
						display:flex;
					}
					
					&.active div{
						flex:1;
						border-right:1px solid #ebebeb;
						padding: 2%;
					}
					
				}
			}
		}
	}
    .tab-nav {
        ul,
        li {
            position: static !important;

            a:hover,
            a:active,
            a:focus {
                outline: 0;
            }
        }

        li.active {
            pointer-events: auto !important;
            + a {
                pointer-events: none;
            }
        }

        @include media(bp-lt-md) {
            display: none;
        }
    }

    .tab-content {
        display: block;
        padding-bottom: 30px;

        @include media(bp-lt-md) {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    section {
        display: none;

        &.active {
            display: block;
        }

        @include media(bp-lt-md) {
            padding: 0 15px 15px;
        }
    }

    .border-bottom {
        padding-bottom: 30px;
        margin-bottom: 30px;

        @include media(bp-lt-md) {
            border: 0;
            padding-bottom: initial;
            margin-bottom: auto;
        }
    }

    .offers {
        padding: 0;
        background: transparent;

        .container {
            max-width: 100%;
            margin: 0;
        }
    }

    .offer-wrap {
        margin-bottom: 10px;
    }

    .offers-area {
        .heading {
            margin-bottom: 12px;
        }

        @include media(bp-lt-md) {
            .desc {
                display: block;
            }

            .col,
            .col:first-child {
                width: 100%;
            }
        }
    }

    .offers-more {
        display: none;

        @include media(bp-lt-md) {
            display: block;
        }
    }

    .offer {
        .image-wrap {
            width: auto;
        }

        .content {
            width: auto;
            padding: 12px 0 0 20px;
        }

        .expiration {
            margin-top: 12px;
        }
    }

    .sixty-fourty {
        .col {
            width: 40%;

            &:first-child {
                width: 60%;
            }
        }
    }

    .service {
        img {
            float: left;
            margin-right: 10px;
        }

        .desc {
            h3 {
                overflow: auto;
                margin-top: 0;
            }

            p {
                overflow: auto;
            }
        }

        @include media(bp-lt-md) {
            float: left;

            &:last-child {
                margin-bottom: 15px;
            }

            .desc {
                h3 {
                    padding-top: 20px;
                }

                p {
                    overflow: visible;
                    float: left;
                }
            }
        }
    }

    table {
        margin-bottom: 20px;
        border-collapse: separate;

        .header-dark {
            th {
                padding: 8px 20px 8px;
                text-transform: uppercase;
            }
        }
    }

    th,
    td {
        small {
            display: block;
        }
    }

    // table modifier class
    .table-detailed-tire-specs {
        width: 100%;

        th,
        td {
            width: 180px;
            text-align: center;
        }

        tbody {
            th,
            td {
                height: 75px;
                padding: 9px 20px 7px;
            }
        }
    }

    // table modifier class
    .table-speed-rating {
        width: 100%;
        margin-bottom: 40px;

        td {
            width: percentage(math.div(1, 11));
            padding: 15px 8px 12px;
            text-align: center;
            vertical-align: middle;
            line-height: 0.8em;

            strong {
                display: block;
            }

            small {
                text-transform: uppercase;
            }
        }
    }

    .image-float {
        padding: 30px 30px 0 30px;

        .image {
            &.left {
                float: left;
            }

            &.right {
                float: right;
            }
        }

        .desc {
            overflow: auto;
            padding-right: 30px;
            padding-left: 15px;
        }

        &:after {
            content: "";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }
    }

    // the mobile accordion tabs
    .m-tab {
        display: none;
        height: 64px;
        line-height: 64px;
        text-transform: uppercase;
        padding: 0 15px;
        margin: 0;

        &:after {
            content: "+";
            float: right;
        }

        &.active {
            margin: auto;
            border-bottom: 0;
            background-color: transparent;

            &:after {
                content: "–";
            }

            + section {
                display: block;
            }
        }

        @include media(bp-lt-md) {
            display: block;
            width: 100%;
        }
    }

    .disclaimers {
        .accordion .folder {
            padding: 12px 0;
            border-bottom: 0;
            background-color: transparent;
            text-transform: uppercase;

            &:after {
                display: none;
            }

            a {
                text-transform: none;
            }
        }

        @include media(bp-lt-md) {
            clear: left;
        }
    }
}