@use 'sass:math';
@import '~common/scss/shared/shared';

.contact-us,
.cv-generation-form,
.cv-military-form,
.cv-off-road-form {
    margin-bottom: 50px;

    .container {
        @include media(bp-lt-sm) {
            margin: 0;
        }
    }

    .heading {
        margin-bottom: 0;

        @include media(bp-lt-sm) {
            text-align: center;
            font-size: 25px;
        }
    }

    .wrap {
        padding: 35px 0;

        @include media(bp-lt-sm) {
            padding: 0;
        }

        .inner {
            .contact-btn {
                min-width: 138px;

                &.email {
                    float: left;
                }
            }

            @include media(bp-lt-sm) {
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
            }
        }
    }

    .wrap-inner {
        @include clearfix;
        width: percentage(math.div(1150px, 1300px));
        margin: 0 auto;

        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .details-col,
    .contact-col {
        width: 50%;

        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .heading {
        margin-bottom: 15px;
    }

    .desc {
        overflow: hidden;
        margin-bottom: 20px;

        > div {
            float: left;
            width: 70%;
        }

        .icn {
            width: 70px;
        }
    }

    .details-col {
        padding-right: percentage(math.div(30px, 573px));

        @include media(bp-lt-sm) {
            margin-bottom: 30px;
            padding: 0;
        }
    }

    .contact-col {
        padding-left: percentage(math.div(30px, 573px));
        margin-bottom: 40px;

        @include media(bp-lt-sm) {
            margin-bottom: 20px;
            padding: 0;
        }
    }

    .fields {
        margin-top: 15px;
        > div {
            float: left;
            width: 49%;
            margin: 0 0 26px;

            @include media(bp-lt-sm) {
                float: none;
                width: 100%;
                clear: both;
            }

            &:nth-child(even) {
                float: right;

                @include media(bp-lt-sm) {
                    float: none;
                }
            }
        }
        > div.signup-optin {
            width: 100%;
        }
        > div.field-geographical {
            width: 100%;
        }
        .textarea-message {
            width: 100%;

            @include media(bp-lt-sm) {
                clear: both;
            }

            textarea {
                width: 100%;
                height: 170px;
                resize: none;
            }
            span {
                font-style: italic;
                font-size: 12px;
            }
        }

        .contact-category-add-on-fields {
            display: none;
            width: 100%;
        }

        div {
            position: relative;

            span.coral-Icon {
                position: absolute;
                bottom: -15px;
                left: 0;
            }
        }
    }
    input,
    select {
        width: 100%;
    }
    select {
        padding: 5px 8px;
    }
    input[type='checkbox'],
    input[type='radio'] {
        width: auto;
    }

    dl {
        overflow: hidden;
        margin-top: 0;
    }

    dt,
    dd {
        float: left;
        margin: 0;
    }

    dt {
        clear: both;
        width: 70px;
    }

    .contact-fields-simple {
        [data-address],
        [data-address-more],
        [data-city],
        [data-state] {
            display: none;
        }

        [data-zip] {
            float: right;
        }
    }
    [data-day-phone],
    [data-evening-phone],
    [data-mobile-phone],
    [data-company] {
        display: none;
    }

    .contact-fields-customer-service {
        @extend .contact-fields-simple;

        [data-zip] {
            display: none;
        }

        .contact-store-visited {
            display: block;
        }
    }

    .contact-fields-maintenance-repair {
        @extend .contact-fields-simple;

        [data-zip] {
            float: right;
        }
    }

    .contact-fields-vehicle-lookup {
        @extend .contact-fields-simple;

        [data-zip] {
            display: none;
        }

        .contact-select-vehicle {
            display: block;
        }
    }

    .contact-fields-fleet-care {
        @extend .contact-fields-simple;

        [data-zip] {
            float: left;
        }

        [data-company],
        [data-address],
        [data-address-more],
        [data-city],
        [data-state],
        [data-reemail],
        [data-day-phone],
        [data-evening-phone],
        [data-mobile-phone] {
            display: block;
        }
    }

    .contact-fields-fleet-care-services {
        @extend .contact-fields-simple;

        [data-company],
        [data-address],
        [data-address-more],
        [data-reemail],
        [data-day-phone],
        [data-evening-phone],
        [data-mobile-phone],
        .contact-store-visited {
            display: block;
        }
    }

    .rebate-inquiry {
        [data-address] {
            display: block;
            float: right;
        }
        [data-state] {
            float: left !important;
        }
    }
}

.cv-off-road-form {
    .fields {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        > div {
            flex: 1 1 calc(50% - 20px);
            box-sizing: border-box;
        }
        .signup-optin {
            width: 100%;
            box-sizing: border-box;
            flex: 0 0 100%;
        }
        .emailError,
        .nameError,
        .phoneError {
            color: red;
            position: absolute;
            display: block;
            margin-top: 5px;
            visibility: hidden;
        }

        input:invalid {
            + .emailError,
            + .nameError,
            + .phoneError {
                visibility: visible;
            }
        }
        .nameError.showError {
            visibility: visible;
        }
    }
}

.cv-generation-form,
.cv-military-form {
    margin-top: 50px;

    .heading {
        font-size: 30px;
        text-transform: none;

        @include media(bp-lt-sm) {
            font-size: 27px;
            text-transform: none;
            text-align: left;
        }
    }

    .details-col {
        padding-right: percentage(math.div(40px, 573px));

        @include media(bp-lt-sm) {
            padding-right: 0;
            margin-bottom: 0;
        }
    }

    .form-col {
        padding-left: percentage(math.div(40px, 573px));
        border-left: 1px solid #999999;

        @include media(bp-lt-sm) {
            border-left: 0;
            padding-left: 0;
        }

        .heading {
            text-align: center;

            @include media(bp-lt-sm) {
                border-left: 0;
                border-top: 1px solid #999999;
                padding-top: 50px;
                text-align: left;
                margin-bottom: 10px;
            }
        }
        .desc {
            text-align: center;
            width: 85%;
            margin: 0 auto;

            @include media(bp-lt-sm) {
                text-align: left;
                width: 80%;
                margin: unset;
            }
        }
        .required-fields {
            clear: both;
            text-align: center;
            width: 100%;

            @include media(bp-lt-sm) {
                text-align: left;
            }
        }
        .cv-btn {
            float: none;
            margin: 0 auto;
            display: block;
            padding: 10px 70px;
            margin-top: 25px;

            @include media(bp-lt-sm) {
                margin-top: 10px;
            }
        }
        label {
            color: $black;

            @include media(bp-lt-sm) {
                display: block;
                margin-bottom: 10px;
            }
        }
        input,
        select {
            @include media(bp-lt-sm) {
                padding: 13px 8px;
            }
        }
        .fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include media(bp-lt-md) {
                flex-direction: column;

                > div {
                    width: 100%;
                }
            }

            > div {
                @include media(bp-lt-sm) {
                    margin: 0 0 10px;
                }
            }
        }
    }

    .job-roles {
        dt {
            width: 100%;
            color: $brand-color-red;
            @include font-body-bold;
        }
        dd {
            margin-bottom: 15px;
        }
    }
}

.chat-container {
    width: 60%;
    padding: 25px !important;

    .chat-section {
        height: 555px;
    }

    @include media(bp-lt-sm) {
        width: 80%;
    }

    .close-modal {
        right: 0;
        top: 0px;
    }

    iframe {
        border: 0;
        height: 100%;
        width: 100%;
    }
}

.emailError,
.zipError,
.phoneError {
    color: $red;

    @include media(bp-lt-md) {
        line-height: 12px;
        display: block;
        margin-top: 2px;
    }
}

.bsro-ui-chatbot-v1 {
    .contact-methods {
        display: flex;
        flex-direction: column;

        .contact-method-chat {
            order: -1;
        }
    }
}
