.tooltip-curbside {
    @include media(bp-lt-lg) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    > a {
        display: block;
        width: 19px;
        height: 19px;
        margin: 3px 5px;
        background: $brand-color-blue;
        border-radius: 50%;
        color: $white;
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        pointer-events: bounding-box;
    }
}
