@import '../../../../content/react-shared/components/product-price/product-price';

body.BSRO-UI-CompOn {
    .tsr-card__action {
        display: block !important;
        border-radius: 6px;
        color: #fff !important;
        background-color: $brand-color-red !important;
    }
}

.tsr-card {
    width: 310px;
    min-height: 400px;
    padding: 40px 20px 0;
    background-color: $white;
    border-radius: 10px;
    position: relative;
    border: 2px solid #f6f6f6;
    margin-bottom: 28px;
    color: #282828;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: none !important;
    display: block;

    &__offer-tag {
        @include font-body-bold;
        background-color: $blue-50;
        font-size: 12px;
        width: 115px;
        height: 27px;
        text-align: center;
        line-height: 27px;
        color: #005db6;
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
        z-index: 1;
    }

    &__rebate-msg {
        display: none;
        font-size: 14px;
    }

    &__offers-icon {
        background-image: url(https://s7d1.scene7.com/is/content/bridgestone/tag-badge-outline-icon-web-global-bsro);
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: center;
        margin-right: 10px;
        height: 16px;
        width: 16px;
        display: none;
        position: relative;
        top: 4px;
    }

    &__both-offers {
        display: none;
    }

    &__special-offer-icon {
        @include font-body-bold;
        color: #005db6;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        display: inline-flex;
        padding: 4px 8px;
        border-radius: 4px;
        background: #dfe8ff;
        margin-bottom: 10px;
        width: 94px;
    }

    &__brand-icon {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 13px;
        left: 13px;

        &--primewell {
            width: 68px;
            height: 7px;
        }

        &--toyo {
            width: 68px;
            height: 10px;
        }

        &--suredrive {
            width: 107px;
            height: 10px;
        }

        &--prinx {
            width: 89px;
            height: 13px;
        }

        img {
            width: 100%;
        }
    }

    &__brand-tag-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 150px;
        height: 100px;
    }

    &__brand-tag {
        background-color: $brand-color-red;
        color: $white;
        font-size: 9px;
        font-weight: bold;
        text-transform: uppercase;
        transform: rotate(-30deg);
        text-align: center;
        position: absolute;
        top: 26px;
        left: -37px;
        padding: 5px 45px;
        white-space: pre;

        &--special-offer {
            left: -45px;
        }
    }

    &__brand {
        font-size: 12px;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 3px;
        margin-top: 16px;
    }

    &__tire-name {
        @include font-title;
        font-size: 21px;
        text-align: center;
        margin-bottom: 5px;
        line-height: 30px;
    }

    &__tire-image {
        text-align: center;
        height: 90px;
        overflow: hidden;

        img {
            width: 211px;
        }
    }

    &__tire-info {
        font-size: 13px;
        text-align: center;
        width: 100%;
        border-top: 1px solid #d4d4d4;
        padding-top: 15px;
        margin-bottom: 10px;
    }

    &__reviews {
        width: 100%;
        padding-bottom: 15px;
        border-bottom: 1px solid #d4d4d4;
        margin-bottom: 20px;
        text-align: center;

        &.reviews {
            .reviews-inline-rating {
                justify-content: center;
            }

            .stars {
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &__inventory-tool {
        margin-left: 10px;
    }

    &__mileage {
        font-size: 14px;
        margin-bottom: 10px;

        strong {
            font-size: 16px;
        }
    }

    &__price {
        margin-left: 0;
        margin-bottom: 30px;
        height: auto;
        max-height: 100%;

        strong {
            font-size: 21px;
        }

        .grid {
            display: block;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 16px;

            & > div {
                @include font-title;
                font-size: 36px;
                line-height: 1;
                // display: inline-block;

                span {
                    font-size: 20px;
                }
            }
        }

        .list {
            display: none;
        }

        .no-price {
            font-size: 14px;
            line-height: 1;
            display: block;
        }

        .tag {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 10px;

            svg {
                path {
                    fill: #d31a0b;
                }
            }
        }

        .hide-price {
            @include font-title;
            display: block;
            font-size: 20px;
            line-height: 29px;
            font-weight: 400;
            border-top: 1px solid #d4d4d4;
            padding-top: 16px;
        }
    }

    &__prices {
        margin-left: 0;
        margin-bottom: 30px;
        height: auto;
        max-height: 100%;

        .grid {
            display: block;
            font-size: 14px;
            line-height: 1;

            & > div {
                @include font-title;
                font-size: 21px;
                line-height: 30px;
                margin-left: 10px;
                display: inline-block;
            }
        }

        .list {
            display: none;
        }

        .no-price {
            font-size: 14px;
            line-height: 1;
            display: block;
        }

        .tag {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 9px;

            svg {
                path {
                    fill: #d31a0b;
                }
            }
        }

        .hide-price {
            display: block;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
            border-top: 1px solid #d4d4d4;
            margin-top: 10px;
            padding-top: 10px;
        }
    }

    &__price,
    &__prices {
        .grid {
            .single-product {
                font-size: 16px;
                margin-left: 3px;
            }

            sup {
                font-size: 15px;
            }
        }
    }

    &__total-price {
        font-size: 14px;
        margin-left: 10px;
        margin-bottom: 60px;
    }

    &__offer {
        font-size: 14px;
        margin-left: 10px;
        margin-bottom: 20px;
    }

    &__action {
        @include font-body-bold;
        border-top: 1px solid $brand-color-red;
        text-align: center;
        padding: 10px 0 13px;
        text-align: center;
        border-radius: 6px;
        margin: 0 -20px;
        color: $brand-color-red;
        width: 100%;
        position: absolute;
        bottom: -2px;
        left: 20px;
        display: none;
        height: 44px;

        svg {
            width: 14px;
            height: 14px;
            fill: $brand-color-red;
            position: relative;
            top: 4px;
        }

        /* Customize the label (the container) */
        .container {
            display: inline-block;
            position: absolute;
            padding-left: 28px;
            cursor: pointer;
            font-size: 13px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 44%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;

            /* Hide the browser's default checkbox */
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                /* When the checkbox is checked, add a blue background */
                &:checked ~ .checkmark {
                    background-color: $white;

                    /* Show the checkmark when checked */
                    &:after {
                        display: block;
                    }
                }
            }

            /* On mouse-over, add a grey background color */
            &:hover input ~ .checkmark {
                background-color: $white;
            }

            /* Style the checkmark/indicator */
            .checkmark:after {
                left: 4px;
                top: 0px;
                width: 5px;
                height: 10px;
                border: solid #282828;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: $white;
            border: 1px solid #979797;

            /* Create the checkmark/indicator (hidden when not checked) */
            &:after {
                content: '';
                position: absolute;
                display: none;
            }
        }

        .wait {
            pointer-events: none;
        }

        &.mobile {
            display: block;
            color: $white;
            background-color: $brand-color-red;
            position: relative;
            left: 0;
            width: calc(100% + 40px);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            svg {
                fill: $white;
            }

            .checkmark {
                border-color: $brand-color-red;
            }

            .container {
                @include media(bp-gt-lg) {
                    left: 20%;
                    transform: translateY(-50%);
                    width: 60%;
                }
            }

            &.show {
                width: calc(100% + 40px);
                display: block !important;
                left: 0;
            }

            .tsr-card__total-price {
                margin-bottom: 10px;
            }

            &.disabled {
                color: #666;

                background-color: $white;

                .checkmark {
                    background-color: #ccc;
                    border-color: #666;
                }
            }
        }

        &.show {
            width: calc(100% + 4px);
            left: 18px;
            color: $white;
            background-color: $brand-color-red;
            display: none !important;

            svg {
                fill: $white;
            }

            &:not(.list) .container {
                width: 49%;
            }

            .checkmark {
                border-color: $brand-color-red;
            }

            @include media(bp-gt-lg) {
                display: block !important;
            }
        }

        &.disabled {
            color: #666;

            .checkmark {
                background-color: #ccc;
            }
        }

        &:hover {
            background-color: #c11b03 !important;
        }

        @include media(bp-gt-lg) {
            display: block;
        }
    }

    &__info-action {
        @include font-body-bold;
        font-size: 14px;
        border: none;
        outline: none;
        background: transparent;
        border-top: 1px solid #d4d4d4;
        width: 100%;
        text-align: left;
        margin-left: 10px;
        padding: 16px 0;
        cursor: pointer;
        text-transform: uppercase;
        color: #282828;

        svg {
            width: 20px;
            height: 8px;
            margin-left: 10px;
        }

        &.open {
            svg {
                transform: rotate(-180deg);
            }
        }

        @include media(bp-gt-lg) {
            display: none;
        }
    }

    &__info-wrapper {
        display: none;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            padding-bottom: 20px;
            border-bottom: 1px solid #d4d4d4;
            margin-bottom: 20px;
        }

        li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            span {
                font-size: 12px;
            }
        }

        &.open {
            display: block;
        }

        @include media(bp-gt-lg) {
            &.open {
                display: none;
            }
        }
    }

    &__stat-name {
        font-size: 12px;
        line-height: 1;
        width: 60px;
        text-align: right;
        margin-right: 10px;
    }

    &__stat-bar {
        width: 176px;
        height: 3px;
        background-color: #cacaca;
        border-radius: 2px;
        margin-right: 10px;
    }

    &__stat-graph {
        width: 0;
        height: 3px;
        background-color: $brand-color-red;
        border-radius: 2px;
    }

    &__other-stat {
        font-size: 14px;
        margin-bottom: 20px;
    }

    &:hover {
        border: 2px solid #282828;
        text-decoration: none !important;
        color: inherit !important;

        .tsr-card__action {
            color: $white;
            background-color: $brand-color-red;

            svg {
                fill: $white;
            }

            .checkmark {
                border-color: $brand-color-red;
            }

            &.disabled {
                color: #666;

                background-color: white !important;

                .checkmark {
                    background-color: #ccc;
                    border-color: #666;
                }
            }

            &.mobile {
                width: calc(100% + 44px);
                left: -2px;
            }
        }
    }

    &:focus {
        color: $black !important;
    }

    .spacer {
        width: 7px;
        display: inline-block;
    }

    .strike-pricing {
        font-size: 20px !important;
        display: inline-block;
        margin-left: 10px;
        color: rgba(74, 74, 74, 0.7);
        position: relative;

        &:after {
            content: '';
            width: 110%;
            height: 2px;
            background-color: #a5a5a5;
            display: block;
            transform: rotate(-25deg);
            position: absolute;
            top: 50%;
        }

        sup {
            font-size: 12px !important;
        }
    }

    &.boosted {
        border: 1px solid #000 !important;
    }

    &.list {
        width: 100%;
        display: flex;
        min-height: 0;
        padding: 22px 20px 21px 0;
        border-radius: 10px;
        border: 2px solid #f6f6f6;
        margin-bottom: 18px;

        &.open {
            width: 100%;

            .tsr-card {
                &__tire-info {
                    width: 20%;
                }

                &__offer-tag {
                    width: 130px;
                    height: 29px;
                }
            }
        }

        .tsr-card {
            &__boosted {
                border-radius: 0 6px 0 12px;
                background: #000;
                width: 129px;
                height: 29px;
                color: #fff;
                text-align: center;
                align-content: center;
                position: absolute;
                right: 0;
                top: 0;
            }

            &__image-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 19.75%;
            }

            &__tire-image {
                width: 100%;
                height: 141px;

                img {
                    width: calc(100% - 20px);
                }
            }

            &__name-wrapper {
                padding-top: 5px;
                margin-left: 21.5%;
                width: 18.4%;
                padding-right: 3px;
                border-right: 1px solid #d4d4d4;
            }

            &__brand-tag {
                background-color: $brand-color-red;
                color: $white;
                font-size: 9px;
                font-weight: bold;
                text-transform: uppercase;
                transform: rotate(-30deg);
                text-align: center;
                position: absolute;
                top: 26px;
                left: -37px;
                padding: 5px 45px;
                white-space: pre;

                &--special-offer {
                    left: -37px;
                }
            }

            &__brand {
                text-align: left;
            }

            &__tire-name {
                @include font-title;
                text-align: left;
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 10px;
                padding-right: 10px;
            }

            &__reviews {
                padding: 0;
                border: none;
                margin-bottom: 11px;
                text-align: left;

                &.reviews {
                    .reviews-inline-rating {
                        justify-content: start;
                    }
                }

                .write-a-review-button {
                    p {
                        margin: 5px 0px 0px 0px;
                    }

                    strong {
                        font-family: unset !important;
                    }
                }
            }

            &__size-info {
                p {
                    margin: 0;
                }
            }

            &__tire-info {
                width: 15%;
                margin: 11px 4px 0 25px;
                border: none;
                padding: 0;
                text-align: left;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                // line-height: 32px; This messed up line wrapping
            }

            &__tire-type {
                margin-bottom: 0.5em;
            }

            &__inventory-tool {
                margin-left: 0;

                > div {
                    margin-bottom: 0.5em;
                }
            }

            &__mileage,
            &__other-stat {
                margin-bottom: 0.5em;
                margin-left: 0;

                strong {
                    font-size: 14px;
                }
            }

            &__info-wrapper {
                width: 25%;
                padding-top: 16px;
                padding-right: 26px;
                border-right: 1px solid #d4d4d4;
                display: flex;
                flex-direction: column;
                justify-content: center;

                ul {
                    margin: 0;
                    padding: 0;
                    border: none;
                }

                li {
                    margin-bottom: 0;

                    span {
                        line-height: 20px;
                    }
                }
            }

            &__stat-bar {
                width: 65%;
            }

            &__price-wrapper {
                margin-left: 27px;
                width: 22%;
                margin-top: 11px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &__price {
                font-size: 22px;
                margin: 0;

                div {
                    @include font-title;
                    font-size: 36px;
                    line-height: 1;

                    span {
                        font-size: 20px;
                    }

                    sup {
                        font-size: 15px;
                    }

                    &.hide-price {
                        display: block;
                        font-size: 20px;
                        line-height: 29px;
                        font-weight: 400;
                    }
                }

                .grid {
                    display: none;
                }

                .list {
                    display: block;
                    margin-bottom: 16px;
                }

                .no-price {
                    font-size: 14px;
                    line-height: 1;
                    display: block;
                }
            }

            &__prices {
                @include font-body;
                font-size: 11px;
                margin: 0;
                margin-bottom: 12px;

                & > div {
                    display: flex;
                    align-items: center;

                    & > div {
                        @include font-title;
                        font-size: 22px;

                        sup {
                            font-size: 13px;
                        }

                        span {
                            font-size: 15px;
                            margin-left: 0;
                        }

                        &.hide-price {
                            display: block;
                            font-size: 20px;
                            line-height: 29px;
                            font-weight: 400;
                            border-top: 1px solid #d4d4d4;
                            margin-top: 10px;
                            padding-top: 10px;
                        }
                    }
                }

                .grid {
                    display: none;
                }

                .list {
                    display: flex;
                }

                .no-price {
                    @include font-title;
                    font-size: 14px;
                    line-height: 1;
                    display: block;
                }
            }

            &__total-price {
                margin-left: 0;
                border-top: 1px solid #d4d4d4;
                padding-top: 16px;
            }

            &__offer {
                margin-bottom: 0;
                margin-left: 0;
                display: inline-block;

                & a {
                    color: $black;

                    &:hover {
                        color: $black;
                    }
                }
            }

            &__offer-tag {
                width: 184px;
                height: 30px;
                line-height: 30px;
                border-bottom-right-radius: 0;
                border-top-right-radius: 6px;
                left: auto;
                right: 0;
                transform: translateX(0);
            }

            &__action {
                left: 33px;
                bottom: auto;
                top: 23%;
                transform: translateY(-50%);
                width: 167px;
                height: 38px;
                display: none;

                .container {
                    line-height: 18px;
                    width: calc(100% - 15px);
                    left: 4%;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.show {
                    display: block;
                    border-radius: 6px;

                    color: $white;
                    background-color: $brand-color-red;

                    svg {
                        fill: $white;
                    }

                    .checkmark {
                        border-color: $brand-color-red;
                    }
                }

                &.disabled {
                    background-color: #666;
                    border-color: #666;
                    color: #ccc;

                    .checkmark {
                        border-color: #666;
                        background-color: #ccc;
                    }
                }
            }
        }

        &:hover {
            border: 2px solid #282828;

            .tsr-card__action {
                display: block;
                border-radius: 6px;

                &.disabled {
                    background-color: #666 !important;
                    border-color: #666;
                    color: #ccc;

                    .checkmark {
                        border-color: #666;
                        background-color: #ccc;
                    }
                }
            }

            .tsr-card__tire-image {
                opacity: 0.41;
            }
        }
    }

    &.grid {
        & .tsr-card {
            &__boosted {
                border-radius: 0px 0px 12px 12px;
                background: #000;
                display: flex;
                width: 90px;
                padding: 6px 10px;
                justify-content: center;
                align-items: center;
                color: #fff;
                position: absolute;
                top: 0;
                left: calc(50% - 45px);
                height: 29px;
            }

            &__special-offer-icon {
                position: absolute;
                right: 13px;
                top: 13px;
            }

            &__offer {
                display: inline;
                margin-left: 0;

                & a {
                    color: $black;

                    &:hover {
                        color: $black;
                    }
                }
            }

            &__hide-offer {
                margin: 0 0 60px 10px;
            }
        }
    }

    &__total-price {
        margin-bottom: 15px;
        border-top: 1px solid #d4d4d4;
        padding-top: 16px;
    }

    .btn.otd-price {
        display: block;
        font-size: 14px;
        border-radius: 6px;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-right: 12px;
        max-width: 231px;

        &.grid {
            margin: 0 auto 60px auto;

            &.mobile {
                margin-bottom: 20px;
            }
        }
    }
}

.product-results .compare-checkbox label {
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
}

// Changes for TDP pages.

.tsr-card {
    .no-price {
        display: none;
    }

    .strike-pricing {
        display: none;
    }

    &__offer-tag {
        display: none;
    }

    &__total-price {
        display: block;
    }

    .tag {
        display: none;
    }

    &__offer.rebate,
    &__offer.coupon {
        display: none;
    }

    &.without-price {
        .tsr-card__price,
        .tsr-card__prices {
            .grid {
                display: none;
            }

            .no-price {
                display: block;

                button.store-phone {
                    border: none;
                    background: none;
                    color: $brand-color-red;
                    cursor: pointer;
                }
            }
        }

        .tsr-card__total-price {
            display: none;
        }
    }

    &.price-sale {
        .tsr-card__offer-tag {
            display: block;
        }

        .strike-pricing {
            display: inline-block;
        }
    }

    &.with-hidden-price {
        .tag {
            display: inline-block;
        }
    }

    &.with-rebate {
        .tsr-card__offer.rebate {
            display: inline;

            button {
                border: none;
                background: none;
                color: $brand-color-red;
                cursor: pointer;
            }
        }

        .tsr-card__rebate-msg {
            display: inline-block;
        }

        .tsr-card__offers-icon {
            display: inline-block;
        }
    }

    &.with-coupon {
        .tsr-card__offer.coupon {
            display: inline;

            button {
                border: none;
                background: none;
                color: $brand-color-red;
                cursor: pointer;
            }
        }

        .tsr-card__offers-icon {
            display: inline-block;
        }
    }

    &.with-offers {
        .tsr-card__no-both-offers {
            display: none;
        }

        .tsr-card__both-offers {
            display: inline-block;
        }
    }
}

.tsr-card__inventory {
    display: none;
}

/* Hide section headings */
.tsr-results__heading {
    display: none;
}

/* remove the gap between sections */
.tsr-card-deck {
    margin-bottom: 0;
}

/* add back the margin after last section */
.tsr-results {
    margin-bottom: 35px;
}

/* display inventory for mobile */
.tsr-card__inventory {
    display: block;
    margin: 0px 0px 10px 10px;

    .tsr-card__inventory__message {
        margin-left: -10px;

        a {
            font-size: 14px;
        }
    }
}

/* display inventory for desktop */
.tsr-card.list .tsr-card__inventory {
    margin-bottom: 0.5em;
    margin-left: 0px;

    .tsr-card__inventory__message {
        font-size: 14px;
        line-height: 20px;
        @include font-body;

        a {
            font-size: 14px;
        }
    }
}

// LIDI styles.
#tire_search_results .tsr-card {
    &.list {
        min-height: 283px;
        padding: 16px 0;

        .tsr-card__price-wrapper {
            $wrapper-margin-top: 34px;
            min-width: 314px;
            margin-top: $wrapper-margin-top;
            margin-left: 19px;

            // moves prices with badges up the .tsr-card__price-wrapper margin-top value.
            & .product-price.lidi,
            & .product-price.map,
            & .product-price.signin-only {
                margin-top: -#{($wrapper-margin-top + 2)};
            }

            & .product-price__offer-copy {
                padding-right: 20px;
            }
        }

        .otd-price {
            margin-bottom: 0;
        }
    }

    &.grid {
        .tsr-card__price {
            margin-left: 0;
            margin-bottom: 30px;
            height: auto;
            max-height: 100%;
        }

        .product-price__price-dollar {
            font-size: 30px;
            line-height: 30px;
        }
    }
}

// App specific styles.
#tire_search_results {
    @include product-price;
}
