$border-gray: #979797;
$bg-gray: #bfbfbf;
.vehicle-selector__modal {
    width: 100% !important;
    max-width: 1200px;
    background-color: $white !important;
    .vehicle-selector-column {
        display: flex;
        justify-content: center;
        form {
            max-width: 1200px;
            width: 100%;
            margin: 0 15px;
        }
        input[type='radio']:checked + label {
            background-color: $brand-color-blue !important;
            color: $white !important;
        }
        input[type='radio'] {
            + label {
                @include font-title;
                color: $black;
            }
            + label:hover,
            &:checked + label {
                background-color: #e9e9e9;
                color: $black;
            }
        }
        .columns {
            width: 100%;
            @include media(bp-lt-md) {
                margin-bottom: 20px;
            }
        }
        .column {
            border: none;
            &:not(:last-child) {
                border-right: none;
            }
        }
        .column-title {
            @include media(bp-lt-md) {
                background: $border-gray;
                margin: 0 15px 5px;
                padding: 5px 0;
                font-size: 18px;
                text-transform: uppercase;
                border-radius: 5px;
            }
        }
        .selected-option {
            @include media(bp-lt-md) {
                margin: 0 15px 5px;
                padding: 5px 0;
                border-radius: 5px;
                font-size: 18px;
                text-transform: uppercase;
                background-color: $black;
            }
        }
        .list {
            @include media(bp-lt-md) {
                label {
                    width: auto;
                    margin: 15px 45px;
                    padding: 5px 10px 5px 15px;
                    border: $border-gray 1px solid;
                    border-radius: 5px;
                    font-size: 18px;
                }
            }
            @include media(bp-gt-sm) {
                border: $border-gray 1px solid;
                label {
                    font-size: 20px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        .year,
        .make,
        .model {
            .list {
                border-right: none;
            }
        }
        .next {
            width: auto;
            margin: 0 15px;
            float: none;
            padding: 10px 0;
            border-top: $border-gray 1px solid;
            @include media(bp-gt-sm) {
                margin: 0;
                border: none;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            .btn {
                width: 100%;
                border-radius: 5px;
                font-size: 18px;
                &:disabled {
                    background: $bg-gray !important;
                    border: none !important;
                    color: $black;
                    opacity: 1;
                }
                @include media(bp-gt-sm) {
                    font-size: 20px;
                    width: auto;
                    min-width: 300px;
                }
            }
        }
    }
}
