.quote-modal {
    max-width: 400px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .quote-store-message {
        display: block !important;
        color: $red;
    }

    .close-quote {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    p {
        font-size: 18px;
    }

    .status-cta {
        .btn {
            margin-bottom: 10px;
        }
    }

    .store-message {
        padding: 5px 0;
        margin: 0;
    }

    @include media(bp-lt-sm) {
        height: 320px;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        margin: auto;
        padding: 20px 20px 40px !important;
    }
}
