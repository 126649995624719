.my-account {
    .store-wrap {
        margin-bottom: 30px;
    }
    .store-content {
        flex-direction: column;

        @include media(bp-gt-sm) {
            flex-direction: row;

            .store-copy {
                order: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .picture-wrap {
                order: 2;
            }
        }
    }

    .store-top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;

        @include media(bp-gt-sm) {
            justify-content: flex-start;
        }

        a {
            font-size: 16px;
            text-decoration: underline;
            color: $dark-gray;
        }
    }

    .store-left-align {
        display: flex;
        align-items: center;
    }

    .delimiter {
        background-color: #eee;
        margin: 0 23px;
        height: 35px;
        width: 2px;
    }

    .picture-wrap {
        img {
            width: 350px;
            height: 410px;
        }
    }

    .cta-store {
        margin-top: 33px;
        white-space: nowrap;
        a {
            margin-top: 8px;
            width: 250px;
        }
    }

    address {
        font-size: 16px;
        font-style: normal;
        line-height: 1.6;
        margin-top: 10px;
    }

    .store-hrs {
        padding-top: 35px;
        font-size: 16px;
        dl {
            display: inline-block;
            margin: 0;
            dt {
                clear: both;
                text-transform: uppercase;
                width: 70px;
            }
            dt,
            dd {
                float: left;
                margin: 0;
            }
        }
        p {
            margin: 8px 0;
        }
        .holiday {
            color: $brand-color-red;
        }
    }
}
