@use "sass:math";

.appt-step {
    margin-bottom: 50px;

    .container {
        @include media(bp-lt-sm) {
            margin: 0;
        }
    }

    .heading {
        margin-bottom: 0;
        padding-top: 20px;

        @include media(bp-lt-sm) {
            text-align: center;
            font-size: 25px;
        }
    }

    .subhead {
        margin: 10px 0 20px;

        @include media(bp-lt-sm) {
            text-align: center;
        }
    }

    .wrap {
        padding: 35px 0;
		background: -webkit-linear-gradient(to top, #eee, transparent 150px);

        @include media(bp-lt-sm) {
            padding: 0;
        }

		@media print{
			background: none !important;
		}
    }

    .wrap-inner {
        @include clearfix;
        width: percentage(math.div(1228px, 1300px));
        margin: 0 auto;

        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    // the DL is for the hours, which is in multiple steps
    dl {
        overflow: hidden;
        margin-top: 0;
    }

    dt,
    dd {
        float: left;
        margin: 0;
    }

    dt {
        clear: both;
        width: 70px;
    }
}
