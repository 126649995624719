.error-message {
    margin: 20px 0;
    padding: 40px;
    border: 1px solid #dcdcdc;
    text-align: center;

    >h2,
    >h4 {
        margin-top: 0;
    }

    p {
        width: 50%;
        margin: 1em auto;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media(bp-lt-lg) {
        p {
            width: 75%;
        }
    }

    @include media(bp-lt-sm) {
        margin: 0;
        padding: 10px 0 10px;
        border: 0;

        p {
            width: 100%;
        }
    }
}