@import 'common/components/content/tire-search-results/v1/scss/card';
@import 'common/components/content/tire-search-results/v1/scss/card-deck';

.result-card {
    @include font-body;

    .empty {
        display: none;
    }

    &.clearance-only {
        .corner {
            border-width: 0 100px 100px 0;
            border-color: transparent #03a51e transparent transparent;

            + .empty {
                display: block;
                position: absolute;
                right: -22px;
                top: -22px;
                width: 10px;
                background: white;
                z-index: 9;
                border-style: solid;
                border-width: 0 50px 50px 0;
                border-color: transparent transparent #03a51e transparent;
            }
        }

        .top {
            position: relative;

            .corner .corner-text {
                top: 28px;
                z-index: 9190;
                @include font-title;
                font-weight: normal;
                font-size: 16px;
            }
        }
    }

    .brand-info {
        h4 {
            &.product-name {
                a {
                    vertical-align: text-top;
                }
            }

            &.product-id,
            &.size {
                @include font-body-bold;
            }

            &.product-id:first-child {
                margin-bottom: 8px;
            }
        }

        .brand-img {
            .brand-image {
                &.toyo {
                    margin-bottom: -5px;
                }
            }
        }
    }

    .details {
        position: relative;

        .tire-image {
            margin: 10px 34px 30px 10px;
            max-width: 123px;
        }

        .compare-checkbox {
            position: absolute;
            bottom: 11px;
        }

        .pricing-specs {
            .pricing {
                .front-rear-text {
                    @include font-body-bold;
                }
            }
        }
    }

    .price {
        .retail-price,
        .discounted-price {
            line-height: normal;
        }
    }
}

.center {
    text-align: center !important;
}
