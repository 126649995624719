/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.appt-step {
  margin-bottom: 50px;
}
@media only screen and (max-width: 539px) {
  .appt-step .container {
    margin: 0;
  }
}
.appt-step .heading {
  margin-bottom: 0;
  padding-top: 20px;
}
@media only screen and (max-width: 539px) {
  .appt-step .heading {
    text-align: center;
    font-size: 25px;
  }
}
.appt-step .subhead {
  margin: 10px 0 20px;
}
@media only screen and (max-width: 539px) {
  .appt-step .subhead {
    text-align: center;
  }
}
.appt-step .wrap {
  padding: 35px 0;
  background: -webkit-linear-gradient(to top, #eee, transparent 150px);
}
@media only screen and (max-width: 539px) {
  .appt-step .wrap {
    padding: 0;
  }
}
@media print {
  .appt-step .wrap {
    background: none !important;
  }
}
.appt-step .wrap-inner {
  width: 94.4615384615%;
  margin: 0 auto;
}
.appt-step .wrap-inner::after {
  clear: both;
  content: "";
  display: table;
}
@media only screen and (max-width: 539px) {
  .appt-step .wrap-inner {
    width: 100%;
  }
}
.appt-step dl {
  overflow: hidden;
  margin-top: 0;
}
.appt-step dt,
.appt-step dd {
  float: left;
  margin: 0;
}
.appt-step dt {
  clear: both;
  width: 70px;
}

.appt-step-1 .results-col {
  width: 63.7622149837%;
  margin-right: 2.8501628664%;
}
@media only screen and (max-width: 1024px) {
  .appt-step-1 .results-col {
    width: 100%;
    margin-right: 0;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1 .results-col {
    /*padding-bottom: 225px;*/
  }
}
.appt-step-1 .map-col {
  width: 33.3876221498%;
}
@media only screen and (max-width: 1024px) {
  .appt-step-1 .map-col {
    width: 100%;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1 .map-col {
    display: none;
  }
}
.appt-step-1 .map {
  width: 100%;
  height: 540px;
}
@media only screen and (max-width: 1024px) {
  .appt-step-1 .map {
    margin-top: 20px;
  }
}
.appt-step-1 .search-block {
  margin-bottom: 19px;
}
@media only screen and (max-width: 539px) {
  .appt-step-1 .search-block {
    margin: 0 4.86111% 19px;
    /*position: absolute;*/
    /*bottom: 0;*/
  }
}
.appt-step-1 .search-block h2 {
  display: none;
}
@media only screen and (max-width: 539px) {
  .appt-step-1 .search-block h2 {
    display: inline-block;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1 .search-block input {
    width: 100%;
    margin: 10px 0;
  }
}
.appt-step-1 .search-block .zipcodeError {
  display: block;
  color: #d81e05;
}
.appt-step-1 .search-block-mobile {
  display: none;
  padding-top: 15px;
}
.appt-step-1 .search-block-mobile .inner {
  margin: 0 4.8611111111%;
}
.appt-step-1 .search-block-mobile input {
  width: 100%;
  margin: 20px 0;
}
.appt-step-1 #show-more-toggle {
  display: none;
}
.appt-step-1 #show-more-toggle:checked ~ label {
  display: none;
}
.appt-step-1 #show-more-toggle:checked ~ div {
  display: inline-block;
}
.appt-step-1 .find-store-show-more-toggle {
  display: none;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 25px auto;
}
@media only screen and (max-width: 539px) {
  .appt-step-1 .find-store-show-more-toggle {
    display: flex;
  }
}

.appt-step-1-results {
  overflow: auto;
  height: 475px;
  background-color: #fff;
  /* stores near you heading*/
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results {
    overflow: hidden;
    height: auto;
  }
}
.appt-step-1-results .heading {
  display: none;
  padding: 15px 0;
  margin: 0 4.8611111111%;
  border-top: solid 1px #eee;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .heading {
    display: block;
  }
}
.appt-step-1-results .result {
  padding: 30px 0;
  background: #fff;
}
.appt-step-1-results .result::after {
  clear: both;
  content: "";
  display: table;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result:first-child {
    border-top: 1px solid #ccc;
    margin-top: 15px;
  }
}
.appt-step-1-results .result .inner {
  overflow: hidden;
}
.appt-step-1-results .result .inner > div {
  float: left;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .inner > div {
    float: none;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .inner {
    margin: 0 4.8611111111%;
  }
}
.appt-step-1-results .result .pin {
  width: 8.9858793325%;
  text-align: center;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .pin {
    float: left !important;
    margin-right: 10px;
  }
}
.appt-step-1-results .result .info {
  width: 58.5365853659%;
  padding-right: 15px;
}
.appt-step-1-results .result .info .reviews-v2 .reviews-inline-rating {
  flex-direction: row;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .info {
    width: 100%;
  }
}
.appt-step-1-results .result .info .cta {
  margin-top: 10px;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .info .cta {
    display: none;
  }
}
.appt-step-1-results .result .info .direction-message {
  padding-top: 10px;
}
.appt-step-1-results .result .ctas-mobile {
  display: none;
  float: right;
}
.appt-step-1-results .result .ctas-mobile a {
  display: block;
  text-align: center;
}
.appt-step-1-results .result .ctas-mobile a:first-child {
  margin-bottom: 10px;
}
.appt-step-1-results .result .ctas-mobile .call-cta .icon {
  display: block;
}
.appt-step-1-results .result .ctas-mobile .call-cta .icon:before {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 32px;
  height: 32px;
  font-size: 0;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20x='0px'%20y='0px'%20width='32px'%20height='32px'%20viewBox='0%200%2032px%2032px'%3E%3Cpath%20style='fill:%23d81e05;'%20d='M9.7,22.2C1.1,13.6,0,6.3,2.2,3.9c0.6-0.7,2.5-1.9,3.3-2.1c0.8-0.2,1.6,0.1,2,0.8c1.3,2.1,2.8,4.1,4.4,6c0.5,0.6,0.5,1.4,0,2.1c-0.6,0.7-1.6,1.2-2.3,1.9c-0.6,0.6-0.7,2.8,3.1,6.6c3.9,3.8,6.1,3.7,6.6,3.1c0.7-0.8,1.2-1.7,1.9-2.3c0.6-0.5,1.4-0.5,2.1,0c1.9,1.5,3.9,3,6,4.4c0.7,0.5,1,1.2,0.8,2c-0.2,0.8-1.4,2.7-2.1,3.3C25.5,31.9,18.3,30.8,9.7,22.2z'/%3E%3Cpath%20style='fill:%23d81e05;'%20d='M22.4,16.2c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-1-0.4-2-1.1-2.7l0,0c-0.7-0.7-1.6-1.1-2.7-1.1l0,0c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.7,0.6-1.3,1.3-1.3l0,0c1.8,0,3.4,0.7,4.5,1.9l0,0c1.1,1.1,1.9,2.7,1.9,4.5l0,0c0,0.4-0.1,0.7-0.4,0.9l0,0C23.1,16.1,22.8,16.2,22.4,16.2L22.4,16.2z'/%3E%3Cpath%20style='fill:%23d81e05;'%20d='M27.6,16.1c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-2.4-1-4.6-2.6-6.2l0,0C22.1,7,19.9,6,17.5,6l0,0c-0.7,0-1.3-0.6-1.3-1.3l0,0c0-0.7,0.6-1.3,1.3-1.3l0,0c3.1,0,6,1.3,8,3.3l0,0c2.1,2,3.3,4.9,3.3,8l0,0c0,0.4-0.1,0.7-0.4,0.9l0,0C28.2,15.9,27.9,16.1,27.6,16.1L27.6,16.1z'/%3E%3C/svg%3E");
  display: block;
  margin: 0 auto;
}
.appt-step-1-results .result .ctas-mobile .directions-cta .icon {
  display: block;
}
.appt-step-1-results .result .ctas-mobile .directions-cta .icon:before {
  background-repeat: no-repeat;
  background-size: 100%;
  width: 32px;
  height: 32px;
  font-size: 0;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='32px'%20height='32px'%20viewBox='0%200%2032px%2032px'%3E%3Cpath%20style='fill:%23d81e05;'%20d='M29.3,14.7v4.4c0,0.6-0.5,1.1-1.1,1.1H5.3c-0.4,0-0.9-0.2-1.2-0.5l-2.4-2.4c-0.2-0.2-0.2-0.6,0-0.8l2.4-2.4c0.3-0.3,0.7-0.5,1.2-0.5H14v-3.3h4.4v3.3h9.8C28.8,13.6,29.3,14.1,29.3,14.7z%20M30.7,6.4l-2.4,2.4c-0.3,0.3-0.7,0.5-1.2,0.5H4.2c-0.6,0-1.1-0.5-1.1-1.1V3.8c0-0.6,0.5-1.1,1.1-1.1H14V1.6c0-0.6,0.5-1.1,1.1-1.1h2.2c0.6,0,1.1,0.5,1.1,1.1v1.1h8.7c0.4,0,0.9,0.2,1.2,0.5l2.4,2.4C30.9,5.8,30.9,6.2,30.7,6.4z%20M18.4,21.3V30c0,0.6-0.5,1.1-1.1,1.1h-2.2c-0.6,0-1.1-0.5-1.1-1.1v-8.7H18.4z'/%3E%3C/svg%3E");
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .ctas-mobile {
    display: block;
  }
}
.appt-step-1-results .result .rating {
  margin: 8px 0;
}
.appt-step-1-results .result .info-2 {
  width: 32.3491655969%;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .info-2 {
    width: 100%;
  }
}
.appt-step-1-results .result .info-2 a {
  display: block;
}
.appt-step-1-results .result .phone {
  margin-bottom: 15px;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .phone {
    display: none;
  }
}
.appt-step-1-results .result .hours {
  margin-bottom: 15px;
}
.appt-step-1-results .result .hours dl {
  margin: 0;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .hours {
    margin-top: 15px;
  }
}
.appt-step-1-results .result .open-nights {
  display: none;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .open-nights {
    display: block;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .cta {
    display: none;
  }
}
.appt-step-1-results .result .map-mobile {
  display: none;
  margin-bottom: 20px;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .map-mobile {
    display: block;
  }
}
.appt-step-1-results .result .cta-mobile {
  display: none;
}
@media only screen and (max-width: 539px) {
  .appt-step-1-results .result .cta-mobile {
    display: block;
  }
}
.appt-step-1-results .result .holiday {
  color: #d81e05;
}

/**
 * BSROAEM-3142, 3144
 * widemap-view
 */
.appt-step-1.widemap {
  background-color: #ebebeb;
  margin-bottom: 0;
  padding-top: 40px;
}
.appt-step-1.widemap .title {
  margin: 0;
  font-size: 42px;
  min-height: 1em;
  text-align: center;
}
.appt-step-1.widemap .subhead {
  font-size: 18px;
  min-height: 2em;
  margin-bottom: 80px;
  text-align: center;
}
.appt-step-1.widemap .wrap {
  position: relative;
  display: flex;
  padding: 25px 5px 25px 25px;
  background: #fff;
  border: 1px solid #aaa;
}
.appt-step-1.widemap .search-block {
  position: absolute;
  margin-bottom: 0;
}
.appt-step-1.widemap .search-block form {
  display: flex;
  align-items: center;
}
.appt-step-1.widemap .search-block label {
  display: inline-block;
  min-width: 90px;
}
.appt-step-1.widemap .search-block input {
  margin: 0 10px;
  width: 90px;
  padding: 6px 8px;
}
.appt-step-1.widemap .search-block .btn {
  padding: 8px 20px;
}
.appt-step-1.widemap .map-col {
  width: 75%;
  height: 328px;
  margin-top: 60px;
}
.appt-step-1.widemap .map {
  height: 100%;
  border: 0;
  margin-top: 0;
}
.appt-step-1.widemap .results-col {
  width: 315px;
  margin-right: 0;
}
.appt-step-1.widemap .results-col .btn {
  white-space: nowrap;
}
.appt-step-1.no-bg {
  background-color: #fff;
}
.appt-step-1.no-bg .container {
  margin: 0;
  max-width: 1440px;
}
.appt-step-1.no-bg .subhead {
  margin-bottom: 20px;
}
.appt-step-1.no-bg .map-col {
  width: 78%;
}
@media only screen and (min-width: 768px) {
  .appt-step-1.dark-background .title,
  .appt-step-1.dark-background .subhead {
    color: #fff;
  }
  .appt-step-1.light-background .title,
  .appt-step-1.light-background .subhead {
    color: #000;
  }
  .appt-step-1 .search-block .results-zip,
  .appt-step-1 .search-block .change-store {
    display: none;
  }
}
@media only screen and (max-width: 539px) {
  .appt-step-1.widemap {
    background: linear-gradient(to bottom, #ebebeb, rgba(255, 255, 255, 0) 120px);
    background: -webkit-linear-gradient(top, #ebebeb, rgba(255, 255, 255, 0) 120px);
    background-image: none !important;
  }
  .appt-step-1.widemap .title {
    font-size: 8vw;
    padding: 10px;
  }
  .appt-step-1.widemap .subhead {
    margin-bottom: 10px;
    padding: 10px 10vw;
    font-size: inherit;
  }
  .appt-step-1.widemap .wrap {
    flex-direction: column;
    padding: 0;
    border: 0;
  }
  .appt-step-1.widemap .search-block {
    position: relative;
  }
  .appt-step-1.widemap .search-block form {
    justify-content: center;
  }
  .appt-step-1.widemap .search-block label {
    display: inline-block;
    min-width: none;
    margin-right: 5px;
  }
  .appt-step-1.widemap .search-block .results-zip,
  .appt-step-1.widemap .search-block .change-store {
    display: inline;
  }
  .appt-step-1.widemap .search-block input,
  .appt-step-1.widemap .search-block .btn {
    display: none;
  }
  .appt-step-1.widemap .map-col {
    display: block;
    width: 100%;
    height: 328px;
    margin-top: 20px;
  }
  .appt-step-1.widemap .map {
    height: 100%;
  }
  .appt-step-1.widemap .results-col {
    width: 100%;
  }
}

.widemap .appt-step-1-results {
  height: 392px;
  border: 0;
}
.widemap .appt-step-1-results.slick-slider {
  margin-bottom: 0;
}
.widemap .appt-step-1-results .result {
  padding: 0 0 25px 0;
  border-bottom: 1px solid #dcdcdb;
  margin-bottom: 20px;
}
.widemap .appt-step-1-results .result .inner {
  padding: 0 20px 0 25px;
}
.widemap .appt-step-1-results .result .inner > div {
  float: none;
}
.widemap .appt-step-1-results .result .info {
  width: 100%;
  padding: 0;
}
.widemap .appt-step-1-results .result .directions-cta {
  padding: 10px 0;
}
.widemap .appt-step-1-results .result .directions-cta svg {
  float: left;
  margin-right: 5px;
}
.widemap .appt-step-1-results .result .directions-cta a {
  display: inline-block;
}
.widemap .appt-step-1-results .result .schedule-cta {
  margin-top: 10px;
}
.widemap .appt-step-1-results .result .schedule-cta a {
  width: 100%;
}
.widemap .appt-step-1-results .result:nth-child(odd) {
  background: none;
}
@media only screen and (min-width: 768px) {
  .widemap .appt-step-1-results .result:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 539px) {
  .widemap .appt-step-1-results {
    height: auto;
  }
  .widemap .appt-step-1-results .result {
    padding-top: 20px;
    border-bottom: 0;
    border-right: 1px solid #dcdcdb;
    margin-bottom: 0;
  }
  .widemap .appt-step-1-results .result .inner {
    padding: 0 0 10px 0;
  }
}

/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.local-seo-theme .appt-step {
  margin-bottom: 0;
}
.local-seo-theme .container {
  display: block;
}
.local-seo-theme .container.local-geolocation-search {
  margin: 0 auto;
  max-width: 100%;
}
.local-seo-theme .container h2 {
  color: #000;
  text-align: center;
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.local-seo-theme .search-block {
  width: 98%;
  padding: 0 20px;
  margin: auto;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .search-block {
    padding-left: 0;
    padding-right: 0;
  }
}
.local-seo-theme .search-block input {
  width: 100%;
}
.local-seo-theme .search-block .btn.find-store.mobile,
.local-seo-theme .search-block .experiencefragment button {
  display: block;
  width: 100%;
  border-radius: 5px;
}
.local-seo-theme .search-block .default-block {
  display: block;
  position: relative;
}
.local-seo-theme .search-block .default-block .btn.find-store.mobile-secondary {
  display: block;
  position: absolute;
  top: 0px;
  right: 5px;
  width: 35px;
  height: 35px;
  text-indent: -150px;
  overflow: hidden;
  color: red;
  background-color: transparent;
  border: none;
  background-image: url("https://s7d1.scene7.com/is/content/bridgestone/search-icon-web-global-bsro");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.local-seo-theme .appt-step .wrap {
  background: none;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  margin: auto;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step .wrap.has-results .search-block {
    width: 408px;
    height: auto;
    background-image: none;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step .wrap.has-results .search-block form {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 0;
    text-align: right;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 10px;
  }
}
.local-seo-theme .appt-step .wrap.has-results .search-block form .geolocation-header {
  display: none;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step .wrap.has-results .search-block form .geolocation-header {
    display: none;
  }
}
.local-seo-theme .appt-step .wrap.has-results .search-block form input {
  margin-bottom: 20px;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step .wrap.has-results .search-block form input {
    width: 100% !important;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step .wrap.has-results .search-block form .btn.find-store {
    right: 0;
    top: 0;
  }
}
.local-seo-theme .appt-step .wrap.local .search-block form .zipcodeError, .local-seo-theme .appt-step .wrap.state .search-block form .zipcodeError {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step .wrap.local .search-block form .zipcodeError, .local-seo-theme .appt-step .wrap.state .search-block form .zipcodeError {
    margin-bottom: 0;
  }
}
.local-seo-theme .wrap-inner {
  display: none;
  flex-direction: column;
  width: 100%;
}
.local-seo-theme .wrap-inner:not(.local):not(.state) {
  display: flex;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .wrap-inner {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .wrap-inner .col.results-col {
    display: flex;
    order: 2;
    width: 472px;
    height: 810px;
  }
}
.local-seo-theme .wrap-inner .map-col {
  display: flex;
  order: 2;
  height: 181px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0 20px;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .wrap-inner .map-col {
    position: relative;
    top: -52px;
    width: 427px;
    height: 817px;
    order: 3;
    padding: 0;
  }
}
.local-seo-theme .wrap-inner .map-col iframe {
  height: 100%;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .wrap-inner .map-col iframe {
    height: 100%;
  }
}
.local-seo-theme .wrap-inner .results-col {
  display: flex;
  order: 3;
}
.local-seo-theme .wrap-inner.has-results {
  display: flex;
  padding-bottom: 40px;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .wrap-inner.has-results {
    padding-bottom: 0;
  }
}
.local-seo-theme .wrap-inner.has-results .results-col .appt-step-1-results {
  margin-top: 0;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .wrap-inner.has-errors .col.map-col {
    top: -72px;
  }
}
.local-seo-theme .appt-step-1 #show-more-toggle:checked ~ div {
  width: 100%;
}
.local-seo-theme .appt-step-1-results {
  border: none;
  width: 100%;
}
.local-seo-theme .appt-step-1-results .result {
  background: none !important;
  border-bottom: 1px solid #ccc;
}
.local-seo-theme .appt-step-1-results .result:first-child {
  border-top: 1px solid #ccc;
  margin-top: 15px;
}
@media only screen and (min-width: 768px) {
  .local-seo-theme .appt-step-1-results .result:first-child {
    margin-top: 0;
  }
}
.local-seo-theme .appt-step-1-results .result .inner {
  background: #fff;
}
.local-seo-theme .appt-step-1-results .result .inner .info {
  width: 100%;
  padding: 0;
}
.local-seo-theme .appt-step-1-results .result .inner .info .cta a,
.local-seo-theme .appt-step-1-results .result .inner .info .cta-mobile a,
.local-seo-theme .appt-step-1-results .result .inner .info .cta button {
  border-radius: 4px;
  width: 100%;
}
.local-seo-theme .appt-step-1-results .result .inner .info .address {
  margin-bottom: 12px;
  color: #5c5f5f;
}
.local-seo-theme .appt-step-1-results .result .inner .info .store-name,
.local-seo-theme .appt-step-1-results .result .inner .info .address a,
.local-seo-theme .appt-step-1-results .result .inner .info .phone a,
.local-seo-theme .appt-step-1-results .result .inner .info .cta-mobile.mobile-phone a {
  font-family: "Avenir", Arial, sans-serif !important;
  font-style: normal;
  color: var(--color-base-Black, #000);
}
.local-seo-theme .appt-step-1-results .result .inner .info .store-name {
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
}
.local-seo-theme .appt-step-1-results .result .inner .info .phone a {
  text-align: right;
  font-size: 14px;
  font-weight: 350;
  line-height: 16px;
  text-decoration-line: underline;
}
.local-seo-theme .appt-step-1-results .result .inner .info .map-mobile {
  margin-top: 30px;
}
.local-seo-theme .appt-step-1-results .pin {
  float: none !important;
  margin-bottom: 12px;
}
.local-seo-theme .appt-step-1-results .pin figure {
  width: fit-content;
}
.local-seo-theme .appt-step-1-results .pin figure figcaption {
  margin-top: -22px;
  color: #fff;
}
.local-seo-theme .appt-step-1-results .pin .store-number {
  width: 24px;
  display: flex;
  height: 24px;
  padding: 10px;
  background: #333;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.local-seo-theme .appt-step-1-results::-webkit-scrollbar {
  background: #e1e3e3;
  width: 4px;
}
.local-seo-theme .appt-step-1-results::-webkit-scrollbar-thumb {
  background: #d81e05;
  height: 180px;
}
.local-seo-theme .appt-step-1-results .find-store-show-more-toggle.btn {
  width: 74%;
  background-color: transparent;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d81e05;
}
.local-seo-theme .appt-step-1-results .find-store-show-more-toggle.btn img {
  margin-left: 8px;
}
.local-seo-theme .search-block input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.local-seo-theme .search-block label {
  display: none;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step-1-results {
    padding: 0 15px;
    height: 750px;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .appt-step-1-results .result {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .container:not(.local-geolocation-search) {
    display: flex;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .search-block {
    width: 425px;
    margin-left: 15px;
  }
}
.local-seo-theme .search-block form {
  position: relative;
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .search-block label {
    display: block;
    float: left;
    text-align: left;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .search-block button.btn {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .search-block .default-block {
    position: relative;
    display: inline-block;
    width: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .search-block .default-block .btn.find-store.mobile {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .local-seo-theme .btn.find-store.mobile,
  .local-seo-theme .experiencefragment button {
    text-indent: -9999px;
    border: none;
    width: 20px !important;
    height: 29px;
    position: absolute;
    right: 5px;
    top: 5px;
    background: transparent;
  }
  .local-seo-theme .btn.find-store.mobile:hover,
  .local-seo-theme .experiencefragment button:hover {
    background-color: transparent;
    border: none;
  }
}
.local-seo-theme .hide-form {
  display: none;
}
.local-seo-theme .show-form {
  display: block;
}
.local-seo-theme .wrap-inner.show-form {
  display: flex;
}

.local-geolocation-search .search-block .geolocation-header {
  display: inline;
}
.local-geolocation-search .search-block {
  height: auto;
  align-content: center;
  padding: 40px 30px;
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block {
    height: 400px;
    padding: 0;
  }
}
.local-geolocation-search .search-block .default-block input {
  top: -3px;
}
.local-geolocation-search .search-block .default-block button {
  top: -3px;
}
.local-geolocation-search .search-block form {
  text-align: center;
}
.local-geolocation-search .search-block form button {
  border-radius: 5px;
}
.local-geolocation-search .search-block form button.geo-locate-me {
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block form button.geo-locate-me {
    margin-bottom: 0;
  }
}
.local-geolocation-search .search-block form button.geo-locate-me img {
  filter: brightness(0) invert(1);
  margin-right: 20px;
}
.local-geolocation-search .search-block form input {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block form input {
    margin-bottom: 0;
  }
}
.local-geolocation-search .search-block form span.heading {
  display: inline-block;
  width: 90%;
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  text-transform: none;
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block {
    background-image: url("https://s7d1.scene7.com/is/image/bridgestone/locate-me-map-background-local-pages-global-web-bsro?scl=1&fmt=webp");
    width: 100%;
    margin: auto;
  }
  .local-geolocation-search .search-block .default-block {
    display: inline;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block form {
    padding: 20px;
    background: #fff;
    align-content: center;
    align-items: center;
    width: 535px;
    margin: auto;
    text-align: center;
    border-radius: 8px;
    position: relative;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block form button.geo-locate-me {
    vertical-align: middle;
    position: relative;
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block form button.geo-locate-me:after {
    background: #ccc;
    content: "";
    display: block;
    height: 35px;
    position: absolute;
    right: -20px;
    top: 2px;
    width: 2px;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .btn.find-store.mobile-secondary {
    top: -8px !important;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .search-block input {
    vertical-align: middle;
    display: inline-block;
    width: 260px !important;
    border: 1px solid #ccc;
    line-height: 18px;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .results-col {
    width: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  .local-geolocation-search .btn.find-store.mobile {
    display: none;
  }
}