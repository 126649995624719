.rm-open-body .rm-modal,
.my-account {
    .react-date-picker {
        .react-date-picker__inputGroup {
            display: flex;
            align-items: center;
        }

        .react-date-picker__wrapper {
            background-color: $white;
            width: 100%;
            input[type='number'] {
                -moz-appearance: textfield;
            }
        }

        .react-calendar__navigation button[disabled] {
            background-color: $white !important;
            color: #eee;
            border: none !important;
        }

        .react-calendar__month-view {
            .react-calendar__month-view__days {
                .react-calendar__tile[disabled] {
                    background-color: #eee !important;
                    color: #c0c0c0;
                    border: none !important;
                }
            }
        }
    }
    .react-calendar__tile.react-calendar__month-view__days__day--weekend {
        color: $text-color;

        &.react-calendar__month-view__days__day--neighboringMonth {
            color: #969696;
        }
    }

    /* FORM SPECIFIC OVERRIDES */

    #addServiceRecord .react-date-picker__calendar,
    #addServiceRecord .react-calendar {
        width: 400px;
    }
}
