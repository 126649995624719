.my-account {
    .profile-progress {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 8px;

        border: none;
        border-radius: 10px;
        background-color: #ccc;

        margin-top: 20px;
        margin-bottom: 20px;
    }

    /* Webkit */
    /* outer bar */
    .profile-progress::-webkit-progress-bar {
        background-color: #ccc;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    /* inner bar */
    .profile-progress::-webkit-progress-value {
        background-color: $brand-color-red;
        border-radius: 10px;
    }

    /* Mozilla */
    .profile-progress::-moz-progress-bar {
        border-radius: 10px;
        background: $brand-color-red;
    }

    /* IE/Edge */
    .profile-progress::-ms-fill {
        border-radius: 10px;
        background: $brand-color-red;
    }

    .progress-list {
        .progress-row {
            display: flex;
            .progress-name {
                flex: 1;
                @include media(bp-gt-sm) {
                    p {
                        margin-left: 30px;
                    }
                }
            }
            .progress-value {
                display: flex;
                flex: 1;
                p {
                    text-align: right;
                    width: 100%;
                    img {
                        margin-top: -2px;
                    }
                }
            }
        }

    }
}
