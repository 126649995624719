@import '~common/scss/shared/shared';

/* Container Component Global Styles */
.container-component.background-gray {
	background: $light-gray;
}

.container-component.gradient-top {
	@include gradient-top();
}

.container-component.gradient-bottom {
	@include gradient-bottom();
}

.container-component.max-width > .container {
	max-width: 100%;
}

.container-component.margin-collapsed > .container {
	margin: 0;
}

/* Nested Containers */
.container-component:not(.margin-collapsed) > .container .container {
	margin: 0;
}

/* Column Layout, e.g., 50/50, 30/70, 60/40  */
.container-component[class*="-layout"] > .container {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	> div {
		width: 100%;
	}
}

/* Column Layout, default padding between adjacent columns */
.container-component[class*="-layout"] > .container > div {
	&:first-child {
		padding-right: 2%;
	}
	&:last-child {
		padding-left: 2%;
	}
	&:not(:first-child):not(:last-child) {
		padding-left: 2%;
		padding-right: 2%;
	}
}

/* Column Layout, collapse padding between adjacent columns */
.container-component.padding-collapsed[class*="-layout"] > .container > div {
	&:first-child {
		padding-right: 0;
	}
	&:last-child {
		padding-left: 0;
	}
	&:not(:first-child):not(:last-child) {
		padding-left: 0;
		padding-right: 0;
	}
}

/* Column Layout, narrow padding between adjacent columns */
.container-component.padding-narrow[class*="-layout"] > .container > div {
	&:first-child {
		padding-right: 0.5%;
	}
	&:last-child {
		padding-left: 0.5%;
	}
	&:not(:first-child):not(:last-child) {
		padding-left: 0.5%;
		padding-right: 0.5%;
	}
}

/* Column Layout, wide padding between adjacent columns */
.container-component.padding-wide[class*="-layout"] > .container > div {
	&:first-child {
		padding-right: 4%;
	}
	&:last-child {
		padding-left: 4%;
	}
	&:not(:first-child):not(:last-child) {
		padding-left: 4%;
		padding-right: 4%;
	}
}

/* Column Layout, vertical rule between adjacent columns */
.container-component.vertical-rule[class*="-layout"] > .container > div:not(:last-child) {
	border-right: 1px solid #dcdcdb;
}

/* Column Layout width */
.container-component.fifty-fifty-layout > .container > div {
	width: 50%;
}

.container-component.thirty-seventy-layout > .container > div {
	&:nth-child(odd) {
		width: 30%;
	}
	&:nth-child(even) {
		width: 70%;
	}
}

.container-component.thirty-seventy-layout-flip > .container > div {
	&:nth-child(odd) {
		width: 70%;
	}
	&:nth-child(even) {
		width: 30%;
	}
}

.container-component.sixty-forty-layout > .container > div {
	&:nth-child(odd) {
		width: 60%;
	}
	&:nth-child(even) {
		width: 40%;
	}
}

.container-component.sixty-forty-layout-flip > .container > div {
	&:nth-child(odd) {
		width: 40%;
	}
	&:nth-child(even) {
		width: 60%;
	}
}

/* Column Layout removed for mobile */
@include media(bp-lt-sm) {

	.container-component[class*="-layout"] > .container {
		flex-direction: column;

		> div {
			width: 100%;
			padding-top: 30px;
			padding-bottom: 30px;

			&:nth-child(odd),
			&:nth-child(even) {
				width: 100%;
			}
		}
	}

	.container-component.layout-mobile-flip > .container {
		flex-direction: column-reverse;
	}

	.container-component.padding-collapsed[class*="-layout"] > .container > div {
		padding: 0;
	}

	.container-component.padding-narrow[class*="-layout"] > .container > div {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.container-component[class*="-layout"] > .container > div,
	.container-component.padding-narrow[class*="-layout"] > .container > div,
	.container-component.padding-wide[class*="-layout"] > .container > div {
		&:first-child {
			padding-right: 0;
		}
		&:last-child {
			padding-left: 0;
		}
		&:not(:first-child):not(:last-child) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.container-component.vertical-rule[class*="-layout"] > .container > div:not(:last-child) {
		border-right: 0;
	}
}
