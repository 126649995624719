.product-results-grid--v1 {
    .container {
        @include media(bp-lt-md) {
            margin: 0;
        }

        .results-container {
            background: #f6f4f4;
            width: 100%;
            clear: both;
            float: none;
            padding-top: 1.92%;

            @include media(bp-lt-md) {
                padding-top: 0;
                background: transparent;
            }

            .tire-sizing-options {
                margin: 0 1.92% 1.92% 1.92%;
                display: inline-block;
                line-height: normal;
                background: transparent;

                p {
                    margin: 0;
                }

                .message-container {
                    display: inline-block;
                    text-align: left;
                    vertical-align: middle;

                    .icon,
                    .message {
                        display: table-cell;
                        vertical-align: middle;
                    }

                    .message {
                        @include media(bp-lt-md) {
                            width: 80%;
                            padding: 0 0 0 7px;
                        }

                        @media screen and (max-width: 360px) {
                            font-size: 12px;
                        }
                    }

                    .view-cta {
                        display: none;
                        width: 20%;
                        text-align: right;
                        padding: 0 10px 0 5px;

                        a {
                            text-decoration: none;
                            color: $dark-gray;
                            span {
                                &:after {
                                    display: inline-block;
                                    content: '';
                                    width: 13px;
                                    height: 13px;
                                    margin: 0 0 0 7px;
                                    @include icon('select-arrow', $dark-gray, 16px);
                                    background-size: contain;
                                    background-position: center 2px;
                                    background-repeat: no-repeat;
                                }
                            }
                        }

                        &.active {
                            span:after {
                                transform: rotate(180deg);
                                background-position: center -2px;
                            }
                        }

                        @include media(bp-lt-md) {
                            display: table-cell;
                            vertical-align: middle;
                        }
                    }

                    @include media(bp-lt-md) {
                        border-bottom: 0px;
                        background: #e3e3e3;
                        display: table;
                        height: 49px;
                    }
                }

                &.stack {
                    .message-container {
                        border-bottom: 1px solid #a8a8a8;
                    }

                    button {
                        display: block;
                    }
                }

                button {
                    color: #666;
                    background: transparent;
                    border: 1px solid #666;
                    padding: 8px 11px;
                    margin: 0 0 0 14px;
                    text-transform: uppercase;
                    font-weight: bold;
                    text-align: left;

                    &:first-child {
                        margin-left: 23px;
                    }

                    &.active,
                    &[disabled] {
                        background: #666666 !important;
                        color: white;
                        border: 1px solid #666 !important;
                        opacity: 1;
                    }

                    @include media(bp-lt-md) {
                        background: #ebebeb;
                        display: none;
                        height: 49px;
                        width: 100%;
                        margin: 0;
                        border-top: 0px;
                        border-right: 0px;
                        border-bottom: 1px solid #a8a8a8;
                        border-left: 0px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                @include media(bp-lt-md) {
                    background: white;
                    display: block;
                    margin: 0 0 22px 0;
                    width: 100%;

                    p {
                        margin: 0 22px;
                    }
                }
                .buttons-container {
                    @include media(bp-gt-sm) {
                        display: block;
                        margin-top: 10px;
                        button {
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                    @include media(bp-gt-lg) {
                        display: inline;
                        margin-top: 0;
                        button {
                            &:first-child {
                                margin-left: 23px;
                            }
                        }
                    }
                }
            }
        }

        .filter {
            &.category {
                .custom-selected {
                    background-color: rgba(0, 0, 0, 0.1);
                    /*padding-left: 3px;*/
                }
            }
        }
    }

    .results,
    .gbb {
        width: 100%;
        margin: 0 auto;
        display: inline-block;

        .result-card {
            display: inline-block;
            width: 30.76%;
            margin: 0 0 1.92% 1.92%;
            vertical-align: top;

            p.store-on-hand {
                display: none;

                span:last-child {
                    border-left: 1px solid $dark-gray;
                    padding-left: 4px;
                }
            }

            &:first-child {
                @include media(bp-lt-md) {
                    border-top: 0px;
                }
            }

            @media screen and (max-width: 1100px) {
                width: 46.14%;
                margin: 0 0 2.56% 2.56%;
            }

            @include media(bp-lt-md) {
                width: 100%;
                margin: 0;
                border-bottom: 7px solid #ebebeb;
            }
        }
    }

    .gbb {
        .heading,
        .post-heading {
            font-size: 20px;

            @include media(bp-lt-md) {
                margin-left: 22px;
                margin-right: 22px;
            }
        }

        .post-heading {
            @include media(bp-lt-md) {
                margin-bottom: 0;
            }
        }

        .card {
            margin-top: 50px !important;
            &::before {
                content: attr(data-heading);
                height: 50px;
                width: calc(100% + 2px);
                display: flex;
                background-color: $brand-color-blue;
                position: absolute;
                top: -50px;
                left: -1px;
                color: white;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                text-transform: uppercase;
            }
        }
    }

    .gbb-card-container {
        display: flex;
        flex-wrap: wrap;

        &.order-desktop {
            @include media(bp-gt-sm) {
                .gbb-d-order-1 {
                    order: 1;
                }

                .gbb-d-order-2 {
                    order: 2;
                }

                .gbb-d-order-3 {
                    order: 3;
                }
                // .gbb-card:nth-child(1) {
                //     order: 3;
                // }

                // .gbb-card:nth-child(2) {
                //     order: 2;
                // }

                // .gbb-card:nth-child(3) {
                //     order: 1;
                // }
                // couldn't use row-reverse with wrap because last element was showing up in second row instead of first
                // flex-direction: row-reverse;
                // justify-content: flex-end;
            }
        }

        &.order-mobile {
            @include media(bp-lt-md) {
                .gbb-m-order-1 {
                    order: 1;
                }

                .gbb-m-order-2 {
                    order: 2;
                }

                .gbb-m-order-3 {
                    order: 3;
                }
                // flex-direction: column-reverse;
            }
        }
    }

    .disclaimer {
        display: inline-block;
        float: none;
        padding: 22px;
        margin: 0;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
            }
        }
    }

    .icon-all-season {
        @include icon('all-season-grid', #245b91, 20px);
        margin-top: -2px;
    }

    .icon-performance {
        @include icon('performance-grid', #940000, 20px);
        margin-top: -4px;
    }

    .icon-winter {
        @include icon('winter-grid', #45a9cb, 20px);
        margin-top: -4px;
    }

    .icon-summer {
        @include icon('summer-grid', #ff9900, 20px);
        margin-top: -2px;
    }

    .icon-eco-friendly {
        @include icon('eco-friendly-grid', #009900, 20px);
        margin-top: -2px;
    }

    .icon-all-terrain {
        @include icon('all-terrain-grid', #663366, 20px);
        margin-top: -2px;
    }

    .icon-checkmark {
        @include icon('checkmark-grid', $brand-color-blue, 20px);
        margin-top: -2px;
    }

    .icon-phone {
        @include icon('phone-grid', #a1a1a1, 20px);
        margin-top: -2px;
    }

    .icon-ban {
        @include icon('ban', #a1a1a1, 20px);
        margin-top: -2px;
    }

    .icon-thumb-up {
        @include icon('thumb-up', #03a51e, 20px);
        margin-top: -2px;
    }

    .icon-tire {
        @include icon('grid-tire', #666666, 34px);
        margin-top: -2px;

        @include media(bp-lt-md) {
            margin: 0 0 0 10px;
        }
    }

    .icon-kilo {
        @include base-icon-styles(22px, 22px);
        color: $brand-color-blue;
        font-style: normal;
        font-weight: bold;
        margin: 0 6px 0 0 !important;
    }

    .triple-guarantee-mobile {
        display: none;
        padding: 42px;
        text-align: center;

        @media screen and (max-width: 768px) {
            display: block;
            border-top: 7px solid #ebebeb;
        }
    }

    .seo-footer-content-container {
        margin-left: 20px;
        margin-right: 20px;
        padding: 52px 0;

        @media screen and (max-width: 768px) {
            background: #ebebeb;
        }

        .seo-footer-content {
            color: #666;
            margin: 0 auto;
            text-align: center;

            h3 {
                color: #666;
                margin-top: 0;
            }

            hr {
                width: 50%;
                border-style: solid;
                border-top: 0;
                color: $brand-color-red;
            }
        }
    }

    &.catitem-match,
    &.catitem-match-multiple {
        .top-result-container {
            display: none;
        }
    }

    &.catitem-match {
        .catalog-match {
            display: block;
        }
    }

    &.catitem-no-match {
        .top-result-container {
            display: block;
        }

        .no-catalog-match {
            display: block;
        }
    }

    .no-catalog-match,
    .catalog-match {
        display: none;
        color: $brand-color-red;
    }

    .inventory-grid-heading,
    .low-inventory-grid-heading {
        margin: 1.92%;
        border-bottom: 1px solid #dcdcdc;

        h4 {
            margin: 10px 0;
            color: $dark-gray;
        }

        @media screen and (max-width: 1100px) {
            margin: 2.56%;
        }

        @include media(bp-lt-md) {
            margin: 0;
            padding: 10px 22px;
            background-color: #ebebeb;
            border-bottom: none;

            h4 {
                margin: 0;
            }
        }
    }
}
