@import '~common/scss/shared/shared';

.blog-subnav-wrapper {
    position: sticky;
    top: 0;
    z-index: 2;
}

.blog-nav {
    background-color: #313439;
    color: $white;
    font-family: inherit;

    @include media(bp-lt-md) {
        background-color: $red;
    }

    nav {
        position: relative;
        flex-wrap: wrap;
        align-items: center;
        max-width: 1440px;
        height: 56px;
        margin: 0 auto;
        background-color: #313439;

        @include media(bp-lt-lg) {
            top: 0;
            bottom: 0;
            z-index: 9999;
            width: 100%;
            height: auto;
        }

        ul {
            list-style-type: none;
        }

        .menu {
            display: flex;
            margin: 0;

            @include media(bp-lt-lg) {
                position: absolute;
                z-index: 9999;
                flex-wrap: wrap;
                justify-content: left;
                height: auto;
                padding-left: 0;
                border-bottom: 1px solid #424346;
                background: #313439;
                transform-origin: top left;
            }

            @include media(bp-gt-md) {
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                height: 56px;
                margin-right: 100px;
            }

            // ONLY US LI IF IT PERTAINS TO ALL LEVELS (ITEM AND SUBMENU__ITEM)
            li {
                a {
                    @include font-body-bold;
                    font-weight: bold;
                    text-decoration: none;
                    white-space: nowrap;

                    &:focus {
                        outline: none;
                        border-bottom: none;
                    }
                }
            }
        }

        .item {
            order: 1;

            @include media(bp-lt-lg) {
                order: 2;
                width: 100%;
                margin: 0 20px;
                border-top: 1px solid #424346;
                text-align: left;
                text-transform: uppercase;
            }

            @include media(bp-gt-md) {
                height: 100%;
            }

            &:nth-child(1) {
                @include media(bp-lt-lg) {
                    margin: 0;
                    padding: 0 20px;
                }
            }

            a {
                display: flex;
                align-items: center;
                padding: 0 20px;
                color: $white;

                @include media(bp-lt-lg) {
                    height: 48px;
                    font-size: 14px;
                    letter-spacing: 1.3px;
                }

                @include media(bp-gt-md) {
                    height: 100%;
                    // margin-left: 0;
                    font-size: 18px;
                }

                &:hover {
                    &::after {
                        color: #e10c27;
                    }
                }

                .chevron {
                    position: absolute;
                    right: 30px;
                    display: block;
                    width: 9px;
                    filter: invert(1);
                    transform: rotate(270deg);

                    @include media(bp-gt-md) {
                        display: none;
                    }
                }
            }

            &.home {
                @include media(bp-lt-lg) {
                    display: none;
                }
            }

            &:not(.has-submenu) {
                a.active,
                a:hover,
                a:active,
                a:focus {
                    text-decoration: underline;
                    text-decoration-color: #e10c27;
                    text-decoration-style: solid;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 3px;
                }
            }

            &:not(.item--submenu) {
                @include media(bp-gt-md) {
                    order: 1;
                    width: auto;
                    margin: 0;
                    border-top: none;
                }
            }
        }

        .item--submenu {
            @include media(bp-gt-md) {
                display: none;
            }
        }

        .submenu {
            display: none;
        }

        .submenu__item {
            a {
                padding-bottom: 12px 20px;
                font-size: 15px;
                letter-spacing: 1.3px;
                text-transform: uppercase;
            }
        }

        .mobile-toggle {
            width: 100%;
            padding: 13px 0;
            background: #313439;
            color: $white;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            position: relative;
            font-size: 16px;
            letter-spacing: 0.5px;
            display: inline-block;

            svg {
                fill: $white;
                position: absolute;
                top: 21px;
                right: 23px;
            }

            @include media(bp-gt-md) {
                display: none;
            }
        }

        #mobile-toggle__checkbox {
            display: none;

            + label {
                @include media(bp-lt-lg) {
                    & ~ .menu .item:not(.has-submenu) {
                        display: none;
                    }
                }

                @include media(bp-gt-md) {
                    display: none;

                    & ~ .menu .item:not(.item--submenu) {
                        display: block;
                    }
                }
            }

            &:checked + label {
                svg {
                    transform: rotate(180deg);
                }

                @include media(bp-lt-lg) {
                    & ~ .menu .item:not(.has-submenu) {
                        display: block;
                    }
                }
            }
        }

        .has-submenu {
            @include media(bp-lt-lg) {
                display: none;
            }

            ul {
                z-index: 2;
                position: absolute;
                background: #efefef;
                padding: 0;
                top: 56px;

                li {
                    text-align: left;
                }

                a {
                    padding: 15px 20px;
                    color: $black;
                    display: block;

                    &:hover {
                        color: #e10c27;
                    }
                }
            }

            img {
                width: 11px;
                height: 7px;
                margin-left: 5px;
                filter: invert(1);
            }

            &:hover {
                cursor: pointer;

                .submenu {
                    display: block;
                }

                img {
                    transform: rotate(180deg);
                }
            }
        }

        .search {
            position: relative;
            right: 10px;
            padding-top: 2px;

            @include media(bp-lt-lg) {
                position: inherit;
                order: 1;
                margin-left: 0;
                margin-right: 0;
                border-top: none;
                border-bottom: none;
                padding-top: 10px;
                padding-bottom: 60px;
                text-align: center;
            }

            @include media(bp-gt-md) {
                position: relative;
                order: 2;
                padding: 0;
            }

            form {
                @include media(bp-lt-lg) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                @include media(bp-gt-md) {
                    margin-top: 8px;
                }
            }

            .search__input {
                @include font-body;
                width: 300px;
                height: 34px;
                padding-left: 30px;

                @include media(bp-lt-lg) {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 100%;
                }

                @include media(bp-gt-md) {
                    width: 225px;
                    height: 34px;
                }

                @include media(bp-lt-sm) {
                    width: 100%;
                    height: 46px;
                    padding-left: 40px;
                    border-top: none;
                    font-size: 14px;
                }
            }

            input[type='submit'] {
                position: absolute;
                display: block;
                width: 17px;
                margin-top: 10px;
                margin-left: 10px;
                border: none;
                background: url(/https://s7d1.scene7.com/is/content/bridgestone/magnifying-glass-icon-global-web-bsro);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 30px;
                text-indent: -99999px;
            }

            img {
                @include media(bp-lt-lg) {
                    margin-top: 0;
                }
            }
        }
    }
}
