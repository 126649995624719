///////////////////////////////////////////
////        Offer refresh styles       ////
///////////////////////////////////////////
@import '~common/scss/shared/shared';
@import 'offers';
@import 'offer-card';
@import 'offer-subnav';
@import 'offers-signup';
@import 'swiper/css/bundle';

$bg-gray: #eeeeee;

.offers-list {
    margin: 0 -15px;

    @include media(bp-xs) {
        margin: 0;
    }

    &__swiper-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media(bp-lt-xxl) {
            justify-content: center;
        }

        &::after {
            content: '';
            min-width: 270px;

            @include media(bp-gt-nav) {
                flex: 0 1 calc(33.33% - 20px);
                margin: 10px;
            }

            @include media(bp-gt-md) {
                flex: 0 1 calc(25% - 30px);
                margin: 15px;
            }

            @include media(bp-gt-lg) {
                flex: auto;
                min-width: initial;
            }
        }
    }

    &__expand-cta {
        @include media(bp-gt-xs) {
            display: none !important;
        }

        font-size: 16px;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin: -20px 0 40px 0;
        padding: 15px 25px;

        @include media(bp-xs) {
            width: 225px;
        }
    }

    &__wrapper {
        background: $white;
        @include media(bp-xs) {
            &--is-collapsed {
                .offer-card--hide-mobile {
                    display: none;
                }
            }
        }

        &--background-gray {
            background: $bg-gray;
        }

        &--background-black {
            background-color: $black;
            background-image: url('https://s7d1.scene7.com/is/image/bridgestone/mobile-black-background-offers-bsro-global?scl=1&fmt=jpg&qlt=85');
            background-size: auto;
            background-repeat: repeat-y;

            @include media(bp-gt-sm) {
                background-image: url('https://s7d1.scene7.com/is/image/bridgestone/black-background-offers-bsro-global?wid=1440&scl=1&fmt=jpg&qlt=85');
                background-repeat: repeat;
            }
        }

        &--background-white {
            background: $white;
        }
    }

    &__cta-all-offers {
        background: #d31a0b;
        color: $white;
        text-decoration: none;
        padding: 15px 25px;
        border-radius: 0;
        text-transform: uppercase;
        @include font-title;
        border: 2px solid #d31a0b;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        margin: 0 auto;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin-bottom: 60px;

        &:hover,
        &:active {
            color: $white !important;
            background-color: darken(#d31a0b, 10%);
            text-decoration: none !important;
            border-color: darken(#d31a0b, 10%);
        }
    }

    &__container {
        margin: 0 auto;
        padding: 0;
        max-width: 1210px;
        padding-top: 20px;

        @include media(bp-gt-sm) {
            padding-top: 30px;
        }

        @include media(bp-lt-xl) {
            padding: 0 20px;
            padding-top: 30px;
        }
    }

    &__header-wrap {
        border-top: 4px solid #e10b27;
        margin: 0;
        padding-top: 0;
        padding-bottom: 20px;

        @include media(bp-gt-sm) {
            padding-bottom: 30px;
            padding-top: 15px;
        }
    }

    &__heading {
        @include font-body-bold;
        letter-spacing: 2px;
        line-height: 20px;
        font-size: 15px;
        margin-top: 2px;

        @include media(bp-gt-sm) {
            font-size: 20px;
            margin: 0;
            line-height: 25px;
        }

        &--color-white {
            color: $white;
        }
    }

    &--carousel {
        &.swiper {
            margin: 0 auto;
            max-width: 1170px;

            @include media(bp-lt-xl) {
                max-width: calc(100% - 80px);
            }

            @include media(bp-lt-md) {
                max-width: 590px;
            }

            .swiper-slide {
                flex: initial;
                padding: 35px 10px;
                margin: 0;
                flex-shrink: 0;
            }
        }

        .swiper-scrollbar {
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            bottom: 40px;

            @include media(bp-gt-nav) {
                width: 50%;
            }

            .swiper-scrollbar-drag {
                background: #d31a0b;
                cursor: grabbing;
            }
        }

        &__swiper-wrapper {
            flex-wrap: nowrap;
            margin: 0;
            width: auto;

            @include media(bp-lt-lg) {
                justify-content: initial;
            }

            .offer-card-refresh {
                max-width: 300px;
                min-width: 260px;
                margin: 35px auto;
            }
        }

        &__btn-prev,
        &__btn-next {
            background: $white;
            border: 1px solid #cecece;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            margin-top: 0px;
            top: 37%;

            @include media(bp-lt-nav) {
                width: 30px;
                height: 30px;
            }

            &:after {
                border: solid $black;
                border-width: 0 3px 3px 0;
                content: '';
                display: block;
                height: 4px;
                padding: 3px;
                position: absolute;
                right: 18px;
                top: 17px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                width: 4px;

                @include media(bp-lt-nav) {
                    height: 2px;
                    padding: 3px;
                    right: 10px;
                    top: 8px;
                    width: 2px;
                }
            }
        }

        &__btn-prev {
            left: -60px;

            @include media(bp-lt-lg) {
                left: -20px;
            }

            @include media(bp-lt-xl) {
                left: 20px;
            }

            @include media(bp-lt-nav) {
                left: 20px;
            }

            &:after {
                right: 15px;
                transform: rotate(135deg);

                @include media(bp-lt-nav) {
                    right: 7px;
                }
            }
        }

        &__btn-next {
            right: -60px;

            @include media(bp-lt-lg) {
                right: -20px;
            }

            @include media(bp-lt-xl) {
                right: 20px;
            }
        }
    }

    // XF Container Styles
    &__xf-container {
        min-height: 340px;
        max-height: 340px;
        margin-bottom: 100px;
        display: none;

        &:last-child {
            display: block;
        }

        @include media(bp-gt-xs) {
            flex: 0 1 calc(46% - 0px);
            margin: 35px 10px;
        }

        @include media(bp-gt-nav) {
            flex: 0 1 calc(33.33% - 20px);
        }

        @include media(bp-gt-md) {
            flex: 0 1 calc(55% - 98px);
            margin: 5px 15px 80px 15px;
            min-height: 370px;
            max-height: 370px;
        }

        .xfpage,
        .component {
            height: 100%;
        }

        .title-text-cta {
            height: 100%;
            position: relative;

            & > {
                .container {
                    padding: 0;
                    margin: 0;
                }
            }

            .image {
                margin: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
                width: 100%;

                img {
                    margin: 0 auto;
                    object-fit: cover;
                    height: 100%;
                    border-radius: 10px;
                    width: 100%;
                }
            }

            &.image-left .copy {
                padding: 20px 25px;

                @include media(bp-gt-md) {
                    padding: 20px 40px;
                }
            }

            .copy {
                padding: 20px 25px;
                text-align: center;
                color: $white;
                z-index: 1;
                width: 100%;

                @include media(bp-gt-md) {
                    padding: 20px 40px;
                    padding-top: 30px;
                }

                p {
                    width: 100%;
                    text-align: center;
                    margin-top: 10px;
                    font-size: 13px;

                    @include media(bp-gt-md) {
                        width: 49%;
                        margin-bottom: 15px;
                        margin-top: 13px;
                        line-height: 22px;
                        text-align: left;
                    }
                }
            }

            .title,
            .subtitle {
                color: $white;
            }

            .title {
                margin-bottom: 0;
                font-size: 20px;
                text-align: center !important;

                @include media(bp-gt-md) {
                    text-align: left !important;
                }
            }

            .subtitle {
                color: $white;
                font-size: 36px;
                letter-spacing: 1px;
                margin-top: 10px;
                margin-bottom: 0;
                text-align: center !important;

                @include media(bp-gt-md) {
                    text-align: left !important;
                }
            }

            .cta {
                @include media(bp-lt-lg) {
                    text-align: center !important;
                }
            }

            .btn {
                font-size: 13px;
                border-radius: 4px;
                padding: 15px 30px;

                @include media(bp-lt-md) {
                    width: auto;
                }

                @include media(bp-gt-md) {
                    font-size: 14px;
                    padding: 17px 33px;
                }
            }

            .floating-image {
                position: absolute;
                width: 65%;
                bottom: -13px;
                right: 28%;
                z-index: -1;
                transform: translateX(50%);
                filter: drop-shadow(5px 5px 5px #3535356e);

                @include media(bp-gt-md) {
                    bottom: -18px;
                    right: -45px;
                    transform: none;
                    width: 60%;
                }
            }
        }
    }
}

.offers-refresh {
    &__wrapper {
        &--has-carousel {
            .offers-list__component {
                height: 0;
                overflow: hidden;
            }
        }
        &--edit-mode {
            .offers-list__component {
                height: auto;
                overflow: initial;
            }
        }

        & .offers-list__component {
            display: block;
            &.exclude {
                display: none;
            }
        }
        &.is-loading[data-has-carousel='list-view'] {
            .offers-subnav__list,
            .offers-subnav__heading-count {
                visibility: hidden;
            }
            .offers-list__component {
                display: none;
            }
            .offers-blank {
                display: block;
                min-height: 800px;
            }
        }
        & .offers-blank {
            display: none;
        }

        &[data-has-carousel='carousel-view'] {
            & .offers-refresh__title {
                font-size: 30px;
                @include media(bp-gt-sm) {
                    font-size: 32px;
                }
            }

            & .offers-refresh__subtitle {
                padding: 0 5% 0 5%;
                text-align: center;
                font-size: 14px;
                color: #4a4a4a;
                line-height: 20px;

                & .offers-subnav__heading-count {
                    display: none;
                }
            }
        }

        & .fifty-fifty-advanced-component {
            padding-bottom: 0;
            & > .container > .title {
                font-size: 20px;
                border-top: 4px solid #e10b27;
                margin: 0;
                padding-top: 15px;
            }

            & .fifty-fifty-advanced {
                font-size: 16px;
                margin-bottom: -20px;
            }
        }
    }

    &__carousel-container {
        margin: 0 auto;
        padding: 0;
        max-width: 1170px;
        padding-top: 20px;

        @include media(bp-gt-sm) {
            padding-top: 30px;
        }

        @include media(bp-lt-xl) {
            padding: 0 20px;
            padding-top: 30px;
        }
    }

    &__heading {
        padding: 15px 0;

        @include media(bp-gt-sm) {
            padding: 30px 0;
        }
    }

    &__header-wrap {
        margin: 0 auto;
        padding: 0 4.86111%;
        max-width: 1355px;
    }

    &__title {
        font-size: 25px;
        margin: 0;

        @include media(bp-gt-sm) {
            font-size: 40px;
        }
    }

    &__subtitle {
        @include font-body;
        font-size: 15px;
        color: #757575;
        text-transform: capitalize;
        margin-top: 10px;
        margin-bottom: 0;

        @include media(bp-gt-sm) {
            font-size: 20px;
        }
    }
}
