/* Product Result Filter */
$result-number-color: $brand-color-red;
$product-results-border-color: #d0d0d0;
$grey-text-color: $dark-gray;

.top-result-container {
    width: 100%;
    padding: 20px 0;

    .number-of-results {
        float: left;
        width: 10%;
        color: $result-number-color;
        font-size: 16px;

        @include media(bp-lt-lg) {
            width: 14%;
        }
    }

    .filter {
        float: left;
        width: 72%;
        border: none;
        padding: 0px;
        margin: 0 0 10px 0;

        @include media(bp-lt-lg) {
            width: 64%;
        }

        .filter-label {
            float: left;
            color: $grey-text-color;
            font-weight: bold;
        }
    }

    .custom-select-result {
        display: inline-block;
        padding-right: 25px;
        margin-left: 5px;
        border: 0;
        position: relative;
        overflow: visible;

        &.custom-select-sort {
            background-position: 95% 15%;
            padding-right: 11px;

            @include media(bp-lt-lg) {
                margin-left: 0px;
            }
        }

        &.custom-selected:after {
            width: 16px;
            content: '';
            display: table;
            @include icon('select-arrow', #951200, 16px);
            height: 15px;
            position: absolute;
            right: 4px;
            top: 2px;
            transform: rotate(180deg);
        }

        &.custom-bg {
            background-image: none;
        }

        &.filterBg {
            background-color: #e5e5e5;
        }

        a {
            width: auto;
            text-decoration: none;
            color: $grey-text-color;
        }

        select {
            width: auto;
        }

        .filter-details {
            width: 200px;
            background: $white;
            border: 1px solid #666666;
            position: absolute;
            padding: 15px 0;
            top: 20px;
            left: 0;
            z-index: 1;

            h3 {
                font-size: 16px !important;
                margin-bottom: 0;
            }

            ul {
                width: 85%;
                display: block;
                margin: 0 auto;
                padding: 0;

                li {
                    list-style-type: none;
                    padding-bottom: 10px;
                }
            }

            .price-label {
                .price {
                    font-weight: bold;
                    color: $result-number-color;
                }
            }
        }

        .price-details,
        .mileage,
        .stdOpt {
            width: 300px;
        }

        @media screen and (min-width: 0\0) {
            select {
                width: auto !important;
            }
        }
    }

    .sorting {
        float: right;
        width: auto;
        margin-bottom: 10px;

        @include media(bp-lt-lg) {
            width: 22%;
        }

        .sort-label {
            float: left;
            color: $grey-text-color;
            font-weight: bold;
        }

        .sort-select-1 {
            padding-top: 0px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .mobile-only-filter {
        display: none;
        position: relative;
    }

    .price-range-slider {
        display: inline-block;
        width: 75%;

        .input-range {
            -webkit-appearance: none;
            width: 100%;
            height: 10px;
            background: #666666;
            outline: none;
            border: 0;
            padding: 0;
        }
        .input-range::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: $result-number-color;
            cursor: pointer;
            -webkit-transition: background 0.15s ease-in-out;
            transition: background 0.15s ease-in-out;
        }
        .input-range::-moz-range-thumb {
            -moz-appearance: none;
            width: 25px;
            height: 25px;
            border: 0;
            border-radius: 50%;
            background: $result-number-color;
            cursor: pointer;
            -webkit-transition: background 0.15s ease-in-out;
            transition: background 0.15s ease-in-out;
        }

        .min-price {
            font-size: 16px;
            float: left;
            margin-top: 10px;
        }

        .max-price {
            font-size: 16px;
            float: right;
            margin-top: 10px;
        }
    }

    /*For mobile filter*/
    @include media(bp-lt-md) {
        .desktop-only-filter {
            display: none;
        }

        .mobile-only-filter {
            display: block;
            width: 100%;
            padding: 20px 0;

            .filter-clear {
                width: 100%;
                background: $white;
                padding: 10px;
                float: left;

                a {
                    color: $dark-gray;
                    display: inline;
                    font-size: 18px;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                .clear-btn {
                    position: absolute;
                    display: inline-block;
                    width: auto;
                    float: none;
                    margin: 0;
                    left: 15px;
                }

                .done-btn {
                    position: absolute;
                    display: inline-block;
                    width: auto;
                    float: none;
                    margin: 0;
                    right: 15px;
                }
            }

            .filter-btn {
                position: absolute;
                display: inline-block;
                width: auto;
                float: none;
                margin: 0;
                left: 22px;

                a {
                    position: relative;
                    color: $grey-text-color;
                    font-size: 18px;
                    text-decoration: none;
                    margin-right: 0px;

                    &:after {
                        @include icon(filter-sliders, $grey-text-color, 16px);
                        position: absolute;
                        top: 4px;
                        margin-left: 4px;
                        transform: none;
                    }
                }
            }

            .number-of-results {
                width: 100%;
                float: left;
                text-align: center;
            }

            .sorting-btn {
                position: absolute;
                display: inline-block;
                width: auto;
                float: none;
                margin: 0;
                right: 22px;

                a {
                    position: relative;
                    color: $grey-text-color;
                    font-size: 18px;
                    text-decoration: none;

                    &:after {
                        @include icon(sort-arrows, $black, 16px);
                        position: absolute;
                        top: 4px;
                        margin-left: 4px;
                        transform: none;
                        content: '';
                    }
                }
            }

            .filter-container {
                width: 100%;
                clear: both;
                position: relative;
                top: -35px;

                .price-range {
                    padding: 10px 10px 20px;
                    background: #e3e3e3;
                    border-bottom: 1px solid $product-results-border-color;

                    &.category {
                        margin: 0px;
                    }
                    .saleonly {
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                    }
                    .saleonly-label,
                    .price-label {
                        font-size: 16px;
                        font-weight: bold;
                        display: inline-block;
                        text-align: center;
                    }

                    .price-range-slider {
                        .input-range::-webkit-slider-thumb {
                            width: 35px;
                            height: 35px;
                        }
                        .input-range::-moz-range-thumb {
                            width: 35px;
                            height: 35px;
                        }
                    }

                    .price-label {
                        position: relative;
                        top: 10px;
                    }
                    .price {
                        font-size: 20px;
                        color: $result-number-color;
                    }

                    #sliderLabel {
                        border: 0 solid black;
                        border-radius: 35%;
                        cursor: pointer;
                        display: inline-block;
                        height: 26px;
                        top: 10px;
                        left: 10px;
                        position: relative;
                        width: 65px;
                        background: $white;
                        z-index: 1;
                    }
                    #sliderLabel input {
                        display: none;
                    }
                    #sliderLabel input:checked + #slider {
                        left: 30px;
                    }
                    #slider {
                        background: #666666;
                        display: block;
                        height: 35px;
                        left: 0px;
                        position: absolute;
                        top: -5px;
                        -moz-transition: left 0.25s ease-out;
                        -webkit-transition: left 0.25s ease-out;
                        transition: left 0.25s ease-out;
                        width: 35px;
                        z-index: 9;
                        border-radius: 50%;
                    }
                }

                .filter-tab,
                .filter-content {
                    width: 50%;
                    float: left;
                    background: #e3e3e3;
                    margin: 0 0 22px 0;

                    ul {
                        padding: 0;
                        margin: 0;

                        li {
                            width: 100%;
                            list-style-type: none;

                            a {
                                padding: 15px 10px;
                                float: left;
                                width: 100%;
                                text-decoration: none;
                                color: $grey-text-color;
                                position: relative;
                                border-bottom: 2px solid $product-results-border-color;
                                border-right: 2px solid $product-results-border-color;
                                font-weight: bold;
                            }

                            a.active {
                                background: $result-number-color;
                                border-right: 0;
                                color: $white;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    border-width: 25px;
                                    border-style: solid;
                                    border-color: transparent transparent transparent $result-number-color;
                                    top: 0px;
                                    right: -49px;
                                    z-index: 999;
                                }
                            }
                        }
                    }
                }

                .filter-content {
                    width: 50%;
                    background: $white;

                    input {
                        opacity: 0;
                        width: 100%;
                        position: absolute;
                        left: 0px;
                        height: 51px;
                    }

                    &.category {
                        padding: 0px;
                        margin: 0 0 22px 0;
                    }

                    ul {
                        li {
                            position: relative;
                            clear: both;

                            a {
                                border-right: 0 !important;
                                height: 51px;
                                padding-left: 30px;
                            }

                            a.active-filter {
                                color: $result-number-color;
                                background-color: $white;

                                &:after {
                                    position: absolute;
                                    right: 0;
                                    @include icon('checkmark', $result-number-color);
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobile-only-sorting {
            display: block;
            width: 100%;

            ul {
                width: 100%;
                padding: 0;
                margin: 0;

                li {
                    list-style-type: none;
                    clear: both;

                    a {
                        display: block;
                        width: 100%;
                        padding: 20px 15px;
                        background: #e3e3e3;
                        border-bottom: 2px solid #d0d0d0;
                        color: $dark-gray;
                        font-size: 16px;
                        font-weight: bold;
                        text-decoration: none;
                    }

                    a.active {
                        background: $result-number-color;
                        color: $white;
                    }
                }
            }
        }
    }
}
