@import '~common/scss/shared/shared';

.blog {
    #main {
        max-width: none;
    }

    .title-text-cta.pull-quote {
        background-color: $white;
        width: 100%;

        .container {
            padding: 10px;
        }

        .copy {
            margin-top: 1%;
            margin-bottom: 1%;
            border-top: 5px solid #ef3d36;
            border-bottom: 5px solid #ef3d36;
            padding: 5px;

            p {
                @include font-body-bold;
                font-size: 25px;
                @include media(bp-lt-lg) {
                    font-size: 16px;
                }
                padding-left: 5%;

                &:first-of-type {
                    padding-top: 10px;
                    &:first-of-type::before {
                        content: '\0022';
                        color: #e7e7e7;
                        position: absolute;
                        margin-left: -3%;
                        margin-top: -0.5%;
                    }
                }

                &:last-of-type::after {
                    content: '\0022';
                    color: #e7e7e7;
                    position: absolute;
                    padding-left: 0.5%;
                    @include media(bp-lt-lg) {
                        padding-left: 1%;
                    }
                }
            }

            .cta {
                padding-left: 5%;
                padding-bottom: 2%;
                margin-bottom: 16px;
                @include media(bp-lt-lg) {
                    padding-bottom: 3%;
                }

                a {
                    font-size: 12px;
                    width: 180px;
                    height: 50px;
                    display: unset;
                    @include media(bp-lt-lg) {
                        font-size: 9px;
                        width: 120px;
                        height: 37px;
                    }
                }
            }
        }
    }
}
