@import 'common/scss/shared/mixins';
@import 'common/scss/shared/fonts';
@import '~fcac/scss/shared/fonts';

// Reusable product price styles, to be selected by app container.
@mixin product-price {
    .product-price {
        &__price {
            @include font-title;
            font-size: 20px;
            line-height: 20px;
            display: flex;
            align-items: baseline;
            margin-bottom: 4px;

            &-dollar {
                font-size: 36px;
                line-height: 36px;
            }

            &-or {
                align-self: center;
                @include font-body;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                margin: 0 10px;
            }

            sup {
                align-self: normal;
                font-size: 20px;
                margin-top: 1px;
                margin-left: 2px;
            }

            &-ea {
                font-size: 20px;
                line-height: 20px;
            }
        }

        &__text {
            flex-grow: 1;
        }

        &__four-tires {
            display: block;
            @include font-body;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: black;
            margin-bottom: 0;

            strong {
                @include font-body-bold;
                font-size: 14px;
                line-height: 16px;
                font-weight: 900;
            }
        }

        &__offer-icon {
            margin-bottom: 10px;
        }

        &__tag-icon {
            margin-right: 6px;
            min-width: 16px;
        }

        &__offer-detail {
            color: black;

            &:hover {
                color: black !important;
            }
        }

        &__offer-copy-text,
        &__signin-only-description {
            display: flex;
            gap: 1px;

            span {
                @include font-body;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: black;
            }

            strong {
                @include font-body-bold;
                font-weight: 900;
                font-size: 14px;
                line-height: 16px;
            }
        }

        &__separator {
            border: 0;
            border-top: 1px solid #d4d4d4;
            margin: 16px 0;
        }

        // Grouping to override specificity in app styles.
        &__signin-only-description,
        &__signin-only-link,
        &__signin-only-links,
        &__fitment {
            @include font-body;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: black;
        }

        &__signin-only-description {
            margin-top: 8px;
        }

        &__fitment {
            margin-bottom: 8px;
        }

        &__signin-only-link {
            background: none !important;
            border: none;
            padding: 0 !important;
            text-decoration: underline;
            cursor: pointer;
            color: black;
        }
    }

    // Centers the icon in each card in grid view.
    .grid .product-price {
        &__offer-icon {
            position: absolute;
            top: 13px;
            right: 13px;
        }
    }
}
