@import '~common/scss/shared/shared';

.thirty-seventy {
    @include outer-container;
    @include cta-link();

    padding: 30px 0;

    @include media(bp-gt-sm) {
        padding: 50px 0;
		@include gradient-top();
    }

    .title {
        text-align: left;

        @include media(bp-lt-md) {
            margin: .5em 0;
            text-align: center;
        }

        @include media(bp-gt-sm) {
            margin-top: 0;
            margin-bottom: .5em;
            line-height: 1em;
        }
	}

	.subtitle-above {
		margin-top: 0px;
		margin-bottom: 0.5em;
	}

    .img-container {
        @include media(bp-gt-sm) {
            max-width: 30%;
            float: left;
            padding: 0 5% 0 0;
        }

        img {
            display: block;
            width: 100%;
        }

        .cq-dd-image {
            @include media(bp-lt-md) {
                display: none;
            }
        }
    }

    .copy-container {
        @include media(bp-gt-sm) {
            width: 70%;
            float: left;
        }

        @include media(bp-lt-md) {
            margin: 25px 0 50px;
			padding: 0;
        }
    }

    .copy {
        ul {
            @include bulleted-list();

            li {
                padding-bottom: 1em;
            }
        }

        p img {
            max-width: 180px;
            margin: 5px 10px;
            float: right;
        }
	}
}

.thirty-seventy.thirty-seventy-valign-center {
	.container {
		display: flex;
		align-items: center;

		@include media(bp-lt-md) {
			flex-direction: column-reverse;
		}
	}

	.title {
		font-size: 5vw;
	}
	.subtitle {
		font-size: 3vw;
	}

    @include media(bp-lt-xl) {
		.title {
			font-size: 70px;
		}
		.subtitle {
			font-size: 42px;
		}
	}

	@include media(bp-lt-sm) {
		.title {
			font-size: 8vw;
		}
		.subtitle {
			font-size: 5vw;
		}
	}
}

.thirty-seventy-mobile-image {
	@include media(bp-lt-sm) {
		.container {
			display: flex;
			flex-direction: column-reverse;
		}

		.img-container {
			margin: -40px 15% 0 15%;

			.cq-dd-image {
				display: block;
			}
		}
	}
}

// collapse so image can be flush w/ section top and bottom
.thirty-seventy-vmargin-collapse {
	padding-top: 0;
	padding-bottom: 0;
}

.thirty-seventy-right {
    @include media(bp-gt-sm) {
        .img-container,
        .copy-container {
            float: right;
        }

        .img-container {
           padding: 0 0 0 5%;
        }

        .copy-container {
           padding: 0;
        }

		&.thirty-seventy-valign-center {
			.container {
				flex-direction: row-reverse;
			}
		}
	}
}

.thirty-seventy-wide-margins {
    @include media(bp-gt-sm) {
        padding: 55px 17%;

        > .title {
            margin-bottom: 55px;
            text-align: center;
        }

        .copy-container {
            .title {
                display: none;
            }
        }

        .subtitle {
            margin-top: 0;
        }
    }

    @include media(bp-lt-md) {
        > .title {
            display: none;
        }
    }
}
