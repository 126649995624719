@use 'sass:math';
@import '~common/scss/shared/shared';

.schedule-appointment-v2 {
    display: flex;
    flex-direction: column;

    .not-pinnacle-store {
        display: block;
    }

    .pinnacle-store {
        display: none;
    }

    &.at-pinnacle-store {
        .not-pinnacle-store {
            display: none;
        }
        .pinnacle-store {
            display: block;
        }

        #email-optin {
            margin-left: 4px !important;
        }
    }

    .h2,
    .h3 {
        display: block;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    .h2 {
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        text-align: center;
    }

    .h3 {
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    .subheading {
        font-size: 0.7em;
        display: block;
        color: #cecece;
        margin-bottom: 10px;
    }

    .circ {
        display: flex;
        border: 2px solid #cecece;
        border-radius: 50%;
        width: 4.7ex;
        height: 4.7ex;
        margin-bottom: 8px;
        text-align: center;
        font-size: 24px;
        @include font-body;
        justify-content: center;
        align-items: center;

        @include media(bp-lt-md) {
            width: 4ex;
            height: 4ex;
            line-height: 43px;
        }
    }

    .activated .circ,
    .active .circ {
        border: 2px solid $brand-color-blue;
        background-color: $brand-color-blue;
        color: $white;
    }

    .one:before {
        content: '1';
    }

    .two:before {
        content: '2';
    }

    .three:before {
        content: '3';
    }

    .four:before {
        content: '4';
    }

    .five:before {
        content: '5';
    }

    section {
        flex: 1 100%;

        /*
			Begin TEMPORARY CSS

			Just allows you to easily see where the sections start and stop
		*/
        /*
		padding: 30px 0;

		&:first-child {
			margin-top: 30px;
		}

		border-top: 1px solid #ccc;


		&:last-child {
			margin-bottom: 30px;
			border-bottom: 1px solid #ccc;
		}

		@include media(bp-lt-md) {
			border: none;
		}
		*/
        /*
			End TEMPORARY CSS
		*/
    }

    .list ul {
        list-style-type: none;
        padding: 0;
    }

    /* @include media(bp-gt-sm) { */
    .icon-car {
        @include icon('car', #aaa, 50px);
    }

    .icon-flat-repair {
        @include icon('flat-repair', #aaa, 40px);
    }

    .icon-calendar {
        @include icon('calendar', #aaa, 40px);
    }

    .icon-contact-info {
        @include icon('contact-info', #aaa, 40px);
    }

    .icon-drop-pin {
        @include icon('drop-pin', $white, 40px);
    }
    /* } */

    // .drop-pin {
    //     margin-left: 3px !important;
    // }

    .steps.list ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 335px;
        margin: 0 auto;
        flex: 1 100%;

        @include media(bp-gt-sm) {
            max-width: 700px;
        }

        li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include font-title;
            color: #555555;
            /* flex: 1 100%; */

            i {
                display: none;
                /* margin: -11px 0 0 0; */

                &.normal-icon {
                    display: flex;
                }
            }

            em {
                /* disabling numeric display */
                display: none;
                /* disabling numeric display */

                font-style: normal;
                @include font-title;
                font-size: 18px;

                @include media(bp-lt-sm) {
                    font-size: 18px;
                }
            }

            p {
                margin-top: 0;

                @include media(bp-lt-sm) {
                    font-size: 13px;
                }

                span {
                    display: none;

                    @include media(bp-gt-sm) {
                        display: inline-block;
                    }
                }
            }

            span.line {
                height: 1px;
                width: 100%;
                border: 1.5px solid #cecece;
                margin: -30px 0 0 0;
            }

            &.activated {
                .normal-icon {
                    display: none;
                }

                .check-icon {
                    display: flex;
                }

                .icon-tickmark {
                    @include icon('tickmark', $white, 45px);
                    background-position: 8px 8px;
                }

                span.line {
                    border: 1.5px solid $brand-color-blue;
                }
            }

            &.active {
                .normal-icon {
                    display: inline-block;
                }

                .check-icon {
                    display: none;
                }

                .icon-car {
                    @include icon('car', $white, 50px);
                }

                .icon-flat-repair {
                    @include icon('flat-repair', $white, 40px);
                }

                .icon-calendar {
                    @include icon('calendar', $white, 40px);
                }

                .icon-contact-info {
                    @include icon('contact-info', $white, 40px);
                }
            }

            &.activated,
            &.active {
                p {
                    color: $brand-color-blue;
                }
            }

            &:nth-of-type(even) {
                flex-grow: 2;
                /* flex-basis: 20px;
				margin: 0 -10px; */
            }

            &:nth-of-type(odd) {
                min-width: 76px;
            }
        }
    }

    .service-items {
        /* margin: 0 -4.86111%; */
        background-color: #fafafa;
        padding: 20px 20px 35px 20px;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        @include media(bp-lt-md) {
            padding: 0;
        }

        .service-items-group {
            margin: 0 auto;
            max-width: 800px;
            width: 100%;

            & > h3 {
                &::before {
                    .bsro-ui-pinnacle & {
                        background-image: url(https://s7d1.scene7.com/is/content/bridgestone/white-check-icon-global-web-bsro);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        content: '';
                        display: inline-block;
                        height: 20px;
                        padding-right: 7px;
                        width: 20px;
                        vertical-align: top;

                        @include media(bp-lt-md) {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }
            }

            .service-icon {
                margin-right: 10px;
                max-height: 45px;
            }

            .total-service-duration {
                display: none;
                font-weight: 600;
                padding: 0 0 20px 45px;

                @include media(bp-lt-md) {
                    display: block;
                    padding-left: 0;
                    text-align: center;
                }
            }
        }

        h3 {
            color: white;
            background: $brand-color-blue;
            padding: 10px;
            margin-bottom: 10px;
            font-size: 20px;

            @include media(bp-lt-md) {
                text-align: center;
                margin-bottom: 0px;
                font-size: 16px;
            }
        }

        ul {
            margin-top: 0;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 400;
                font-size: 20px;
                padding: 10px 20px;
                @include font-title;
                border-bottom: 1px solid $light-gray;

                @include media(bp-lt-md) {
                    padding: 15px;
                }

                a {
                    color: #656565;
                    text-decoration: none;
                }

                .btn-cancel {
                    background: transparent;
                    border: 1px solid #656565;
                    padding: 7px 30px;
                    margin-right: 15px;
                    max-width: 88px;
                }

                &.other-service {
                    flex-flow: row wrap;

                    textarea {
                        font-size: 14px;
                        width: 87%;
                        margin: 10px auto;
                        display: block;
                        @include media(bp-lt-md) {
                            width: 100%;
                        }
                    }

                    label {
                        cursor: default;
                        padding-bottom: 15px;
                    }

                    .bsro-ui-pinnacle & {
                        position: relative;
                    }
                }

                .bsro-ui-pinnacle & {
                    border: none;

                    @include media(bp-lt-md) {
                        padding: 0 15px;
                    }

                    .accordion-item {
                        margin: 0;
                        width: 100%;

                        label {
                            padding: 0 20px;

                            @include media(bp-lt-md) {
                                padding: 5px;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            font-size: 15px;
            padding: 10px 15px;
        }

        .btn-next {
            display: block;
            margin: 20px auto 0;

            @include media(bp-lt-md) {
                float: none;
                width: 80%;
                margin: 20px 10%;
            }
        }
    }

    .accordion-item {
        background-color: #fafafa;
        position: relative;

        label {
            position: relative;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            @include font-title;
            display: block;
            padding-bottom: 0;
            border-bottom: 1px solid #dcdcdb;
            .bsro-ui-pinnacle & {
                display: block;
            }

            @include media(bp-gt-sm) {
                padding: 0 20px;
                font-size: 20px;
            }

            /*
			&:after {
				position: absolute;
				right: 20px;
				top: 7px;
				content: '+';
				height: 25px;
				width: 25px;
				color: $white;
				background-color: #d31a0b;
				line-height: 25px;
				text-align: center;
				border-radius: 3px !important;


				//@include icon('select-arrow', $dark-gray, 16px);
				//transform: rotate(360deg);


				@include media(bp-lt-md) {
					right: 10px;
				}
			}
			*/

            small.details {
                margin-left: 25px;
                font-size: 10px;
                text-decoration: underline;
                align-self: center;

                @include media(bp-lt-md) {
                    margin-left: 10px;
                }

                .bsro-ui-pinnacle & {
                    display: block;
                    margin-left: 0;
                    padding: 0 0 8px 56px;

                    @include media(bp-lt-md) {
                        padding-left: 10px;
                    }
                }
            }

            span:not(.service-duration) {
                align-items: center;
                display: block;
                border-bottom: 1px solid $light-gray;

                @include media(bp-lt-md) {
                    padding: 12px 35% 5px 10px;
                    line-height: 1.2em;
                    min-height: 50px;
                }

                &.call-store {
                    position: absolute;
                    top: 4px;
                    right: 0;
                    font-size: 14px;
                    padding: 10px 50px 10px 10px;
                    color: #4a4a4a;
                    background-color: transparent !important;
                    border: 0 !important;

                    @include media(bp-lt-md) {
                        padding: 15px 10px 10px;
                    }

                    @media screen and (max-width: 370px) {
                        width: 100px;
                        max-width: 100%;
                        line-height: 15px;
                        word-break: break-word;
                        overflow-wrap: break-word;
                    }
                }
                .service-duration {
                    display: none;
                    flex-shrink: 0;
                    font-size: 10px;
                    min-height: 0px;
                    padding-left: 12px;
                    .bsro-ui-pinnacle & {
                        display: inline-block;
                    }
                }

                .bsro-ui-pinnacle & {
                    border: none;
                }
            }

            [data-add-service] {
                position: absolute;
                top: 4px;
                right: 50px;
                font-size: 14px;
                padding: 10px 35px 10px 10px;
                color: #4a4a4a;
                background-color: transparent !important;
                border: 0 !important;

                // &:before {
                /* \2795 -- heavy plus sign, gray? */
                /* \2716 -- heavy multiplication sign */
                /*content: '+ '; */
                // }

                &:after {
                    position: absolute;
                    right: 0;
                    top: 5px;
                    content: '+';
                    height: 25px;
                    width: 25px;
                    color: $white;
                    background-color: $brand-color-red;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 3px !important;
                    font-size: 15px;
                    font-weight: 100;

                    @include media(bp-lt-md) {
                        right: 10px;
                    }
                }

                @include media(bp-lt-md) {
                    width: auto;
                    top: 7px;
                    right: 0px;
                    padding-right: 40px;
                }
            }

            .state-inspection-icon {
                padding: 6px;
            }
        }

        &:last-child {
            label {
                border: none;
            }
        }

        input[type='checkbox'],
        .accordion-item-content {
            display: none;
        }

        input[type='checkbox']:checked ~ .accordion-item-content {
            display: block;
        }

        input[type='checkbox']:checked + label {
            background-color: #f3f3f3;

            &:after {
                transform: rotate(180deg);
            }
        }

        input[type='checkbox']:checked + label span {
            border: none;
        }

        img {
            margin-right: 10px;
            max-height: 45px;

            @include media(bp-lt-md) {
                display: none;
            }
        }
    }

    .accordion-item input[type='checkbox']:checked + label {
        border-top: 1px solid #b4b4b4;
    }

    .accordion-content-container {
        font-weight: 300;
        padding: 5px 20px 45px;
        background-color: #f3f3f3;
        border-bottom: 2px solid #b4b4b4;

        @include media(bp-gt-sm) {
            padding: 15px 40px 45px;
        }

        > h2 {
            padding: 10px;
            font-size: 20px;
            margin: 0 0 10px 0;
            background-color: $black;
            color: $white;

            @include media(bp-gt-sm) {
                padding-bottom: 8px;
                border-bottom: 1px solid $light-gray;
                color: $red;
                font-size: 20px;
                background: none;
                padding: 0 0 5px 0;
            }
            @include media(bp-lt-md) {
                font-size: 16px;
            }
        }

        > h4 {
            padding: 11px;
            background-color: #747474;
            color: $white;
            font-size: 17px;

            @include media(bp-gt-sm) {
                margin-bottom: 0;
                padding: 0 0 8px 0;
                border-bottom: 1px solid $light-gray;
                background: none;
                color: $brand-color-blue;
                font-size: 20px;
            }
        }

        > p {
            margin-top: 2px;

            @include media(bp-lt-md) {
                margin-top: -100px;
                margin-bottom: 55px;
            }
        }

        [data-add-service] {
            &:before {
                /* \2795 -- heavy plus sign, gray? */
                /* \2716 -- heavy multiplication sign */
                content: '+ ';
            }
        }

        .recommended-content {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-bottom: 65px;

            @include media(bp-lt-md) {
                margin-bottom: px;
            }

            > img {
                flex-basis: 23%;
                max-height: 165px;

                @include media(bp-lt-sm) {
                    max-width: 115px;
                    max-height: 115px;
                }
            }

            > div {
                flex-basis: 55%;

                @include media(bp-gt-sm) {
                    padding-left: 55px;
                }
            }

            > button {
                flex-basis: 100%;
                margin-top: 10px;
                font-size: 16px;

                @include media(bp-gt-sm) {
                    flex-basis: 19%;
                    margin-top: 0;
                }
            }

            h4 {
                padding-top: 0;
                font-size: 20px;
                text-transform: none;
                margin: 0;

                @include media(bp-gt-sm) {
                    padding-top: 7px;
                    font-size: 23px;
                }
            }

            h5 {
                @include font-body;
                font-size: 12px;
                text-transform: none;
                margin: 7px 0;
                letter-spacing: 1px;

                @include media(bp-gt-sm) {
                    font-size: 16px;
                }
            }

            a {
                &:first-child {
                    margin-top: 10px;
                }

                &:last-child {
                    margin-bottom: 26px;
                }
            }

            a,
            a:hover,
            a:visited,
            a:focus {
                display: inline-block;
                font-size: 12px;
                letter-spacing: 1px;
                color: #ccc;
                text-decoration: underline;
            }
        }

        .service-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include media(bp-gt-sm) {
                margin-top: 50px;
                font-size: 17px;
            }

            label {
                padding: 0;
                cursor: default;

                @include media(bp-lt-md) {
                    cursor: pointer;
                }
            }

            > div {
                flex-basis: 100%;
            }

            h5 {
                padding: 11px 5px;
                margin: 0;
                border-bottom: 1px solid $light-gray;
                font-size: 15px;
                @include font-body;
                font-weight: bold;

                @include media(bp-gt-sm) {
                    padding: 0;
                    margin: 0 0 15px 0;
                    border-bottom: none;
                }
            }

            input[type='checkbox'] + label {
                @include media(bp-gt-sm) {
                    &:after {
                        display: none;
                    }
                }
            }

            input[type='checkbox']:checked + label,
            input[type='checkbox']:checked + label h5 {
                border-bottom: none;
                border-top: none;

                @include media(bp-gt-sm) {
                    &:after {
                        display: none;
                    }
                }
            }

            p {
                color: #6a6a6a;
            }

            .accordion-item-content {
                padding-bottom: 15px;
                border-bottom: 1px solid $light-gray;
                color: #656565;
            }

            @include media(bp-gt-sm) {
                .accordion-item-content {
                    display: block;
                    border-bottom: none;
                }

                > div {
                    flex-basis: 25%;
                }
            }
        }
    }

    .accordion {
        @include media(bp-lt-md) {
            /* margin: 0 -4.86111%; */
        }

        h3 {
            color: white;
            background: $brand-color-blue;
            padding: 10px;
            margin-bottom: 10px;
            font-size: 20px;

            @include media(bp-lt-md) {
                text-align: center;
                margin-bottom: 0px;
                font-size: 16px;
            }
        }
    }

    .date-store {
        display: flex;
        flex-wrap: wrap;

        .heading {
            flex: 1 100%;
            text-align: center;
            margin-bottom: 55px;
        }

        .map {
            flex: 1 25%;
            background: #efefef;
            height: 50vh;

            @include media(bp-lt-md) {
                display: none;
            }

            .map-fixed {
                border: 1px solid #aaa;
                position: sticky;
                height: 50vh;
                top: 20px;
                overflow: hidden;
            }
        }

        &.no-zip {
            .stores,
            .otherStoresList,
            .dates,
            .map,
            .dates + .heading-bar.mobile-only {
                display: none !important;
            }

            .find {
                margin-bottom: 300px;
            }
        }
    }

    .date-store-container {
        display: flex;
        flex-direction: column;
        flex: 1 75%;
        padding-right: 30px;
        flex-wrap: wrap;

        @include media(bp-lt-md) {
            flex: 1 100%;
            padding-right: 0;
            /* margin: 0 -4.86111%; */
        }

        .drop-off-mileage {
            display: none;

            .drop-off-wrapper {
                border: 1px solid #dcdcdc;

                @include media(bp-lt-md) {
                    border: 0;
                }
            }

            h3 {
                color: #4a4a4a;
                font-size: 20px;
                background: #d8d8d8;
                margin: 0;
                padding: 10px;

                @include media(bp-lt-md) {
                    background-color: $brand-color-blue;
                    color: $white;
                    padding: 15px 0;
                    text-align: center;
                    font-size: 16px;
                }
            }

            .drop-off {
                display: flex;
                flex: 1 100%;
                align-items: center;
                justify-content: center;
                padding: 20px;
                flex-wrap: wrap;

                @include media(bp-gt-md) {
                    padding-right: 0;
                }

                input[type='radio'] {
                    display: none;
                }

                label {
                    display: flex;
                    text-transform: capitalize;
                    background: $white;
                    border: 1px solid $light-gray;
                    color: #4a4a4a;
                    justify-content: center;
                    align-items: center;
                    width: 25%;

                    &[for='wait-in-store'],
                    &[for='drop-off-vehicle'] {
                        margin-right: 10px; //20px;

                        @include media(bp-lt-lg) {
                            margin-right: 0;
                            margin-bottom: 15px;
                        }
                    }

                    /*
						&:last-child {
							margin-right: 0;
						}
						*/

                    @include media(bp-lt-lg) {
                        /* margin-right: 0; */
                        width: 100%;
                    }

                    @include media(bp-gt-md) {
                        min-height: 55px; // while curbside label is super tall so all buttons are same height
                        font-size: 16px;
                    }
                }

                input:checked + label {
                    background: #4a4a4a;
                    color: $white;
                }

                .btn:hover,
                .btn:active {
                    background: #4a4a4a;
                    color: $white;
                    border: 1px solid $light-gray;
                }

                @include media(bp-lt-lg) {
                    flex-flow: column nowrap;
                    width: 100%;
                }
            }

            .time-slot-error-message {
                color: $red;
                font-size: 15px;
                text-align: center;
            }
            .appointmentContainer {
                display: none;
            }
        }
        .timeDurationText {
            display: none;
        }
        .heading-bar {
            background-color: $brand-color-blue;
            color: $white;
            padding: 15px 0;
            text-align: center;
            margin-bottom: 0;
            font-size: 18px;

            @include media(bp-lt-md) {
                font-size: 16px;
            }
        }

        .find {
            background-color: #fafafa;
            padding: 25px 40px;

            @include media(bp-lt-lg) {
                padding: 25px 20px;
            }

            form {
                width: 100%;
            }

            label {
                flex: 1 100%;
                font-weight: 600;
                font-size: 20px;
                line-height: 1em;
                margin-right: 10px;

                @include media(bp-lt-sm) {
                    margin-bottom: 20px;
                }

                @include media(bp-sm) {
                    flex: 0 auto;
                }
            }

            .form-group {
                display: flex;
                flex-direction: row;
                align-items: center;

                @include media(bp-sm) {
                    justify-content: center;
                }

                @include media(bp-lt-md) {
                    flex-wrap: wrap;
                }

                .custom-select {
                    display: flex;
                    width: 120px;
                    min-width: 120px;
                    margin-right: 5px;
                    padding: 0;

                    @include media(bp-lt-lg) {
                        width: 76px;
                        min-width: 76px;
                    }

                    @include media(bp-xs) {
                        width: percentage(math.div(76, 265));
                        min-width: percentage(math.div(76, 265));
                        margin-right: percentage(math.div(5, 265));
                    }
                }

                .zipcode-group {
                    display: flex;

                    @include media(bp-xs) {
                        width: percentage(math.div(184, 265));
                    }

                    * {
                        display: flex;

                        &:not(:last-child) {
                            margin-right: 5px;

                            @include media(bp-xs) {
                                margin-right: percentage(math.div(5, 184));
                            }
                        }
                    }

                    input[type='text'] {
                        width: 169px;
                        background-color: $white;

                        @include media(bp-lt-lg) {
                            width: 110px;
                        }

                        @include media(bp-xs) {
                            width: percentage(math.div(76, 184));
                        }
                    }

                    .btn {
                        width: 49px;
                        min-width: 49px;
                        align-items: center;
                        justify-content: center;
                        padding: 0;

                        @include media(bp-xs) {
                            width: percentage(math.div(49, 184));
                        }
                    }
                }
            }

            select,
            input {
                padding: 14px 11px;
            }
        }

        .pinnacle-availability {
            width: 267.5px;
            height: 35.83px;
            background: #005db6;
            color: white;
            border-radius: 5px;
            align-self: center;
            position: relative;
            left: 200px;
            top: 80px;
            z-index: 1;
            cursor: pointer;
            border: 2px solid #005d86;
            font-size: 13.33px;
            line-height: 125%;
            border-radius: 3.333px;

            @include media(bp-lt-sm) {
                left: 0;
                top: 230px;
            }
        }

        .dates {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
            /* postion inherit and width 100% needed for ie11 since sticky is not supported */
            position: inherit;
            width: 100%;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background: $white;
            max-height: 75px;
            z-index: 1000;

            .sort-by {
                flex-grow: 1;
                padding: 5px 40px;

                select {
                    border: none;
                    margin-left: 10px;
                    font-size: 15px;
                }

                label {
                    font-weight: 600;
                    font-size: 16px;
                }

                .custom-select {
                    border: 0;

                    select {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }

                @include media(bp-lt-md) {
                    display: none !important;
                }
            }

            .available-dates {
                flex-grow: 2;

                .nav-control {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-basis: 10%;
                    height: 100%;

                    span {
                        width: 16px;
                        height: 16px;
                        @include icon('select-arrow', $red, 16px);
                    }

                    &.next,
                    &.prev {
                        cursor: pointer;
                    }

                    &.next {
                        span {
                            transform: rotate(270deg);
                        }
                    }

                    &.prev {
                        span {
                            transform: rotate(90deg);
                        }
                    }

                    &.disable {
                        opacity: 0.5;
                        cursor: default;
                        pointer-events: none;
                    }
                }

                .date-items {
                    flex-basis: 80%;
                    height: 100%;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-grow: 1;
                        flex-flow: column nowrap;
                        text-align: center;
                        color: #cecece;
                        text-transform: uppercase;
                        pointer-events: none;
                        height: 100%;

                        .date {
                            font-weight: bold;
                            color: #656565;
                            font-size: 16px;
                        }

                        @include media(bp-lt-md) {
                            pointer-events: auto;
                            cursor: pointer;

                            &.active {
                                background-color: #0172b6;
                                color: $white;

                                span {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            .sort-by,
            .available-dates,
            .available-dates .date-items {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
            }
        }

        .stores,
        .otherStoresList {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            position: relative;
            background: $white;

            .noStoresFound {
                color: $red;
                font-weight: bold;
                text-align: center;
                padding: 20px;
            }

            &.storeSelected {
                display: block;
                height: 232px;
                flex-wrap: nowrap;
                overflow-x: hidden;

                & ~ .drop-off-mileage {
                    display: inline-block;
                }

                .store-info-wrapper {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 20px;
                }
            }

            .store-item {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                border-bottom: 1px solid $light-gray;
                position: relative;

                .store-info {
                    .pin {
                        float: left;
                        margin-right: 20px;
                        margin-top: 6px;
                    }

                    .details {
                        overflow: auto;

                        @include media(bp-lt-md) {
                            width: 100%;
                        }

                        p {
                            margin: 0 auto;
                            font-size: 15px;

                            b {
                                font-size: 20px;

                                &.uppercase {
                                    text-transform: uppercase;
                                }
                            }

                            .address-left {
                                @include media(bp-lt-md) {
                                    display: inline-block;
                                    width: 60%;
                                    float: left;
                                }
                                .rating {
                                    color: $red;
                                    font-size: 18px;
                                }
                            }

                            .address-right {
                                @include media(bp-lt-md) {
                                    display: inline-block;
                                    width: 40%;
                                    float: right;
                                    text-align: right;

                                    .btn {
                                        margin-top: 30px;
                                    }
                                }
                            }
                        }
                    }
                }

                .store-hours {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    @include media(bp-lt-md) {
                        display: block;
                        border-top: 1px solid $light-gray;
                        position: relative;

                        .swipe-for-more {
                            display: block;
                        }
                    }

                    .swipe-for-more {
                        display: none;
                        text-align: center;
                        margin: 10px 0 0;
                        background: #f3f3f3;
                        position: absolute;
                        width: 100%;
                        top: 0;
                    }

                    .hours {
                        display: flex;
                        flex-basis: 80%;
                        flex-direction: row;
                        justify-content: space-around;
                        flex-wrap: nowrap;
                        margin-top: 12px;
                        position: relative;

                        &.preview-hours {
                            @include media(bp-lt-md) {
                                margin-top: 0;
                                background: #f3f3f3;
                            }

                            .hours-item {
                                @include media(bp-gt-sm) {
                                    li:nth-of-type(1n + 6) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .hours-item-group {
                            display: flex;

                            .wait {
                                display: none;

                                .coral-Wait--center {
                                    margin-top: -15rem;
                                    margin-left: -18px;
                                }

                                .coral-Wait--small {
                                    width: 36px;
                                    height: 38px;
                                }
                            }
                        }

                        @include media(bp-lt-md) {
                            display: flex;
                            flex: 1 100%;
                            overflow: hidden;
                            padding: 0 20px;
                            height: 122px;
                            align-items: center;

                            /*
                        &:after {
                            content: '';
                            position: absolute;
                            width: 100px;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.01) 1%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,1) 100%);
                            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,1) 100%);
                            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='$white',GradientType=1 );
                            pointer-events: none;
                        }
                        */
                        }
                    }

                    .hours-item {
                        margin: 10px 0;
                        display: flex;
                        flex: 1 16%;
                        flex-direction: column;
                        align-items: center;

                        @include media(bp-lt-md) {
                            position: absolute;
                            flex: 1 100%;
                            width: 100%;
                            text-align: left;
                            height: 122px;
                            flex-flow: row nowrap;
                            margin: 0;
                            display: none;
                            padding-top: 20px;
                            overflow: hidden;

                            /* &:not(.hide):not(:first-child) {
                            display: none;
                        } */

                            ul {
                                display: flex;
                                width: 100%;
                                overflow-x: scroll;
                                padding: 40px 20px;
                            }

                            &.active {
                                display: flex;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                width: 100px;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$white+1,ffffff+50,ffffff+100&0+0,1+100 */
                                // background: -moz-linear-gradient(
                                //     left,
                                //     rgba(255, 255, 255, 0) 0%,
                                //     rgba(255, 255, 255, 0.01) 1%,
                                //     rgba(255, 255, 255, 0.5) 50%,
                                //     rgba(255, 255, 255, 1) 100%
                                // ); /* FF3.6-15 */
                                // background: -webkit-linear-gradient(
                                //     left,
                                //     rgba(255, 255, 255, 0) 0%,
                                //     rgba(255, 255, 255, 0.01) 1%,
                                //     rgba(255, 255, 255, 0.5) 50%,
                                //     rgba(255, 255, 255, 1) 100%
                                // ); /* Chrome10-25,Safari5.1-6 */
                                background: linear-gradient(
                                    to right,
                                    rgba($white, 0) 0%,
                                    rgba($white, 0.01) 1%,
                                    rgba($white, 0.5) 50%,
                                    rgba($white, 1) 100%
                                ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='$white',GradientType=1 ); /* IE6-9 */
                                pointer-events: none;
                            }

                            &.no-slots {
                                padding-top: 0;

                                .swipe-for-more {
                                    display: none;
                                }

                                ul {
                                    display: none;
                                }

                                .no-slots-message {
                                    display: flex;
                                    width: 100%;
                                    justify-content: center;
                                    margin: 0;
                                }

                                .show-more {
                                    display: none !important;
                                }

                                &:after {
                                    display: none;
                                }
                            }
                        }

                        .no-slots-message {
                            display: none;
                        }

                        li {
                            background-color: #f3fdff;
                            /*background-color: #D5E8FF; */
                            margin-bottom: 3px;
                            padding: 10px 5px;
                            font-size: 14px;
                            width: 75px;
                            margin: 3px 4px;
                            text-align: center;
                            user-select: none;

                            /* hack - weird height mismatch between empty and available time slots causing grid alignment issues */
                            /*&:after {
                            content: '';
                            display: block;
                            height: 1px;
                        }*/

                            /*
                        &:last-child {
                            text-transform: uppercase;
                            display: flex !important;
                        }
                        */

                            &.show-more {
                                font-size: 12px;
                                font-weight: bold;
                                color: #656565;
                                text-align: center;
                                text-transform: uppercase;
                                display: flex !important;
                                justify-content: center;
                                cursor: pointer;

                                @include media(bp-lt-md) {
                                    /* display: none !important; */
                                    pointer-events: none;
                                    visibility: hidden;
                                    min-width: 30px;
                                }

                                &.hide {
                                    display: none !important;
                                }

                                &.less {
                                    span.more {
                                        display: none;
                                    }
                                    span.less {
                                        display: inline;
                                    }
                                }

                                span.less {
                                    display: none;
                                }
                            }

                            &.next-available-slot {
                                background-color: #f3fdff;
                                padding: 10px 5px;
                                font-size: 13px;
                                min-width: 420px;
                                text-align: center;
                                margin-top: 60px;
                                text-transform: none;
                            }

                            &.available {
                                cursor: pointer;
                            }

                            &.selected {
                                background-color: $brand-color-blue;
                                color: $white;

                                a {
                                    text-decoration: initial;
                                    color: $white;
                                }
                            }

                            &.active {
                                background-color: #e3f0f9;
                            }

                            @include media(bp-md) {
                                font-size: 11px;
                            }

                            @include media(bp-lt-md) {
                                padding: 10px 10px;
                                /* background-color: #e3f0f9; */
                                background-color: #d5e8ff;
                                font-size: 16px;
                                margin: 6px 12px 0px;
                                min-width: 95px;

                                &:not(.available) {
                                    display: none;
                                }
                            }

                            a {
                                text-decoration: initial;
                                color: #747474;
                                font-size: 14px;
                                width: 100%;
                                height: 100%;

                                &.more-btn {
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: #656565;
                                    text-align: center;
                                    width: 100%;

                                    @include media(bp-lt-md) {
                                        font-size: 16px;
                                    }
                                }

                                @include media(bp-lt-md) {
                                    color: #4a4a4a;
                                }
                            }
                        }
                    }
                }
            }
        }
        .otherStoresContainer {
            display: none;
            justify-content: space-between;
            margin-right: 15px;
            .viewOthersTitle {
                font-size: 24px;
                padding-left: 10px;

                @include media(bp-lt-sm) {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    text-decoration-line: underline;
                    text-transform: none;
                }
            }
            .viewOtherText {
                font-size: 12px;
                margin-left: 10px;
            }
            img {
                margin-top: 30px;
                cursor: pointer;
                // rotate: 180deg;
                &.rotate {
                    rotate: 180deg;
                }
            }

            .other-stores-text {
                display: flex;
                img {
                    margin-top: 0;
                }

                @include media(bp-lt-sm) {
                    margin-left: 20px;
                }
            }

            .otherText {
                margin-left: 35px;
                margin-top: 0;

                @include media(bp-lt-sm) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    width: 300px;
                    margin: 0;
                    padding-left: 50px;
                }
            }
        }
        .otherStoresList {
            &.hide {
                display: none;
            }
        }
        .dates .sort-by,
        .stores .store-item .store-info,
        .otherStoresList .store-item .store-info {
            flex: 1 40%;
            padding: 25px 40px;
            height: auto;

            @include media(bp-md) {
                padding: 0;
            }

            @include media(bp-lt-md) {
                flex: 1 100%;
                padding: 25px 20px 20px;
            }
        }

        .dates .available-dates,
        .stores .store-item .store-hours,
        .otherStoresList .store-item .store-hours {
            flex: 1 60%;
            position: relative;
        }
    }

    .map-iframe {
        border: 0;
        width: 100%;
        height: 100%;
    }

    .contact {
        .contact-form {
            form {
                * {
                    display: block;
                }
                input {
                    margin-bottom: 10px;
                }
                .form-field {
                    &__asterisk {
                        display: inline;
                    }
                }

                .radio-squares {
                    position: relative;
                    &__disclaimer {
                        font-size: 14px;
                        margin-top: 10px;

                        &.hidden {
                            display: none;
                        }
                    }
                    &__heading {
                        display: flex;
                        font-weight: 600;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    &__options {
                        display: flex;
                        justify-content: flex-start;
                    }

                    &__input {
                        opacity: 0;
                        position: absolute;
                        &:checked + .radio-squares__text {
                            background-color: #e1e3e3;
                        }
                        &:focus + .radio-squares__text {
                            outline: 2px solid Highlight;
                            outline-color: -webkit-focus-ring-color;
                            outline-offset: 2px;
                        }
                    }
                    &__label {
                        align-items: center;
                        border: 1px solid black;
                        border-radius: 3px;
                        display: inline-flex;
                        flex-shrink: 1;
                        position: relative;
                        margin: 0 8px 10px;
                        text-align: center;

                        @include media(bp-lt-sm) {
                            margin: 0 4px 10px;
                        }

                        &:first-of-type {
                            margin-left: 0;
                        }
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                    &__text {
                        align-content: center;
                        display: inline-block;
                        height: 100%;
                        padding: 8px 20px;
                        flex-grow: 1;

                        &:hover {
                            cursor: pointer;
                            z-index: 1;
                        }
                    }
                }
                .communication-disclaimer {
                    display: flex;
                    align-items: flex-start;
                    &--hidden {
                        display: none;
                    }
                    &__checkbox {
                        margin-right: 10px;
                        width: 20px;
                    }
                }
            }
        }
    }

    // .appointment-details b:after {
    // 	display: inline-block;
    // 	content: "\270E";
    // 	margin-left: 5px;
    // 	transform: scaleX(-1);
    // }

    .email-details,
    .prepare-appointment,
    .cancel-appointment {
        b:after {
            display: none;
        }
    }

    .prepare-appointment {
        display: none;
    }

    /* Helper class / Utility class: START */
    .btn-transparent-small {
        width: auto;
        padding: 9px 14px;
        margin-right: 10px;
        line-height: 11px;
        border: 1px solid $red;
        background: transparent;
        color: $red;
        font-size: 13px;

        @include media(bp-gt-sm) {
            width: auto;
        }
    }

    .btn-remove {
        /*
		padding: 9px 14px;
		line-height: 11px;
		border: 1px solid #656565;
		background: transparent;
		color: #656565;
		font-size: 13px;
		width: auto;
		*/
        position: relative;
        display: inline-block;
        width: 25px;
        height: 25px;
        overflow: hidden;
        background: transparent;
        color: $text-color;
        border: 1px solid $dark-gray;
        padding: 0 !important;

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 50%;
            top: 50%;
            left: 25%;
            margin-top: -1px;
            background: $dark-gray;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        .bsro-ui-pinnacle & {
            position: absolute;
            right: 50px;
            top: 21px;
        }
    }

    .icon-pencil {
        @include icon('pencil', $black, 10px);
        margin: -5px 0 0 5px;
        cursor: pointer;
    }

    .hidden-tablet {
        @include media(bp-gt-sm) {
            display: none !important;
        }
    }

    .hidden-phone {
        @include media(bp-lt-sm) {
            display: none !important;
        }
    }
    /* Helper class / Utility class: END */

    section.steps {
        padding: 30px 0 8px 0;
        border-bottom: 1px solid #ccc;
    }

    section.step {
        margin: 0 4.86111%;
        padding: 0 0 30px 0;

        @include media(bp-lt-md) {
            margin: 0;
        }

        .appointment-details-info {
            .store-directions,
            .store-details {
                display: none;
            }
        }

        h2.heading {
            text-align: center;
            @include media(bp-lt-md) {
                font-size: 26px;
            }
        }
    }

    /* Vehicle Step */
    section.vehicle {
        border-top: 0;
        min-height: 400px;
    }

    /* Service Step */
    section.service {
        margin: 0 auto;

        > .accordion {
            margin: 0 auto;
            max-width: 800px;
            width: 100%;
        }
    }

    /* Contacts Step */
    section.contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;

        h3 {
            margin-top: 0;
        }

        .heading {
            flex: 1 100%;
            margin-bottom: 55px;
            text-align: center;

            @include media(bp-lt-md) {
                display: none;
            }
        }

        .contact-form {
            flex-basis: 40%;
            order: 2;
            padding: 30px;
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;

            @include media(bp-lt-md) {
                flex-basis: 100%;
                order: 1;
                border: none;
            }

            .form-group {
                position: relative;
                margin-bottom: 20px;
            }

            input,
            textarea {
                width: 100%;
                padding: 15px 11px;
                line-height: 15px;
            }

            label:not(.radio-squares__label) {
                position: absolute;
                top: 3px;
                left: 7px;
                padding: 11px 10px 0;
                transition: all 200ms;
                opacity: 0.5;
            }

            input:focus + label,
            input:valid + label,
            input:invalid + label,
            textarea:focus + label,
            textarea:valid + label,
            textarea:invalid + label {
                font-size: 75%;
                -webkit-transform: translate3d(0, -100%, 0);
                transform: translate3d(0, -80%, 0);
                opacity: 1;
                background-color: white;
                padding-top: 0;
            }

            i {
                margin-bottom: 10px;
                color: #656565;
                font-size: 12px;
            }

            #email-optin {
                width: auto;
                display: inline-block;
                margin-left: 15px;
                margin-right: 7px;
                float: left;
            }
        }

        .appointment-details {
            flex-basis: 40%;
            order: 1;
            padding: 30px;
            margin-right: 30px;
            background-color: #fafafa;

            @include media(bp-lt-md) {
                flex-basis: 100%;
                margin-right: 0;
                order: 2;
            }

            p {
                margin-bottom: 25px;
            }

            h3 {
                padding-bottom: 10px;
                border-bottom: 1px solid $light-gray;
            }
        }

        @include media(bp-lt-md) {
            padding-top: 24px;
        }
    }

    section.vehicle.active,
    section.service.active,
    section.date-store.active {
        & ~ .btn-previous-container {
            display: none;
        }
    }

    .btn-previous-container {
        display: flex;
        position: absolute;
        flex: 1 100%;
        margin: 0 4.86111%;
        padding: 36px 0 0 0;
        z-index: 9000; //9190;

        @include media(bp-lt-md) {
            margin: 0;
            padding: 18px 0 0 30px;
        }

        .btn-previous {
            display: block;
            text-decoration: none;
            /* padding-left: 5px; */
            text-transform: uppercase;
            font-weight: 600;

            span {
                display: inline-block;
                color: #656565;
                font-size: 14px;
            }

            &:before {
                display: inline-block;
                content: '\2190';
                margin-right: 5px;
                color: $text-color;
            }
        }
    }

    /* Confirmation Step */
    section.confirmation {
        margin: 0 4.86111%;
        display: block;

        h3 {
            margin-top: 0;
        }

        .heading {
            text-align: center;
            margin-bottom: 50px;
        }

        ul.bulleted-list {
            li {
                padding-bottom: 25px;
                color: #656565;

                &:before {
                    color: #656565;
                }
            }
        }

        .confirmation-item-group {
            justify-content: space-between;

            @include media(bp-gt-sm) {
                display: flex;
            }

            .icon-pencil {
                display: none !important;
            }

            .appointment-info {
                flex-basis: 60%;
                color: #656565;

                > div {
                    padding: 30px;
                    border-top: 1px solid $light-gray;

                    @include media(bp-lt-md) {
                        border-top: none;
                        padding: 30px 0 0 0;
                    }

                    &:first-child {
                        border-top: 1px solid $light-gray;
                    }

                    &.appointment-details {
                        @include media(bp-lt-md) {
                            padding: 35px 30px;
                        }
                    }

                    &.info-group {
                        padding-bottom: 5px;
                    }
                }
            }

            .appointment-details {
                flex-basis: 35%;
                background-color: #fafafa;
                padding: 35px 30px;

                h3 {
                    border-bottom: 1px solid $light-gray;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                }

                p {
                    margin-bottom: 25px;
                    color: #656565;

                    b {
                        color: $black;
                    }

                    &.cancel-appointment {
                        margin-top: 25px;
                        border-top: 1px solid $light-gray;

                        b {
                            display: inline-block;
                            margin: 20px 0;
                        }
                    }
                }

                input {
                    width: 76%;

                    + button {
                        display: inline-block;
                        width: auto;
                        margin-left: -4px;
                        border: 0;
                        padding: 6px;
                        line-height: 1;
                        vertical-align: top;
                        background-color: $brand-color-blue;
                    }
                }

                .appointment-details-info {
                    .store-directions {
                        display: inline-block;
                    }
                }
            }
        }
        #newFooter .btn {
            display: block;
            margin: 0 auto;
            width: 60%;
        }
        #newFooter {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        @media only screen and (min-width: 768px) {
            #newFooter {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }
    }

    .steps-list {
        position: relative;
        min-height: 300px;

        &.hide-steps {
            .coral-wait-container {
                display: block;
            }

            .step {
                display: none !important;
            }
        }

        &.finish {
            .btn-previous-container {
                display: none;
            }

            .steps.list {
                display: none;
            }
        }

        .coral-wait-container {
            display: none;
            background: rgba($white, 0.8);

            .coral-Wait--center {
                margin-top: -3rem;
            }
        }
    }

    .mobile-only {
        display: none !important;

        @include media(bp-lt-md) {
            display: block !important;
        }
    }

    .desktop-only {
        @include media(bp-lt-md) {
            display: none !important;
        }
    }

    [data-step-to],
    [data-next],
    [data-previous] {
        cursor: pointer;
    }

    .hide-half-hours .half-hour {
        display: none;
    }

    .next-btn-wrapper {
        text-align: right;
        margin-top: 20px;
        padding: 1px;

        @include media(bp-lt-md) {
            text-align: center;
            margin: 0 auto;
            width: 100%;
            padding: 20px;
        }

        &.pinnacle {
            margin-top: -55px;
        }
    }

    .pickup-preference {
        display: none;
        width: 100%;
        margin-bottom: 20px;
        padding: 0 25%;

        @include media(bp-lt-md) {
            width: 100%;
            padding: 0 20px;
        }

        &.show {
            display: block;
        }

        .custom-select {
            background-position: 97% 50%;
        }
    }

    .view-store-map {
        display: none;
        padding: 0 20px 20px;

        .view-store-map-link {
            text-decoration: none;

            img {
                border: 1px solid #cccccc;
                width: 100%;
            }
        }

        @include media(bp-gt-sm) {
            display: none !important;
        }
    }

    .view-store-map-toggle {
        span {
            display: none;

            &:first-child {
                display: inline-block;
            }
        }

        &.is-selected {
            span {
                display: inline-block;

                &:first-child {
                    display: none;
                }
            }
        }
    }

    #vehicle-search {
        max-width: 800px;
        margin: 0 auto;

        .container {
            max-width: none;
            margin: 0;
        }

        .vehicle-type-select input:checked + .vehicle-type {
            &.drop-down-arrow:after {
                transform: rotate(180deg);
            }
        }

        .vehicle-type {
            position: relative;
            font-weight: bold;
            text-transform: uppercase;
            padding-left: 11px;

            .icon-car {
                @include icon('car', $white, 35px);
                margin-bottom: 3px;
            }

            &.drop-down-arrow:after {
                margin: 0 0 2px 8px;
                width: 11px;
                height: 11px;
            }
        }

        .drop-down-arrow {
            &:after {
                content: '';
                height: 14px;
                width: 14px;
                background-size: 100%;
                background-repeat: no-repeat;
                @include icon('select-arrow', $white, 16px);
                transform: rotate(360deg);
            }
        }
    }

    .vehicle-search-tabs {
        @include media(bp-lt-md) {
            padding: 20px;

            [for='search-field'],
            [for='vehicle-type'] {
                width: 50%;
                display: none;
            }

            .search-field,
            .search-field-container {
                display: none;
            }

            .vehicle-select {
                width: 100%;
            }
        }
    }

    .feature-service.accordion-item {
        margin-top: -5px;
        margin-bottom: 10px;
        border: 1px solid #dcdcdb;

        @include media(bp-lt-md) {
            margin: 5px;
        }

        img {
            max-height: 60px;

            @include media(bp-lt-md) {
                display: block;
                max-height: 48px;
            }
        }

        label {
            padding: 10px 20px 20px;

            @include media(bp-lt-md) {
                padding: 5px;
            }

            [data-add-service] {
                top: 21px;
            }

            span {
                flex-wrap: wrap;
                border: none;
                color: black;

                small.details {
                    width: 100%;
                    margin-left: 10px;

                    @include media(bp-lt-md) {
                        margin: 0;
                    }

                    .bsro-ui-pinnacle & {
                        border: none;
                    }
                }

                small.feature-copy {
                    @include font-body;
                    margin: 0 0 10px 10px;
                    width: 100%;
                    font-size: 14px;

                    @include media(bp-lt-md) {
                        margin: 0 0 5px 0;
                    }
                }
            }
        }

        .accordion-content-container,
        input[type='checkbox']:checked + label {
            border: none;
        }
    }
    .vehicle-selector-column {
        .next {
            .btn.complete-vehicle-selection {
                font-weight: bold;
            }
        }
    }
}

/* hide drop-off options for Pinnacle Store */
.schedule-appointment-v2.at-pinnacle-store {
    .date-store-container {
        .otherStoresList,
        .stores {
            &.storeSelected {
                & ~ .drop-off-mileage {
                    .drop-off-wrapper {
                        display: none;
                    }
                }
            }
        }
        .next-btn-wrapper.pinnacle {
            display: none;
        }
    }
}

.schedule-appointment-v2,
[data-schedule-appointment] {
    .coral-Modal-backdrop {
        background-color: rgba($black, 0.65);
    }

    .coral-Modal.preferredStoreSubModal {
        position: absolute;
        background: #fafafa;
        width: 40%;
        height: auto;
        left: 30%;
        top: 10%;
        z-index: 9100;

        @include media(bp-lt-md) {
            width: 60%;
            left: 20%;
        }
    }

    .preferredStoreSubModal .coral-Modal-body {
        padding: 0;
        height: 100%;
    }
    .preferredStoreSubModalContent {
        margin: 0 auto;
        padding: 25px;
        width: 100%;
    }

    .preferredStoreSubModalContent .message-wrapper {
        width: 100%;
        height: 100%;
        text-align: center;

        h2 {
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 25px;
        }

        button {
            width: 100px;
            margin-bottom: 10px;

            @include media(bp-lt-md) {
                width: 100%;
            }
        }
    }

    .service-duration {
        display: inline-block;
        flex-shrink: 0;
        font-size: 10px;
        min-height: 0px;
        @include media(bp-gt-sm) {
            padding-left: 12px;
        }
    }

    .steps-list {
        .vehicle-selector-column {
            .columns {
                width: 100% !important;
                input[type='radio'] + label {
                    font-size: 20px;
                    color: $black;
                }
                input[type='radio']:checked + label {
                    color: $white !important;
                }
            }
            .search-field-container {
                display: none !important;
            }
        }
        .service {
            .additional-messaging {
                display: flex;
                flex-direction: column;
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                text-transform: uppercase;
                padding: 25px 25px;
                align-items: center;
                @include media(bp-gt-sm) {
                    padding: 25px 0;
                }
                img {
                    width: 15px;
                    margin: 20px 0;
                }
            }
        }
    }
}

.schedule-appointment {
    .appt-progress {
        @media print {
            background: none !important;
        }
    }
}

.pay {
    display: flex;
}

.pinnacle-container {
    display: none;
}

.bsro-ui-pinnacle {
    .service-items-group {
        .total-service-duration {
            display: flex !important;
        }
    }
    .date-store-container {
        .timeDuration {
            @include media(bp-gt-md) {
                position: relative;
                top: 63px;
                z-index: 1001;
                width: 300px;
            }

            .timeDurationText {
                display: flex;
                font-size: 16px;
                font-weight: bold;
                justify-content: center;
            }
        }

        .pinnacle-container {
            .appointmentContainer {
                display: flex;
                flex-direction: column;
                .appointmentTitle {
                    font-size: 16px;
                    @include media(bp-lt-sm) {
                        justify-content: center;
                    }
                    margin-top: 50px;
                }
                .appointmentText {
                    margin: 0;
                    @include media(bp-lt-sm) {
                        justify-content: center;
                    }
                }

                @include media(bp-lt-sm) {
                    margin-left: 24px;
                }
            }
        }

        .other-stores.dates.pinnacle {
            border-top: 0;
            .left-panel {
                @include media(bp-lt-sm) {
                    display: none;
                }
                flex: 1 40%;
                font-weight: 700;
            }
        }
    }
}

/* BEGIN support for the bsro-ui-contact-methods feature switch */
.schedule-appointment-v2 .contact .contact-form form .contact-methods {
    display: none;
}

.bsro-ui-contact-methods {
    .schedule-appointment-v2 .contact .contact-form form .contact-methods {
        display: block;
    }
}
/* END support for the bsro-ui-contact-methods feature switch */
