@import '~common/scss/shared/shared';

.fifty-fifty-advanced-component {
    overflow: hidden;
    .container {
        &.no-margins {
            margin: 0px 0px -25px;
            max-width: 100%;
            line-height: 0;
            .fifty-fifty-advanced {
                margin: 0px;
                > .col {
                    &:first-child,
                    &:last-child {
                        padding-right: 0px;
                        padding-left: 0px;
                    }
                }
            }
            .copy-container {
                padding: 5%;
            }
            .fifty-fifty-basic .title {
                margin-bottom: 0px;
                font-size: 32px;
                color: $text-color;
                @include media(bp-md) {
                    font-size: 3vw;
                }
                @include media(bp-lt-md) {
                    margin-bottom: 18px;
                    text-align: center !important;
                }
            }
            .fifty-fifty-basic .subtitle {
                font-size: 18px;
                margin-top: 0px;
                color: $text-color;
                @include media(bp-md) {
                    font-size: 18px;
                }
            }
            > div {
                color: $text-color;
            }
        }
    }
    .bg-none {
        background-color: transparent;
        background: none;
    }
}

.fifty-fifty-advanced {
    display: inline-block;
    width: 100%;
    margin: 20px 0;
    &.bg-gray-gradient {
        @include media(bp-gt-sm) {
            @include gradient-top();
        }
    }
}
.fifty_fifty_video {
    padding: 70px 0;
    @include media(bp-lt-md) {
        padding: 30px 0;
    }
}

.fifty-fifty-advanced,
.fifty_fifty_video {
    display: block;
    width: auto;

    &.bg-gray-gradient {
        @include media(bp-gt-sm) {
            @include gradient-top();
        }
    }
    .reskin {
        display: flex;
        @include media(bp-lt-md) {
            display: block;
        }
        strong {
            @include font-title;
        }
    }
    .fifty-fifty-basic.image .col {
        width: 100%;
        &.cover {
            + .col {
                width: 100%;
                p {
                    width: 49%;
                }
            }
            width: 100%;
        }
    }
    .col {
        display: inline-block;
        width: 50%;
        line-height: 20px;
        &.right {
            float: right;
            order: 2;
            padding-right: 0 !important; // override the padding-right from the .reskin
            padding-left: 20px; // reverse padding to the left of the right column
            + .col {
                padding-left: 0 !important; // override the padding-left from the .reskin
                padding-right: 20px; // reverse padding to the right of the left column
            }
        }
        &:first-child {
            display: flex;
            flex-direction: column;
            padding-right: 15px;
        }
        &:last-child {
            align-self: center;
            padding-left: 15px;
            @include media(bp-lt-md) {
                display: block;
            }
        }
        @include media(bp-lt-md) {
            display: block;
            width: 100%;
            &.right {
                float: none;
            }
            &:first-child {
                padding: 0;
            }
            &:last-child {
                padding: 0;
            }
        }
    }
    &.vertical-rule {
        > .col {
            &:first-child {
                padding-right: 60px;
                border-right: 1px solid #dcdcdb;
            }
            &:last-child {
                padding-left: 60px;
            }
            @include media(bp-lt-md) {
                &:first-child {
                    padding-right: 0;
                    border-right: 0;
                }
                &:last-child {
                    padding-left: 0;
                }
            }
        }
    }

    .video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .col {
        display: inline-block;
        width: 50%;
        line-height: 20px;
        &.right {
            float: right;
        }
        &:first-child {
            padding-right: 15px;
        }
        &:last-child {
            padding-left: 15px;
            .col {
                width: 100%;
                padding: 0;
            }
        }
        @include media(bp-lt-md) {
            display: block;
            width: 100%;
            &.right {
                float: none;
            }
            &:first-child {
                padding-right: 0;
            }
            &:last-child {
                padding-left: 0;
            }
        }
        .copy-container {
            .title {
                margin: 0;
                @include media(bp-lt-md) {
                    font-size: 20px;
                    margin: 15px 0 0 0;
                }
            }
            .subtitle {
                margin: 0 0 30px;
                @include media(bp-lt-md) {
                    font-size: 18px;
                }
            }
        }
    }

    // fixes nesting problem
    .container {
        margin: 0;
    }
    .icons {
        margin-top: 36px;
        margin-bottom: 37px;
        .icon {
            width: 24%;
            text-align: center;
            vertical-align: top;
            @include media(bp-lt-md) {
                width: 49%;
                margin-bottom: 40px;
            }
        }
        .icon-text {
            margin-top: 17px;
            @include media(bp-lt-md) {
                width: 70%;
                margin: 17px auto 0;
            }
        }
        @include media(bp-lt-md) {
            margin-top: 42px;
        }
    }
}
