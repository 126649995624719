.rm-open-body .rm-modal,
.my-account {
    h1 {
        @include media(bp-lt-md) {
            padding: 0 5%;
        }
    }

    .vehicle-service-history-list,
    .declined-services-list {
        /* margin-bottom: 50px; */
        font-size: 16px;

        &__no-records {
            padding: 0 6%;
        }

        .table-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            margin: 10px 0;
            padding: 0 6%;

            &:not(.header) {
                cursor: pointer;
            }

            &:hover:not(.header) {
                color: white;
                background-color: #057ac2;
            }

            &.flex-wrap-mobile {
                flex-wrap: wrap;

                > :nth-child(odd) {
                    flex: 1 50%;
                }

                > div {
                    margin: 5px 0;
                    white-space: nowrap;

                    &:nth-child(5) {
                        width: 100%;

                        .service-type {
                            width: 90%;
                        }
                    }
                }
            }
        }

        .wrapper {
            display: flex;
            flex-direction: row;
        }

        .column {
            flex-grow: 0;
            flex-shrink: 0;
            vertical-align: top;
        }

        .date {
            width: 14%;
        }
        .service-type {
            width: 29%;
        }
        .company-name {
            width: 29%;
        }
        .mileage {
            width: 14%;
            text-align: right;
        }
        .cost {
            width: 14%;
            text-align: right;
        }

        .service-type {
            flex-grow: 1;
            overflow: hidden;
            padding-right: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .header {
            font-weight: bold;
        }

        .cta-vm {
            background-color: transparent;
            color: #4a4a4a;
            margin: 0 auto;
            display: block;
            border: none;
            outline: none;
            @include font-body;
            text-transform: capitalize;
            font-size: 16px;

            &:hover {
                color: darken(#4a4a4a, 10%);
            }

            @include media(bp-lt-md) {
                font-size: 18px;
                margin: 20px auto 0;
            }

            img {
                display: block;
                margin: 3px auto 0;
                width: 15px;

                @include media(bp-lt-md) {
                    margin: 12px auto 0;
                    width: auto;
                }
            }
        }
    }

    .declined-services-list {
        margin-bottom: 50px;
        font-size: 16px;

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 90%;
            margin: 0 auto;
            background-color: #e0e0e0;
            height: 1px;
        }

        @include media(bp-lt-md) {
            border-bottom: none;
        }

        .table-row {
            cursor: inherit !important;
            color: $black;
            padding: 18px 6%;
            margin: 0;

            &:hover {
                color: $black !important;
                background-color: inherit !important;
            }

            @include media(bp-lt-md) {
                padding: 0 5%;
            }
        }

        &__row {
            position: relative;

            &:nth-child(2n + 3) {
                background: #e0e0e0;
            }

            &:first-child {
                .table-row {
                    padding: 0 6%;

                    &.header {
                        @include media(bp-lt-md) {
                            display: none;
                        }
                    }
                }
            }

            &:nth-child(2) {
                .table-row {
                    padding-top: 15px;

                    @include media(bp-lt-md) {
                        padding-top: 5px;
                    }
                }

                .declined-services-list__actions {
                    top: 5px;
                }
            }

            @include media(bp-lt-md) {
                padding: 1px;
            }
        }

        &__actions {
            position: absolute;
            top: 10px;
            right: 30px;
            display: flex;

            button {
                padding: 0;
                background: 0;
                border: 0;
            }

            a {
                padding: 6px 15px;
                border-right: 1px solid #ababab;
                color: #a41204;

                &:hover {
                    color: darken(#a41204, 10%);
                }

                @include media(bp-lt-md) {
                    padding: 0px 15px 0 20px;
                }
            }

            svg {
                margin: 8px 15px 0 15px;
                cursor: pointer;
                fill: #858585;

                &:hover {
                    fill: darken(#858585, 10%);
                }

                @include media(bp-lt-md) {
                    margin: 1px 15px 0 12px;
                }
            }

            @include media(bp-lt-md) {
                position: static;
                margin-bottom: 40px;
            }
        }

        .service-type {
            flex-grow: 0;
            text-transform: capitalize;

            @include media(bp-lt-md) {
                flex-grow: 1;
                margin-bottom: 20px;
                margin-top: 15px;
                font-size: 18px;
            }
        }

        .date {
            @include media(bp-lt-md) {
                display: none;
            }
        }

        .cost {
            @include media(bp-lt-md) {
                width: 20%;
                font-size: 18px;
                margin-top: 15px;
            }
        }

        &__items {
            margin: 10px 0 0 20px;
            font-size: 14px;

            @include media(bp-lt-md) {
                margin: 0 0 0 35px;
            }
        }

        &--extended {
            margin-top: 20px;

            .table-row {
                padding: 0;

                .service-type {
                    width: 50%;

                    @include media(bp-lt-md) {
                        font-size: 15px;
                        margin-bottom: 0;
                    }
                }

                .cost {
                    @include media(bp-lt-md) {
                        font-size: 15px;
                    }
                }
            }

            .declined-services-list__row {
                margin-bottom: 30px;

                @include media(bp-lt-md) {
                    border-bottom: 1px solid #ebebeb;
                    margin-bottom: 10px;
                }

                &:nth-child(2n + 3) {
                    background: transparent;
                }

                &:nth-child(2) {
                    .table-row {
                        padding: 0;
                    }

                    .declined-services-list__actions {
                        top: -7px;
                    }
                }

                &:first-child .table-row {
                    padding: 0;
                }
            }

            .declined-services-list__actions {
                top: -7px;
                right: 0;

                @include media(bp-lt-md) {
                    margin-top: 15px;
                    margin-bottom: 25px;

                    a {
                        padding: 8px 15px 8px 0px;
                    }

                    svg {
                        margin: 8px 15px 0 12px;
                    }
                }
            }
        }
    }

    .import.vehicle-service-history-list {
        display: table;
        .table-row {
            display: table-row;
            .column {
                display: table-cell;
                padding: 1px;
                &.date {
                    width: 11%;
                }
                &.company-name {
                    width: 18%;
                }
                &.location {
                    width: 25%;
                }
                &.description {
                    width: 35%;
                }
                &.cost {
                    width: 11%;
                }
            }
            &:hover {
                color: black;
                background-color: transparent;
            }
        }
        button:first-of-type {
            margin-right: 20px;
        }
    }
    .mobile-service-invoice-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        p {
            text-align: left;
            margin-top: 3px;
        }
        .invoice-date-and-cost {
            display: flex;
            justify-content: space-between;
            > div:nth-child(1) {
                p {
                    text-align: left;
                    span {
                        text-decoration: underline;
                    }
                }
            }
            > div:nth-child(2) {
                p {
                    text-align: right;
                }
            }
        }
    }
    .first-hr,
    .mobile-service-invoice-list ~ hr {
        @include media(bp-lt-md) {
            margin-bottom: 25px;
        }
    }
    .record-import-actions {
        display: flex;
        margin-bottom: 50px;
        margin-top: 50px;

        @include media(bp-lt-md) {
            align-items: center;
            flex-direction: column;
            button {
                width: 94%;
                &.btn.secondary {
                    margin-left: 0px;
                    margin-top: 14px;
                }
            }
        }
        @include media(bp-gt-sm) {
            button.btn {
                width: 240px;
                &.secondary {
                    margin-left: 25px;
                    margin-top: 0px;
                }
            }
        }
    }
}
