@import '~common/scss/shared/shared';
.blog-article-container {
    // margin: 0 5%;

    @include media(bp-gt-md) {
        content: '';
        clear: both;
        display: table;
    }

    .blog-article {
        position: relative;

        .inject-cta-section {
            display: none;

            .container {
                padding: 0;
                padding-bottom: 10px;

                .title {
                    font-size: 18px;
                }

                .btn {
                    width: auto;
                }
            }

            @include media(bp-lt-lg) {
                display: block;
            }
        }

        .article-tag {
            @include font-body-bold;
            width: 100%;
            font-size: 13px;
            color: #e10c27;
            text-transform: uppercase;

            @include media(bp-lt-md) {
                font-size: 10px;
            }

            @include media(bp-md) {
                font-size: 12px;
            }
        }

        h1 {
            font-size: 40px;
            margin: 0.2em 0 0.3em;

            @include media(bp-lt-md) {
                font-size: 24px;
            }

            @include media(bp-md) {
                font-size: 30px;
            }
        }

        header {
            padding-top: 25px;

            ul {
                display: flex;
                flex-direction: row;
                margin-top: 0;
                padding: 0;

                li {
                    list-style: none;
                    text-transform: uppercase;
                }
            }
        }

        .article-card {
            @include media(bp-gt-lg) {
                width: 100%;

                &__copy {
                    padding: 0 0 0 10px;

                    h3 {
                        font-size: 16px;
                        padding-left: 10px;
                    }
                }

                &__category {
                    color: #e10c27;
                    font-size: 9px;
                    font-weight: bold;
                    padding-left: 10px;
                }
            }

            &__publicationDate,
            &__readTime {
                font-size: 16px;
                color: #585858;

                @include media(bp-lt-md) {
                    font-size: 12px;
                }

                @include media(bp-md) {
                    font-size: 14px;
                }
            }

            &__readTime {
                color: #c7c7c7;
            }

            &__separator {
                font-size: 5px;
                position: relative;
                top: -2px;
                padding: 0 6px;

                @include media(bp-gt-sm) {
                    font-size: 8px;
                    padding: 0 8px;
                }
            }
        }

        .teaser-image-wrapper {
            display: flex;
            width: 100%;
            max-height: 55vh;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            margin-bottom: 40px;
            background: $black;
            position: relative;

            @include media(bp-lt-md) {
                max-height: 65vw;
                margin-bottom: 20px;
            }

            img.teaser-background {
                position: absolute;
                top: 50%;
                left: 50%;
                filter: blur(10px);
                width: 100%;
                transform: translate(-50%, -50%) scale(1.1);
                opacity: 0.7;
            }

            img.teaser-image {
                height: 100%;
                z-index: 0;

                @include media(bp-gt-sm) {
                    object-fit: contain;
                    min-height: 360px;
                }

                @include media(bp-gt-md) {
                    min-height: 530px;
                }
            }
        }

        div[itemprop='articleBody'] {
            width: 100%;
            padding: 0 50px 80px 0;
            font-size: 20px;

            iframe {
                width: 100%;
                min-height: 450px;

                @include media(bp-xs) {
                    min-height: 315px;
                }
            }

            @include media(bp-lt-md) {
                font-size: 16px;
            }

            @include media(bp-md) {
                font-size: 18px;
            }

            @include media(bp-lt-xl) {
                padding: 0 0 40px;
            }

            @include media(bp-gt-md) {
                width: 65%;
                float: left;
            }

            p {
                &:first-child {
                    margin-top: 0;
                }

                img {
                    width: auto;
                }
            }
        }
    }

    .blog-sidebar {
        width: 100%;

        .xf-content-height {
            margin: 0;
        }

        @include media(bp-lt-lg) {
            & > div > div:not(.experiencefragment) {
                display: none;
            }
        }

        @include media(bp-gt-md) {
            width: 34%;
            float: right;
            overflow-x: hidden;

            &::after {
                clear: both;
            }
        }

        .title-text-cta {
            margin-bottom: 35px;
            background-color: #efefef;

            .container {
                padding: 10px 0 30px;

                .title {
                    font-size: 23px;
                }

                a {
                    color: $white;
                    .btn {
                        height: 55px;
                        width: 220px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 17px;
                        background-color: #fe0404;
                    }
                }
            }
        }

        .article-list__list {
            margin: 2px;
        }
    }

    // Offers XF Styles
    .offers {
        .container {
            padding: 0;
        }

        &.reskin {
            .carousel {
                width: 100%;
            }

            .heading {
                display: none;
            }

            .offer-wrap {
                width: auto;
                margin: 0;
            }

            .offer {
                min-height: 194px;

                .content {
                    padding: 20px;
                    width: 100%;
                    align-content: space-between;
                    min-height: unset;
                }

                .image-wrap {
                    max-width: 32%;
                    width: auto;

                    img {
                        object-fit: cover;
                    }
                }

                .cta-link {
                    margin-bottom: 5px;
                }

                .coupon-cta .btn {
                    padding: 8px 16px;
                    font-size: 17px;
                }
            }
        }
    }

    .sticky-cta-popup {
        position: fixed;
        bottom: 2%;
        right: 0;
        z-index: 11;
        height: auto;
        padding: 20px;
        background: white;
        width: 100%;
        box-shadow: 1px 7px 12px 0px #a7a7a7;
        overflow-y: hidden;
        max-height: 500px;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

        @include media(bp-gt-sm) {
            width: 450px;
        }

        .close-cta-popup {
            position: absolute;
            right: 5px;
            top: 5px;
            background: none;
            border: none;
            font-size: 25px;
            width: 32px;
            z-index: 2;
            height: 32px;
            cursor: pointer;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 15px;
                right: 5px;
                width: 22px;
                height: 3px;
                background-color: #8d8d8d;
            }

            &:before {
                transform: rotate(-45deg);
            }

            &:after {
                transform: rotate(45deg);
            }
        }

        .title-text-cta {
            background-color: transparent;
            .container {
                padding: 0;

                .btn {
                    width: auto;
                }
            }
        }

        &.close {
            max-height: 0; //slideup fast effect with javascript
        }
    }

    .back-to-top {
        &__wrapper {
            background: #252525;
            border-radius: 50%;
            padding: 7px;
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 50px;
            right: 30px;
            display: none;
            z-index: 5;

            @include media(bp-xs) {
                display: block;
            }
        }

        &__link {
            position: relative;
            color: $white;
            text-decoration: none;
            display: flex;
            align-items: center;
            flex-direction: column;

            &:active,
            &:focus,
            &:hover {
                text-decoration: none;
                color: $white;
            }
        }

        &__icon {
            transform: rotate(-90deg);
            fill: $white;
            width: 15px;
            position: absolute;
            top: -14px;
        }

        &__copy {
            font-size: 10px;
            text-transform: uppercase;
            position: absolute;
            top: 15px;
        }
    }
}

.blog-article-progress {
    &__container {
        width: 100%;
        position: sticky;
        top: 50px;
        height: 4px;
        z-index: 1;

        @include media(bp-gt-md) {
            top: 59px;
        }
    }

    &__bar {
        background: #a8a8a8;
        height: 100%;
        width: 0;
    }
}
