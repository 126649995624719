@import '~common/scss/shared/shared';

.more-products-carousel {
    margin: 50px 0 75px;

    .carousel {
        width: 90%;
        margin: 0 auto;
    }

    .slide-content {
        display: table;
        padding: 15px 20px 20px;

        > div {
            display: table-cell;
            vertical-align: top;
        }

        > .btn {
            display: block;
            width: 100%;
            margin-top: 1em;

            @include media(bp-gt-sm) {
                display: none;
            }
        }
    }

    .img-container {
        box-sizing: content-box;
        width: 125px;
        padding-right: 20px;
    }

    .copy-container {
        .btn {
            margin-top: 1em;
        }
    }

    .title {
        margin-left: 5%;
    }

    .product-name {
        margin-top: 0;
        line-height: 1em;
    }

    .product-id {
        margin: 0.5em 0;
        line-height: 1em;
    }

    .price {
        margin: 0.3em 0 0.1em;
        line-height: 0.8em;

        & .cents {
            vertical-align: top;
            line-height: 0.8em;
        }
    }

    .disclaimer {
        margin: 0;
        letter-spacing: 0.05em;
    }

    p {
        margin: 0.4em 0;
    }

    .btn-mobile {
        display: none;
    }

    @include media(bp-lt-md) {
        padding: 0 30px;

        .btn-mobile {
            margin-top: 20px;
            display: block;
        }

        .slide-content {
            padding: 0;
        }

        .copy-container {
            .btn {
                display: none;
            }
        }

        .title {
            margin-left: 0;
        }
    }
}

.more-products-carousel-reskin {
    .container {
        margin: 0 auto;

        h2.title {
            text-align: center;
        }
    }

    &.v2 {
        .results-container {
            background: transparent !important;
            padding: 0;

            .results {
                display: flex !important;
                flex-wrap: wrap;

                .result-card {
                    margin: 0 !important;
                    border: 0;
                    background: transparent;
                }
            }
        }
    }

    .slick-dots {
        position: relative;
        margin-top: 0;
        bottom: initial;
    }

    @media print {
        display: none;
    }
}

.tires-for-you {
    .results-container {
        text-align: center;

        > button {
            @include font-title;
            padding: 14px 16px;
            border: 2px solid $brand-color-red;
            border-radius: 4px;
            color: $brand-color-red;
            font-size: 20px;
            line-height: 110%;
            text-align: center;
            background: none;
            cursor: pointer;
        }

        .tsr__card {
            text-align: left;
        }
    }

    .tsr-card {
        & .btn.otd-price.grid {
            padding: 8px 11px;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        &-content {
            padding: 40px 20px 20px;
        }

        .compare-button {
            width: 100%;
            height: 50px;
            margin: 0;
            padding: 0;
        }
    }
}
