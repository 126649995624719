.product-results-interstitial {
    display: table;
    width: 100%;
    margin: 30px 0; // margin: 30px 10%;
    padding: 0;

    .img-container {
        display: inline-block;//display: table-cell;
        width: 22%;
        min-width: 160px;
        padding: 0 5% 0 0;
        box-sizing: content-box;
    }

    .copy-container {
        display: inline-block;//display: table-cell;
        width: 72%;
        padding: 0;
        vertical-align: top;
    }

    p {
        margin: 1.2em 0;
        line-height: 1.2em;

        strong {
            font-weight: bold;
        }
    }

    .btn-container {
        display: block;
        width: 100%;

        > div {
            display: inline-block;
            margin-bottom: 15px;
            padding-right: 20px;
            vertical-align: top;
        }
    }

    .tooltip {
        margin-top: .7em;
        font-weight: bold;

        .coral-Tooltip {
            font-size: 10px !important;
        }

    }

    .tooltip-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        padding-bottom: 4px;
        border-radius: 8px;
        font-weight: bold;
        line-height: 1.2em;
        text-align: center;
    }

    @include media(bp-lt-lg) {
        margin: 30px 0;//5%;
        padding: 0;// 15px;

        .img-container {
            padding-right: 3%;
        }

        .btn-container {
            > div {
                padding-right: 15px;
            }
        }
    }

    @include media(bp-lt-md) {
        display: block;
        // margin: 30px 15px;

        .img-container {
            display: none;
        }

        .copy-container {
            display: block;
            width: 100%;
        }

        .btn-container {
            > div {
                display: block;
                padding-right: 0;
            }
        }

        .btn {
            width: 100%;
        }

        .tooltip {
            display: none;
        }
    }
}
