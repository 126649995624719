.product-results-interstitial {
    p {
        color: $text-color;
        font-size: 16px;
    }

    .tooltip {
        color: $text-color;
        font-size: 16px;
    }

    .tooltip-icon {
        background-color: $text-color;
        color: $white;
        font-size: 14px;
    }

    @include media(bp-lt-lg) {
        .btn {
            font-size: 16px;
        }
    }

    @include media(bp-lt-md) {
        font-size: 15px;
    }
}
