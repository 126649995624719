.offers-subnav {
    background-color: #d31a0b;

    @include media(bp-lt-lg) {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        align-items: flex-start;
    }

    &--sticky {
        position: sticky;
        top: -1px;
        z-index: 11;
        box-shadow: 0 2px 3px #0000006e;
    }

    &__list {
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0 4.86111%;
        max-width: 1385px;

        @include media(bp-gt-lg) {
            align-items: center;
            min-height: 60px;
            margin: 0 auto;
        }

        @media (max-width: 1280px) {
            flex-wrap: unset;
        }

        &-item {
            padding: 0 8px;
            display: inline-block;
            &.exclude {
                display: none;
            }
        }

        &-cta {
            color: $white;
            text-decoration: none !important;
            font-size: 14px;
            @include font-body-bold;
            padding: 5px 11px;
            border-radius: 25px;
            display: inline-block;
            text-transform: capitalize;

            &:hover {
                color: $black !important;
                background: rgba(255, 255, 255, 0.75);
            }
            &:active,
            &--active {
                color: $black !important;
                background: $white;
                &:hover {
                    background: $white;
                }
            }
            &:focus,
            &:focus:not(.btn) {
                color: $white;
            }
        }
    }
}
