@import '~common/scss/shared/shared';

.speed-rating {
    @include clearfix;

    .title {
        width: 100%;
        margin: 10px 0;
        text-align: left;
    }

    .ratings {
        @include clearfix;
        display: inline-block;
    }

    .item {
        float: left;
        width: 9.0909%;
        min-width: 85px;
        padding: 1%;
        margin: -1px 0 0 -1px; // to prevent border doubling.
        text-align: center;
        vertical-align: middle;
    }

    .type,
    .speed,
    .unit {
        line-height: 1.2em;
    }
}
