@use "sass:math";

.offers {
    .heading {
        margin: 0 0 20px;
        text-transform: uppercase;
        text-align: center;
    }

    .desc {
        text-align: center;
    }
}

.offers-list {
    max-width: 100vw;
    width: 100%;
    @include media(bp-lt-sm) {
        margin-bottom: 20px;
    }
}
