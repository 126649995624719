@use 'sass:math';
@import '~common/scss/shared/shared';

$border-color-grey: #dcdcdb;
$light-color-grey: #cccccc;
$inactive-text-color: #a5a5a5;

.raq {
    margin-bottom: 60px;

    .container {
        @include media(bp-lt-sm) {
            margin: 0;
        }
    }

    .wrap {
        background: linear-gradient(to top, #eee, transparent 150px);
        border: 1px solid $border-color-grey;

        @include media(bp-lt-sm) {
            .inner {
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
            }
        }
    }

    .wrap-inner {
        width: 88.4615384615%; //TODO change for function
        margin: 0 auto;

        & > div {
            margin-bottom: 20px;
            padding: 0;
        }
        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .heading {
        @include media(bp-lt-sm) {
            margin-top: 40px;
            font-size: 17px;
            text-align: left;
        }
    }

    .details-col,
    .contact-col {
        @include media(bp-lt-sm) {
            background: linear-gradient(to bottom, #eee, transparent 50px);
        }

        .heading {
            font-size: 22px;

            @include media(bp-lt-sm) {
                margin-top: 40px;
                font-size: 17px;
                text-align: left;
            }
        }

        .sub-heading {
            clear: both;
            margin: 15px 0;
        }
    }

    .details-col {
        border-right: solid 1px $appt-step-border-color-light;

        @include media(bp-lt-sm) {
            border: none;
        }

        .icn-call-text {
            @include font-title;
            font-size: 26px;
            color: $brand-color-red;
            text-transform: uppercase;

            span {
                font-size: 16px;
                display: block;
            }
        }

        .inner-details {
            border-bottom: 1px solid $border-color-grey;
            padding-bottom: 15px;
        }

        .visit-us {
            margin-bottom: 10px;
        }
    }

    .contact-col {
        .fields {
            .contact-city-disable {
                .custom-select {
                    background-color: $light-color-grey;
                    select {
                        color: $inactive-text-color;
                    }
                }

                label {
                    color: $light-color-grey;
                }
            }

            .contact-select-store {
                width: 100%;
            }
        }
    }

    input,
    .custom-select {
        width: 100%;
        margin: 0 0 26px;

        &.no-spacing {
            margin-bottom: 8px;
        }
    }

    .custom-select {
        margin-top: 45px;
        height: 52px;
        box-sizing: border-box;

        select {
            padding: 13px 11px;
            height: 50px;
        }

        &.services {
            margin-top: 20px;
        }

        &.error {
            border: 1px solid red;
        }
    }

    input,
    textarea {
        width: 100%;
        padding: 15px 11px;
        line-height: 15px;
        height: 50px;
        box-sizing: border-box;

        &.error {
            border: 1px solid red;
        }
    }

    label {
        display: inline-block;
        position: relative;
        top: 10px;
        left: 7px;
        background: $white;
        padding: 0 10px;
        color: #646464;
        font-size: 14px;
        line-height: 19px;
    }

    input:focus + label,
    input:valid + label,
    input:invalid + label,
    textarea:focus + label,
    textarea:valid + label,
    textarea:invalid + label {
        font-size: 75%;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -80%, 0);
        opacity: 1;
        background-color: white;
        padding-top: 0;
    }

    textarea {
        width: 100%;
        height: 170px;
        resize: none;
    }

    .fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .textarea-message {
            width: 100%;

            span {
                font-style: italic;
                font-size: 12px;
            }
        }

        @include media(bp-lt-sm) {
            display: block;
        }
    }

    .signup-optin {
        margin-bottom: 26px;

        label {
            display: flex;
            padding: 0;
            left: 0;
        }
    }

    .message {
        font-size: 12px;

        &.margin {
            margin-bottom: 26px;
        }
    }

    .col {
        width: 49%;

        @include media(bp-lt-sm) {
            width: 100%;
        }

        @include media(bp-gt-sm) {
            &.call,
            &.free {
                transform: translateY(-80px);
            }
        }
    }

    .hours-header {
        font-weight: bold;
        margin: 15px 0;
    }

    .hours-display {
        display: flex;
        justify-content: space-between;
        max-width: 50%;
    }

    .store-phone {
        font-weight: bold;
        font-size: 22px;
        line-height: 32px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &:before {
            content: '';
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgY2xhc3M9Imljb24tcGhvbmUiPgoJPHBhdGggc3R5bGU9ImZpbGw6I2Q4MWQwNTsiIGQ9Ik0yNS43LDM4LjJjLTguNi04LjYtOS43LTE1LjgtNy41LTE4LjJjMC42LTAuNywyLjUtMS45LDMuMy0yLjFjMC44LTAuMiwxLjYsMC4xLDIsMC44CgkJYzEuMywyLjEsMi44LDQuMSw0LjQsNmMwLjUsMC42LDAuNSwxLjQsMCwyLjFjLTAuNiwwLjctMS42LDEuMi0yLjMsMS45Yy0wLjYsMC42LTAuNywyLjgsMy4xLDYuNmMzLjksMy44LDYuMSwzLjcsNi42LDMuMQoJCWMwLjctMC44LDEuMi0xLjcsMS45LTIuM2MwLjYtMC41LDEuNC0wLjUsMi4xLDBjMS45LDEuNSwzLjksMyw2LDQuNGMwLjcsMC41LDEsMS4yLDAuOCwyYy0wLjIsMC44LTEuNCwyLjctMi4xLDMuMwoJCUM0MS41LDQ3LjksMzQuMyw0Ni44LDI1LjcsMzguMnoiLz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNkODFkMDU7IiBkPSJNMzguNCwzMi4yYy0wLjcsMC0xLjMtMC42LTEuMy0xLjNsMCwwYzAtMS0wLjQtMi0xLjEtMi43bDAsMGMtMC43LTAuNy0xLjYtMS4xLTIuNy0xLjFsMCwwCgkJCWMtMC43LDAtMS4zLTAuNi0xLjMtMS4zbDAsMGMwLTAuNywwLjYtMS4zLDEuMy0xLjNsMCwwYzEuOCwwLDMuNCwwLjcsNC41LDEuOWwwLDBjMS4xLDEuMSwxLjksMi43LDEuOSw0LjVsMCwwCgkJCWMwLDAuNC0wLjEsMC43LTAuNCwwLjlsMCwwQzM5LjEsMzIuMSwzOC44LDMyLjIsMzguNCwzMi4yTDM4LjQsMzIuMnoiLz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNkODFkMDU7IiBkPSJNNDMuNiwzMi4xYy0wLjcsMC0xLjMtMC42LTEuMy0xLjNsMCwwYzAtMi40LTEtNC42LTIuNi02LjJsMCwwQzM4LjEsMjMsMzUuOSwyMiwzMy41LDIybDAsMAoJCQljLTAuNywwLTEuMy0wLjYtMS4zLTEuM2wwLDBjMC0wLjcsMC42LTEuMywxLjMtMS4zbDAsMGMzLjEsMCw2LDEuMyw4LDMuM2wwLDBjMi4xLDIsMy4zLDQuOSwzLjMsOGwwLDBjMCwwLjQtMC4xLDAuNy0wLjQsMC45bDAsMAoJCQlDNDQuMiwzMS45LDQzLjksMzIuMSw0My42LDMyLjFMNDMuNiwzMi4xeiIvPgo8L3N2Zz4K);
            display: inline-block;
            background-size: cover;
            width: 30px;
            height: 30px;
            top: 10px;
            position: relative;
        }
    }

    [data-hours-list],
    [data-holidays-list] {
        padding: 0;
    }

    .store {
        position: relative;
    }

    .map-container {
        @include font-title;
        display: inline-block;
        position: absolute;
        top: 30px;
        right: 0;
        font-size: 20px;
        line-height: 30px;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        .locations {
            &:before {
                content: '';
                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgY2xhc3M9Imljb24tbG9jYXRpb25zIj4KICAgIDxwYXRoIGZpbGw9IiNkODFkMDUiIGQ9Ik0zMS4zLDE0LjRjMC4zLDAsMC42LDAsMC45LDBjMCwwLDAuMSwwLDAuMSwwYzAuMywwLDAuNiwwLDAuOSwwLjFjMC43LDAuMSwxLjMsMC4zLDIsMC41YzEuMywwLjUsMi40LDEuMiwzLjUsMi4xCiAgICAgICAgYzEuMSwxLDEuOSwyLjMsMi41LDMuN2MwLjQsMSwwLjYsMiwwLjYsMy4xYzAsMCwwLDAuMSwwLDAuMWMwLDAuMiwwLDAuNSwwLDAuN2MwLDAsMCwwLjEsMCwwLjFjMCwwLjMsMCwwLjUtMC4xLDAuOAogICAgICAgIGMtMC4xLDAuNi0wLjIsMS4yLTAuNCwxLjljLTAuMywxLjEtMC43LDIuMi0xLDMuM2MtMC42LDEuOC0xLjMsMy42LTIuMSw1LjRjLTEuMywzLjEtMi43LDYuMi00LjEsOS4zYy0wLjcsMS42LTEuNSwzLjEtMi4yLDQuNwogICAgICAgIGMwLDAsMCwwLjEtMC4xLDAuMWMwLDAsMC0wLjEtMC4xLTAuMWMtMS4yLTIuNC0yLjMtNC44LTMuNC03LjNjLTEuMS0yLjMtMi4xLTQuNi0zLTdjLTAuOC0xLjgtMS41LTMuNy0yLjEtNS42CiAgICAgICAgYy0wLjQtMS4zLTAuOC0yLjUtMS4xLTMuOGMtMC4xLTAuNS0wLjItMS4xLTAuMy0xLjZjMC0wLjEsMC0wLjEsMC0wLjJjMC0wLjIsMC0wLjUsMC0wLjdjMCwwLDAtMC4xLDAtMC4xYzAtMC4yLDAtMC40LDAuMS0wLjYKICAgICAgICBjMC4xLTAuNiwwLjItMS4yLDAuNC0xLjhjMC42LTIsMS44LTMuNiwzLjQtNC45YzEuNy0xLjMsMy42LTIsNS43LTIuMUMzMS4zLDE0LjQsMzEuMywxNC40LDMxLjMsMTQuNHogTTMxLjgsMjguOQogICAgICAgIGMyLjUsMCw0LjUtMiw0LjUtNC41YzAtMi40LTItNC41LTQuNC00LjVjLTIuNSwwLTQuNSwyLTQuNSw0LjVDMjcuMywyNi44LDI5LjIsMjguOSwzMS44LDI4Ljl6Ii8+Cjwvc3ZnPg==);
                width: 30px;
                height: 30px;
                display: inline-block;
                margin-right: -10px;
                position: relative;
                top: 7px;
                left: -8px;
                background-size: cover;
            }
        }
    }

    .highlight {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 20px;
        color: #d81d05;
    }

    .vehicle-lookup {
        label {
            display: none !important;

            &[for='mileage'] {
                display: inline-block !important;
            }
        }
    }

    .submit-error {
        color: red;
        display: block;
        margin-top: 10px;
    }
}

/* Confirmation Step */
section.confirmation {
    margin: 0 4.86111%;
    display: none;

    &.no-hide {
        display: block;
    }

    h3 {
        margin-top: 0;
    }

    .heading {
        text-align: center;
        margin-bottom: 50px;
    }

    ul.bulleted-list {
        li {
            padding-bottom: 25px;
            color: #656565;

            &:before {
                color: #656565;
            }
        }
    }

    .confirmation-item-group {
        justify-content: space-between;

        @include media(bp-gt-sm) {
            display: flex;
        }

        .icon-pencil {
            display: none !important;
        }

        .appointment-info {
            flex-basis: 60%;
            color: #656565;

            > div {
                padding: 30px;
                border-top: 1px solid $light-gray;

                @include media(bp-lt-md) {
                    border-top: none;
                    padding: 30px 0 0 0;
                }

                &:first-child {
                    border-top: 1px solid $light-gray;
                }

                &.appointment-details {
                    @include media(bp-lt-md) {
                        padding: 35px 30px;
                    }
                }

                &.info-group {
                    padding-bottom: 5px;
                }
            }
        }

        .appointment-details {
            flex-basis: 35%;
            background-color: #fafafa;
            padding: 35px 30px;

            h3 {
                border-bottom: 1px solid $light-gray;
                padding-bottom: 10px;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 25px;
                color: #656565;

                b {
                    color: $black;
                }

                &.date-time {
                    color: $black;

                    span {
                        color: #656565;
                    }
                }

                &.cancel-appointment {
                    margin-top: 25px;
                    border-top: 1px solid $light-gray;

                    b {
                        display: inline-block;
                        margin: 20px 0;
                    }
                }
            }

            input {
                width: 76%;

                + button {
                    display: inline-block;
                    width: auto;
                    margin-left: -4px;
                    border: 0;
                    padding: 6px;
                    line-height: 1;
                    vertical-align: top;
                    background-color: $brand-color-blue;
                }
            }

            .appointment-details-info {
                .store-directions {
                    display: inline-block;
                }
            }
        }
    }
}

.mobile-only {
    display: none !important;

    @include media(bp-lt-md) {
        display: block !important;
    }
}

.desktop-only {
    @include media(bp-lt-md) {
        display: none !important;
    }
}
