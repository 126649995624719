@import '~common/scss/shared/shared';

.blog__article-list--carousel {
    .article-list__list {
        padding: 0;

        .carousel {
            width: 100%;
            margin: 0 auto;
            margin-bottom: 40px;

            @include media(bp-gt-md) {
                margin-bottom: 120px;
            }

            .slick-list {
                @include media(bp-xs) {
                    overflow: visible;
                }
            }

            .slick-slide:not(.slick-current) {
                opacity: 0.4;

                .article-card {
                    margin: 0 12px;
                }
            }

            .slick-prev,
            .slick-next {
                top: 34%;
                z-index: 2;

                @include media(bp-gt-sm) {
                    top: 50%;
                }
            }

            .slick-prev {
                left: -20px;
                @include media(bp-gt-md) {
                    left: -40px;
                }
            }

            .slick-next {
                right: -20px;
                @include media(bp-gt-md) {
                    right: -40px;
                }
            }

            .slick-prev {
                &:before {
                    content: '';
                    border-left: 2px solid black;
                    border-top: 2px solid black;
                    width: 12px;
                    height: 12px;
                    transform: rotate(-45deg);
                    margin-right: 0;
                    float: right;

                    @include media(bp-gt-sm) {
                        border-left: 3px solid black;
                        border-top: 3px solid black;
                        width: 17px;
                        height: 17px;
                    }
                }
            }
            .slick-next {
                &:before {
                    content: '';
                    border-left: 2px solid black;
                    border-top: 2px solid black;
                    width: 12px;
                    height: 12px;
                    transform: rotate(-225deg);
                    margin-right: 15px;
                    float: right;

                    @include media(bp-gt-sm) {
                        border-left: 3px solid black;
                        border-top: 3px solid black;
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }

        .slick-dots {
            bottom: -40px;
            @include media(bp-gt-md) {
                bottom: -60px;
            }

            li {
                margin: 0 10px;

                button {
                    width: 15px;
                    height: 15px;

                    &:before {
                        border: 2px solid $black;
                        border-radius: 50%;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            color: #e10c27;
                            background-color: #e10c27;
                            border-color: #e10c27;
                        }
                    }
                }
            }
        }

        .article-card {
            display: flex;
            flex: 0 1 100%;
            flex-direction: column;
            margin: 0 12px;
            padding: 0;
            border: none;

            @include media(bp-gt-sm) {
                flex-direction: row;
            }

            &__image {
                height: 235px;
                img {
                    height: 100%;
                }
            }

            &__publicationDate,
            &__readTime {
                font-size: 12px;
                letter-spacing: 1px;

                @include media(bp-gt-lg) {
                    font-size: 14px;
                }
            }

            &__headline {
                font-size: 22px;
                line-height: 28px;

                @include media(bp-gt-sm) {
                    width: 100%;
                    margin-bottom: 2px;
                    font-size: 20px;
                }
                @include media(bp-gt-lg) {
                    font-size: 37px;
                    line-height: 55px;
                }
            }

            &__btn {
                display: none;

                @include media(bp-gt-sm) {
                    display: inline-block;
                    margin-top: 10px;
                    padding: 5px 15px;
                    font-size: 12px;
                }
                @include media(bp-gt-lg) {
                    margin-top: 50px;
                    padding: 10px 43px;
                    font-size: 15px;
                }
            }

            &__separator {
                @include media(bp-xs) {
                    padding: 0;
                    color: $black;
                    font-size: 4px;
                    position: relative;
                    top: -3px;
                }
            }

            &__image {
                @include media(bp-gt-sm) {
                    width: 50%;
                }

                @include media(bp-gt-lg) {
                    width: 69%;
                }

                @include media(bp-gt-lg) {
                    height: 475px;

                    img {
                        height: 100%;
                    }
                }
            }

            &__copy {
                @include media(bp-gt-sm) {
                    width: 40%;
                    padding: 10px 0 0 15px;
                }

                @include media(bp-gt-lg) {
                    width: 30%;
                    padding: 20px 0 0 25px;
                }
            }

            &__category {
                @include media(bp-gt-sm) {
                    margin-bottom: 10px;
                    font-size: 12px;
                }
            }

            &__description {
                @include media(bp-gt-sm) {
                    display: none;
                }
            }
        }

        .swiper-wrapper {
            margin-bottom: 120px;
            @include media(bp-lt-md) {
                margin-bottom: 50px;
            }
        }
        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: unset;
            margin-top: 200px;
            font-size: 36px;

            @include media(bp-lt-xl) {
                margin-top: 100px;
            }

            &:after {
                font-size: 30px;
                font-weight: normal;
                color: $black;
                font-weight: 600;
            }
        }

        .swiper-pagination {
            bottom: 60px;
            @include media(bp-lt-lg) {
                bottom: 30px;
            }
            .swiper-pagination-bullet {
                margin: 0 6px !important;
            }

            .swiper-pagination-bullet-active {
                opacity: 1;
                background: #e10c27;
            }
        }
    }
}
