.clearfix {
    content: '';
    display: table;
    clear: both;
}

.hide {
    display: none !important;
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }
}

/*
 * mobile-only cant be a global helper
 * because of the accordion's usage of mobile only
 * which breaks on the tire result page. this should be fixed.

@include media(bp-gt-sm) {
    .mobile-only {
        display: none;
    }
}
*/

@include media(bp-lt-md) {
    .desktop-only {
        display: none;
    }
}

.bg-none {
    background-color: transparent;
}

.disable-pointer-events {
    pointer-events: none;
}