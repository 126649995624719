@use "sass:math";
@import '~common/scss/shared/shared';

$basic-thirty-seventy-border-color: #dcdcdb !default;

.basic-thirty-seventy {
    &.border-top {
        .container {
            border-top: solid 1px $basic-thirty-seventy-border-color;

            @include media(bp-lt-sm) {
                border-top: none;
            }
        }
    }

    &.border-bottom {
        .container {
            border-bottom: solid 1px $basic-thirty-seventy-border-color;
            padding-bottom: 50px;

            @include media(bp-lt-sm) {
                border-bottom: none;
                padding-bottom: 25px;
            }
        }
    }

    .container {
        overflow: hidden;
        padding: 25px 0;

        @include media(bp-lt-sm) {
            padding: 25px 0 0;
        }
    }

    .img-container,
    .copy-container {
        float: left;

        @include media(bp-lt-sm) {
            float: none;
        }
    }

    .img-container {
        width: percentage(math.div(350px, 1300px));
        margin-right: percentage(math.div(65px, 1300px));

        @include media(bp-lt-sm) {
            width: 100%;
            margin: 0 0 25px;
        }
    }

    .copy-container {
        width: percentage(math.div(885px, 1300px));

        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .title {
        margin: 0;
        font-size: 22px;

        @include media(bp-lt-sm) {
            font-size: 18px;
        }
    }
}
