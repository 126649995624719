@use 'sass:math';

.appt-step-1 {
    .results-col {
        width: percentage(math.div(783px, 1228px));
        margin-right: percentage(math.div(35px, 1228px));

        @include media(bp-lt-lg) {
            width: 100%;
            margin-right: 0;
        }

        @include media(bp-lt-sm) {
            /*padding-bottom: 225px;*/
        }
    }

    .map-col {
        width: percentage(math.div(410px, 1228px));

        @include media(bp-lt-lg) {
            width: 100%;
        }

        @include media(bp-lt-sm) {
            display: none;
        }
    }

    .map {
        width: 100%;
        height: 540px;
        @include media(bp-lt-lg) {
            margin-top: 20px;
        }
    }

    .search-block {
        margin-bottom: 19px;

        @include media(bp-lt-sm) {
            margin: 0 4.86111% 19px;
            /*position: absolute;*/
            /*bottom: 0;*/
        }

        h2 {
            display: none;

            @include media(bp-lt-sm) {
                display: inline-block;
            }
        }

        input {
            @include media(bp-lt-sm) {
                width: 100%;
                margin: 10px 0;
            }
        }

        .zipcodeError {
            display: block;
            color: $brand-color-red;
        }
    }

    .search-block-mobile {
        display: none;
        padding-top: 15px;

        .inner {
            margin: 0 percentage(math.div(70px, $wrapper-max-width));
        }

        input {
            width: 100%;
            margin: 20px 0;
        }
    }

    #show-more-toggle {
        display: none;
    }

    #show-more-toggle:checked ~ label {
        display: none;
    }

    #show-more-toggle:checked ~ div {
        display: inline-block;
    }

    .find-store-show-more-toggle {
        display: none;
        align-items: center;
        justify-content: center;
        width: 70%;
        margin: 25px auto;

        @include media(bp-lt-sm) {
            display: flex;
        }
    }
}

.appt-step-1-results {
    overflow: auto;
    height: 475px;
    background-color: $white;

    @include media(bp-lt-sm) {
        overflow: hidden;
        height: auto;
    }

    /* stores near you heading*/
    .heading {
        display: none;
        padding: 15px 0;
        margin: 0 percentage(math.div(70px, $wrapper-max-width));
        border-top: solid 1px #eee;

        @include media(bp-lt-sm) {
            display: block;
        }
    }

    .result {
        @include clearfix;
        padding: 30px 0;
        background: $white;

        @include media(bp-lt-sm) {
            &:first-child {
                border-top: 1px solid #ccc;
                margin-top: 15px;
            }
        }

        .inner {
            overflow: hidden;

            > div {
                float: left;

                @include media(bp-lt-sm) {
                    float: none;
                }
            }

            @include media(bp-lt-sm) {
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
            }
        }

        .pin {
            width: percentage(math.div(70px, 779px));
            text-align: center;

            @include media(bp-lt-sm) {
                float: left !important;
                margin-right: 10px;
            }
        }

        .info {
            width: percentage(math.div(456px, 779px));
            padding-right: 15px;

            .reviews-v2 .reviews-inline-rating {
                flex-direction: row;
            }

            @include media(bp-lt-sm) {
                width: 100%;
            }

            .cta {
                margin-top: 10px;

                @include media(bp-lt-sm) {
                    display: none;
                }
            }

            .direction-message {
                padding-top: 10px;
            }
        }

        .ctas-mobile {
            display: none;
            float: right;

            a {
                display: block;
                text-align: center;

                &:first-child {
                    margin-bottom: 10px;
                }
            }

            .call-cta {
                .icon {
                    display: block;

                    &:before {
                        @include icon('phone', $brand-color-red);
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            .directions-cta {
                .icon {
                    display: block;

                    &:before {
                        @include icon('map-signs', $brand-color-red);
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            @include media(bp-lt-sm) {
                display: block;
            }
        }

        .rating {
            margin: 8px 0;
        }

        .info-2 {
            width: percentage(math.div(252px, 779px));

            @include media(bp-lt-sm) {
                width: 100%;
            }

            a {
                display: block;
            }
        }

        .phone {
            margin-bottom: 15px;

            @include media(bp-lt-sm) {
                display: none;
            }
        }

        .hours {
            margin-bottom: 15px;

            dl {
                margin: 0;
            }

            @include media(bp-lt-sm) {
                margin-top: 15px;
            }
        }

        .open-nights {
            display: none;

            @include media(bp-lt-sm) {
                display: block;
            }
        }

        .cta {
            @include media(bp-lt-sm) {
                display: none;
            }
        }

        .map-mobile {
            display: none;
            margin-bottom: 20px;

            @include media(bp-lt-sm) {
                display: block;
            }
        }

        .cta-mobile {
            display: none;

            @include media(bp-lt-sm) {
                display: block;
            }
        }

        .holiday {
            color: $brand-color-red;
        }
    }
}

/**
 * BSROAEM-3142, 3144
 * widemap-view
 */
.appt-step-1 {
    &.widemap {
        background-color: #ebebeb;
        margin-bottom: 0;
        padding-top: 40px;

        .title {
            margin: 0;
            font-size: 42px;
            min-height: 1em;
            text-align: center;
        }
        .subhead {
            font-size: 18px;
            min-height: 2em;
            margin-bottom: 80px;
            text-align: center;
        }

        .wrap {
            position: relative;
            display: flex;
            padding: 25px 5px 25px 25px;
            background: $white;
            border: 1px solid #aaa;
        }

        .search-block {
            position: absolute;
            margin-bottom: 0;

            form {
                display: flex;
                align-items: center;
            }

            label {
                display: inline-block;
                min-width: 90px;
            }

            input {
                margin: 0 10px;
                width: 90px;
                padding: 6px 8px;
            }

            .btn {
                padding: 8px 20px;
            }
        }

        .map-col {
            width: 75%;
            height: 328px;
            margin-top: 60px;
        }

        .map {
            height: 100%;
            border: 0;
            margin-top: 0;
        }

        .results-col {
            width: 315px;
            margin-right: 0;

            .btn {
                white-space: nowrap;
            }
        }
    }

    &.no-bg {
        background-color: $white;

        .container {
            margin: 0;
            max-width: 1440px;
        }

        .subhead {
            margin-bottom: 20px;
        }

        .map-col {
            width: 78%;
        }
    }

    @include media(bp-gt-sm) {
        &.dark-background {
            .title,
            .subhead {
                color: $white;
            }
        }
        &.light-background {
            .title,
            .subhead {
                color: $black;
            }
        }

        .search-block {
            .results-zip,
            .change-store {
                display: none;
            }
        }
    }

    @include media(bp-lt-sm) {
        &.widemap {
            @include gradient-top(120px);
            background-image: none !important;

            .title {
                font-size: 8vw;
                padding: 10px;
            }
            .subhead {
                margin-bottom: 10px;
                padding: 10px 10vw;
                font-size: inherit;
            }

            .wrap {
                flex-direction: column;
                padding: 0;
                border: 0;
            }

            .search-block {
                position: relative;

                form {
                    justify-content: center;
                }

                label {
                    display: inline-block;
                    min-width: none;
                    margin-right: 5px;
                }

                .results-zip,
                .change-store {
                    display: inline;
                }

                input,
                .btn {
                    display: none;
                }
            }

            .map-col {
                display: block;
                width: 100%;
                height: 328px;
                margin-top: 20px;
            }

            .map {
                height: 100%;
            }

            .results-col {
                width: 100%;
            }
        }
    }
}

.widemap {
    .appt-step-1-results {
        height: 392px;
        border: 0;

        &.slick-slider {
            margin-bottom: 0;
        }

        .result {
            padding: 0 0 25px 0;
            border-bottom: 1px solid #dcdcdb;
            margin-bottom: 20px;

            .inner {
                padding: 0 20px 0 25px;

                > div {
                    float: none;
                }
            }

            .info {
                width: 100%;
                padding: 0;
            }

            .directions-cta {
                padding: 10px 0;
                svg {
                    float: left;
                    margin-right: 5px;
                }
                a {
                    display: inline-block;
                }
            }

            .schedule-cta {
                margin-top: 10px;
                a {
                    width: 100%;
                }
            }
        }
        .result:nth-child(odd) {
            background: none;
        }

        @include media(bp-gt-sm) {
            .result:last-child {
                padding-bottom: 0;
                border-bottom: 0;
                margin-bottom: 0;
            }
        }

        @include media(bp-lt-sm) {
            height: auto;

            .result {
                padding-top: 20px;
                border-bottom: 0;
                border-right: 1px solid #dcdcdb;
                margin-bottom: 0;

                .inner {
                    padding: 0 0 10px 0;
                }
            }
        }
    }
}
