.app-global,
.form-global {
    .fl-input-container {
        display: flex;
        flex-direction: column;
        position: relative;

        .fl-error-msg {
            bottom: -22px;
            left: 10px;
            order: 4;
            font-size: 14px; //15px;
        }

        input:not(:focus):not(.fl-valid):not(.fl-invalid):not(.react-date-picker__inputGroup__input) {
            color: transparent;
        }

        .react-date-picker input {
            color: $dark-gray;
        }
    }

    .fl-input-container input,
    .fl-input-container label,
    .fl-error-msg {
        -webkit-font-smoothing: antialiased;
        text-shadow: none;
    }

    .fl-input-container input,
    .fl-input-container select {
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
        border-radius: 0;
        display: flex;
        font-size: 16px; //100%;
        line-height: 25px;
    }

    .fl-input-label {
        color: #4a4a4b;
        font-weight: normal;
        opacity: 0.9;
        order: 1;
        padding-left: 10px;
        pointer-events: none;
        /* text-transform: capitalize; */
        transform-origin: left top;
        transform: scale(1) translate3d(0, 32px, 0);
        transition: 200ms ease all;
        font-size: 16px;
        line-height: 1em;
    }

    .fl-input-container textarea:focus + label,
    .fl-input-container textarea.fl-valid + label,
    .fl-input-container input:focus + label,
    .fl-input-container input.fl-valid + label,
    .fl-input-container select:focus + label,
    .fl-input-container select.fl-valid + label,
    .fl-invalid + label {
        color: #6e6e6e;
        opacity: 1;
        // transform: scale(0.8) translate3d(0, 5px, 0);
        // transform: scale(0.8) translate3d(3px, 15px, 0);
        transform: scale(0.8) translate3d(3px, 13px, 0);
    }

    .fl-input:active,
    .fl-input:focus,
    .fl-input-label {
        outline: 0;
    }

    .fl-input {
        border: 0;
        border: 2px solid rgba($black, 0.15);
        color: $black;
        flex: 1 1 auto;
        order: 2;
    }

    .fl-input-bar {
        display: block;
        order: 3;
        top: 0;
    }

    .fl-input-bar::after,
    .fl-input-bar::before {
        background: #6e6e6e;
        bottom: 0;
        content: '';
        height: 2px;
        position: absolute;
        transition: 200ms ease all;
        width: 0;
    }

    .fl-input-bar::before {
        left: 50%;
    }

    .fl-input-bar::after {
        right: 50%;
    }

    .fl-input:focus ~ .fl-input-bar::after,
    .fl-input:focus ~ .fl-input-bar::before,
    .fl-invalid ~ .fl-input-bar::after,
    .fl-invalid ~ .fl-input-bar::before {
        width: 50%;
    }

    .fl-input-bar,
    .fl-error-msg {
        position: relative;
        width: inherit;
    }

    .fl-error-msg {
        bottom: 0;
        display: none;
        font-size: 13px;
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
        word-break: break-all;
        word-wrap: break-word;
    }

    .fl-error-show {
        display: inline-block;
    }

    .fl-invalid ~ .fl-input-bar::after,
    .fl-invalid ~ .fl-input-bar::before {
        background: $brand-color-red;
    }

    .fl-input-container .fl-input.fl-invalid + label,
    .fl-error-msg {
        color: $brand-color-red;
    }

    .fl-input.fl-invalid {
        // border: thin solid $brand-color-red;
        border: 2px solid $brand-color-red; // match input border width so form height doesn't jump
    }

    // .fl-invalid ~ .fl-pass-toggle {
    //   bottom: 37%;
    // }

    .fl-pass-toggle {
        position: absolute;
        bottom: -7%;
        right: 2%;
        cursor: pointer;
        width: 32px;
        height: 32px;
    }

    .form-error-message {
        color: $brand-color-red;
        font-weight: bold;
    }

    .form-wrap {
        position: relative;
        margin-top: 14px;

        @include media(bp-gt-sm) {
            flex: 1 35%;
        }

        input,
        select {
            width: 100%;
            outline: 0;
            padding: 10px 8px;
        }

        img {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }

        select {
            background-color: $white;
        }

        // label::before {
        //     content: '';
        //     width: 34%;
        //     max-width: 200px;
        //     display: block;
        //     position: absolute;
        //     bottom: 0;
        //     z-index: -1;
        //     height: 56%;
        //     transform: translateX(-6px);
        //     background-color: transparent;
        // }

        label span {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                bottom: 0;
                left: -6px;
                right: -6px;
                display: block;
                width: calc(100% + 12px);
                height: 60%;
                background-color: transparent;
            }
        }

        input,
        select,
        textarea {
            &.fl-input,
            &:focus {
                // + label::before {
                //     background-color: $white;
                // }

                + label span::before {
                    background-color: white;
                }
            }
        }

        input.fl-input:disabled {
            // + label::before {
            //     background-color: transparent;
            // }

            + label span::before {
                background-color: white;
            }
        }

        ~ .btn.right-float {
            float: right;
            margin-right: 20%;
            margin-top: 10px;
            width: 25%;
        }

        ~ .btn.secondary {
            margin-left: 20%;
            margin-top: 10px;
        }
    }
}
