.vehicle-selector-column {
    @include media(bp-lt-md) {
        height: calc(100% - 150px);
        margin-bottom: 35px;
    }

    .columns {
        display: flex;
        width: 100%;

        @include media(bp-lt-md) {
            flex-wrap: wrap;
        }

        @include media(bp-gt-sm) {
            flex-direction: row;
        }

        @include media(bp-md) {
            width: 650px;
        }

        @include media(bp-gt-md) {
            width: 725px;
        }
    }

    .column {
        background-color: $white;
        border: solid 1px black;

        @include media(bp-lt-md) {
            width: 100%;
            text-align: center;

            label {
                display: block;
                line-height: 2em;
                padding: 0;
                width: 100%;

                &:focus {
                    background-color: $brand-color-blue;
                    color: $white;
                }
            }

            &:not(.active) {
                display: none;
            }

            &:not(.selected) {
                .selected-option {
                    display: none;
                }
            }

            &.selected {
                &:not(:last-child) {
                    border-bottom-color: $white;
                }

                .column-content {
                    display: none;
                }
            }

            &:not(:first-child) {
                border-top: none;
            }
        }

        @include media(bp-gt-sm) {
            flex: 1 25%;
            width: 25%;

            &:not(:first-child) {
                border-left: none;
            }

            &:not(:last-child) {
                border-right: solid 1px #ccc;
            }
        }

        > .fl-input-container.form-wrap {
            margin-top: 0;
        }

        .list {
            height: 450px;
            overflow-y: auto;

            @include media(bp-lt-md) {
                height: 300px;
            }
        }

        .selected-option {
            @include media(bp-lt-md) {
                @include font-title;
                text-transform: uppercase;
                background-color: $brand-color-blue;
                font-size: 14px;
                color: $white;
                margin: 0;
                padding-bottom: 1em;
                padding-top: 1em;
            }

            @include media(bp-gt-sm) {
                display: none;
            }
        }
    }

    .column-title {
        @include font-title;
        margin: 10px 10px 5px 15px;
        text-transform: capitalize;
        font-size: 30px;
        color: black;

        @include media(bp-lt-md) {
            margin-top: 0;
            padding-top: 10px;
        }
    }

    input[type='radio'] {
        display: none;

        + label {
            display: block;
            width: 100%;
            padding: 2px 10px 2px 15px;
            line-height: 2em;

            &:hover {
                background-color: #a5d8f8;
            }
        }

        &:checked + label {
            background-color: $brand-color-blue;
            color: $white;

            &:hover {
                background-color: $brand-color-blue;
            }
        }
    }

    .confirm-message {
        padding: 0 0 10px;
        font-size: 16px;
        max-width: 300px;

        @include media(bp-gt-sm) {
            font-size: 20px;
        }
    }

    // Button Container
    .next {
        float: right;
        margin-top: 20px;
        margin-bottom: 50px;
        //width: 150px;

        .btn {
            padding: 8px 16px;
            border-radius: 4px;
            opacity: 1;
            background: #ef0000 !important;
            border-color: #ef0000 !important;

            &[disabled] {
                background: #888 !important;
                border-color: #888 !important;
            }
        }
        @media only screen and (max-width: 767px) {
            float: none;
            text-align: center;
            .btn {
                width: auto !important;
                border-radius: 4px !important;
            }
        }
    }

    .fl-input-bar {
        display: none;
    }
}

.cmp-zipcode-selection {
    text-align: center;
    border-top: solid 2px $brand-color-red;
    width: 80%;
    margin: auto;
    margin-top: 50px;
    min-height: 230px;
    @media only screen and (max-width: 539px) {
        min-height: 230px;
    }

    &__input-wrap {
        display: inline-block;
        margin: auto;
        width: 100%;
        max-width: 335px;
        @media only screen and (max-width: 539px) {
            min-width: auto;
            max-width: 320px;
        }

        label {
            font-size: 14px;
            text-align: left;
            color: $black;
            display: block;
        }

        input {
            height: 45px;
            border: solid 1px #888;
            width: 100%;
        }
    }
    &__error {
        display: block;
        text-align: left;
        color: $brand-color-red;
        font-size: 12px;
    }

    .heading {
        @media only screen and (max-width: 539px) {
            font-size: 24px;
        }
    }
}

.form--error {
    label {
        color: $brand-color-red;
    }
    input {
        border-color: $brand-color-red;
    }
}

.cmp-pinnacle {
    .column .list {
        height: 230px !important;
    }

    .next {
        position: absolute;
        right: 69px;
        bottom: -26px;

        @media only screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            right: 0;
        }
    }
}
