// should this be part of scaffolding or utils?

.inline-list {
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        display: inline;
    }
}

.large-numbers-list {
    counter-reset: li;

    li {
        position: relative;
        /* Create a positioning context */
        margin: 0;
        /* Give each list item a left margin to make room for the numbers */
        padding: 2px 10px;
        /* Add some spacing around the content */
        list-style: none;
        /* Disable the normal item numbering */

        &:before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            top: 27px;
            left: -1em;
            //width: 32px;
            //margin-right: 8px;
            padding: 4px;
            color: $text-color;
            text-align: center;
            //border-radius: 50%;
            font-size: 32px;
        }
    }
}

.blocklinks {
    margin: 0 2%;
    padding: 0;
    text-align: center;

    @include media(bp-lt-md) {
        margin: 0 -1%;
    }

    li {
        display: inline-block;
        width: 22%;
        border: 1px solid gray;
        padding: 5px 15px;
        margin: 1%;
        text-align: center;
        vertical-align: top;

        a {
            display: block;
            text-decoration: none;
            padding: 5px 15px
        }

        @include media(bp-lt-md) {
            width: 30%;
            padding: 6px 4px;
            line-height: 1em;
        }
    }
}