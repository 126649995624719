@import '~common/scss/shared/shared';

.content-carousel {
    padding: 50px 0 75px;

    .logo {
        text-align: center;
    }

    .title {
        margin: 10px 0;

        span {
            // the title icon
            &:first-child {
                margin-right: 10px;
            }

            @include media(bp-lt-sm) {
                display: block;
                margin: 0;
            }
        }

        @include media(bp-gt-sm) {
            text-align: center;
        }
    }

    .swiper {
        & .swiper-wrapper {
            margin: 40px 0;
        }
        & .swiper-pagination {
            bottom: 0;
        }
    }
    .swiper-slide {
        display: block !important;
    }
    .carousel {
        margin-top: 40px;

        @include media(bp-lt-md) {
            width: 100%;
        }

        @include media(bp-gt-sm) {
            width: 102%;
            margin-left: -1.25%;
        }
    }

    .slick-slide {
        display: flex;
        justify-content: center;

        @include media(bp-gt-sm) {
            padding: 0 1.25%;
        }
    }

    .slide-content {
        display: table;
        width: 100%;
        padding: 0 4%;

        > div {
            display: table-cell;
            vertical-align: top;
        }

        .img-container {
            display: block;
            text-align: center;

            img {
                margin: 0 auto 15px;
            }
        }

        .icon-container {
            box-sizing: content-box;
            width: 55px;
            padding-right: 20px;
        }
    }

    _:-ms-lang(x),
    .slide-content {
        display: inline-block !important;
    }

    .slide-title {
        margin-top: 0;
        line-height: 1em;
        text-transform: none;
    }

    @include media(bp-lt-md) {
        padding: 30px 0 65px;
    }

    @media print {
        .slick-list {
            overflow: visible;
        }

        .carousel {
            width: 100%;
        }
    }
}

/**
 * default is gradient-top for backwards compatability
 */
.content-carousel:not(.no-gradient) {
    @include gradient-top();

    @include media(bp-lt-md) {
        @include gradient-top(30px, #ebebeb, $white);
    }
}
