@use 'sass:math';
@import '~common/scss/shared/shared';
@import 'quote-modal';
@import 'overlay';

$battery-quote-border-light: #dcdcdb !default;
$battery-quote-border-dark: #999 !default;

.battery-quote {
    .container {
        @include media(bp-lt-sm) {
            margin: 0;
        }
    }

    .outer-wrap {
        overflow: hidden;
        padding: 35px 0;
        border: solid 1px $battery-quote-border-dark;

        @include media(bp-lt-sm) {
            border: none;
            padding-top: 10px;
            overflow: inherit;
        }
    }

    .battery-col,
    .contact-col {
        float: left;
        padding-top: 10px;

        @include media(bp-lt-sm) {
            float: none;
            width: 100%;
        }

        .heading {
            margin: 5px 0 10px;
            font-size: 22px;
        }
    }

    .battery-col {
        width: percentage(math.div(705px, 1300px));

        @include media(bp-lt-sm) {
            width: 100%;
            margin-bottom: 35px;
        }

        .inner {
            position: relative;
            width: percentage(math.div(630px, 703px));
            margin: 0 auto;

            @include media(bp-lt-sm) {
                width: auto;
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
            }
        }

        .heading {
            margin: 5px 0 10px 21px;
        }
    }

    .battery {
        overflow: hidden;
    }

    .image-wrap,
    .details {
        float: left;

        @include media(bp-lt-lg) {
            float: none;
        }
    }

    .image-wrap {
        margin-right: percentage(math.div(30px, 598px));
        width: percentage(math.div(245px, 598px));

        @include media(bp-lt-lg) {
            width: 100%;
            margin: 0 0 20px;
            text-align: center;
        }
    }

    .details {
        width: percentage(math.div(323px, 598px));

        @include media(bp-lt-lg) {
            width: 100%;
            text-align: center;
        }

        @include media(bp-lt-sm) {
            margin-bottom: 15px;
        }
    }

    li {
        @include media(bp-lt-lg) {
            text-align: center;
        }

        @include media(bp-lt-sm) {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    ul {
        padding-left: 20px;
        font-size: 20px;

        @include media(bp-lt-lg) {
            padding: 0;
            list-style: none;
            font-size: 16px;
        }

        @include media(bp-lt-sm) {
            margin-bottom: 0;
        }

        &.expand {
            li {
                display: block;
            }
        }
    }

    .more {
        display: none;
        text-align: center;
        font-size: 16px;

        @include media(bp-lt-sm) {
            display: block;
        }
    }

    .table {
        display: table;
        width: 100%;
        margin: 10px 0 15px;
        border-top: solid 1px $battery-quote-border-light;
    }

    .row {
        display: table-row;

        > div {
            text-align: right;
            display: table-cell;
            vertical-align: middle;
            padding: 8px 4% 8px;

            @include media(bp-lt-sm) {
                text-align: left;
            }

            &:first-child {
                position: relative;
                padding-right: 20px;
                border-left: solid 1px $battery-quote-border-light;
                border-right: solid 1px $battery-quote-border-light;
                border-bottom: solid 1px $battery-quote-border-light;
            }

            &:last-child {
                width: 20%;
                border-right: solid 1px $battery-quote-border-light;
                border-bottom: solid 1px $battery-quote-border-light;
                text-transform: uppercase;
                white-space: nowrap;
                text-align: center;

                @include media(bp-lt-sm) {
                    width: 30%;
                    text-align: right;
                }
            }
        }
    }

    .total {
        margin: 0 0 10px;
        text-align: right;

        .label {
            font-size: 20px;
        }

        .amount {
            font-size: 22px;
            padding: 0 15px 0 20px;

            @include media(bp-lt-sm) {
                padding-right: 0;
            }
        }
    }

    .disclaimer {
        padding-left: 40%;
        text-align: right;
        font-size: 12px;

        @include media(bp-lt-sm) {
            padding-left: 20%;
        }
    }

    .contact-col {
        width: percentage(math.div(595px, 1300px));
        border-left: solid 1px $battery-quote-border-light;

        @include media(bp-lt-sm) {
            clear: both;
            width: 100%;
            padding-top: 25px;
            border: none;
        }

        .inner {
            width: percentage(math.div(510px, 594px));
            margin: 0 auto;

            @include media(bp-lt-sm) {
                width: auto;
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
            }
        }

        .desktop {
            @include media(bp-lt-sm) {
                display: none;
            }
        }

        .mobile {
            display: none;

            @include media(bp-lt-sm) {
                display: block;
            }
        }
    }

    .form {
        width: 95%;

        @include media(bp-lt-sm) {
            width: 100%;
        }

        input {
            width: 100%;
            margin-bottom: 10px;
        }

        .col {
            width: 50%;

            @include media(bp-lt-sm) {
                width: 100%;
            }

            &:first-child {
                padding-right: 5px;

                @include media(bp-lt-sm) {
                    padding: 0;
                }
            }

            &:nth-child(2) {
                padding-left: 5px;

                @include media(bp-lt-sm) {
                    padding: 0;
                }
            }
        }
    }

    .ctas {
        margin: 15px 0;

        span {
            padding: 0 10px;

            @include media(bp-lt-lg) {
                display: none;
            }
        }

        .print {
            @include media(bp-lt-lg) {
                display: none;
            }
        }
    }

    .secure {
        overflow: hidden;
        margin-bottom: 25px;

        > div {
            float: left;

            &:last-child {
                width: 78%;
                padding-left: 15px;
                vertical-align: middle;

                @include media(bp-lt-sm) {
                    width: 60%;
                }
            }
        }

        .copy {
            line-height: 4;
        }
    }

    .credit-card {
        overflow: hidden;

        @include media(bp-lt-sm) {
            display: none;
        }

        .footer-tool {
            @include media(bp-lt-lg) {
                width: 100%;
            }

            .image {
                width: 15%;
                margin-right: 2%;
            }

            .content {
                width: 83%;
            }
        }
    }

    .coral-Tooltip {
        max-width: 25em;
        max-height: 40em;
        font-size: 12px;

        ul {
            margin: 0;

            li {
                font-size: 12px;
            }
        }
    }

    .battery-col {
        padding-top: 40px;
    }
}

@media print {
    .battery-quote .contact-col,
    .local-store-map,
    .schedule-cta,
    .ctas,
    .chat,
    .info a:last-child {
        display: none;
    }
    .battery-quote .battery-col,
    .local-store-content {
        display: block;
        width: 100% !important;
    }
}
