.overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    background: $dark-gray;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
    opacity: 0.5;

    &.print-overlay {
        left: 0;
        z-index: 9940;
    }
}
