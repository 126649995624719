.tsr-card-deck {
    // display: flex;
    // flex-wrap: wrap;
    width: 100vw;
    margin-bottom: 15px;
    display: block;

    @include media(bp-gt-sm) {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        margin-bottom: 35px;
    }

    .swiper-slide {
        width: 320px;
    }
}

.tsr-signup {
    width: 100%;
    background-color: $white;
    color: #282828;
    padding: 65px 15px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 24px;

    &__title {
        @include font-title;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    &__info {
        font-size: 16px;
        line-height: 35px;
        margin-bottom: 18px;
    }

    &__underline {
        text-decoration: underline;
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
    }

    &__field {
        display: inline-flex;
        flex-wrap: nowrap;
        margin-bottom: 8px;
        width: 100%;
        max-width: 360px;
        input {
            width: 80%;
            border: 1px solid #e7e7e7;

            @include media(bp-gt-sm) {
                width: 280px;
            }
        }
        button {
            width: 20%;
            border-radius: 5px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            position: relative;
            left: -4px;
            height: 50px;
            font-size: 14px;

            &:focus {
                outline: none;
            }

            @include media(bp-gt-sm) {
                width: 80px;
            }
        }
        @include media(bp-gt-sm) {
            width: 360px;
        }
    }
    &__error {
        color: $brand-color-red;
        font-size: 16px;
        margin: 5px 0;
    }

    &__action {
        color: $brand-color-red;
        text-decoration: underline;
        font-size: 16px;
        line-height: 35px;
    }

    @include media(bp-gt-sm) {
        margin-bottom: 100px;
    }
}
.tsr-no-results {
    width: 100%;
    background: $white;
    text-align: center;
    padding: 25px 0;
    color: #282828;
    border-radius: 8px;
    margin-bottom: 24px;

    svg {
        width: 40px;
        height: 40px;
        fill: #282828;
        margin-bottom: 10px;
    }

    &__title {
        @include font-title;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
    }

    &__info {
        font-size: 18px;
        line-height: 25px;
        margin: 0 auto 16px;
        max-width: 80%;

        @include media(bp-gt-sm) {
            max-width: 700px;
        }
        button {
            border: none;
            text-decoration: underline;
            color: #d01c05;
            background: transparent;
            &:hover {
                color: #6c0f03;
            }
        }
    }

    @include media(bp-gt-sm) {
        padding: 35px 0;
        margin-bottom: 100px;
    }

    &__action {
        &.btn-rounded {
            width: auto;
        }
    }
}

.tsr-stage__container {
    width: 100%;
}
