.app-global {
    // This code is already on _buttons.scss, his objective is unknown and is causing some issues throgh the site.
    // should be removed after some time.
    // .btn:not(.secondary) {
    //     border: 2px solid $brand-color-red;

    //     &:hover,
    //     &:active {
    //         border: 2px solid darken($brand-color-red, 10%);
    //     }
    // }

    .btn.disabled {
        opacity: 0.3;
        pointer-events: none;
        cursor: initial;
    }

    .btn-inline {
        display: inline;
        border: none;
        background: none;
        text-decoration: underline;
        color: $link-color;
        cursor: pointer;
        text-align: left;
        padding: 0;

        &:hover,
        &:active {
            color: $link-hover-color;
        }
    }

    .btn-rounded {
        border-radius: 5px;

        &.btn-secondary {
            border: 3px solid $brand-color-red;
            background-color: transparent;
            color: $brand-color-red;
        }
    }

    .btn-micro {
        min-height: 30px; //height: 30px;
        padding: 7px 12px;
        font-size: 12px;
    }

    .btn-standard {
        display: block;
        width: 100%;
        max-width: 392px;
        min-height: 45px; // height: 45px;
        margin: 0 auto;
    }

    .btn-multiline {
        @include font-title;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        @include media(bp-xs) {
            font-size: 14px;
        }

        span {
            display: block;
            font-size: 12px;

            @include media(bp-xs) {
                font-size: 9px;
            }
        }
    }

    .btn-toggled {
        background-color: #4a4a4a;
        border-color: #4a4a4a;

        &:hover,
        &:active {
            border: 2px solid darken(#4a4a4a, 10%);
        }
    }
}
