@use 'sass:math';
@import '~common/scss/shared/shared';

$footer-font-size: 12px;
$footer-link-color: #666;
$footer-border-color: #dcdcdb;

.site-footer {
    @include gradient-top();

    padding-top: 50px;
    border-top: solid 1px $footer-border-color;

    @include media(bp-lt-sm) {
        padding-top: 10px;
    }

    .container {
        max-width: $wrapper-max-width;
        margin: 0 auto;
        padding: 0 percentage(math.div(70px, $wrapper-max-width)) 60px;
        .bsro-ui-global-nav-v2 & {
            display: none;
        }
    }
}

.footer-heading {
    position: relative;
    text-transform: uppercase;
    font-weight: bold;

    .toggle {
        display: none;

        @include media(bp-lt-sm) {
            display: block;
        }

        a {
            text-decoration: none;
            font-size: 18px;
            line-height: 1;
        }
    }

    .footer__links,
    .footer__tools {
        @include media(bp-gt-sm) {
            display: flex;
            float: left;
        }
    }
}

.footer__links,
.footer-links {
    width: percentage(math.div(995px, 1300px));

    @include media(bp-lt-lg) {
        width: 100%;
    }

    @include media(bp-lt-sm) {
        flex-direction: column;
    }

    ul {
        margin-top: 0;
        padding-left: 0;
        list-style-type: none;
    }

    .footerlinks {
        & .wrap {
            display: block;
        }

        @include media(bp-lt-sm) {
            border-bottom: 1px solid #ccc;

            & .wrap {
                display: none;
            }
        }
    }
}

.footer-heading,
.site-footer a {
    font-size: $footer-font-size;
    color: $footer-link-color;
}

.footer__tools {
    width: percentage(math.div(305px, 1300px));

    @include media(bp-lt-lg) {
        width: 100%;
    }

    .offers-container {
        padding: 0;
    }

    .offers-signup {
        margin: 2px 0px 20px 0px;

        .signup-container {
            h3 {
                font-size: $footer-font-size;
                color: $footer-link-color;
                margin: 0 0 5px 0;
            }
            .form {
                margin-bottom: 7px;

                .btn {
                    @include media(bp-lt-md) {
                        width: auto !important;
                    }
                }
            }
        }

        input {
            width: 88%;
            padding: 5px;
            font-size: 12px;
            @include media(bp-lt-lg) {
                width: 65%;
            }
        }
    }
}

.footer-social {
    display: none;
    overflow: auto;

    div {
        float: left;
        margin-right: 5px;
    }
    a {
        display: block;
        border: solid 1px $footer-border-color;
    }
}

.footer-tool {
    clear: both;
    margin: 0 0 25px;
    @include clearfix;
    //overflow: auto;

    @include media(bp-lt-lg) {
        clear: none;
        float: left;
        width: 33.3333%;
    }

    @include media(bp-lt-sm) {
        float: none;
        width: 100%;
        margin: 0;
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;

        &:last-child {
            border-top: none;
        }

        .wrap {
            display: none;
        }

        &.active {
            padding-bottom: 15px;

            .wrap {
                display: block;
            }
        }
    }

    .footer-heading {
        margin-bottom: 5px;

        @include media(bp-lt-sm) {
            margin-bottom: 0;
            padding: 15px 0;
            font-size: 13px;

            .toggle {
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                line-height: 4;
            }
        }
    }

    .image,
    .content {
        float: left;
    }

    .image {
        width: percentage(math.div(70px, 305px));
        margin-right: percentage(math.div(15px, 305px));
    }

    .content {
        width: percentage(math.div(220px, 305px));
        font-size: $footer-font-size;
    }

    p {
        margin: 5px 0;
    }
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px $footer-border-color;
    width: 100%;
    margin-top: 20px;
    padding-top: 40px;

    .col {
        width: auto;
    }

    .col:last-child {
        display: flex;
        justify-content: flex-end;
    }

    .inline-list {
        width: 100%;
        p {
            // AEM puts a p around the links
            display: inline;
        }
    }

    li,
    a,
    .copyright {
        font-size: $footer-font-size - 2;
        color: $footer-link-color;
        line-height: 2;
    }

    li {
        margin-right: 25px;
        white-space: nowrap;
    }

    .copyright {
        padding-top: 1px;
    }

    .footer-bbb,
    .footer-logo {
        text-align: right;
    }

    .footer-bbb {
        min-width: 116px;
        padding-top: 6px;

        img {
            width: 96px;
        }
    }

    @include media(bp-lt-sm) {
        position: relative;
        flex-wrap: wrap;
        justify-content: flex-start;
        border-top: none;
        padding-bottom: 50px;

        .col:first-child {
            flex: 0 0 100%;
            padding-bottom: 20px;
        }

        .col:last-child {
            justify-content: flex-start;
        }

        .copyright {
            position: absolute;
            bottom: 0;
            right: 0;
            text-align: right;
            font-size: 13px;
            line-height: 1.2;
        }

        .all-rights {
            display: block;
        }

        .footer-bbb,
        .footer-logo {
            text-align: left;
        }
    }
}

.footer-social-links-container {
    & > .container {
        padding: 0;

        .image-lazy-load {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin: 5px 3px 0 0;

            a {
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 539px) {
    .footer-heading:last-child {
        border-top: none;
    }

    .footerlinks .footer-heading {
        margin-bottom: 0;
        padding: 15px 0;
        font-size: 13px;
    }

    .footer__links .footer-heading .toggle,
    .footerlinks .footer-heading .toggle {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 4;
    }

    .footer__links .footer-heading:last-child {
        border-top: none;
    }

    .footer__links .footer-heading {
        float: none;
        width: 100%;
        margin: 0;
        border-bottom: solid 1px #ccc;
    }
}

.footer__links ul {
    margin-top: 0;
    padding: 4px;
    list-style-type: none;
}

.footer__links .footer-heading .toggle a {
    text-decoration: none;
    font-size: 18px;
    line-height: 1;
}

.footer-heading a {
    color: #d81d05;
}

@media only screen and (max-width: 767px) {
    .footer-tools .offers-signup .signup-container .form .btn {
        width: auto !important;
    }
}

.footer-tools .offers-signup {
    margin: 2px 0 20px 0;
}

.footer-bottom {
    flex-direction: column;
}

.footer-links,
.footer-tools {
    float: left;
}

.footer-links {
    width: percentage(math.div(995px, 1300px));

    @include media(bp-lt-lg) {
        width: 100%;
    }

    .col {
        width: 33%;

        @include media(bp-lt-sm) {
            width: 100% !important;
        }
    }

    ul {
        margin-top: 0;
        padding-left: 0;
        list-style-type: none;
    }
}

.footer-tools {
    width: percentage(math.div(305px, 1300px));

    @include media(bp-lt-lg) {
        width: 100%;
    }

    .offers-container {
        padding: 0;
    }

    .offers-signup {
        margin: 2px 0px 20px 0px;

        .signup-container {
            h3 {
                font-size: $footer-font-size;
                color: $footer-link-color;
                margin: 0 0 5px 0;
            }
            .form {
                margin-bottom: 7px;

                .btn {
                    @include media(bp-lt-md) {
                        width: auto !important;
                    }
                }
            }
        }

        input {
            width: 88%;
            padding: 5px;
            font-size: 12px;
            @include media(bp-lt-lg) {
                width: 65%;
            }
        }
    }
}
