// Font specific mixins. Don't use directly
@mixin ultramagnetic {
    font-family: 'Ultramagnetic', Arial, sans-serif !important;
}

@mixin avenir-heavy {
    font-family: 'AvenirHeavy', Arial, sans-serif !important;
}

@mixin avenir {
    font-family: 'Avenir', Arial, sans-serif !important;
}

// Context specific mixins. Use these!
@mixin font-title {
    @include ultramagnetic;
}

@mixin font-body {
    @include avenir;
}

@mixin font-body-bold {
    @include avenir-heavy;
}
