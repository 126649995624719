/* Product Result Card */
/* @include font-title; */
/* @include font-body; */
/* @include font-body-bold; */

$skeleton-bg-color: #dcdcdc;

@mixin skeleton-bg {
    background-color: $skeleton-bg-color;
    animation-name: skeleton-keyframes;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.result-card {
    /* min-width: 320px; */

    .top {
        padding: 0;
        margin: 0 0 28px 0;

        .corner {
            .corner-text {
                font-size: 14px;
                font-weight: bold;
                top: 15px;
            }
        }
    }

    &.price-sale {
        &.with-rebate {
            .rebate {
                display: block !important;

                &.rear {
                    display: none !important;
                }
            }
        }

        &.with-rebate-rear {
            .rebate {
                &.rear {
                    display: block !important;
                }
            }

            .price-rear-tire {
                .price-no-discount {
                    display: block;
                }
            }
        }

        &.with-coupon {
            .coupon {
                display: block !important;

                &.rear {
                    display: none !important;
                }
            }
        }

        &.with-coupon-rear {
            .coupon {
                &.rear {
                    display: block !important;
                }
            }
        }

        .corner {
            display: block;
        }
    }

    .brand-badge {
        float: left;
        margin: 0 5px 0 0;
    }

    .brand-info {
        overflow: hidden;

        .brand-img {
            height: auto;
            margin-bottom: 8px;
        }

        h4 {
            margin: 0;

            &.product-name {
                font-size: 20px;
            }

            &.product-id,
            &.size {
                font-size: 14px;
                color: $dark-gray;
            }
        }
    }

    .details {
        margin: 0 0 10px 0;

        @include media(bp-lt-md) {
            display: inline-block;
        }

        .tire-image {
            float: left;
            max-height: 160px;
            margin: 0 48px 5px 14px;

            @include media(bp-lt-md) {
                width: 116px;
                height: 162px;
            }
        }

        .compare-checkbox {
            float: left;
            clear: both;
            margin-top: 12px;

            label {
                display: table;
                font-size: 13px;
                font-weight: normal;
                text-transform: uppercase;

                span {
                    font-size: 13px;
                    display: table-cell;
                    vertical-align: middle;
                }

                // &:before {
                // 	padding: 6px;
                // }
            }
        }

        .pricing-specs {
            overflow: hidden;

            .pricing {
                margin: 0 0 10px;
                padding: 0 0 10px;
                border-bottom: 1px dotted black;

                .front-tire,
                .rear-tire {
                    font-size: 14px;
                }

                .front-rear-text {
                    font-size: 11px;

                    .front-text,
                    .rear-text {
                        font-size: 11px;
                        color: $text-color;
                        margin-left: 5px;
                    }

                    @media screen and (max-width: 1400px) {
                        display: block;
                    }
                }

                sup {
                    font-size: 14px;
                    top: -11px;
                }

                .each {
                    font-size: 20px;
                }

                .details {
                    font-size: 14px;
                    color: #666;
                    line-height: 1.5em;
                }
            }

            .specs {
                ul {
                    margin: 0;
                    padding: 0;
                }

                li {
                    list-style-type: none;
                }
            }
        }

        .availability {
            display: block;
            font-weight: normal;
            margin: 0;

            .desktop {
                @include media(bp-lt-md) {
                    display: none;
                }
            }

            .mobile {
                display: none;

                @include media(bp-lt-md) {
                    display: inline-block;
                }
            }
        }
    }

    .bottom {
        .cta {
            .button-script {
                margin: 0;
            }

            .script {
                font-size: 12px;
                color: #666;

                @include media(bp-lt-md) {
                    font-size: 10px;
                }
            }
        }

        .btn {
            width: 100%;
            font-size: 18px;
            padding-right: 5px;
            padding-left: 5px;
        }

        .coupon,
        .rebate {
            padding: 12px;
            border: 1px dashed $brand-color-blue;
            background: #f2f8fc;
        }

        .coupon > span,
        .rebate > span {
            position: relative;
            margin-left: 30px;

            &:before {
                @include icon(coupon, $brand-color-blue);

                position: absolute;
                left: -34px;
                top: -8px;
            }
        }
    }

    .price-front-discount,
    .price-rear-discount {
        .price {
            .retail-price {
                text-decoration: line-through;
                color: $dark-gray;
                font-size: 24px !important;

                .each {
                    color: $dark-gray;
                    font-size: 17px;
                }
            }
        }
    }

    .price-no-discount {
        .details {
            @media screen and (max-width: 1250px) {
                br {
                    display: none;
                }
            }
        }
    }

    .price {
        .retail-price,
        .discounted-price {
            font-size: 28px !important;
            line-height: 42px;
        }
    }

    &.without-price {
        .bottom {
            display: none;
        }
    }

    .product-specs {
        span[class^='icon'] {
            float: left;
            margin: -3px 6px 0 0;
        }

        li > p {
            overflow: hidden;
        }
    }

    .firestone-badge {
        width: 34px;
        height: 36px;
        background: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-images/v1/resources/icon/badge.png') no-repeat
            0 -30px;
    }

    .bridgestone-badge {
        width: 34px;
        height: 30px;
        background: url('/etc.clientlibs/bsro/common/clientlibs/clientlib-images/v1/resources/icon/badge.png') no-repeat
            0 0;
    }

    &.with-price,
    &.without-price,
    &.with-hidden-price,
    &.with-coupon,
    &.with-rebate,
    &.price-sale,
    &.price-0,
    &.price-1,
    &.price-2,
    &.price-3 {
        .wait {
            display: none;
        }

        &.get-quote {
            .wait {
                display: block;

                .message {
                    .load-price {
                        display: none;
                    }

                    .load-quote {
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.inactive {
        // .wait not necessary with skeleton
        i,
        .wait,
        .corner,
        .price-front-tire,
        .price-rear-tire,
        .reviews-app,
        .reviews-inline-rating,
        .reviews {
            display: none;
        }

        .btn {
            pointer-events: none !important;
        }

        .reviews-summary {
            display: none;
        }

        .brand-info {
            .brand-img {
                display: none;
            }

            .product-name {
                @include skeleton-bg;

                a {
                    color: transparent;
                }

                .required {
                    display: none;
                }
            }

            h4.size {
                @include skeleton-bg;
                color: transparent;
            }

            h4.product-id {
                @include skeleton-bg;
                color: transparent;

                & + .product-id {
                    display: none;
                }
            }
        }

        .details {
            min-height: 250px;

            .pricing {
                @include skeleton-bg;
                min-height: 100px;
                border: 0;

                * {
                    display: none;
                }
            }

            .hide-price,
            .no-price {
                display: none;

                * {
                    display: none;
                }
            }

            .tire-image {
                @include skeleton-bg;
                background-color: transparent;
                object-position: -500px -500px;
                //         background-image: svg-url(
                //             '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                // 		width="100px" height="155.984px" viewBox="0 0 100 155.984">
                //    <path fill="' +
                //                 $skeleton-bg-color +
                //                 '" d="M48.566,0c-2.997,0-14.273,0-17.269,0C14.012,0,0,34.915,0,77.988s14.012,77.989,31.297,77.989
                // 	   c3.021,0,14.297-0.046,17.269-0.002c43.673,0.667,50.682-37.388,51.434-77.986C96.746,25.2,83.725,0,48.566,0z"/>
                //    </svg>'
                //         );
                background-image: svg-url(
                    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="155.984px" viewBox="0 0 100 155.984"><path fill="#dcdcdc" d="M48.566,0c-2.997,0-14.273,0-17.269,0C14.012,0,0,34.915,0,77.988s14.012,77.989,31.297,77.989c3.021,0,14.297-0.046,17.269-0.002c43.673,0.667,50.682-37.388,51.434-77.986C96.746,25.2,83.725,0,48.566,0z"/><ellipse fill="#d1d1d1" cx="31.438" cy="78" rx="31.438" ry="77.5"/><ellipse fill="#bcbcbc" cx="23.5" cy="77.997" rx="19.5" ry="52.331"/></svg>'
                );
                background-repeat: no-repeat;
                min-width: 100px;
                min-height: 156px;
            }

            .product-specs li {
                @include skeleton-bg;
                color: transparent;

                span {
                    display: none;
                }

                a {
                    color: transparent;
                }
            }

            .custom-checkbox label {
                span {
                    color: transparent;
                    @include skeleton-bg;
                }

                &:before {
                    border: 2px solid transparent;
                    @include skeleton-bg;
                }
            }
        }

        .bottom {
            .cta {
                @include skeleton-bg;
                height: 45px;
                width: 100%;
            }

            .btn {
                opacity: 0;
            }
            // .tire-quote-btn {
            //     background-color: #ccc;
            //     color: transparent;

            //     span {
            //         color: transparent;
            //     }
            // }

            // .quote-annex-cta {
            //     @include skeleton-bg;

            //     .btn {
            //         opacity: 0;
            //     }
            // }

            .cta .script {
                display: none;
                // opacity: 0;
                // color: transparent;
            }
        }
    }

    @keyframes skeleton-keyframes {
        0% {
            // background-color: rgba($skeleton-bg-color, 1);
            opacity: 1;
        }

        50% {
            // background-color: rgba($skeleton-bg-color, 1);
            opacity: 1;
        }

        75% {
            // background-color: rgba($skeleton-bg-color, 0.25);
            opacity: 0.25;
        }

        100% {
            // background-color: rgba($skeleton-bg-color, 1);
            opacity: 1;
        }
    }

    /*
	.wait {
		position: absolute; 
		top: 0; 
		right: 0; 
		bottom: 0; 
		left: 0; 
		background: rgba(255,255,255,0.8);
		z-index: 999;  
		
		.coral-Wait {
			display: inline-block;
			background-repeat: no-repeat; 
		    background-size: 100%;
		    vertical-align: middle;
		    
			animation: alternate 1s fadeInOut infinite;
			
			@keyframes fadeInOut { 
			  from { 
			    opacity: 1;  
			  } to { 
			    opacity: 0;  
			  }
			}

		}
		
		.message {
			position: absolute; 
			top: 50%;
			left: 0%; 
			width: 100%; 
			
			text-transform: uppercase; 
			text-align: center; 
			font-size: 16px;   
			color: #666; 	
			margin: 5px 0; 
			
			-webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		    font-weight: bold;						
			
			.load-price {
				display: inline-block; 
			}
			
			.load-quote {
				display: none; 
			}
		}
	}
	*/
}
