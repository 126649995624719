@import '~common/scss/shared/shared';

.refresh-modal {
    position: fixed;
    z-index: 9999;
    background: #0000008c;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &--visible {
        position: relative;
        overflow: hidden;
    }

    &__overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__wrapper {
        position: fixed;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        background: $white;
        border-radius: 18px;
        min-width: 570px;
        width: auto;
        border: 1px solid #cccccc;
        padding: 50px 0 40px;

        @include media(bp-xs) {
            min-width: auto;
            height: 100%;
            width: 100%;
            border: none;
            border-top: 1px solid #ccc;
            top: 0;
            left: 0;
            transform: translateX(0);
            border-radius: 0;
        }
    }

    &__content {
        max-width: 570px;
        margin: 0 auto;
        width: auto;
    }

    &__close {
        background-color: transparent;
        border: none;
        color: transparent;
        position: absolute;
        width: 20px;
        height: 17px;
        margin: 0;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        top: 20px;
        right: 20px;
        z-index: 999;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 7px;
            right: 0;
            width: 20px;
            height: 2.6px;
            background-color: #ccc;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }

        &:hover::before {
            background-color: $black;
        }

        &:hover::after {
            background-color: $black;
        }
    }
}
