$bg-gray: #eeeeee;

.offers-signup {
    margin: 30px 0;

    &__container {
        position: relative;
        margin: 0 auto;
        padding: 0 4.86111%;
        max-width: 1355px;
        display: flex;
        
        @include media(bp-xs) {
            flex-direction: column;
        }
    }

    &__left-col,
    &__right-col {
        flex: 0 0 50%;
        overflow: hidden;
    }

    &__left-col {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        
        @include media(bp-lt-lg) {
            height: 270px;
        }

        @include media(bp-xs) {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
        }
    }

    &__right-col {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        &.background-gray {
            background: $bg-gray;
        }

        &.background-black {
            background: $black;
        }

        &.background-white {
            background: $white;
        }

        @include media(bp-lt-xl) {
            padding: 0 50px;
        }

        @include media(bp-lt-lg) {
            padding: 0 20px;
        }

        @include media(bp-xs) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 0;
            padding: 20px 10px;
        }
    }

    &__title {
        font-size: 24px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        line-height: 30px;
        margin-top: 0;

        @include media(bp-lt-xl) {
            font-size: 20px;
        }

        @include media(bp-lt-lg) {
            font-size: 17px;
            line-height: 20px;
        }
        
        @include media(bp-xs) {
            font-size: 18px;
            text-align: center !important;
            margin-bottom: 20px;
        }
    }

    &__privacy {
        font-size: 16px;
        margin-top: 15px;
        color: $black;

        @include media(bp-lt-md) {
            font-size: 13px;
        }
        
        @include media(bp-xs) {
            font-size: 15px;
            text-align: center;
        }
    }

    &__form {
        @include media(bp-xs) {
            width: 85%;
            margin: 0 auto;
        }
    }

    &__btn {
        border-radius: 4px;
        padding: 12px 16px 13px;
        margin: 0;
        position: relative;
        top: 2px;
        left: -7px;

        @include media(bp-lt-md) {
            position: static;
            padding: 8px 16px;
            margin-top: 5px;
        }
    }

    &__input {
        padding: 14px 13px;
        border: 1px solid #e8e8e8;
        border-radius: 1px;
        width: 55%;

        @include media(bp-lt-md) {
            width: 100%;
            padding: 14px 13px;
        }
    }

    &__image {
        width: 100%;

        @include media(bp-lt-lg) {
            height: 100%;
            object-fit: cover;
        }
    }

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
        font-weight: 500;
        color: $black;
        font-size: 15px;
    }
}