.overview-thumbnail-item {
    display: table;

    .item-img {
        display: table-cell;
        width: 140px;
        vertical-align: top;

        img {
            margin: 0 auto;
        }
    }

    .item-copy {
        display: table-cell;
        vertical-align: top;
    }

    .item-title {
        margin: 0.5em 0;
        line-height: 1em;
    }

    .item-subtitle {
        margin: 0;
        text-transform: none;
        // font-weight: bold;
    }

    p {
        margin-top: 0;
        line-height: 1.2em;
    }

    @include media(bp-lt-md) {
        display: block;

        .item-img {
            display: block;
            width: 100%;
            text-align: center;
        }

        .item-copy {
            display: block;
        }

        .item-title {
            margin-top: 1em;
        }
    }
}
