@import '~common/scss/shared/shared';
@import '~common/scss/shared/breakpoints';
@import '~fcac/scss/shared/fonts';
@import '~common/components/content/react-shared/components/product-price/product-price';

.tires-near-you {
    &.tsr {
        background-color: #e1e3e3;
        padding: 0 30px 30px 30px;
        .tsr-card {
            border: 2px solid transparent;
            padding: 0;
            &__offer-tag {
                display: none;
            }
            &__brand-icon {
                &--suredrive {
                    width: 107px;
                }
                &--toyo {
                    width: 130px;
                }
                min-height: 25px;
                left: 13px;
                top: 13px;
                width: 25px;
                img {
                    width: 100%;
                }
            }
            &__brand {
                font-size: 9pt;
                line-height: 1;
                margin-bottom: 3px;
                margin-top: 1pc;
                text-align: center;
                text-transform: uppercase;
                @include font-body;
            }
            &__tire-name {
                @include font-title;
                font-size: 21px;
                line-height: 30px;
                margin-bottom: 5px;
                text-align: center;
            }
            &__tire-image {
                height: 90px;
                overflow: hidden;
                text-align: center;
                border-bottom: 1px solid #d4d4d4;
            }
            &__reviews {
                border-bottom: 1px solid #d4d4d4;
                text-align: center;
                width: 100%;
                padding: 16px 0;
                .reviews-v2 {
                    .reviews-inline-rating {
                        margin: 0;
                        padding: 0;
                        flex-direction: row;
                        justify-content: center;
                    }
                    .write-review-first {
                        .write-a-review-button {
                            .btn-inline {
                                background-color: $white;
                                color: #4a4a4a;
                                border: none;
                                font-size: 14px;
                                text-align: center;
                                @include font-body;
                                text-transform: unset;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                p {
                    margin: 0;
                }
            }
            &__mileage {
                font-size: 14px;
                margin-bottom: 24px;
                text-align: center;
                padding: 16px 0;
                border-bottom: 1px solid #d4d4d4;
                @include font-body;
            }
            .btn {
                width: 100%;
                padding: 5px 0;
                border-radius: 5px;
                margin-top: auto;
                font-size: 16px;
                line-height: 16px;
            }
            &__pricing {
                @include product-price;
                margin-bottom: 24px;
                .product-price {
                    &__price {
                        sup {
                            margin-left: -5px;
                        }
                        &-or {
                            margin: 0 5px;
                        }
                    }
                    &__offer-icon {
                        position: absolute;
                        top: 2rem;
                        right: 2rem;
                    }
                    &__signin-only-link {
                        text-transform: unset !important;
                        &:hover {
                            color: $black !important;
                            border: none !important;
                        }
                    }
                }
            }
            .tsr-card-wrapper {
                height: 100%;
            }
            &:hover {
                border: 2px solid transparent;
            }
        }
        .tsr-card-content {
            max-width: 310px;
            margin: 0 auto;
            background-color: $white;
            border-radius: 10px;
            padding: 20px;
            margin: 0 auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            .cq-LinkChecker {
                &.cq-LinkChecker--prefix {
                    width: 20px;
                    height: 11px;
                }
                &.cq-LinkChecker--suffix {
                    width: 3px;
                    height: 11px;
                }
            }
        }
        .swiper {
            .swiper-wrapper {
                padding: 40px 0 10px 0;
                align-items: stretch;
                .swiper-slide {
                    height: auto;
                }
            }
            .swiper-scrollbar {
                @include media(bp-gt-md) {
                    display: none;
                }
            }
        }
    }
    .tires-near-you-title-bg {
        background-color: #e1e3e3;
        .container {
            padding-bottom: 0 !important;
            .copy {
                p {
                    &:nth-child(2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .cta {
        a.btn {
            border-radius: 4px;
            border: 2px solid $brand-color-red !important;
            color: $brand-color-red;
            background: $white;
            @include media(bp-lt-lg) {
                margin-top: 30px;
            }
            &:hover {
                color: $brand-color-red;
                background: $white;
            }
        }
    }
}
/* specific to local pages css */
.local-seo-page {
    .tires-near-you {
        &.tsr {
            .swiper {
                .swiper-wrapper {
                    padding: 40px 0;
                }
            }
        }
    }
}
