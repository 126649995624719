@use "sass:math";
@import '~common/scss/shared/shared';

$border-color-grey: #dcdcdb;

.store-locator {
    .container {
        .wrap {
            background: linear-gradient(to top, #eee, transparent 150px);
            border: 1px solid $border-color-grey;
            padding: 35px 0;
        }

        .wrap-inner {
            @include clearfix;

            width: percentage(math.div(1150px, 1300px));
            margin: 0 auto;

            @include media(bp-lt-sm) {
                width: 100%;
            }

            .col {
                float: left;
                width: 50%;
                padding-right: percentage(math.div(30px, 573px));

                .fields {
                    > div {
                        padding-bottom: 10px;

                        > label {
                            display: block;
                            width: 50%;
                        }

                        > input,
                        .custom-select {
                            width: 65%;
                        }
                    }
                }

                .sub-heading {
                    font-size: 16px;
                }

                .store-details {
                    padding-top: 10px;

                    span,
                    a {
                        display: block;
                        padding-bottom: 5px;
                    }

                    .holiday {
                        color: $brand-color-red;
                    }

                    .store-zip {
                        color: $text-color;
                        text-decoration: none;
                        display: inline-block;
                        cursor: text;
                    }
                }

                .inline {
                    display: inline;
                    padding: 0 10px;
                }

                span {
                    display: block;
                    padding-bottom: 5px;
                }

                @include media(bp-lt-sm) {
                    width: 100%;
                    padding: 0;

                    .inner {
                        padding: 0 5%;
                    }
                }
            }

            .directionsPanel {
                width: 100%;
            }
        }
        .map-col {
            margin: 20px 0 0;
            float: left;
        }
        .map-content {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 500px;
            margin: 20px 0 0;
        }
        #myMap_error {
            display: none;
            color: $red;
        }
    }
}

.direction-message {
    font-size: 12px;
    padding-top: 5px;
    color: $red !important;
}

@media print {
    html,
    body,
    .store-locator .container .wrap {
        background: $white !important;
    }
}
