@import '~common/scss/shared/shared';
@import 'vehicle-selector';

.lid-content__no-tire__store {
    button {
        border: none;
        text-decoration: underline;
        color: $brand-color-red;
        background: transparent;
        &:hover {
            color: #6c0f03;
        }
    }
}
