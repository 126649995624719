@import '~common/scss/shared/shared';

/** defaults */
.title-text-cta {
    height: 100%;

    > .container {
        display: flex;
        height: 100%;
        padding: 50px 0;
    }

    .image {
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    .title {
        margin-bottom: 10px;
    }

    &.hidden {
        display: none;
    }

    &.seo-offers {
        .container {
            .copy {
                div {
                    p {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.title-text-cta a.tile-link {
    width: 100%;
}

.title-text-cta[class*='padding-'] {
    h2,
    h3 {
        margin: 0 auto 0.5em auto;
    }
}

.title-text-cta.padding-collapsed {
    > .container {
        padding: 0;
    }
}

.title-text-cta.padding-narrow {
    > .container {
        padding: 8px;
    }
}

.title-text-cta.padding-medium {
    > .container {
        padding: 20px;
    }
}

.title-text-cta.padding-wide {
    > .container {
        padding: 40px;
    }
}

.title-text-cta.dark-background {
    .copy,
    h2,
    h3 {
        color: $white;
    }
}

.title-text-cta.background-gray {
    background: $light-gray;
}

.title-text-cta.background-gray-light {
    background: #eff1f1;
}

.title-text-cta.gradient-top {
    @include gradient-top();
}

.title-text-cta.gradient-bottom {
    @include gradient-bottom();
}

.title-text-cta.large-title {
    h2 {
        font-size: 42px;
    }
    h3 {
        font-size: 32px;
    }
}

.title-text-cta.huge-title {
    h2 {
        font-size: 70px;
    }
    h3 {
        font-size: 42px;
    }
}

.title-text-cta.image-twenty {
    .image {
        width: 20%;
    }
    .copy {
        width: 80%;
    }
}

.title-text-cta.image-thirty {
    .image {
        width: 30%;
    }
    .copy {
        width: 70%;
    }
}

.title-text-cta.image-forty {
    .image {
        width: 40%;
    }
    .copy {
        width: 60%;
    }
}

.title-text-cta.image-fifty {
    .image {
        width: 50%;
    }
    .copy {
        width: 50%;
    }
}

.title-text-cta.image-sixty {
    .image {
        width: 60%;
    }
    .copy {
        width: 40%;
    }
}

.title-text-cta.image-seventy {
    .image {
        width: 70%;
    }
    .copy {
        width: 30%;
    }
}

.title-text-cta.image-center {
    > .container {
        flex-direction: column;
    }
    .copy {
        width: 100%;
    }
}

.title-text-cta.image-cover {
    .image {
        width: auto;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            width: 100%;
        }
    }
    .copy {
        width: auto;
    }
}

.title-text-cta.image-cover-clip {
    .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
        }
    }
    .copy {
        width: 100%;
    }
}

.title-text-cta.image-left {
    .copy {
        padding-left: 16px;
    }
}

.title-text-cta.image-right {
    .image {
        order: 2;
    }
    .copy {
        padding-right: 16px;
        order: 1;
    }
}

.title-text-cta.valign-center {
    > .container {
        align-items: center;
    }
}

.title-text-cta.valign-bottom {
    > .container {
        align-items: flex-end;
    }
}

@include media(bp-lt-sm) {
    .title-text-cta > .container {
        padding: 6.5vw 0;
    }

    .title-text-cta {
        h2,
        h3 {
            text-align: center;
        }
    }
    .title-text-cta.large-title {
        h2 {
            font-size: 5.5vw;
        }
        h3 {
            font-size: 4.2vw;
        }
    }

    .title-text-cta.huge-title {
        h2 {
            font-size: 9vw;
        }
        h3 {
            font-size: 5.5vw;
        }
    }
}

.blog {
    .title-text-cta {
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;

        h2 {
            @include font-body-bold;
        }

        .title {
            text-transform: capitalize;
            margin-bottom: 22px;
            font-size: 2em;
        }

        .btn {
            @include font-body-bold;
            font-size: 1em;
            padding: 8px 15px;
            border-radius: 3px;
        }

        .copy {
            padding: 16px;
        }
    }

    .fifty-fifty-layout {
        padding-top: 40px;
        padding-bottom: 40px;

        @include media(bp-lt-md) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
