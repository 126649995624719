@import '~common/scss/shared/shared';
$offer-gray-color: #d6d6d6;

.offer-details {
    &__wrapper {
        .container {
            @include media(bp-xs) {
                margin: 0;
            }
        }
    }

    &__header {
        padding: 50px 0 70px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(bp-xs) {
            padding: 30px 0;
            margin: 0 20px;
        }

        @media print {
            display: none;
        }
    }

    &__header-title {
        margin: 0 0 10px;
        font-size: 40px;

        @include media(bp-xs) {
            width: 100%;
            font-size: 30px;
        }
    }

    &__header-ctas,
    &__modal-ctas {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__modal-ctas {
        padding: 12px 20px;

        a {
            margin: 0 5px;
            width: 100%;
        }
    }

    .offerSMSBtn {
        margin: 0 10px 0 0;
    }

    &__cta-phone,
    &__cta-print {
        @include media(bp-xs) {
            flex: 0 0 49%;
            padding: 15px 30px;
        }
    }

    &__container {
        position: relative;
        background-color: #000000b8;
        display: flex;
        justify-content: center;

        @media print {
            background-color: $white;
        }

        &.native-app {
            background-color: $white;
        }
    }

    &__cover-image {
        position: absolute;
        opacity: 0.5;
        object-fit: cover;
        height: 100%;
        left: 50%;
        transform: translatex(-50%);
        top: 0;

        @media print {
            display: none;
        }
    }

    &__offer {
        position: relative;
        max-width: 570px;
        z-index: 1;
        background: $white;

        &--with-spacing {
            margin: 40px 20px;
            padding: 50px 0;
            border-radius: 15px;
            border: 1px solid $offer-gray-color;
        }

        @include media(bp-xs) {
            margin: 20px;
        }
    }

    &__offer-top {
        display: flex;
        padding: 0 30px 50px;

        @include media(bp-xs) {
            padding-bottom: 20px;
            flex-flow: column-reverse;
            align-items: center;
        }
    }

    &__offer-header {
        width: 355px;

        @include media(bp-xs) {
            text-align: center;
        }
    }

    &__offer-title,
    &__offer-subtitle {
        margin: 0;
        font-size: 22px;

        @include media(bp-xs) {
            font-size: 16px;
        }
    }

    &__offer-subtitle1,
    &__offer-subtitle2 {
        font-size: 17px;
    }

    &__offer-subtitle {
        margin-bottom: 10px;
    }

    &__offer-price {
        margin: 10px 0;
        color: #d31a0b;
        font-size: 45px;
        max-height: 95px;
        overflow: hidden;

        &--desktop {
            display: block;
        }

        &--mobile {
            display: none;
        }

        @include media(bp-xs) {
            font-size: 30px;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: block;
            }
        }
    }

    &__offer-summary {
        text-transform: capitalize !important;
        font-size: 15px;
        @include font-body;
        margin: 0;
        line-height: 20px !important;
        width: 80%;

        p {
            margin: 0;
        }

        @include media(bp-xs) {
            width: 100%;
            margin-top: 10px;
            font-size: 13px;
        }
    }

    &__offer-image {
        img {
            height: 100%;
            max-height: 135px;

            @include media(bp-xs) {
                max-height: 90px;
                margin-bottom: 20px;
            }
        }

        &:before {
            content: '';
            position: absolute;
            right: 36px;
            top: 6px;
            width: 115px;
            height: 121px;
            background: #e3e3e3;
            border-radius: 50%;
            z-index: -1;

            @include media(bp-xs) {
                left: 40%;
                top: 5px;
                width: 84px;
                height: 84px;
            }
        }
    }

    &--refresh {
        & .offer-details__offer-image {
            &:before {
                content: '';
                position: absolute;
                right: 35px;
                top: 58px;
                width: 118px;
                height: 121px;
                background: #e3e3e3;
                border-radius: 50%;
                z-index: -1;

                @include media(bp-xs) {
                    left: 40%;
                    top: 51px;
                    width: 84px;
                    height: 84px;
                }
            }
        }
    }

    &__offer-validity {
        text-align: center;
        font-style: italic;
        padding: 8px 0;
        border-top: 1px solid $offer-gray-color;
        border-bottom: 1px solid $offer-gray-color;
        color: $black;
    }

    &__offer-validity-icon {
        height: 24px;
        position: relative;
        top: -1px;
    }

    &__highlight {
        background-color: #eaeaea;
        padding: 20px 30px;
    }

    &__highlight-title,
    &__body-title {
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        color: $black;
        @include font-body-bold;
        display: block;
    }

    &__highlight-summary {
        @include font-body;
        font-size: 25px;
        color: $black;
        line-height: 30px;
        padding: 7px 0;

        p {
            font-size: 20px !important;
            line-height: 1em;
            margin: 10px 0;
        }

        span {
            font-size: 20px !important;
            line-height: 1em;
        }
    }

    &__highlight-link {
        font-size: 16px;
    }

    &__body {
        padding: 25px 30px 40px;
        color: #434343;
    }

    &__body-validity {
        margin-top: 15px;
        display: block;
    }

    &__body-copy {
        margin: 15px 0;

        p {
            margin: 0;
        }
    }
}

// Offer share modal styles
.coral-Modal.offer-modal {
    background: transparent;
    border: none;
}

.offer-modal {
    &__body {
        position: relative;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        background: $white;
        max-width: 570px;
        width: 100%;
        padding: 0;

        &--success {
            padding: 0 20px;
        }
    }
    &__title {
        text-align: center;
        margin: 0;
        padding: 0 20px;

        @include media(bp-xs) {
            font-size: 27px;
        }
    }
    &__subtitle {
        text-align: center;
        font-size: 16px;
        @include font-body;
        text-transform: capitalize;
        color: $black;
        padding: 0 20px;
    }
    &__share-option {
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        padding: 15px 0;
        margin: 20px 0 0;
        text-align: center;
        color: $black;
        font-size: 17px;
        input[type='radio'] {
            display: none;
            &:checked + label {
                &::after {
                    position: absolute;
                    content: '';
                    top: 46%;
                    left: 2px;
                    width: 10px;
                    height: 10px;
                    margin-top: -4px;
                    background: #d31a0b;
                    border-radius: 100%;
                }
            }
        }
        label {
            position: relative;
            display: inline-block;
            padding: 3px 3px 3px 20px;
            cursor: pointer;
            &::before {
                position: absolute;
                content: '';
                top: 47%;
                left: -2px;
                width: 16px;
                height: 16px;
                margin-top: -8px;
                background: $white;
                border: 1px solid #ccc;
                border-radius: 100%;
            }
        }
    }
    &__share-option label {
        margin: 0 15px;
    }
    &__share-option label input[type='radio'],
    &__share-input label input[type='checkbox'] {
        position: relative;
        top: 2px;
        margin: 0;
    }
    &__share-input {
        padding: 30px 60px 60px;

        @include media(bp-xs) {
            padding: 10px 20px 60px;
        }
    }
    &__textfield {
        width: 100%;
        padding: 11px 10px;
        border: 2px solid rgba(0, 0, 0, 0.15);
    }
    &__disclaimer {
        margin-top: 20px;
        color: $black;
        font-size: 15px;
        a {
            color: $brand-color-blue;
        }
    }
    &__btn-send {
        width: 100% !important;
        padding: 15px 10px;
    }
    &__input-error {
        color: red;
        font-size: 12px;
    }
    &__share-input-email,
    &__share-input-text {
        margin-bottom: 20px;
    }
    &__share-input .form-wrap {
        margin-top: 0;
    }
    &__share-input-info {
        margin-top: 10px;
        color: $black;
    }
    &__close {
        top: 20px;
        right: 20px;
    }
    &__cta-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    &__btn-schedule-appointment {
        margin-right: 10px;

        @include media(bp-xs) {
            padding: 13px 10px;
        }
    }
    &__btn-close {
        @include media(bp-xs) {
            padding: 13px 10px;
        }
    }

    & .refresh-modal__content {
        height: 74vh;
        overflow: auto;

        @include media(bp-xs) {
            height: 100vh;
        }
    }
}

@media print {
    #leaderboard-banner-container,
    #secondary-nav,
    .offers-signup,
    .find-store {
        display: none;
    }

    #main-nav {
        visibility: hidden;
        width: 35%;
    }

    .primary-nav .store-wrapper {
        width: 40%;
    }

    .offer-details__highlight-link {
        display: none;
    }

    .site-footer {
        display: none;
    }

    .offer-details__offer {
        max-width: unset;
    }

    #top-nav {
        display: none;
        height: auto;
        border-bottom: none;
        box-shadow: none;
    }

    .offer-details__wrapper {
        .container {
            margin: 0;
        }
    }

    .logo-wrapper img {
        margin-left: 12px;
    }

    .offer-details__offer--with-spacing {
        margin: 0;
        padding: 40px 0;
    }
}

.offer__modal {
    padding: 0 !important;
    .rm-header {
        p {
            z-index: 9 !important;
        }
    }
}
.offer-details__modal {
    .offer-details__offer {
        max-width: 375px !important;
        border-radius: 5px !important;
        .offer-details__offer-top {
            flex-wrap: wrap;
            padding: 0 30px 20px !important;
            .offer-details__offer-image {
                &::before {
                    right: 110px !important;
                    top: -3px !important;
                }
                img {
                    text-align: center;
                    float: none;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        .offer-details__offer-validity {
            .offer-details__offer-validity-date {
                font-style: normal !important;
            }
        }
    }
    .offer-details__offer-image {
        flex: 1 0 100%;
    }

    .offer-details__offer-title,
    .offer-details__offer-subtitle {
        margin: 5px 0 !important;
        font-size: 18px !important;
        text-align: center !important;
    }

    .offer-details__offer-price {
        margin: 10px 0 10px !important;
        color: #d31a0b;
        font-size: 24px !important;
        max-height: 95px !important;
        overflow: hidden;
        text-align: center !important;
    }

    .offer-details__offer-summary {
        text-transform: capitalize !important;
        font-size: 18px !important;
        @include font-body;
        margin: 5px 0 !important;
        line-height: 20px !important;
        width: 100%;
        text-align: center !important;
    }

    .offer-details__highlight-summary p {
        text-align: left !important;
        font-size: 18px !important;
    }

    .offer-details__body-copy {
        .closeButton {
            float: right !important;
        }
        p {
            text-align: left !important;
        }
    }
}
