/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.overview {
  width: 100%;
}
.overview.mobile-only {
  display: block !important;
}
.overview.mod-disclaimer h2 {
  margin-bottom: 0px;
}
.overview.mod-disclaimer .overview-body {
  text-align: center !important;
  margin-top: 18px;
}
.overview.mod-disclaimer .disclaimer {
  font-size: 14px;
}
.overview.mod-disclaimer .outer-container .item-wrapper:last-of-type {
  border-bottom: none;
}
.overview .outer-container {
  max-width: 10000px;
  margin-left: auto;
  margin-right: auto;
}
.overview .outer-container::after {
  clear: both;
  content: "";
  display: table;
}
.overview .img-container {
  display: flex;
  width: 47.2222222222%;
  padding: 0 4.8611111111% 0 0;
  float: left;
}
.overview .img-container.left {
  justify-content: left;
}
.overview .img-container.right {
  justify-content: right;
}
.overview .img-container img {
  display: block;
}
.overview .copy-container {
  width: 52.7777777778%;
  padding-right: 4.8611111111%;
  float: left;
}
.overview .btn-container {
  margin: 20px 0;
  text-align: center;
}
.overview .title {
  margin: 0 0 50px 0 !important;
  padding-top: 50px;
  font-weight: normal;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
}
.overview .item,
.overview .item-wrapper {
  width: 100%;
}
.overview .item .mobile-only,
.overview .item-wrapper .mobile-only {
  display: none;
}
.overview .item-wrapper {
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.overview .item-title {
  font-weight: normal;
}
.overview .overview-body {
  margin: 2em 0;
  line-height: 1.1em;
}
.overview .overview-body b,
.overview .overview-body strong {
  text-transform: uppercase;
  font-weight: normal;
}
.overview .overview-body.left {
  text-align: left;
}
.overview .overview-body.center {
  text-align: center;
}
.overview .overview-body.right {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .overview {
    padding: 0 15px;
  }
  .overview .img-container {
    display: none;
  }
  .overview .copy-container {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
  }
  .overview .title {
    margin: 0 0 40px;
    padding-top: 40px;
  }
  .overview .carousel {
    padding-bottom: 10px;
  }
  .overview .item {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .overview .item .mobile-only {
    display: inline-block;
  }
  .overview .item-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .overview .item-wrapper .mobile-only {
    display: block;
  }
  .overview .overview-body {
    display: none;
  }
  .overview .desktop-only {
    display: none;
  }
}

.overview-carousel .item-wrapper:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
}

.overview-price-item {
  display: table;
}
.overview-price-item .item-img {
  display: table-cell;
  width: 32.6086956522%;
  padding-right: 4.347826087%;
  vertical-align: top;
}
.overview-price-item .item-copy {
  display: table-cell;
  vertical-align: top;
}
.overview-price-item .item-title {
  margin: 0 0 0.2em;
  line-height: 1.1em;
}
.overview-price-item .details {
  display: table;
}
.overview-price-item .description {
  display: table-cell;
  width: 51.6129032258%;
  padding-right: 6.4516129032%;
}
.overview-price-item .internet-price {
  display: table-cell;
  padding-left: 3.2258064516%;
  text-align: center;
}
.overview-price-item .internet-price .label {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.2em;
}
.overview-price-item .internet-price .price {
  margin-top: 8px;
  line-height: 0.9em;
}
.overview-price-item .internet-price .price span {
  line-height: 1em;
}
.overview-price-item p {
  margin: 0;
  line-height: 1.2em;
}
.overview-price-item .reg-price {
  margin-bottom: 0.8em;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .overview-price-item {
    display: block;
  }
  .overview-price-item .folder {
    margin: 0 -15px;
  }
  .overview-price-item .item-img {
    display: block;
    width: 100%;
    text-align: center;
  }
  .overview-price-item .item-copy {
    display: block;
  }
  .overview-price-item .item-title {
    display: none;
  }
  .overview-price-item .details {
    display: block;
  }
  .overview-price-item .description {
    display: block;
    width: 100%;
    padding: 20px 0 15px;
    text-align: center;
  }
  .overview-price-item .reg-price {
    margin-bottom: 0.5em;
  }
  .overview-price-item .internet-price {
    display: block;
    margin-bottom: 10px;
    padding: 20px 0;
  }
}

.overview-thumbnail-item {
  display: table;
}
.overview-thumbnail-item .item-img {
  display: table-cell;
  width: 140px;
  vertical-align: top;
}
.overview-thumbnail-item .item-img img {
  margin: 0 auto;
}
.overview-thumbnail-item .item-copy {
  display: table-cell;
  vertical-align: top;
}
.overview-thumbnail-item .item-title {
  margin: 0.5em 0;
  line-height: 1em;
}
.overview-thumbnail-item .item-subtitle {
  margin: 0;
  text-transform: none;
}
.overview-thumbnail-item p {
  margin-top: 0;
  line-height: 1.2em;
}
@media only screen and (max-width: 767px) {
  .overview-thumbnail-item {
    display: block;
  }
  .overview-thumbnail-item .item-img {
    display: block;
    width: 100%;
    text-align: center;
  }
  .overview-thumbnail-item .item-copy {
    display: block;
  }
  .overview-thumbnail-item .item-title {
    margin-top: 1em;
  }
}

.overview-basic-item .item-supertitle {
  margin: 0;
  font-weight: normal;
  text-transform: uppercase;
}
.overview-basic-item .item-title {
  margin: 0.1em 0 0.25em;
  line-height: 1em;
}
@media only screen and (max-width: 767px) {
  .overview-basic-item li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}