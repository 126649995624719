@import '~common/scss/shared/shared';

.battery-life-result {
    padding: 40px 0;
    @include gradient-top();

    .container {
        overflow: hidden;
    }

    .img-wrap,
    .content {
        float: left;
    }

    .img-wrap {
        width: 31%;
        margin-right: 4%;

        @include media(bp-lt-sm) {
            width: 100%;
            text-align: center;
        }
    }

    .content {
        width: 65%;

        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .key {
        clear: both;
        padding: 15px 0;
        text-align: center;
    }

    .key-wrap {
        display: inline-block;
        border: solid 1px #ccc;
        border-radius: 20px;
        padding: 10px;
    }

	.battery-date-code {
		margin-top: 2em;

		fieldset{
			display: none;
		}

		.field {
			display: inline-block;

			label {
				display: block;
				font-size: 15px;

				@include media(bp-lt-sm) {
					font-size: 100%;
				}
			}

			.date-code {
				font-size: 18px;

				@include media(bp-lt-sm) {
					font-size: 100%;
				}
			}
		}

		.action {
			display: inline-block;
			margin-left: 25px;

			.submit-code {
				padding-left: 30px;
				padding-right: 30px;

				@include media(bp-lt-sm) {
					padding: 12px 15px;
				}
			}
		}

		.battery-code-results {

			h2 {
				color: $brand-color-red;
			}

			.battery-life-table {

				table {

					@include media(bp-lt-sm) {
						display: none;
					}
				}

				.no-border {
					text-align: center;
					padding: 0px;
				}

				.result-table {

					thead,
					tr,
					td {
						border: 2px solid $black;
						text-align: center;
						font-size: 100%;

						@include media(bp-lt-lg) {
							border: 2px solid $black;
							font-size: 90%;
							padding: 0px;
						}
					}
				}

				.key {

					.uppercase {
						color: $brand-color-red;
					}

					p {
						margin: 0px;
						font-weight: bold;
						font-style: italic;

						@include media(bp-lt-lg) {
							font-size: 13px;
						}
					}

					h3 {
						margin: 15px 0 0;

						@include media(bp-lt-lg) {
							font-size: 23px
						}
					}
				}

				.textcenter {
					text-align: center;

					@include media(bp-lt-sm) {
						display: none;
					}

					.line {
						display: block;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}
