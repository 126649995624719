th,
td {
    padding: 5px;
}

.table-rounded {
    background-image: -webkit-linear-gradient(top, $white 95%, #e3e3e3 100%);
    border-radius: 12px;
    background-clip: padding-box;
    border: 1px solid #ccc;
    overflow: hidden;

    table {
        border-spacing: 0;

        th,
        td {
            padding: 0.5em 18px;
            border: 1px solid #ccc;
        }

        th {
            letter-spacing: -0.03em;
            color: $dark-gray;
            font-size: 24px;
            padding: 0.7em 0.5em 0.2em 0.5em;
            background-color: transparent;
            border: 0;
        }

        tbody {
            > tr {
                &:nth-child(odd) {
                    background-color: #f2f2f2;
                }
            }
        }

        tfoot * {
            border: 0;
            background-color: transparent !important;
            text-align: left;
            @include font-body;
            color: $dark-gray;
            font-size: 11px;

            caption {
                font-weight: bold;
                padding: 1em 0;
            }

            th {
                vertical-align: top;
                font-weight: normal;
                padding: 0.5em 0.5em 0.5em 15px;
            }

            tbody {
                td {
                    padding-left: 0;
                }
            }
        }
    }
}
