// TODO: colors to be refactored in component refactor phase 3
// Define standard colors first (color-gray-100 or color-brand-primary)
// Then define by context (color-link, color-link-hover, etc)
// Color audit: https: //codepen.io/DoodleBot/pen/MWQMVjZ

$white: #fff;
$black: #000;
$gray: #919396 !default;
$red: #d81e05 !default;
$brand-color-red: $red !default;
$brand-color-blue: #005eb8 !default;
$off-white: #bebebe;
$silver: #cccccc;
$light-gray-1: #e8e8e8;
$light-gray-2: #ebebeb;
$light-gray-3: #e0e0e0;
$gray-1: #999999;
$gray-2: #666666;
$off-black: #282828;

$light-gray: #dcdcdb !default;
$dark-gray: #333 !default;

$text-color: $dark-gray !default;
$body-bg: $white !default;
$text-selection-bg-color: #b3d4fc !default;
$link-color: #428bca !default;
$link-hover-color: #428bca !default;

$heading-color: $black !default;

$default-button-background-color: $gray !default;
$default-button-text-color: $white !default;

$appt-step-border-color-light: #dcdcdb !default;
$appt-step-border-color-dark: #aaa !default;
$articles-divider-color: #dcdcdb !default;

$ratings: #de9c50;

// common elements between the different overview components
$overview-gradient-color: #ebebeb !default;
$overview-supertitle-color: #808080 !default;
$overview-item-border-color: #dcdcdc !default;

$form-field-border-color: #999 !default;
