@use 'sass:math';

$articles-divider-color: #dcdcdb !default;

.intro-article {
    text-align: center;

    @include media(bp-lt-sm) {
        padding-bottom: 0;

        .intro-cta {
            position: absolute;
            bottom: 0;
        }

        .subtitle {
            width: 80%;
            margin: 0 auto 15px;
        }
    }

    .title,
    .subtitle {
        line-height: 1;
        text-transform: uppercase;
    }

    .title {
        font-size: 40px;

        @include media(bp-lt-lg) {
            font-size: 25px;
        }

        @include media(bp-lt-sm) {
            float: none;
            width: 100%;
            margin-bottom: 15px;
            font-size: 24px;
        }
    }

    .subtitle {
        font-size: 19px;
        line-height: 1.1;
        margin-bottom: 30px;

        @include media(bp-lt-lg) {
            font-size: 13px;
        }

        @include media(bp-lt-sm) {
            font-size: 14px;
        }
    }
}

.articles {
    @include gradient-top();

    overflow: auto;
    padding: 70px 0;

    @include media(bp-lt-sm) {
        padding: 30px 0;
        @include gradient-top(30px, #ebebeb, $white);
    }

    a {
        display: block;
    }

    .container {
        @include clearfix;
    }

    .articles-wrap {
        overflow: auto;
        @include media(bp-lt-sm) {
            margin-bottom: 60px;
        }
    }

    .col {
        width: 25%;
        border-right: solid 1px $articles-divider-color;

        &:last-child {
            border-right: none;
        }

        @include media(bp-lt-sm) {
            width: 50%;
            border-right: none;

            &:first-child {
                width: 100%;
                border-right: none;
            }
            &:last-child {
                display: none;
            }
        }
    }

    .teaser {
        overflow: hidden;
        width: percentage(math.div(300px, 345px));
    }

    .image {
        position: relative;
        overflow: hidden;
    }

    .video-icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        > div {
            width: 20%;
            margin: 15% auto 0;
        }
    }

    @include media(bp-lt-sm) {
        .intro-cta {
            width: 100%;

            a {
                width: 94% !important;
                @include btn;
            }
        }
    }

    .mobile-cta {
        display: none;
        margin: 15px 0;

        @include media(bp-lt-sm) {
            display: block;
        }

        .btn {
            width: 100%;
        }
    }

    // Begin Reskin
    &.reskin {
        background: none;
        padding: 40px 0;

        h2,
        h3 {
            margin: 5px;
            text-align: center;
        }
        h3.subtitle {
            font-size: 20px;
            padding: 0 15%;
        }
        h2 {
            @include media(bp-lt-sm) {
                font-size: 28px;
                font-size: 7vw;
            }
        }

        .col {
            width: 33%;
            border-right: none;

            @include media(bp-lt-sm) {
                &:last-child {
                    display: block;
                }
            }
        }
        .articles-wrap {
            margin: 30px 0;
            overflow: initial;
            @include media(bp-lt-lg) {
                margin: 30px 0 60px;
            }
            @include media(bp-lt-sm) {
                margin: 20px 0 60px;
            }
        }

        .teaser .image {
            height: auto;
            max-height: none;

            img {
                height: auto;
                max-width: none;
                width: 100%;
            }
        }

        @include media(bp-lt-sm) {
            .teaser .image {
                height: auto;
                max-height: 55vw;
            }
        }

        .teaser .title {
            font-size: 18px;
            @include media(bp-lt-sm) {
                font-size: 14px;
                text-align: center;
                width: 100%;
            }
        }
        .intro-cta {
            text-align: center;
            a,
            a:hover {
                display: inline-block;
                color: $white;
                background-color: $brand-color-red;
            }
        }
    }
    // End Reskin
}
