@import '~common/scss/shared/shared';

.breadcrumbs {
    @media print {
        display: none;
    }
    margin: 12px 0;
    color: $gray;

    a,
    span {
        display: inline-block;
        margin: 10px 0;
        color: $gray;
    }

    a {
        text-decoration: underline;

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }

    span {
        &.delim:before {
            padding-right: 5px;
            padding-left: 5px;
            color: $gray;
            content: '>';
        }
    }
}
