@import '~common/scss/shared/shared';
.tire-pressure-result {
    padding: 40px 0;
    @include gradient-top();

    .title {
        margin: 10px 0;
    }

    .results {
        overflow: auto;
        margin-bottom: 15px;
    }

    th,
    td {
        padding: 10px;
        border: solid 1px #ccc;
    }

    th {
        white-space: nowrap;
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background: #ccc;
            }
        }
    }
}
