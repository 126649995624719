@use 'sass:math';
@import '~common/scss/shared/shared';

$hero-widget-left: percentage(math.div(70px, $wrapper-max-width));
$hero-widget-width: 444px;
$hero-widget-small-width: 275px;
$hero-copy-h1-font-size: 72px;
$hero-copy-h2-font-size: 58px;
$hero-copy-h3-font-size: 44px;
$hero-body-h1-font-size: 32px;
$hero-body-h2-font-size: 28px;
$hero-body-h3-font-size: 24px;
$hero-copy-subtitle-font-size: 40px;
$hero-copy-h1-font-size-mobile: 28px;
$hero-copy-h2-font-size-mobile: 24px;
$hero-copy-h3-font-size-mobile: 20px;
$hero-copy-h1-subtitle-font-size-mobile: 20px;
$hero-copy-h2-subtitle-font-size-mobile: 16px;
$hero-copy-h3-subtitle-font-size-mobile: 14px;
$hero-copy-description-font-size: 24px;

.hero {
    position: relative;
    @include clearfix;
    &.hero-widget {
        .widget-wrap {
            display: block;
            position: absolute;
            top: 50%;
            left: $hero-widget-left;
            transform: translate(0, -50%);
            @include media(bp-lt-xl) {
                left: 0;
            }
            @media only screen and (max-width: 900px) {
                min-width: 0;
            }
            @include media(bp-lt-nav) {
                position: relative;
                width: 100%;
                z-index: 998;
                .tire-service-widget {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .copy {
            left: $hero-widget-width;
            top: auto;
            margin-left: $hero-widget-left;
            @include media(bp-lt-xl) {
                margin-left: 0;
            }
            @media only screen and (max-width: 900px) {
                left: $hero-widget-small-width;
            }
            @media only screen and (min-width: 901px) and (max-width: 1100px) {
                width: auto;
            }
            @include media(bp-gt-nav) {
                width: auto;
                bottom: 0;
                padding: 0 $hero-widget-left 30px 30px;
                text-align: left;
                transform: none;
            }
            @include media(bp-lt-nav) {
                text-align: center;
                padding: 0;
                margin: 0;
                left: auto;
                top: 30vw;
                bottom: auto;
            }
        }
        &.copy-valign-top .copy {
            top: 0;
        }
        .reskin & {
            .widget-wrap {
                min-width: initial;
                min-height: 55px;
                width: 375px;
                @media only screen and (max-width: 900px) {
                    width: 300px;
                }
                @include media(bp-lt-nav) {
                    width: 100%;
                    transform: none;
                }
            }
            _:-ms-fullscreen,
            :root .copy {
                left: 430px;
            }
            .copy {
                left: 375px;
                @media only screen and (max-width: 900px) {
                    left: 300px;
                }
                @include media(bp-lt-nav) {
                    left: auto !important;
                }
            }
            &.xb-hero {
                .copy {
                    left: 415px;
                }
            }
            &.promo-flag-left {
                .copy {
                    left: 358px;
                }
            }
        }
        .image-wrap {
            background-color: $overview-gradient-color; // adding a fill so user knows something is going here

            @include media(bp-gt-nav) {
                height: 557px; // adding height so layout doesn't jump after image loads
            }
        }
        .offer-details.offer-details-v2 {
            /* BSROAEM-9135 align HERO behavior */
            display: block;
        }

        .offer-details.offer-details-v2 .hidden {
            display: none;
        }
    }

    /* BSROAEM-11371 display offers without widget */
    &.no-widget {
        .offer-details.offer-details-v2 {
            display: block;
        }
    }

    & .offer-details-v2 {
        @include media(bp-gt-nav) {
            position: absolute;
        }
        top: 40px;
        right: 56px;
        z-index: 1;
        & .offer-card-refresh {
            cursor: default;

            &__body-wrap {
                min-height: auto;
            }

            &__title {
                padding-top: 30px;
            }

            &__discount-copy {
                font-size: 21px;
                height: auto;
                padding: 5px 10px;
            }

            &__summary {
                padding-bottom: 8px;
            }

            &__body-wrap {
                border-bottom: 0;
            }

            &__body-wrap,
            &__footer-wrap {
                box-shadow: none;
            }

            &__ctas {
                margin-bottom: 10px;
            }
        }
    }

    &.promo-flag {
        .limitedTimeOfferHeading {
            display: none;
        }
        .coupon-cta {
            position: absolute;
            top: 0;
            left: 40px;
        }

        .mini-schedule-appt {
            max-width: 350px;
            padding: 20px 3% 30px;
            h3 {
                font-size: 19px;
            }
            .form form {
                padding: 20px 0;
            }
            input[type='text'] {
                width: 100px;
            }
            @media only screen and (max-width: 900px) {
                width: $hero-widget-small-width;
            }
        }
        .offer-details-container {
            max-width: 350px;
            padding-top: 80px;
            border-width: 2px;
        }
        .offer-container {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            padding: 10px;
            min-height: 200px;
            &.offer-template {
                .logo,
                p {
                    display: none;
                }
                .offer-top {
                    height: auto;
                    .offer-validity {
                        font-size: 10px;
                        font-weight: 400;
                        text-align: center;
                    }
                }
                .price,
                .title {
                    margin: 0;
                    border-bottom: none;
                    line-height: 1;
                }
                .offer-details {
                    .price {
                        font-size: 80px;
                    }
                    .title {
                        font-size: 36px;
                    }
                }
            }
        }
        .offer-dates {
            width: 100% !important;
        }
        .offer-validity {
            font-size: 10px;
            font-weight: normal;
            text-align: center;
        }
        &.promo-flag-left {
            .widget-wrap {
                top: 0;
                margin-top: 0;
                transform: none;
                .offer-details.offer-details-v2 {
                    /* BSROAEM-9135 align HERO behavior */
                    display: block;
                }
            }
            .offer-container {
                min-height: 230px;
            }

            & .offer-details-v2 {
                margin-top: 80px;
            }
        }
        &.promo-flag-right {
            .widget-wrap,
            #expedited-booking-app,
            #expedited-booking-app-v2 {
                .offer-details {
                    @include media(bp-lt-xl) {
                        right: 4.86111%;
                    }
                }
                + .offer-details {
                    position: absolute;
                    top: 40px;
                    right: 56px;
                    z-index: 1;
                }
                .offer-details.offer-details-v2 {
                    /* BSROAEM-9135 align HERO behavior */
                    display: none;
                }
            }

            .copy .description {
                font-size: 20px;
                @include media(bp-lt-xl) {
                    font-size: $hero-copy-description-font-size;
                }
            }
            .coupon-cta {
                display: none;
                @include media(bp-lt-sm) {
                    .btn {
                        width: auto;
                    }
                }
            }
            .offer-details-container {
                padding-top: 10px;
            }
            .offer-container {
                @include media(bp-gt-nav) {
                    min-height: auto;
                }
            }
        }
        @include media(bp-lt-nav) {
            display: flex;
            flex-direction: column;

            &.promo-flag-left,
            &.promo-flag-right {
                .widget-wrap {
                    .offer-details {
                        display: block !important;
                        margin-top: 80px;
                    }
                }
                .offer-details {
                    display: none;
                }
                .copy {
                    top: 30vw;
                }
                .coupon-cta {
                    display: block;
                }
            }
            .copy {
                order: 1;
            }
            .widget-wrap {
                order: 2;
            }

            img.cq-dd-image {
                display: none;
            }
            .hero-text-alt-pos {
                display: block;
            }
            .offer-details-container {
                max-width: 100%;
            }
            .coupon-cta {
                left: 0;
                .btn {
                    width: 100%;
                }
            }
            .mini-schedule-appt {
                display: none;
            }
            .print-gray {
                display: none;
            }
            .offer-details .offer-details-container .offer-container .details-wrapper {
                .price {
                    font-size: 14vw;
                }
                .title {
                    font-size: 8vw;
                    min-height: 2em;
                }
            }
        }
        // nonstandard breakpoint.. try to encorporate in an existing breakpoint
        @media only screen and (min-width: 701px) and (max-width: 900px) {
            .offer-details .offer-details-container .offer-container .details-wrapper .title {
                font-size: 4vw;
            }
        }
        // nonstandard breakpoint.. try to encorporate in an existing breakpoint
        @media only screen and (max-width: 900px) {
            .offer-details-container {
                padding-top: 20px;
                width: auto;
            }
            .coupon-cta {
                position: relative;
                left: 5px;
            }
            .offer-details .offer-details-container .offer-container {
                .offer-top {
                    margin: 0;
                }
                .details-wrapper .price {
                    font-size: 7vw;
                }
                .offer-top .offer-validity {
                    font-size: 10px;
                }
            }
        }
        @include media(bp-lt-lg) {
            .print-gray {
                display: inline-block;
            }
        }
    }

    &.no-widget {
        &.promo-flag-right {
            & .copy {
                top: 55%;
                text-align: center !important;
                left: 0 !important;
            }
        }
        @include media(bp-lt-nav) {
            .copy {
                top: 55% !important;
            }
        }
    }

    .offer-details.offer-details-v2 {
        /* BSROAEM-9135 align HERO behavior */
        display: none;
    }

    .widget-wrap {
        .offer-details.offer-details-v2 {
            /* BSROAEM-9135 align HERO behavior */
            display: block;
        }
    }

    .image-wrap {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        max-height: 557px;
        @include media(bp-lt-nav) {
            width: 100%;
            min-width: 100%;
            justify-content: center;
            &.left-focus {
                justify-content: center;
            }
        }
        picture {
            display: block;
            &.desktop-only {
                @include media(bp-lt-nav) {
                    min-width: 145vw !important;
                    display: block !important;
                }
                height: auto !important;
                > img {
                    width: 100%;
                }
            }
            &.tablet {
                // nonstandard breakpoint.. try to encorporate in an existing breakpoint
                @media screen and (min-width: 701px) and (max-width: 1024px) {
                    min-width: 1024px;
                }
            }
            &.tablet-only {
                @include media(bp-lt-nav) {
                    min-width: 104vw !important;
                }
            }
        }
        > img,
        picture {
            max-width: none;
            width: auto;
            min-width: 1440px; //1665px;
            @include media(bp-lt-nav) {
                height: 56vw;
                width: 100vw;
                min-width: 100vw; // for current desktop image being used in picture element 1440x557
            }
        }
        &.left-focus {
            justify-content: flex-start;
        }
        &.center-focus {
            justify-content: center;
        }

        .image-gradient {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.hero-gradient::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                pointer-events: none;
            }
        }
    }
    .copy {
        position: absolute;
        top: 55%;
        width: 100%;
        text-align: center;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-shadow: 0px 0px 5px black;
        h1,
        h2,
        h3,
        .headline {
            margin: 0;
            padding: 0;
            line-height: 1em;
        }
        h1 {
            font-size: $hero-copy-h1-font-size;
            .subhead {
                font-size: $hero-body-h1-font-size;
                @include media(bp-lt-nav) {
                    font-size: $hero-copy-h1-subtitle-font-size-mobile;
                }
            }
            @include media(bp-lt-nav) {
                font-size: $hero-copy-h1-font-size-mobile;
            }
        }
        h2 {
            font-size: $hero-copy-h2-font-size;
            .subhead {
                font-size: $hero-body-h2-font-size;
                @include media(bp-lt-nav) {
                    font-size: $hero-copy-h2-subtitle-font-size-mobile;
                }
            }
            @include media(bp-lt-nav) {
                font-size: $hero-copy-h2-font-size-mobile;
            }
        }
        h3 {
            font-size: $hero-copy-h3-font-size;
            .subhead {
                font-size: $hero-body-h3-font-size;
                @include media(bp-lt-nav) {
                    font-size: $hero-copy-h3-subtitle-font-size-mobile;
                }
            }
            @include media(bp-lt-nav) {
                font-size: $hero-copy-h3-font-size-mobile;
            }
        }
        .subhead {
            display: block;
            margin: 0;
            padding: 0;
            line-height: 1.2em;

            // nonstandard breakpoint.. try to encorporate in an existing breakpoint
            @media only screen and (max-width: 900px) {
                font-size: 4vw;
            }
        }
        .description {
            margin: 0;
            font-size: $hero-copy-description-font-size;
            line-height: 1em;

            h1 {
                font-size: $hero-body-h1-font-size;
            }
            h2 {
                font-size: $hero-body-h2-font-size;
            }
            h3 {
                font-size: $hero-body-h3-font-size;
            }
            // nonstandard breakpoint.. try to encorporate in an existing breakpoint
            @media only screen and (max-width: 900px) {
                font-size: 20px;
            }
            @include media(bp-lt-nav) {
                .description-content {
                    display: none;
                }
            }
        }
        .btn {
            text-shadow: none;
            @include media(bp-gt-nav) {
                width: auto;
            }
        }
        @include media(bp-lt-nav) {
            &.text-align-left,
            &.text-align-center,
            .text-align-left,
            .text-align-center {
                text-align: center !important;
            }
            .cta {
                display: block;
                width: 80%;
                margin: 10px auto 0;
            }
            > img,
            > picture {
                margin-bottom: 20vw;
            }
        }
        &.white {
            h1,
            h2,
            h3,
            .headline,
            .subhead,
            .description {
                color: $white !important;
                text-shadow: 3px 3px 5px rgba(black, 0.7);
            }
        }
        &.black {
            h1,
            h2,
            h3,
            .headline,
            .subhead,
            .description {
                color: $black !important;
                text-shadow: 3px 3px 5px rgba(white, 0.7);
            }
        }
        &.red {
            h1,
            h2,
            h3,
            .headline,
            .subhead,
            .description {
                color: $brand-color-red !important;
                text-shadow: 3px 3px 5px rgba(black, 0.7);
            }
        }
        &.blue {
            h1,
            h2,
            h3,
            .headline,
            .subhead,
            .description {
                color: #007ac2 !important;
                text-shadow: 3px 3px 5px rgba(black, 0.7);
            }
        }
        &.yellow {
            h1,
            h2,
            h3,
            .headline,
            .subhead,
            .description {
                color: #f6e500 !important;
                text-shadow: 3px 3px 5px rgba(black, 0.7);
            }
        }
        &.top,
        &.bottom,
        &.left,
        &.right {
            padding: 2% !important;
            transform: translate(0, 0);
        }
        &.top {
            top: 0 !important;
        }
        &.bottom {
            top: auto !important;
            bottom: 0 !important;
        }
        &.left {
            .description {
                margin: 0 auto 0 0;
            }
        }
        &.right {
            .description {
                margin: 0 0 0 auto;
            }
        }
    }
    &.copy-valign-top .copy {
        top: 0;
        transform: unset;
        @include media(bp-lt-nav) {
            transform: unset;
        }
    }
    img.cq-dd-image.cq-image-placeholder {
        display: none;
    }
    .zipcodeError {
        display: block;
        min-height: 20px;
        visibility: hidden;
        color: $brand-color-red;
    }
    .zipcodeError.show {
        visibility: visible;
    }

    .find-stores-nearby {
        margin: 30px 0 0 0;
        display: block !important;
        min-width: 320px;
        .label {
            text-align: left;
            width: 100%;
            margin: 0 0 4px;
        }
        .bar {
            display: inline-block;
        }
        .user-zip,
        .auto-zip {
            float: left;
        }
        .find-stores-user-zip,
        .find-stores-auto-zip {
            // nonstandard breakpoint.. try to encorporate in an existing breakpoint
            @media screen and (max-width: 47.9375em) {
                width: auto;
            }
        }
        .sub-desc {
            margin: 0 0 30px;
            padding: 0 7px;
        }
        .sub-desc,
        .label {
            line-height: normal;
        }
        @include media(bp-lt-md) {
            .sub-title {
                font-size: 8vw;
            }
        }
        @include media(bp-lt-nav) {
            margin: 0 0 7%;
            .sub-desc,
            .label {
                font-size: 20px;
            }
        }
        // nonstandard breakpoint.. try to encorporate in an existing breakpoint
        @media screen and (max-width: 600px) {
            .user-zip,
            .auto-zip,
            .find-stores-user-zip,
            .find-stores-auto-zip {
                font-size: 14px;
            }
        }
        // nonstandard breakpoint.. try to encorporate in an existing breakpoint
        @media screen and (max-width: 550px) {
            margin: 0;
            .sub-title {
                font-size: 50px;
            }
        }
        // nonstandard breakpoint.. try to encorporate in an existing breakpoint
        @media screen and (max-width: 500px) {
            margin: 0;
            .sub-title {
                font-size: $hero-copy-subtitle-font-size;
            }
            .sub-desc {
                margin: 0 0 15px;
            }
        }
        // nonstandard breakpoint.. try to encorporate in an existing breakpoint
        @media screen and (max-width: 450px) {
            .sub-title {
                font-size: 30px;
                .subhead {
                    font-size: 16px;
                }
            }
            .sub-desc,
            .label {
                font-size: 14px;
                line-height: normal;
            }
            .user-zip,
            .auto-zip,
            .find-stores-user-zip,
            .find-stores-auto-zip {
                font-size: 12px;
            }
        }
        @media screen and (max-width: 400px) {
            .sub-title {
                font-size: $hero-copy-description-font-size;
                .subhead {
                    font-size: 16px;
                }
            }
        }
        @media screen and (max-width: 374px) {
            .sub-title {
                font-size: 20px;
                .subhead {
                    font-size: 16px;
                }
            }
            .sub-desc {
                margin: 0;
            }
            .sub-desc,
            .label {
                font-size: 12px;
            }
            .user-zip,
            .auto-zip,
            .find-stores-user-zip,
            .find-stores-auto-zip {
                font-size: 11px;
            }
        }
    }
    /**
    * BSROAEM-3462
    * only show sms and print buttons for promotion Id
    * respective promo content is static and authored in hero copy
    * NOTE: modules/cta-offer-*.html templates created for the buttons
    */
    &.promo-buttons-only {
        &.hero-widget {
            .offer-details {
                display: none;
            }
        }
        .copy {
            .description {
                width: auto;
            }
            .btn {
                margin-right: 10px;
            }
            @media only screen and (max-width: 900px) {
                margin-left: 8vw;
            }
            @include media(bp-lt-nav) {
                margin-left: 0;
                .btn:not(.desktop-only) {
                    display: block;
                    width: 94%;
                    margin: 10px auto 0;
                }
            }
        }
    }
    .offer-details-container .offer-container {
        &.offer-template {
            max-height: 485px;
            overflow: auto;
        }
    }
    // nonstandard breakpoint.. try to encorporate in an existing breakpoint
    @media only screen and (min-width: 901px) {
        /**
	* BSROAEM-3222
	* desktop only copy width dialog control
	* options:
	* Medium (default) no value;
	*/
        .copy {
            h1,
            h2,
            h3,
            .headline,
            .description,
            .sub-desc {
                width: 80%; // default
            }
        }
        &.hero-copy-wide .copy {
            h1,
            h2,
            h3,
            .headline,
            .description,
            .sub-desc {
                width: 100%;
            }
        }
        &.hero-copy-narrow .copy {
            h1,
            h2,
            h3,
            .headline,
            .description,
            .sub-desc {
                width: 60%;
            }
        }
        &:not(.hero-widget) > .copy {
            h1,
            h2,
            h3,
            .headline,
            .description,
            .sub-desc {
                margin: 0 auto;
            }
        }
        &.promo-buttons-only .copy {
            margin-left: 80px;
        }
    }
    @include media(bp-lt-nav) {
        &:not(.hero-widget) .copy {
            top: 40vw;
        }
        &.mobile-description-on .copy .description {
            display: block;
        }
    }
    // BSRO-6156 TDG hero section
    &.tdg {
        .copy {
            h1 {
                margin-left: 20%;
                width: 30%;
            }
            .description {
                width: 60%;
                margin: 0 auto;
                .description-content {
                    max-width: 380px;
                }
            }
            .btn {
                min-width: 360px;
            }
        }
        @include media(bp-lt-nav) {
            margin-bottom: 100px;
            .copy {
                transform: none;
                h1 {
                    background: rgba(0, 0, 0, 0.6);
                    padding: 5px 0;
                    margin-left: auto;
                    width: 100%;
                }
                .description {
                    width: 100%;
                    .description-content {
                        display: block;
                        width: 80%;
                        margin: 0 auto;
                        color: black;
                        text-shadow: none;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &.rm-tdg {
        .image-wrap {
            min-height: 210px;
            picture {
                height: auto;
            }
        }
    }

    // Expedited Booking Version
    &.xb-hero {
        @include media(bp-lt-md) {
            .image-wrap {
                height: 210px;
            }
            .widget-wrap {
                display: none !important;
            }
            .copy {
                padding: 0;
                margin: 0;
                top: 100px;
                left: 0 !important;
                bottom: auto;
                width: 100%;
                justify-content: center;
            }
        }
        @include media(bp-gt-sm) {
            .copy {
                padding: 0 10px 0 0;
            }
        }
        @include media(bp-gt-md) {
            .copy {
                padding: 0 30px 30px 0;
            }
        }
    }
}

//BSROAEM-11380
.offer-details-disclaimer {
    &.hidden {
        display: none;
    }
}

// THESE STYLES NEED TO BE NAMESPACED

/**
 * BSROAEM-3305
 * for use with promo-flag only
 */

.promo-flag-form.mini-schedule-appt {
    text-align: center;
    h3 {
        margin-top: 10px;
    }
    &section {
        display: none;
        margin: 30px 0;
        @include media(bp-lt-nav) {
            display: block;
        }
    }
}

.bsro-image-edit-panel {
    .x-panel-bbar {
        display: none !important;
    }
}
