@import '~common/scss/shared/shared';

.fifty-fifty-basic {
    display: inline-block;
    width: 100%;

    &.bg-gray-gradient {
        @include media(bp-gt-sm) {
            @include gradient-top();
        }
    }

    .col {
        &:last-child {
            width: 100%;
        }
        overflow-wrap: break-word;
    }

    &.image {
        div:first-child {
            display: block;
        }

        .col {
            display: inline-block;
            width: 49%;

            @include media(bp-md) {
                width: 100%;
            }
        }
    }

    .right {
        float: right;
    }

    .configure {
        text-align: center;
    }

    .copy {
        h2 {
            margin-top: 0;
        }

        h3:first-child {
            margin-top: 0;
        }

        p:first-child {
            margin-top: 0;
        }
    }

    @include media(bp-lt-md) {
        &.image .col {
            display: block;
            width: 100%;
        }

        .right {
            float: none;
        }
    }
}
