@import '~common/scss/shared/shared';

.good-better-best-list {
    .swiper-slide,
    .slick-slide {
        @include media(bp-gt-sm) {
            margin: 0 15px;
        }
        padding-bottom: 20px;
    }

    .swiper {
        padding: 0px 55px;

        @include media(bp-lt-md) {
            padding: 0px 10px;
        }

        & .swiper-wrapper {
            @include media(bp-gt-sm) {
                justify-content: center;
            }

            @include media(bp-lt-lg) {
                margin-bottom: 40px;
            }
        }
    }

    .children .component {
        @include media(bp-lt-md) {
            width: 100%;
            display: block;
        }

        @include media(bp-md) {
            padding: 0;
        }
    }

    .good-better-best-card {
        h2 {
            text-align: left;
            margin-bottom: 0px;
            font-size: 28px;
            @include media(bp-lt-md) {
                font-size: 22px;
            }

            @include media(bp-md) {
                font-size: 22px;
            }
        }

        h3 {
            text-align: center;
            font-size: 28px;
            color: #aaa;

            @include media(bp-lt-md) {
                display: none;
            }
        }

        h4 {
            display: none;

            @include media(bp-lt-md) {
                display: inherit;
                text-align: center;
                font-size: 26px;
                color: #aaa;
                margin-bottom: 0px;
                padding-top: 20px;
            }
        }

        .info {
            background: $white;
            -webkit-box-shadow: 1px 1px 20px 1px rgba(170, 170, 170, 0.49);
            box-shadow: 1px 1px 20px 1px rgba(170, 170, 170, 0.49);
            min-height: 530px;

            @include media(bp-lt-md) {
                min-height: 430px;
            }

            .card-content {
                padding: 25px 30px 2px;

                @include media(bp-lt-md) {
                    padding: 25px 20px 2px;
                }

                @include media(bp-md) {
                    padding: 25px 15px 2px;
                }
            }

            .image {
                height: auto;

                img {
                    margin: 0 auto;
                }
            }

            ul {
                border-bottom: 1px dashed #aaa;
                padding: 0 20px 20px;
                font-size: 16px;

                li {
                    padding: 2px 10px;
                    line-height: 20px;
                }
            }

            p {
                text-align: center;
                @include font-title;

                &.expiration {
                    font-size: 14px;
                    color: #747474;
                    @include font-body;
                    margin-bottom: 7px;
                    padding: 0;
                    white-space: normal;
                }

                &.offer-info {
                    color: $brand-color-blue;
                    line-height: 20px;
                    font-size: 18px;
                    margin-top: 15px;
                    white-space: normal;

                    a {
                        color: $brand-color-blue;
                    }
                }
            }

            a {
                width: 100%;
                margin-bottom: 10px;
                color: $white;

                &.btn {
                    &.cta-txtLink {
                        background: none;
                        color: $brand-color-red;
                        border: none;
                        text-decoration: underline;
                    }
                }
            }

            .offer-body {
                font-size: 16px;
                line-height: 20px;
                margin-top: 16px;

                p {
                    white-space: normal;
                }
            }
        }

        .legal-txt {
            padding: 30px 0;
            font-size: 12px;
            line-height: 18px;
            white-space: normal;
            color: #747474;
        }
    }

    .active {
        margin-top: -7px;

        h3 {
            color: $brand-color-blue;
        }

        h4 {
            @include media(bp-lt-md) {
                padding-top: 0px;
            }
        }

        .info {
            border: 4px solid $brand-color-blue;
            min-height: 530px;

            @include media(bp-lt-md) {
                min-height: 430px;
            }

            .card-content {
                padding: 0 30px 2px;

                @include media(bp-lt-md) {
                    padding: 0 20px 2px;
                }

                @include media(bp-md) {
                    padding: 0px 15px 2px;
                }

                .image {
                    margin-top: 20px;

                    img {
                        margin: 0 auto;
                    }
                }
            }
        }

        .caption {
            margin: 0;
            padding: 5px;
            background: $brand-color-blue;
            font-size: 16px;
            color: $white;
        }
    }
}
