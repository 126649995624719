@import '~common/scss/shared/shared';

.thirty-seventy-basic {
    display: inline-block;
    width: 100%;
    margin: 20px 0;

    .col {
        &:first-child {
            display: none;
        }

        &:last-child {
            width: 100%;
        }
    }

    &.image {
        div:first-child {
            display: block;
        }

        .col {
            display: inline-block;

            &:first-child {
                width: 29%;
                padding-right: 30px;
            }

            &:last-child {
                width: 69%;
            }
        }
    }

    .right {
        float: right;
    }

    .configure {
        text-align: center;
    }

    .copy h2 {
        margin-top: 0;
    }

    @include media(bp-lt-md) {
        margin: 10px 0;

        &.image {
            .col {
                display: block;

                img {
                    width: 100%;
                }

                &:first-child {
                    width: 100%;
                    padding-right: 0;
                    padding-bottom: 25px;
                }

                &:last-child {
                    width: 100%;
                }
            }
        }

        &.image .col {
            display: block;
            width: 100%;
        }

        .right {
            float: none;
        }
    }
}