@import '~common/scss/shared/shared';

.leaderboard-banner-container {
    .image-wrap {
        > a {
            display: flex;
            justify-content: center;
        }

        img {
            min-height: 70px;

            @media only screen and (max-width: 1440px) {
                min-height: calc(70 * 100vw / 1440);
            }

            @include media(bp-lt-md) {
                min-height: calc(124 * 100vw / 1440);
            }
        }
    }
}

.leaderboard-banner {
    position: relative;

    .cta {
        position: absolute;
        top: 30%;
        right: 18%;
        text-transform: uppercase;
    }

    .image-wrap {
        text-align: center;
    }
}
