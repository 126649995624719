@import '~common/scss/shared/shared';
@import 'variables';

.top-nav-v2 {
    .primary-nav-v2 {
        .main-nav-wrapper-container {
            .main-nav-wrapper {
                ul {
                    &.menu-left {
                        > li {
                            .sub-nav {
                                background: $colorWhite;
                                height: auto;
                                width: $nav-main-sub-nav-div-width;
                                left: 0;
                                right: 0;
                                min-height: 100vh;
                                position: absolute;
                                top: 0;
                                z-index: $z-nav + 4;
                                overflow: auto;
                                text-align: left;
                                cursor: default;
                                padding: 0;
                                margin-top: 82px;
                                @include media(bp-gt-sm) {
                                    top: -999em;
                                    height: auto;
                                    min-height: $nav-main-sub-nav-div-height;
                                    overflow: hidden;
                                    box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.6);
                                    margin-top: 0;
                                    opacity: 0;
                                }

                                @include media(bp-gt-sm) {
                                    padding-left: 22%;
                                }
                                @include media(bp-gt-md) {
                                    padding-left: 22%;
                                }
                                @include media(bp-gt-lg) {
                                    padding-left: 22vw;
                                }
                                @include media(bp-md) {
                                    padding-left: 0%;
                                }

                                hr {
                                    margin: 0;
                                    height: 14px;
                                }

                                &.overflow-visible {
                                    overflow: visible;
                                }
                                &.sub-nav-content {
                                    text-align: left;
                                    width: 100%;
                                    background-color: $colorWhite;
                                    @media only screen and (min-width: $subnav-max-width) {
                                        left: calc(-50vw - ($subnav-max-width / 2));
                                        right: calc(-50vw - ($subnav-max-width / 2));
                                        margin-left: auto;
                                        margin-right: auto;
                                    }

                                    > img {
                                        float: left;
                                    }

                                    h3 {
                                        &.back {
                                            display: flex;
                                            align-items: center;
                                            padding: 16px 19px 16px 19px;
                                            border-top: 1px solid $colorGrey2;
                                            border-bottom: 1px solid $colorGrey2;
                                            @include media(bp-gt-sm) {
                                                display: none;
                                            }
                                            span {
                                                &.mobile {
                                                    &.back {
                                                        text-indent: -9999px;
                                                        margin-right: 8px;
                                                        display: flex;
                                                        align-items: center;
                                                        @include media(bp-gt-sm) {
                                                            display: none;
                                                        }
                                                        &:after {
                                                            border: solid $colorBlack;
                                                            border-width: 0 2px 2px 0;
                                                            content: '';
                                                            display: block;
                                                            height: 4px;
                                                            padding: 1px;
                                                            position: relative;
                                                            transform: rotate(135deg);
                                                            width: 4px;
                                                        }
                                                    }
                                                }
                                            }
                                            a {
                                                &.landing-link {
                                                    font-family: $font-family-base;
                                                    color: $colorBlack !important; //TODO: Remove this !important
                                                    font-size: 16px;
                                                    font-style: normal;
                                                    font-weight: 900;
                                                    line-height: 20px;
                                                    text-decoration: none;
                                                    margin-bottom: 0;
                                                    text-transform: capitalize;
                                                }
                                            }
                                        }
                                    }

                                    &.tires {
                                        display: block;
                                        transition: left 0.5s;
                                        left: -100vw;
                                        @include media(bp-gt-sm) {
                                            left: 0;
                                            background-image: url('https://s7d1.scene7.com/is/image/bridgestone/tires-bg-nav-global-web-bsro?scl=1'),
                                                linear-gradient(179deg, #ebebeb 54.86%, #dbd8da 99.4%);
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            background-position: bottom left;
                                        }

                                        @include media(bp-md) {
                                            left: 0;
                                            background-image: none;
                                            background: linear-gradient(179deg, #ebebeb 54.86%, #dbd8da 99.4%);
                                        }

                                        @media only screen and (min-width: $subnav-max-width) {
                                            left: calc(-50vw - 960px);
                                        }
                                        &.is-active {
                                            left: 0;
                                            top: 0;
                                        }
                                    }
                                    &.repair {
                                        display: block;
                                        background: none;
                                        background-color: $colorWhite;
                                        transition: left 0.5s;
                                        left: -100vw;
                                        @include media(bp-gt-sm) {
                                            left: 0;
                                            background-image: url('https://s7d1.scene7.com/is/image/bridgestone/repair-bg-nav-global-web-bsro?scl=1'),
                                                linear-gradient(179deg, #ebebeb 54.86%, #dbd8da 99.4%);
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            background-position: bottom left;
                                        }
                                        @include media(bp-gt-lg) {
                                            background-size: cover;
                                        }
                                        @include media(bp-md) {
                                            background-image: none;
                                            background: linear-gradient(179deg, #ebebeb 54.86%, #dbd8da 99.4%);
                                        }
                                        @media only screen and (min-width: $subnav-max-width) {
                                            left: calc(-50vw - 960px);
                                        }
                                        &.is-active {
                                            left: 0;
                                            top: 0;
                                        }
                                    }
                                    &.maintenance {
                                        display: block;
                                        background: none;
                                        background-color: $colorWhite;
                                        transition: left 0.5s;
                                        left: -100vw;
                                        @include media(bp-gt-sm) {
                                            left: 0;
                                            background-image: url('https://s7d1.scene7.com/is/image/bridgestone/maintenance-bg-nav-global-web-bsro?scl=1'),
                                                linear-gradient(179deg, #ebebeb 54.86%, #dbd8da 99.4%);
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            background-position: bottom left;
                                        }
                                        @include media(bp-gt-lg) {
                                            background-size: cover;
                                        }
                                        @include media(bp-md) {
                                            background-image: none;
                                            background: linear-gradient(179deg, #ebebeb 54.86%, #dbd8da 99.4%);
                                        }
                                        @media only screen and (min-width: $subnav-max-width) {
                                            left: calc(-50vw - 960px);
                                        }
                                        &.is-active {
                                            left: 0;
                                            top: 0;
                                        }
                                    }

                                    .services {
                                        height: auto;
                                        min-height: 100vh;
                                        @include media(bp-gt-sm) {
                                            padding: 30px;
                                            width: 100%;
                                            padding-left: 20px;
                                            float: left;
                                            height: 100%;
                                            min-height: unset;
                                            display: flex;
                                        }
                                        @include media(bp-lt-lg) {
                                            position: absolute;
                                            left: 0;
                                        }

                                        .service-items {
                                            padding: 0 !important; //TODO: Remove this !important
                                            @include media(bp-gt-sm) {
                                                width: 64%;
                                                border-right: 1px solid $colorGrey3;
                                                padding-right: 40px;
                                                float: left;
                                                height: 100%;
                                            }

                                            h3 {
                                                @include font-title;
                                                font-weight: 400;
                                                font-size: 16px;
                                                color: $colorRed2;
                                                line-height: 20px;
                                                padding: 16px 19px 16px 19px;
                                                margin-bottom: 0;
                                                text-transform: capitalize;
                                                @include media(bp-gt-sm) {
                                                    text-transform: uppercase;
                                                    color: $colorBlack;
                                                    padding-left: 0;
                                                }
                                            }

                                            > a {
                                                width: 100%;
                                                color: $colorBlack !important; //TODO: Remove this !important
                                                font-family: $font-family-base;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 20px;
                                                padding: 16px 19px 16px 19px;
                                                margin-bottom: 0 !important; //TODO: Remove this !important
                                                @include media(bp-gt-sm) {
                                                    line-height: 1;
                                                    display: inline-block;
                                                    width: 32%;
                                                    height: auto;
                                                    padding: 0;
                                                    font-size: calc(10px + 0.390625vw);
                                                }
                                                @include media(bp-gt-lg) {
                                                    font-size: 16px;
                                                }
                                                span {
                                                    &.icon {
                                                        display: none;
                                                        @include media(bp-gt-sm) {
                                                            display: inline-block;
                                                            vertical-align: middle;
                                                            width: 35%;
                                                        }
                                                        img {
                                                            max-width: 48px;
                                                            padding: 8px 0 !important;
                                                        }
                                                    }

                                                    &:last-child {
                                                        width: 100%;
                                                        line-height: inherit !important; //TODO: Remove this !important
                                                        @include media(bp-gt-sm) {
                                                            width: 65%;
                                                        }
                                                    }
                                                }
                                                &:hover {
                                                    color: $colorRed5 !important;
                                                }
                                            }
                                        }

                                        .schedule-cta {
                                            display: none;
                                            width: 36%;
                                            padding-left: 40px;
                                            font-size: 16px;
                                            float: left;
                                            height: 100%;
                                            @include media(bp-lt-lg) {
                                                flex: 1 1 50% !important;
                                            }
                                            @include media(bp-gt-sm) {
                                                display: block;
                                                width: auto;
                                                flex: 1 1 36%;
                                            }
                                            @include media(bp-gt-lg) {
                                                padding-right: 40px;
                                            }
                                            h2 {
                                                font-size: 24px;
                                            }
                                            h3 {
                                                margin-bottom: 12px;
                                                font-size: 16px;
                                            }
                                            h4 {
                                                font-size: 14px;
                                            }
                                            h5 {
                                                margin-bottom: 12px;
                                                font-size: 16px;
                                            }
                                            p {
                                                padding-right: 30%;
                                                font-size: 14px;
                                                margin-bottom: 15px;
                                                @include media(bp-gt-sm) {
                                                    padding-right: 0;
                                                }
                                            }
                                            .btn {
                                                color: $colorWhite !important;
                                                padding: 0 20px;
                                                height: 50px;
                                                line-height: 50px;
                                                @include media(bp-gt-sm) {
                                                    font-size: calc(10px + 0.390625vw);
                                                }
                                                @include media(bp-gt-xl) {
                                                    font-size: 17px;
                                                    min-width: 270px;
                                                }
                                            }
                                        }
                                    }

                                    &.offers-and-coupons {
                                        hr {
                                        }

                                        .col1 {
                                            position: relative;
                                            div {
                                                margin-left: 24%;

                                                h4 {
                                                    font-size: 16px;
                                                }

                                                p,
                                                a {
                                                    font-size: 13px;
                                                }
                                            }
                                        }
                                    }

                                    .tabs {
                                        @include media(bp-gt-sm) {
                                            position: relative;
                                        }
                                        &.shop-for-tires {
                                            position: relative;
                                            top: 0;
                                            width: auto;
                                            padding: 0;
                                            left: auto;
                                            @include media(bp-gt-sm) {
                                                position: relative;
                                                padding: 20px 0 40px 20px;
                                                height: auto;
                                                display: flex;
                                            }

                                            h3 {
                                                @include font-title;
                                                font-weight: normal;
                                                font-size: 17px;
                                                color: $colorRed2;
                                                padding: 16px 19px 16px 19px;
                                                margin-bottom: 0;
                                                text-transform: capitalize;
                                                @include media(bp-gt-sm) {
                                                    font-size: 17px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 20px;
                                                    margin-bottom: 24px;
                                                    color: $colorBlack;
                                                    padding-left: 0;
                                                    padding-right: 0;
                                                }
                                            }

                                            span.icon img {
                                                max-width: 48px;
                                                padding: 8px 0 !important;
                                            }

                                            .tab-nav {
                                                width: 100%;
                                                vertical-align: top;
                                                padding: 0;
                                                margin-bottom: 0;
                                                @include media(bp-gt-sm) {
                                                    width: 25%;
                                                    padding-right: 2%;
                                                    border-right: none;
                                                    margin-bottom: 20px;
                                                }
                                                @include media(bp-gt-md) {
                                                    width: auto;
                                                }
                                                @include media(bp-gt-md) {
                                                    padding-right: 30px;
                                                }
                                                @include media(bp-gt-lg) {
                                                    max-width: 25vw;
                                                }

                                                > ul {
                                                    padding-left: 0;
                                                    @include media(bp-gt-sm) {
                                                        position: relative;
                                                        width: 100%;
                                                    }
                                                    > li {
                                                        height: auto;
                                                        padding: 16px 20px 16px 20px;
                                                        @include media(bp-gt-sm) {
                                                            height: auto;
                                                            background: transparent !important; //TODO: Remove this !important
                                                            padding: 0;
                                                        }

                                                        > a {
                                                            color: $nav-link-color;
                                                            padding-left: 0;
                                                            cursor: pointer !important;
                                                            text-transform: none;
                                                            font-size: 14px;
                                                            line-height: 0.9em;
                                                            margin-bottom: 0;

                                                            @include media(bp-gt-sm) {
                                                                font-size: 13px;
                                                                margin-bottom: 15px;
                                                            }

                                                            span {
                                                                padding-left: 0;
                                                                padding-right: 56px;
                                                                color: $colorBlack;
                                                                font-family: $font-family-base;
                                                                font-size: 16px;
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                line-height: 20px;
                                                                @include media(bp-gt-sm) {
                                                                    font-size: calc(10px + 0.390625vw);
                                                                    white-space: nowrap;
                                                                }
                                                                @include media(bp-gt-lg) {
                                                                    font-size: 16px;
                                                                }
                                                            }
                                                            &:hover,
                                                            &:focus {
                                                                outline: none;
                                                                span {
                                                                    color: $colorRed2;
                                                                }
                                                            }
                                                        }

                                                        span {
                                                            &.indicator {
                                                                display: none;
                                                                @include media(bp-gt-sm) {
                                                                    display: block;
                                                                    background: none;
                                                                    margin: 0;
                                                                    &:after {
                                                                        border: solid $colorBlack;
                                                                        border-width: 0 2px 2px 0;
                                                                        content: '';
                                                                        display: block;
                                                                        height: 4px;
                                                                        padding: 1px;
                                                                        position: relative;
                                                                        top: 7px;
                                                                        left: 96%;
                                                                        transform: rotate(315deg);
                                                                        width: 4px;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        > a:hover,
                                                        > a:focus {
                                                            color: $nav-link-color-hover;
                                                            outline: none;
                                                        }

                                                        &.active {
                                                            span {
                                                                &.indicator {
                                                                    margin: 0 0 0 -8px;
                                                                }
                                                            }

                                                            > a {
                                                                color: $nav-link-color;
                                                            }

                                                            a:hover,
                                                            a:focus {
                                                                color: $nav-link-color-hover;
                                                                outline: none;
                                                            }

                                                            background: transparent !important;
                                                        }
                                                        &:last-child {
                                                            > a {
                                                                @include media(bp-gt-sm) {
                                                                    margin-bottom: 0;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .tab-content {
                                                width: 100%;
                                                height: auto;
                                                padding-left: 0;
                                                @include media(bp-gt-sm) {
                                                    display: inline-block;
                                                    width: auto;
                                                    height: auto;
                                                    padding-left: 1%;
                                                }
                                                @include media(bp-gt-md) {
                                                    padding-left: 0;
                                                }
                                                @include media(bp-gt-lg) {
                                                    padding-left: 10px;
                                                }
                                                .shop-by-vehicle {
                                                    width: 100% !important; //TODO: Remove this !important
                                                    > h3 {
                                                        display: none;
                                                        @include media(bp-gt-sm) {
                                                            display: block;
                                                            margin-bottom: 0;
                                                            padding-left: 20px;
                                                        }
                                                    }
                                                    .tire-brands__wrapper {
                                                        display: flex;
                                                        flex-wrap: wrap;
                                                        flex-direction: column;
                                                        width: 100%;
                                                        padding: 13px 20px 16px 20px;
                                                        @include media(bp-gt-sm) {
                                                            flex-direction: row;
                                                            padding: 13px 20px 16px 20px;
                                                        }
                                                        @include media(bp-gt-md) {
                                                            padding: 13px 20px 16px 20px;
                                                        }
                                                        .tire-brands__section {
                                                            border-bottom: none;
                                                            margin-bottom: 0;
                                                            @include media(bp-gt-sm) {
                                                                width: 50%;
                                                                flex: 0 50%;
                                                                margin-bottom: 15px;
                                                                position: relative;
                                                                padding-right: 1%;
                                                                padding-left: 0;
                                                            }
                                                            @include media(bp-gt-md) {
                                                                width: auto;
                                                                padding-right: 0;
                                                            }
                                                            @include media(bp-gt-lg) {
                                                                width: calc(40% - 20px);
                                                                padding-right: 20px;
                                                            }
                                                            .tire-brands__heading {
                                                                display: flex;
                                                                padding: 16px 19px 16px 0px;
                                                                margin-top: 0;
                                                                @include media(bp-gt-sm) {
                                                                    padding: 0;
                                                                    margin-bottom: 8px;
                                                                }
                                                                a {
                                                                    &.tire-brands__heading-cta {
                                                                        @include font-title;
                                                                        font-size: 15px !important; //TODO: Remove this !important
                                                                        font-style: normal;
                                                                        font-weight: 400;
                                                                        line-height: 19px;
                                                                        color: $colorRed2 !important; //TODO: Remove this !important
                                                                        margin-bottom: 0 !important; //TODO: Remove this !important
                                                                        text-transform: capitalize;
                                                                        text-decoration: none;
                                                                        @include media(bp-gt-sm) {
                                                                            width: auto !important; //TODO: Remove this !important
                                                                            display: flex !important; //TODO: Remove this !important
                                                                            justify-content: space-between;
                                                                            align-items: center;
                                                                            white-space: nowrap;
                                                                            color: $colorBlack !important; //TODO: Remove this !important
                                                                            text-decoration: none;
                                                                            margin-bottom: 7px;
                                                                            font-size: calc(
                                                                                10px + 0.390625vw
                                                                            ) !important; //TODO: Remove this !important;
                                                                            line-height: 17px;
                                                                            text-transform: none;
                                                                        }
                                                                        @include media(bp-gt-md) {
                                                                            font-size: 15px !important; //TODO: Remove this !important
                                                                        }
                                                                        &:after {
                                                                            border: solid $colorBlack;
                                                                            border-width: 0 2px 2px 0;
                                                                            content: '';
                                                                            display: none;
                                                                            height: 4px;
                                                                            padding: 1px;
                                                                            position: relative;
                                                                            top: 0;
                                                                            right: 0;
                                                                            transform: rotate(315deg);
                                                                            width: 4px;
                                                                            margin-left: 4px;
                                                                            @include media(bp-gt-sm) {
                                                                                display: block;
                                                                            }
                                                                        }
                                                                        &:hover {
                                                                            color: $colorRed5 !important;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            .tire-brands__list {
                                                                display: flex;
                                                                flex-wrap: wrap;
                                                                width: 100%;
                                                                justify-content: space-between;
                                                                @include media(bp-gt-sm) {
                                                                    width: 90%;
                                                                }
                                                                a {
                                                                    &.tire-brands__list-item {
                                                                        flex: 1 1 50%;
                                                                        padding: 0;
                                                                        border-bottom: none;
                                                                        position: relative;
                                                                        text-decoration: none;
                                                                        border-top: none !important; //TODO: Remove this !important
                                                                        margin-bottom: 5px !important; //TODO: Remove this !important
                                                                        min-height: 45px;
                                                                        min-width: 135px;
                                                                        @include media(bp-gt-sm) {
                                                                            margin-bottom: 0;
                                                                            flex: 0 46%;
                                                                        }
                                                                        svg {
                                                                            &.tire-brands__list-item__icon {
                                                                                fill: $colorBlack;
                                                                                position: absolute;
                                                                                top: 4px;
                                                                                left: 0;
                                                                                width: 17px;
                                                                                height: auto;
                                                                                min-height: 18px;
                                                                                path {
                                                                                    &:first-child {
                                                                                        fill: $colorRed3;
                                                                                    }
                                                                                }
                                                                                @include media(bp-gt-sm) {
                                                                                    width: 12px;
                                                                                }
                                                                                @include media(bp-gt-md) {
                                                                                    width: 15px;
                                                                                }
                                                                            }
                                                                        }
                                                                        span {
                                                                            @include media(bp-gt-sm) {
                                                                                margin-right: 15px;
                                                                            }
                                                                            @include media(bp-gt-md) {
                                                                                margin-right: 0;
                                                                            }
                                                                            &.tire-brands__list-item__title {
                                                                                margin-left: 22px;
                                                                                margin-bottom: 2px !important; // TODO: remove this !important
                                                                                font-family: $font-family-base;
                                                                                color: $colorBlack;
                                                                                font-size: 14px;
                                                                                line-height: 16px;
                                                                                @include media(bp-gt-sm) {
                                                                                    font-size: calc(9px + 0.390625vw);
                                                                                    font-style: normal;
                                                                                    font-weight: 400;
                                                                                    line-height: 16px;
                                                                                    margin-left: 20%;
                                                                                }
                                                                                @include media(bp-gt-md) {
                                                                                    font-size: 14px;
                                                                                    margin-left: 22px;
                                                                                }
                                                                            }
                                                                            &.tire-brands__list-item__subtitle {
                                                                                color: $colorGrey1;
                                                                                @include font-body;
                                                                                margin-left: 22px;
                                                                                width: 83%;
                                                                                font-style: italic;
                                                                                font-size: 12px;
                                                                                line-height: 16px;
                                                                                font-weight: 400;
                                                                                margin-right: 0;
                                                                                @include media(bp-gt-sm) {
                                                                                    font-size: calc(8px + 0.390625vw);
                                                                                    line-height: 13px;
                                                                                    margin-left: 20%;
                                                                                }
                                                                                @include media(bp-gt-md) {
                                                                                    font-size: 12px;
                                                                                    margin-left: 22px;
                                                                                }
                                                                            }
                                                                        }
                                                                        &:nth-last-child(2) {
                                                                            margin-bottom: 0;
                                                                        }
                                                                        &:last-child {
                                                                            margin-bottom: 0;
                                                                        }
                                                                        &:nth-child(even) {
                                                                            display: flex;
                                                                            flex-direction: column;
                                                                            flex: 0 0 40%;
                                                                            @include media(bp-gt-sm) {
                                                                                display: block;
                                                                                flex: 0 46%;
                                                                            }
                                                                        }
                                                                    }
                                                                    &:hover {
                                                                        &.tire-brands__list-item {
                                                                            span {
                                                                                &.tire-brands__list-item__title {
                                                                                    color: $colorRed5 !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &--bridgestone {
                                                                border-right: none;
                                                                border-bottom: none;
                                                            }
                                                            &--firestone {
                                                                border-bottom: none;
                                                                .tire-brands__list {
                                                                    a {
                                                                        &.tire-brands__list-item {
                                                                            svg {
                                                                                &.tire-brands__list-item__icon {
                                                                                    top: 1px;
                                                                                    path {
                                                                                        fill: $colorRed4;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &--suredrive {
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &:before {
                                                content: '';
                                                display: none;
                                                width: 143px;
                                                height: 113px;
                                                position: absolute;
                                                left: -13vw;
                                                bottom: 2%;
                                                background-image: url('https://assets.firestonecompleteautocare.com/content/dam/bsro/fcac/images/logos/90-day-try-buy-logo-outlined.png');
                                                background-repeat: no-repeat;
                                                background-size: contain;
                                                background-position: center;
                                                @include media(bp-gt-sm) {
                                                    display: block;
                                                }

                                                @include media(bp-md) {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.last-child {
                                .sub-nav {
                                    width: 680px;
                                }

                                border-left: 0px;
                            }
                            a {
                                &:hover {
                                    color: $colorRed5 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
