.compare-button {
    @include font-body-bold;
    border-top: 1px solid $brand-color-red;
    text-align: center;
    padding: 10px 0 13px;
    border-radius: 6px;
    margin: 30px -12px -10px;
    color: $brand-color-red;
    width: calc(100% + 24px);
    position: relative;
    display: block;
    height: 44px;
    cursor: pointer;

    svg {
        width: 14px;
        height: 14px;
        fill: $brand-color-red;
        position: relative;
        top: 4px;
    }

    /* Customize the label (the container) */
    .container {
        display: inline-block;
        position: absolute;
        padding-left: 35px;
        cursor: pointer;
        font-size: 13px;
        user-select: none;
        width: 37%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        min-width: 160px;

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            /* When the checkbox is checked, add a #c11b03 background */
            &:checked ~ .checkmark {
                background-color: #c11b03;
                border-color: $black;

                /* Show the checkmark when checked */
                &:after {
                    display: block;
                    border-color: $white;
                }
            }
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
            left: 4px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid #282828;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: $white;
        border: 1px solid #979797;

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }

    .wait {
        pointer-events: none;
    }

    &.show {
        color: $black;

        .checkmark {
            background-color: #c11b03;
        }
    }

    &.disabled {
        pointer-events: null;

        .container {
            color: #999;

            .checkmark {
                background-color: #ccc;
                border-color: #999;
            }
        }
    }

    &:hover {
        color: $black;
    }
}
