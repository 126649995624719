@import '~common/scss/shared/shared';
@import '~common/components/content/tabs/v1/scss/tabs';
@import 'variables';
@import 'mixins';

.header-v2,
.experience-fragment.header-navigation {
    display: none;

    .bsro-ui-global-nav-v2 & {
        display: block;
    }

    &:has(.author) {
        display: block;
    }

    .top-nav-v2 {
        box-shadow: none;
        position: relative;

        @include media(bp-gt-sm) {
            width: 100%;
            margin: 0 auto;
        }

        .primary-nav-v2 {
            @extend .tab-nav;

            position: relative;
            max-width: $wrapper-max-width;
            margin: 0 auto;
            background-color: $colorWhite;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: auto;
            width: 100%;
            padding: 12px 23px 12px 20px;
            font-family: $font-family-base;
            border-bottom: 1px solid $colorGrey;

            @include media(bp-gt-sm) {
                padding-left: 2%;
                padding-right: 2%;
                padding: 0;
                height: $nav-main-height;
            }

            @include media(bp-gt-md) {
                padding-left: $nav-margin;
                padding-right: $nav-margin;
            }

            .hamburger-mobile {
                display: flex;
                margin-right: 20px;
                padding: 5px;

                @include media(bp-gt-sm) {
                    display: none;
                }

                button {
                    &.hamburger--btn {
                        margin: 0;
                        padding: 0;
                        appearance: none;
                        box-shadow: none;
                        border-radius: 0;
                        border: none;
                        cursor: pointer;
                        width: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 5px;
                        position: relative;
                        background-color: transparent;

                        &:focus {
                            outline: none;
                        }

                        span {
                            &.bar {
                                width: 100%;
                                height: 1px;
                                background-color: $colorBlack;
                            }
                        }
                    }
                }
            }

            .logo-wrapper {
                text-align: left;
                padding-left: 0;
                width: auto;
                margin-right: 19px;
                filter: grayscale(1);
                transition: 1s filter linear;

                @include media(bp-gt-sm) {
                    margin-left: 5px;
                }

                @include media(bp-gt-md) {
                    margin-right: 0;
                    margin-left: 0;
                }

                &.show-animated {
                    filter: grayscale(0);
                }

                a {
                    display: block;

                    > img {
                        max-width: 120px;

                        @include media(bp-gt-md) {
                            max-width: 140px;
                        }

                        @include media(bp-gt-lg) {
                            max-width: 170px;
                        }
                    }

                    @include focusState(2px, -15px, 6px, -15px);
                }
            }

            .main-nav-wrapper-container {
                flex: 1 1 100%;

                .main-nav-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    @include media(bp-gt-sm) {
                        justify-content: space-between;
                    }

                    ul {
                        &.menu-left {
                            position: absolute;
                            top: 0;
                            left: -100vw;
                            width: 96vw;
                            max-width: 345px;
                            height: auto;
                            min-height: 100vh;
                            overflow: auto;
                            transition: left 0.5s;
                            z-index: $z-nav + 4;
                            background-color: $colorWhite;
                            margin-top: 0;
                            margin-bottom: 0;
                            margin-right: auto;
                            margin-left: 0;
                            padding-left: 0;
                            box-shadow: 2.879px 0px 3.839px 0px rgba(0, 0, 0, 0.25);
                            opacity: 0;
                            transition:
                                opacity 0.25s ease-in-out 0.25s,
                                left 0.5s;

                            @include media(bp-gt-sm) {
                                position: static;
                                left: 0;
                                width: auto;
                                max-width: 100%;
                                overflow: visible;
                                height: $nav-main-height;
                                min-height: unset;
                                transition: none;
                                z-index: unset;
                                background-color: transparent;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                flex: 1 1 auto;
                                padding: 0;
                                box-shadow: none;
                            }

                            @include media(bp-gt-md) {
                                margin-left: 2%;
                            }

                            @include media(bp-gt-lg) {
                                margin-left: 92px;
                            }

                            &.is-active {
                                left: 0;
                            }

                            &.show-animated {
                                opacity: 1;
                            }

                            > li {
                                display: flex;
                                height: auto;
                                margin-bottom: 0;

                                &:not(.mobile-only) {
                                    padding: 14px 51px 14px 21px;

                                    @include media(bp-gt-sm) {
                                        padding: 14px 0px 14px 0px;
                                    }

                                    @include media(bp-gt-md) {
                                        padding: 0;
                                    }
                                }

                                &:has(.mobile-only) {
                                    display: flex;

                                    @include media(bp-gt-sm) {
                                        display: none;
                                    }
                                }

                                &:has(.desktop-only) {
                                    display: none;

                                    @include media(bp-gt-sm) {
                                        display: flex;
                                    }
                                }

                                @include media(bp-gt-sm) {
                                    margin-right: 2%;
                                    height: 100%;
                                }

                                @include media(bp-gt-lg) {
                                    margin-right: $nav-padding;
                                }

                                &:last-child {
                                    margin-right: 0;
                                }

                                > a {
                                    display: flex;
                                    height: auto;
                                    text-transform: capitalize;
                                    white-space: normal;
                                    color: $colorBlack;
                                    text-decoration: none;
                                    font-size: calc(15px + 0.390625vw);
                                    line-height: 19px;
                                    font-weight: 400;
                                    width: 100%;
                                    position: relative;

                                    @include media(bp-gt-sm) {
                                        display: block;
                                        font-size: calc(10px + 0.390625vw);
                                        line-height: 20px;
                                        font-weight: 400;
                                        text-align: center;
                                        width: auto;
                                        align-self: center;
                                        position: relative;

                                        @include hover() {
                                            @include openNav();
                                            opacity: 1;
                                            transition: opacity 300ms ease 200ms;
                                        }

                                        &.is-open {
                                            @include openNav();
                                            @include menuDownArrow();
                                        }

                                        @include focusState(-20px, -10px, -20px, -10px, true);
                                    }

                                    @include media(bp-gt-md) {
                                        white-space: nowrap;
                                        text-align: unset;
                                    }

                                    @include media(bp-gt-lg) {
                                        font-size: 16px;

                                        @include focusState(-20px, -20px, -20px, -20px, true);
                                    }

                                    span {
                                        &:not(.arrow-down) {
                                            padding-top: 0;
                                            padding-bottom: 0;
                                            padding-left: 0;
                                            padding-right: 0;
                                            white-space: pre;

                                            &:before {
                                                display: none;
                                            }
                                        }

                                        &.arrow-down {
                                            display: flex;
                                            width: 100%;
                                            position: relative;
                                            left: 0;
                                            bottom: 0px;
                                            flex-direction: row;
                                            align-items: flex-end;
                                            justify-content: flex-end;

                                            &:after {
                                                border: solid $colorRed5;
                                                border-width: 0 2px 2px 0;
                                                content: '';
                                                display: block;
                                                height: 4px;
                                                padding: 1px;
                                                position: relative;
                                                top: -5px;
                                                transform: rotate(315deg);
                                                width: 4px;

                                                @include media(bp-gt-sm) {
                                                    top: 10px;
                                                    transform: rotate(45deg);
                                                }
                                            }

                                            @include media(bp-gt-sm) {
                                                display: none;
                                                position: absolute;
                                                align-items: center;
                                                justify-content: center;
                                            }
                                        }
                                    }
                                }

                                &.mobile-only {
                                    .menu-top {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 24px 19px 27px 35px;

                                        .mobile-logo {
                                            width: 100px;

                                            img {
                                                object-fit: cover;
                                            }
                                        }

                                        .mobile-close-btn {
                                            &:after {
                                                display: inline-block;
                                                content: '\00d7';
                                                font-size: 22px;
                                                line-height: 15px;
                                            }
                                        }
                                    }

                                    .ctas-container {
                                        padding: 19px;
                                        box-shadow: 0px -3.839px 3.839px 0px rgba(0, 0, 0, 0.25);
                                        margin-bottom: 15px;

                                        a {
                                            &.btn {
                                                border-radius: 4px;
                                            }
                                        }
                                    }

                                    @include media(bp-gt-sm) {
                                        display: none;
                                    }
                                }

                                &.desktop-hidden {
                                    @include media(bp-gt-sm) {
                                        display: none;
                                    }
                                }

                                &.contact-us {
                                    @include media(bp-gt-sm) {
                                        display: flex;
                                    }
                                }

                                &.tires,
                                &.repair,
                                &.maintenance {
                                    @include hover() {
                                        @include media(bp-gt-sm) {
                                            .sub-nav {
                                                top: $nav-main-height + $nav-secondary-height;
                                                left: 0;
                                                opacity: 1;
                                                transition: opacity 300ms ease 200ms;
                                            }

                                            > a {
                                                @include menuDownArrow();
                                            }

                                            &:after {
                                                display: none;

                                                @include media(bp-gt-sm) {
                                                    content: '';
                                                    display: block;
                                                    position: absolute;
                                                    background-color: transparent;
                                                    top: $nav-secondary-height;
                                                    left: 0;
                                                    width: 100%;
                                                    height: $nav-main-height + $nav-secondary-height;
                                                    z-index: $z-nav + 2;
                                                }
                                            }
                                        }

                                        @media only screen and (min-width: $subnav-max-width) {
                                            .sub-nav {
                                                left: calc(-50vw - 960px);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.menu-right {
                            margin-top: 0;
                            margin-bottom: 0;
                            margin-left: 0;
                            margin-right: 0;
                            position: relative;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            flex: 0 0 25%;
                            padding: 0;
                            height: auto;
                            align-items: center;
                            justify-content: flex-end;
                            opacity: 0;
                            transition: opacity 0.25s ease-in-out 0.5s;

                            @include media(bp-gt-md) {
                                height: $nav-main-height;
                                top: 0;
                            }

                            &.show-animated {
                                opacity: 1;
                            }

                            > li {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: flex-start;
                                margin-right: 0;
                                min-width: 37px;

                                @include media(bp-gt-sm) {
                                    justify-content: center;
                                    min-width: unset;
                                    margin-right: 2%;
                                }

                                @include media(bp-gt-md) {
                                    margin-right: 4%;
                                }

                                @include media(bp-gt-lg) {
                                    margin-right: 35px;
                                }

                                &:last-child {
                                    margin-right: 0;
                                }

                                a {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 16px;
                                    height: 100%;
                                    text-transform: capitalize;
                                    justify-content: flex-end;
                                    color: $colorBlack;
                                    text-decoration: none;

                                    @include focusState(-5px, 0, -5px, 0);

                                    @include media(bp-gt-sm) {
                                        font-size: 10px;
                                        @include focusState(10px, -10px, 5px, -10px);
                                    }

                                    @include media(bp-gt-md) {
                                        font-size: 12px;
                                    }
                                }

                                &.blog {
                                    a {
                                        background: url('https://s7d1.scene7.com/is/content/bridgestone/firestone-shield-icon-web-global-bsro')
                                            transparent center no-repeat;
                                        background-size: 18px 18px;
                                        background-position: top center;

                                        @include media(bp-gt-sm) {
                                            background-position: center;
                                            background-size: 22px 23px;
                                        }

                                        @include media(bp-gt-md) {
                                            background-position: center;
                                            background-size: 27px 28px;
                                        }

                                        span {
                                            position: relative;
                                            padding-left: 9px;
                                            padding-right: 9px;
                                            margin-top: 18px;

                                            @include media(bp-gt-sm) {
                                                top: -7px;
                                                margin-top: 27px;
                                            }
                                        }
                                    }
                                }

                                &.account {
                                    flex-direction: column;
                                    justify-content: center;
                                    cursor: pointer;
                                    padding-left: 5px;
                                    padding-right: 5px;
                                    position: relative;

                                    @include media(bp-gt-sm) {
                                        padding-left: 0;
                                        padding-right: 0;
                                    }

                                    .desktop-account-menu-wrap {
                                        height: 100%;
                                        display: flex;
                                        position: unset;

                                        > a {
                                            display: none;
                                            flex-direction: column;
                                            align-items: center;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 16px;
                                            height: 100%;
                                            text-transform: capitalize;
                                            background: url('https://s7d1.scene7.com/is/content/bridgestone/active-sign-in-user-icon-global-web-bsro')
                                                transparent center no-repeat;
                                            background-size: 18px 18px;
                                            background-position: top center;

                                            @include media(bp-gt-sm) {
                                                background-size: 23px 23px;
                                                background-position: center;
                                            }

                                            @include media(bp-gt-md) {
                                                background-size: 28px 28px;
                                                background-position: center;
                                            }

                                            &:last-child {
                                                display: flex;
                                                background: url('https://s7d1.scene7.com/is/content/bridgestone/inactive-sign-in-user-icon-global-web-bsro')
                                                    transparent center no-repeat;
                                                background-size: 18px 18px;
                                                background-position: top center;

                                                @include media(bp-gt-sm) {
                                                    background-size: 23px 23px;
                                                    background-position: center;
                                                }

                                                @include media(bp-gt-md) {
                                                    background-size: 28px 28px;
                                                    background-position: center;
                                                }
                                            }

                                            span {
                                                position: relative;
                                                padding-left: 9px;
                                                padding-right: 9px;
                                                white-space: nowrap;
                                                margin-top: 18px;

                                                @include media(bp-gt-sm) {
                                                    top: -7px;
                                                    margin-top: 27px;
                                                }
                                            }
                                        }

                                        .nav-account-panel {
                                            display: none;
                                            border: 1px solid $colorGrey2;
                                            border-top: none;
                                            &.is-active {
                                                display: flex;
                                            }

                                            a {
                                                @include focusState(-14px, -10px, -10px, -10px);

                                                @include media(bp-gt-sm) {
                                                    @include focusState(-10px, -10px, -10px, -10px);
                                                }
                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }

                                    .nav-account-toggle {
                                        background: url('https://s7d1.scene7.com/is/content/bridgestone/active-sign-in-user-icon-global-web-bsro')
                                            transparent center no-repeat;
                                        background-size: 18px 18px;
                                        background-position: top center;
                                        padding-top: 22px;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 15px;
                                        border: none;

                                        @include focusState(-7px, -5px, -7px, -5px);

                                        @include media(bp-gt-sm) {
                                            background-size: 23px 23px;
                                            margin-top: 24px;
                                            padding-top: 27px;
                                            line-height: 16px;

                                            @include focusState(-15px, -10px, 5px, -10px);
                                        }

                                        @include media(bp-gt-md) {
                                            background-size: 28px 28px;
                                        }

                                        &:hover {
                                            text-decoration: none;
                                        }
                                    }

                                    .nav-account-panel {
                                        top: $nav-secondary-height + 8;
                                        margin-top: 0;
                                        z-index: $z-nav + 4;
                                        background-color: $colorWhite;
                                        border: none;
                                        padding: 0;
                                        flex-direction: column;
                                        flex: 1 1 auto;
                                        width: 375px;
                                        height: auto;
                                        max-height: 809px;
                                        right: -6vw;

                                        @include media(bp-gt-sm) {
                                            top: $nav-main-height;
                                            width: 288px;
                                            max-height: 844px;
                                            left: unset;
                                            right: 0;
                                        }

                                        .nav-account-info {
                                            padding: 30px 30px 55px 44px;
                                            border-bottom: 1px solid $colorGrey2;

                                            @include media(bp-gt-sm) {
                                                padding: 26px 20px 25px 37px;
                                            }

                                            button {
                                                &.close-btn {
                                                    $close-small: 20px;
                                                    $close-large: 30px;

                                                    @include focusState(-5px, -5px, -5px, -5px);

                                                    position: absolute;
                                                    margin: 0;
                                                    padding: 0;
                                                    right: 20px;
                                                    height: $close-small;
                                                    width: $close-small;
                                                    background: none;
                                                    border: none;
                                                    outline: none;
                                                    cursor: pointer;

                                                    @include media(bp-gt-sm) {
                                                        width: $close-large;
                                                        height: $close-large;

                                                        @include focusState(-5px, -5px, -4px, -5px);
                                                        position: absolute;
                                                    }

                                                    img {
                                                        $img-small: 16px;
                                                        $img-large: 20px;

                                                        width: $img-small;
                                                        height: $img-small;

                                                        @include media(bp-gt-sm) {
                                                            width: $img-large;
                                                            height: $img-large;
                                                        }
                                                    }
                                                }
                                            }

                                            h3 {
                                                @include font-title;
                                                font-size: 29px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                text-transform: uppercase;

                                                @include media(bp-gt-sm) {
                                                    font-size: 25px;
                                                }
                                            }
                                        }

                                        ul {
                                            flex: 1 1 auto;
                                            display: flex;
                                            flex-direction: column;

                                            li {
                                                display: flex;
                                                height: auto;
                                                padding: 28px 33px 20px 44px;
                                                justify-content: flex-end;
                                                align-items: center;
                                                border-top: none;
                                                border-bottom: 1px solid $colorGrey2;
                                                position: relative;

                                                @include media(bp-gt-sm) {
                                                    padding: 15px 20px 15px 37px;
                                                    border-bottom: none;
                                                }

                                                span {
                                                    &.notification {
                                                        position: absolute;
                                                        visibility: visible;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        background-color: $colorRed2;
                                                        width: 14px;
                                                        height: 14px;
                                                        border-radius: 50%;
                                                        @include font-body;
                                                        font-size: 12px;
                                                        color: $colorWhite;
                                                        font-style: normal;
                                                        font-weight: 500;
                                                        line-height: normal;
                                                        left: 16px;
                                                    }
                                                }

                                                a {
                                                    color: $colorBlack;
                                                    background: none;
                                                    align-items: flex-start;
                                                    @include font-body;
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 14px;
                                                    padding: 0 !important;

                                                    @include media(bp-gt-sm) {
                                                        font-size: 16px;
                                                        line-height: 20px;
                                                    }
                                                }

                                                &.nav-sign-out {
                                                    @include media(bp-gt-sm) {
                                                        border-bottom: none;
                                                    }

                                                    a {
                                                        font-size: 14px;
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        line-height: 17px;

                                                        @include media(bp-gt-sm) {
                                                            line-height: 16px;
                                                        }
                                                    }
                                                }

                                                &:not(.nav-sign-out) {
                                                    a {
                                                        position: relative;

                                                        &:after {
                                                            border: solid $colorBlack;
                                                            border-width: 0 2px 2px 0;
                                                            content: '';
                                                            display: block;
                                                            height: 4px;
                                                            padding: 1px;
                                                            position: absolute;
                                                            transform: rotate(315deg);
                                                            width: 4px;
                                                            right: 0;
                                                            top: 4px;

                                                            @include media(bp-gt-sm) {
                                                                top: 6px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        &.is-active {
                                            display: flex;
                                        }
                                    }

                                    #toolTipApp {
                                        position: absolute;
                                        top: 45px;
                                        z-index: $z-nav + 5;
                                        cursor: auto;
                                        right: 0;

                                        @include media(bp-gt-sm) {
                                            top: 90px;
                                            right: -30px;
                                        }

                                        @include media(bp-md) {
                                            right: 0;
                                        }

                                        .tool-tip {
                                            background-color: $colorBlack;
                                            opacity: 0.9;
                                            border-radius: 13px;
                                            padding: 1px 27px 10px 15px;
                                            display: block;
                                            width: 207px;
                                            opacity: 1;
                                            transition: opacity 0.3s ease;

                                            @include media(bp-gt-sm) {
                                                width: 331px;
                                                padding-bottom: 1px;
                                            }

                                            p {
                                                @include font-body;
                                                color: $colorWhite;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 16px;

                                                @include media(bp-gt-sm) {
                                                    font-size: 16px;
                                                }

                                                &.lighter {
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 16px;

                                                    @include media(bp-gt-sm) {
                                                        font-size: 16px;
                                                        line-height: 23px;
                                                    }
                                                }
                                            }

                                            a {
                                                &.close {
                                                    @include font-body;
                                                    color: $colorWhite;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 17px;
                                                    text-decoration-line: underline;
                                                    display: flex;
                                                    flex-direction: row;

                                                    @include media(bp-gt-sm) {
                                                        font-size: 13px;
                                                        top: -20px;
                                                    }
                                                }
                                            }

                                            &:after {
                                                content: '';
                                                position: absolute;
                                                bottom: 100%;
                                                left: calc(50% + 55px);
                                                border-width: 10px;
                                                border-style: solid;
                                                border-color: transparent transparent $colorBlack transparent;

                                                @include media(bp-gt-sm) {
                                                    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='32' height='27' viewBox='0 0 32 27' fill='none'> <path d='M16 0L31.5885 27H0.411543L16 0Z' fill='black' fill-opacity='0.9'/> </svg>");
                                                    border: none;
                                                    left: calc(100% - 70px);
                                                    bottom: 90%;
                                                }

                                                @include media(bp-md) {
                                                    left: calc(100% - 40px);
                                                }
                                            }
                                        }

                                        &.has-visited-site {
                                            .tool-tip {
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }

                                &.cart {
                                    display: none;

                                    .ecomm-cart-icon {
                                        background: url('https://s7d1.scene7.com/is/content/bridgestone/cart-icon-global-web-bsro')
                                            transparent center no-repeat;
                                        background-size: 18px 18px;
                                        background-position: top center;
                                        position: relative;
                                        height: 20px;
                                        width: 50px;
                                        display: block;
                                        top: unset;
                                        right: unset;
                                        margin-top: 0px;
                                        padding: 0;
                                        @include focusState(-5px, -5px, -12px, -5px);

                                        @include media(bp-gt-sm) {
                                            height: 50px;
                                            background-position: center 22px;
                                            background-size: 23px 23px;
                                        }

                                        @include media(bp-gt-md) {
                                            background-size: 28px 28px;
                                            background-position: center 11px;
                                        }

                                        @include media(bp-md) {
                                            top: -8px;
                                            .ecomm-cart-text {
                                                top: 35px !important;
                                                font-size: 10px !important;
                                            }
                                        }

                                        .counter {
                                            position: absolute;
                                            top: -7px;
                                            right: 11px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: $colorRed2;
                                            width: 13px;
                                            height: 13px;
                                            border-radius: 50%;
                                            @include font-body;
                                            font-size: 8px;
                                            color: $colorWhite;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: normal;

                                            @include media(bp-gt-sm) {
                                                top: 16px;
                                                right: 11px;
                                            }

                                            @include media(bp-gt-md) {
                                                width: 16px;
                                                height: 16px;
                                                top: 4px;
                                                right: 8px;
                                                font-size: 10px;
                                            }

                                            &.counter-0 {
                                                visibility: hidden;
                                            }
                                        }

                                        svg {
                                            display: none;
                                        }

                                        span {
                                            &.ecomm-cart-text {
                                                display: block;
                                                text-align: center;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 16px;
                                                position: relative;
                                                top: 18px;
                                                @include media(bp-gt-md) {
                                                    top: 26px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .secondary-nav-v2 {
            background-color: $colorWhite;
            height: $nav-secondary-height;
            font-family: $font-family-base;
            color: $colorBlack;
            text-align: left;
            flex-direction: row;
            display: flex;
            width: 100%;
            flex-direction: row;
            @include font-body;
            max-width: $wrapper-max-width;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 0;
            border-bottom: 1px solid $colorGrey;
            opacity: 0;
            transition: opacity 0.25s ease-in-out 0.75s;

            @include media(bp-gt-sm) {
                width: 100%;
                padding: 0;
                height: 48px;
                border-bottom: none;
            }

            &.show-animated {
                opacity: 1;
            }

            .links {
                display: flex;
                flex-direction: row;
                max-width: $wrapper-max-width;
                padding: 0;
                width: 100%;
                gap: 4.2%;

                @include media(bp-gt-sm) {
                    gap: 0;
                }

                .schedule-appointment {
                    display: none;
                    flex-direction: row;
                    align-items: center;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    margin-right: auto;
                    width: 51%;

                    @include media(bp-gt-sm) {
                        display: flex;
                        font-size: 14px;
                        width: auto;
                        padding-left: 2%;
                        border-bottom: 1px solid $colorGrey;
                        flex: 1 0 50vw;
                    }

                    @include media(bp-gt-md) {
                        padding-left: $nav-margin;
                    }

                    @include media(bp-gt-xl) {
                        flex: 1 1 auto;
                    }

                    a {
                        border-left: none;
                        color: $colorBlack;
                        text-decoration: none;
                        font-size: calc(10px + 0.390625vw);
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        display: flex;
                        align-items: center;

                        @include media(bp-gt-sm) {
                            z-index: $z-nav + 2;
                        }

                        @include media(bp-gt-md) {
                            font-size: 13px;
                        }

                        span {
                            &.icon {
                                background: url('https://s7d1.scene7.com/is/content/bridgestone/schedule-appointment-icon-global-web-bsro')
                                    transparent center left no-repeat;
                                background-size: contain;
                                width: 22px;
                                height: 22px;
                                margin-right: 8px;

                                @include media(bp-gt-md) {
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }

                        @include hover() {
                            color: $colorBlack;
                        }

                        @include focusState(-4px, -10px, -2px, -10px);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                #account-user-name {
                    display: none;

                    @include media(bp-gt-sm) {
                        // display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid $colorGrey;
                        flex: 1 0 20vw;
                    }

                    @include media(bp-gt-xl) {
                        flex: 1 1 auto;
                    }

                    span {
                        font-size: calc(11px + 0.390625vw);
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        white-space: nowrap;

                        @include media(bp-gt-sm) {
                            padding-left: 2%;
                        }

                        @include media(bp-gt-md) {
                            padding-left: $nav-margin;
                            font-size: 16px;
                        }
                    }
                }

                #my-garage {
                    .my-garage__opener {
                        @include focusState(-4px, -10px, -2px, -10px);

                        .close-btn {
                            &:after {
                                position: absolute;
                            }
                        }
                    }
                }

                #tab-my-store-container-v2 {
                    max-width: 375px;
                    width: calc(50vw - 20px);
                    flex: 1 0 calc(50vw - 40px);

                    @include media(bp-gt-sm) {
                        width: 25%;
                        flex: 1 0 auto;
                        padding-right: 2%;
                        border-bottom: 1px solid $colorGrey;
                        z-index: $z-nav + 2;
                    }

                    @include media(bp-gt-md) {
                        width: 375px;
                        padding-right: $nav-margin;
                    }

                    &.is-active {
                        border-bottom: none;
                        border-right: 1px solid $colorGrey;
                    }

                    .store-wrapper {
                        display: flex;
                        justify-content: space-between;

                        @include media(bp-gt-sm) {
                            margin-left: 20px;
                            margin-right: 0;
                        }

                        @include media(bp-gt-md) {
                            margin-left: 20px;
                        }

                        span {
                            &.icon {
                                background: url('https://s7d1.scene7.com/is/content/bridgestone/store-icon-global-web-bsro')
                                    transparent center no-repeat;
                                width: 22px;
                                height: 22px;
                                background-size: contain;
                                margin-top: 8px;
                                cursor: pointer;
                                display: none;

                                @include media(bp-gt-md) {
                                    width: 32px;
                                    height: 32px;
                                    display: flex;
                                }
                            }
                        }

                        .store-info {
                            margin-left: 0;
                            width: calc(100% - 24px);
                            padding-top: 8px;
                            top: 48px;
                            border-left: 0;
                            max-height: $nav-secondary-height;

                            @include media(bp-gt-sm) {
                                width: 100%;
                            }

                            @include media(bp-gt-md) {
                                margin-left: 12px;
                                width: calc(100% - 62px);
                                max-width: none;
                                overflow: hidden;
                            }

                            .info-container {
                                padding: 0;
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                cursor: pointer;
                                padding-right: 10px;

                                @include media(bp-gt-sm) {
                                    padding-right: 15px;
                                }

                                span {
                                    &.title {
                                        color: $colorDarkGrey;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px;
                                    }

                                    &.detail {
                                        @include font-body;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px;
                                        display: flex;
                                        color: $colorBlack;
                                        white-space: nowrap;

                                        @include media(bp-gt-md) {
                                            font-size: 14px;
                                        }

                                        .store-name {
                                            width: fit-content;
                                            max-width: fit-content;
                                            padding-right: 2px;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            display: inline-block;
                                            white-space: pre;
                                            position: relative;
                                            flex: 1 1 auto;

                                            @include media(bp-gt-md) {
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                display: inline-block;
                                            }

                                            &:before {
                                                content: '\00a0(';
                                            }

                                            &:after {
                                                content: '\00a0)\00a0';
                                                position: absolute;
                                                right: -4px;
                                            }

                                            &.no-store {
                                                &:before {
                                                    display: none;
                                                }

                                                &:after {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        $down-arrow-close-btn-size: 17px;

                        .arrow-down {
                            cursor: pointer;
                            top: auto;
                            align-self: center;
                            width: $down-arrow-close-btn-size;
                        }

                        .close-btn {
                            display: none;
                            position: relative;
                            width: $down-arrow-close-btn-size;
                            height: 14px;
                            right: 5px;
                            align-self: center;
                            cursor: pointer;

                            @include media(bp-gt-sm) {
                                left: -4px;
                            }

                            &:after {
                                position: absolute;
                                display: block;
                                content: '\00d7';
                                font-size: 30px;
                                line-height: 18px;

                                @include media(bp-gt-sm) {
                                    font-size: 30px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }

                    .store-wrapper > button {
                        background: none !important;
                        border: none;
                        padding: 0 !important;
                        text-decoration: underline;
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        .title {
                            text-align: left;
                        }

                        @include focusState(4px, 0, 5px, -10px);
                    }
                }

                span.arrow-down {
                    display: flex;
                    width: auto;
                    position: relative;
                    align-items: center;
                    max-height: $nav-secondary-height;
                    img {
                        display: none;
                    }

                    @include media(bp-gt-sm) {
                        margin-top: -6px;
                    }

                    &:after {
                        border: solid $colorBlack;
                        border-width: 0 2px 2px 0;
                        content: '';
                        display: block;
                        height: 6px;
                        padding: 1px;
                        position: relative;
                        transform: rotate(45deg);
                        width: 6px;
                    }
                }
            }
        }

        .pinnacleBannerContainer {
            display: none;
            flex-direction: column;
            align-items: center;
            padding: 16px;

            @include media(bp-gt-sm) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .pinnacleLogoMobile {
                width: 163.115px;
                height: 16px;

                @include media(bp-gt-sm) {
                    display: none;
                }
            }

            .pinnacleLogoDesktop {
                display: none;

                @include media(bp-gt-sm) {
                    display: flex;
                    width: 103.713px;
                    height: 31px;
                }
            }

            .pinnacleDetail {
                @include media(bp-gt-sm) {
                    flex-direction: column;
                }

                .pinnacleTitle {
                    color: $black;
                    @include font-title;
                    margin: 12px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: none;
                    white-space: nowrap;

                    @include media(bp-gt-sm) {
                        margin: 0;
                        text-align: center;
                    }
                }

                .pinnalceText {
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center !important;
                }
            }

            .pinnacleButton {
                background: transparent;
                color: red;
                border-color: red;
            }
        }

        &.author {
            .primary-nav-v2 {
                .logo-wrapper {
                    filter: grayscale(0);
                }

                .main-nav-wrapper-container {
                    .main-nav-wrapper {
                        ul {
                            &.menu-left {
                                opacity: 1;
                            }

                            &.menu-right {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .secondary-nav-v2 {
                opacity: 1;
            }
        }
    }

    .sub-nav {
        // Links in  the tires submenu
        .shop-for-tires a {
            @include focusState(-10px, -20px, -10px, -20px);
        }

        // Links in the repair and maintenance submenus
        .services {
            .schedule-cta a {
                @include focusState(-10px, -10px, -10px, -10px);
            }

            a {
                @include focusState();
            }
        }
    }
}
