@import '~common/scss/shared/shared';

.blog-container-heading {
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    border-top: 3px solid #e10c27;
    @include font-body-bold;
    padding-top: 4px;
    margin-top: 10px;
    margin-bottom: 15px;

    @include media(bp-gt-sm) {
        font-size: 20px;
        border-top: 4px solid #e10c27;
        padding-top: 5px;
        margin-bottom: 30px;
    }
}

.blog-category-grid-wrapper {
    padding: 20px 0;

    @include media(bp-gt-sm) {
        padding-bottom: 40px;
        padding-top: 35px;
    }

    .blog-category-grid {
        ul {
            margin: 0;
            padding: 0;
            justify-content: space-between;
            flex-wrap: wrap;
            display: flex;

            @include media(bp-gt-sm) {
                &::after {
                    content: '';
                    flex: 0 1 calc(33.33% - 10px);
                }
            }

            li {
                list-style: none;
            }
        }
        .blog-category-tile {
            flex: 0 1 calc(50% - 5px);
            margin-bottom: 10px;
            background-color: $white;
            box-shadow: 0px 0px 14px 1px #e6e6e6;
            height: 100%;
            text-decoration: none;

            @include media(bp-gt-md) {
                margin-bottom: 15px;
            }

            &:hover {
                @include media(bp-gt-md) {
                    .tile-image {
                        transform: scale(1.05);
                    }
                }
            }

            @include media(bp-gt-md) {
                flex: 0 1 calc(33.33% - 10px);
            }

            a {
                display: flex;
                align-items: baseline;
                width: 100%;
                align-items: center;
            }

            .tile-link {
                color: transparent;
            }

            .image-container {
                overflow: hidden;
                position: relative;

                &.is-video::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    margin: auto;
                    border: 0;
                    bottom: 0;
                    right: 0;
                    border: 0;
                    background: transparent;
                    box-sizing: border-box;
                    height: 10px;
                    border-color: transparent transparent transparent $white;
                    border-style: solid;
                    border-width: 7px 0 7px 11px;
                    width: 0;
                    z-index: 2;

                    @include media(bp-gt-sm) {
                        height: 20px;
                        border-width: 10px 0 10px 20px;
                    }

                    @include media(bp-gt-lg) {
                        height: 25px;
                        border-width: 17px 0 17px 30px;
                    }
                }
            }

            .tile-image {
                object-fit: cover;
                width: 67px;
                height: 67px;
                transition: transform 0.3s;
                position: relative;

                @include media(bp-gt-sm) {
                    width: 100px;
                    height: 100px;
                }

                @include media(bp-gt-lg) {
                    width: 140px;
                    height: 140px;
                }

                @include media(bp-gt-xl) {
                    width: 162px;
                    height: 162px;
                }
            }

            .tile-body {
                .tile-title {
                    color: $black;
                    margin-left: 10px;
                    font-size: 13px;
                    @include font-body-bold;
                    line-height: 1.2em;

                    @include media(bp-gt-sm) {
                        margin-left: 20px;
                        font-size: 22px;
                    }

                    @include media(bp-gt-lg) {
                        margin-left: 30px;
                        font-size: 30px;
                        width: 80%;
                    }

                    @include media(bp-gt-xl) {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}
