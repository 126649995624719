$order-summary-tooltip-width: 336px;
$order-summary-tooltip-width-mobile: 280px;

html.no-touch {
    .app-global {
        a.tooltip-origin {
            &:hover {
                color: #0072b5;

                .tooltip {
                    display: block;
                }
            }

            &.tooltip-origin-curbside {
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.app-global {
    a.tooltip-origin {
        position: relative;
        text-decoration: underline;
        color: #4a4a4a;
        line-height: 1.2em;
        cursor: pointer;

        &.active {
            color: #0072b5;

            .tooltip {
                display: block;
            }
        }

        &.tooltip-origin-curbside {
            position: absolute;
            top: 0;
            left: -25px;
            display: block;
            width: 19px;
            height: 19px;
            margin: 10px 5px 0 0;
            background: $brand-color-blue;
            border-radius: 50%;
            color: $white;
            text-decoration: none;
            text-align: center;
            font-weight: bold;
            font-size: 14px;

            &.active {
                color: $white;
            }
        }
    }

    .tooltip {
        position: absolute;
        z-index: 1;
        display: none;
        top: 25px;
        left: 4px;
        width: $order-summary-tooltip-width;
        padding: 10px;
        border-radius: 4px;
        background-color: #e4f0f9;
        font-weight: normal;
        text-align: left;
        font-size: 12px;
        line-height: 1.3em;
        color: #0072b5;
        box-shadow: 0 2px 24px 0 rgba($black, 0.1);

        @include media(bp-xs) {
            left: -32px;
            width: $order-summary-tooltip-width-mobile;
        }

        &::before {
            content: '';
            position: absolute;
            top: -10px;
            left: 5px;
            border-top: 5px solid transparent;
            border-left: 3px solid transparent;
            border-bottom: 5px solid #e4f0f9;
            border-right: 3px solid transparent;

            @include media(bp-xs) {
                left: 35px;
            }
        }

        // allows cursor to hover over tooltip without hiding.
        &::after {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            width: $order-summary-tooltip-width;
            height: 10px;

            @include media(bp-xs) {
                left: -20px;
                width: $order-summary-tooltip-width-mobile;
            }
        }

        ul {
            padding-left: 20px;
            line-height: 1.3em;
            list-style-type: disc;

            > li {
                padding: 0.25em 0;
            }
        }

        p {
            margin: 0.75em 0;
        }

        // fix spacing
        br {
            content: '';
            display: block;
            margin: 2em;
            font-size: 24%;
        }
    }
}
