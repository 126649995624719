@import '~common/scss/shared/shared';

$table-outer-border-color: #999;
$table-cell-border-color: #dcdcdc;
$table-head-bg-color: #808080;
$table-first-column-bg-color: #ebebeb;
$first-column-right-padding: 60px;

.product-compare {
    .outer-table-wrapper {
        position: relative;

        @include media(bp-lt-sm) {
            overflow: scroll;
        }
    }

    .inner-table-wrapper {
        margin-left: 224px; // offsets from the fixed position column.
        overflow-x: scroll;
        overflow-y: visible;
        -webkit-overflow-scrolling: touch;

        @include media(bp-lt-lg) {
            margin-left: 200px;
            padding-left: 0;
            box-sizing: border-box;
            table-layout: fixed;
        }

        @include media(bp-lt-sm) {
            overflow-x: visible;
        }
    }

    table {
        width: 100%;
    }

    // All Table Cells
    th,
    td {
        vertical-align: center;
    }

    // First Row and First Column Table Cells
    th {
        font-weight: bold;

        // First Column Table Cells
        &:first-child {
            position: absolute;
            top: auto;
            width: 225px;
            margin-top: 1px;
            padding: 10px $first-column-right-padding 0 8px;
            text-align: right;

            @include media(bp-gt-sm) {
                position: device-fixed;
                z-index: 9999 !important;
                left: 11px;
            }

            left: 0 !important;
        }
    }

    td {
        min-width: 240px;
        max-width: 420px;
        height: 44px;
        padding: 0 8px 0 30px;
    }

    thead {
        th,
        th:first-child {
            padding: 0.75em;
        }

        th {
            &:first-child {
                margin-top: 0;
                padding-right: $first-column-right-padding;
            }
        }
    }

    tbody {
        th {
            height: 44px;
        }
    }

    tfoot {
        th,
        td {
            height: 110px;
        }
    }

    .btn {
        padding-left: 40px;
        padding-right: 40px;
    }

    .uppercase {
        td {
            text-transform: uppercase;
        }
    }

    .product-img {
        th {
            height: 150px;
            padding-top: 60px;
        }

        td {
            height: 150px;
            padding: 15px 0;
            text-align: center;
        }
    }

    .characteristics {
        i {
            margin-left: 4px;
        }
    }

    .legend {
        margin: -37px 60px 30px;
        text-align: right;

        .label {
            display: inline;
            text-transform: uppercase;
            font-weight: normal;
        }

        ul {
            display: inline;
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            display: inline;
            padding-left: 10px;
        }
    }
    .inner-table {
        tr {
            td {
                span {
                    display: block;
                }
            }
        }

        tfoot {
            td {
                text-align: center;
            }
        }
    }

    .coral-Modal-body {
        padding-top: 3%;

        .tire-compare-error {
            position: absolute;

            h4 {
                position: relative;
                top: 20px;
            }
        }
    }

    .coral-Modal-title {
        font-size: 24px;
        padding: 0 0 25px;
    }

    .coral-Modal-header {
        border: 0;
    }

    td:first-child.white-bg {
        left: 0;
        border: 0;
        padding: 0 !important;
        position: absolute;
        text-align: right;
        top: auto;
        min-width: 225px !important;
    }

    .outer-row {
        border-right: 0 !important;
    }

    .inner-table-title {
        width: 225px;
        th:first-child {
            position: static;
            top: 0;
            text-align: center;
        }
        tr {
            td {
                padding: 10px 60px 0 8px;
                text-align: right;
                min-width: 125px !important;
            }
        }
        tfoot {
            tr {
                td {
                    height: 127px;
                    border-bottom: 0 !important;
                }
            }
        }
    }
    .white-bg {
        padding: 0;
        border-bottom: 0;
        border-top: 0;
    }

    .inner-table,
    .inner-table-title {
        th:first-child {
            position: static;
            top: 0;
            text-align: center;
            padding: 10px !important;
        }
        .product-img {
            td {
                text-align: center;
            }
        }
        td {
            text-align: left;
        }
    }

    padding: 10px 0 30px;
}

//ipad product-compare issue
.left-title-tab {
    display: none;
}

@include media(bp-md) {
    .left-title-tab {
        display: block;
        float: left;
        width: 200px;

        .inner-table-title {
            width: 200px;
        }
    }

    .inner-table-wrapper {
        padding-left: 0px !important;
    }
    .product-compare {
        th:first-child.desk-title {
            display: none;
        }

        .inner-table-title tfoot tr td {
            height: 110px !important;
        }

        .coral-Modal-body {
            top: 6%;

            .tire-compare-error {
                position: absolute;

                h4 {
                    position: relative;
                    top: 40px;
                }
            }
        }
        .inner-table th:first-child,
        .product-compare .inner-table-title th:first-child {
            left: -3px;
            border: 0 !important;
            padding: 11px !important;
        }

        .legend {
            margin: 25px 0px 30px;
        }

        td {
            padding: 0 8px 0 30px;
        }
    }
}
