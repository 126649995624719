@import '~common/scss/shared/shared';

.articles-grid {
    .container {
        .grid-area {
            .article {
                .cta {
                    a {
                        text-transform: uppercase;
                        font-family: 'Ultramagnetic';
                    }
                }
            }
            .article:not(.featured) {
                .image-wrap {
                    max-height: 125px;
                    overflow: hidden;
                }
            }
            .article.featured {
                img {
                    height: 418px;
                }
            }
        }
        @include media(bp-gt-md) {
            .grid-area {
                display: grid;
                grid-template-columns: 40% 30% 30%;
                gap: 20px;
                width: 100%;
                grid-template-rows: auto 1fr;
                .article {
                    width: 100%;
                    &.featured {
                        grid-column: 1;
                        width: 100%;
                        grid-row: span 2;
                    }
                    &:nth-child(n + 2) {
                        &:nth-child(odd) {
                            /* Articles 3 and 5 go in the second column of the 60% section */
                            grid-column: 3; /* Put them in the second column */
                        }
                        &:nth-child(even) {
                            /* Articles 2 and 4 go in the first column of the 60% section */
                            grid-column: 2; /* Put them in the second column */
                        }
                        &:nth-child(2),
                        &:nth-child(3) {
                            grid-row: 1;
                        }
                        &:nth-child(4),
                        &:nth-child(5) {
                            grid-row: 2;
                        }
                    }
                }
            }
        }
        @include media(bp-md) {
            .grid-area {
                display: grid;
                gap: 10px;
                width: 100%;
                padding: 20px;
                grid-template-columns: 100%;
                grid-template-rows: auto;
                grid-template-columns: 1fr 1fr;
                .article {
                    width: 100%;
                    &.featured {
                        grid-column: 1 / -1;
                        width: 100%;
                    }
                    &:nth-child(n + 2) {
                        grid-column: span 1;
                    }
                }
            }
        }
        @include media(bp-lt-md) {
            .grid-area {
                .article:not(.featured) {
                    .image-wrap {
                        max-height: 250px;
                        overflow: hidden;
                    }
                }
                .article.featured {
                    img {
                        height: 250px;
                    }
                }
            }
        }
    }
}
