@import '~common/scss/shared/shared';

.product-catalog {
    aside {
        h3 {
            font-size: 17px;
            margin: 10px 0;
        }

        .categories {
            ul {
                &.item {
                    margin: 7px;
                }

                &:first-of-type {
                    margin-bottom: 20px;
                }
            }
        }

        .title {
            padding: 15px !important;
        }

        .category {
            padding: 10px 0;
            margin: 0 15px;

            @include media(bp-lt-md) {
                padding: 0 10px;
            }
        }

        .custom-checkbox {
            label {
                &:before {
                    float: left;
                }

                span {
                    overflow: hidden;
                    display: block;
                }
            }
        }
    }

    @include media(bp-lt-md) {
        aside {
            .title {
                display: block !important;
                background: transparent !important;
            }

            .brand-filter {
                ul.item {
                    overflow: visible;
                    max-height: none;
                    opacity: 1;
                }
            }
        }

        .toolbar-top {
            height: auto !important;
            padding: 15px !important;
        }
    }

    .results {
        &[class*='brand'] {
            .product {
                display: none !important;
            }
        }

        &[class*='brand--Bridgestone'] {
            .brand--Bridgestone {
                display: table !important;
            }
        }

        &[class*='brand--Firestone'] {
            .brand--Firestone {
                display: table !important;
            }
        }

        &[class*='brand--Toyo'] {
            .brand--Toyo {
                display: table !important;
            }
        }

        &[class*='brand--Primewell'] {
            .brand--Primewell {
                display: table !important;
            }
        }
        &[class*='brand--Suredrive'] {
            .brand--Suredrive {
                display: table !important;
            }
        }

        &.filter-results {
            .product {
                /* display: none; */
            }
        }
    }

    .write-a-review-button {
        p {
            margin: 5px 0px 0px 0px;
        }
    }
}
