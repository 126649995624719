.offer-card {
    &__swiper-slide {
        flex: 0 0 80%;
        margin: 10px;

        @include media(bp-xs) {
            margin-bottom: 20px;
        }

        @include media(bp-gt-xs) {
            flex: 0 1 calc(46% - 0px);
        }

        @include media(bp-gt-nav) {
            flex: 0 1 calc(33.33% - 20px);
        }

        @include media(bp-gt-md) {
            flex: 0 1 calc(25% - 30px);
        }
    }

    &--featured {
        @include media(bp-gt-xs) {
            flex: 0 1 calc(46% - 0px);
            margin: 35px 10px;
        }

        @include media(bp-gt-nav) {
            margin-top: 0;
            flex: 0 1 calc(33.33% - 20px);
        }

        @include media(bp-gt-md) {
            margin-top: 0;
            flex: 0 1 calc(26.33% - 20px);
        }

        @include media(bp-gt-lg) {
            flex: 0 0 calc(50% - 30px);
        }

        .offer-card-refresh {
            @include media(bp-gt-xs) {
                margin: 35px 10px;
            }

            @include media(bp-gt-nav) {
                margin-top: 0;
            }

            max-width: 100%;

            &__icon {
                &-ribbon,
                &-arc {
                    display: none;
                }
            }

            &__body-wrap {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                min-height: 285px;

                @include media(bp-gt-lg) {
                    justify-content: space-between;
                    flex-direction: row;
                }
            }

            &__body-copy {
                @include media(bp-gt-md) {
                    flex: 0 0 72%;
                }
            }

            &__title,
            &__subtitle,
            &__subtitle1,
            &__subtitle2,
            &__discount-copy,
            &__summary {
                text-align: center;

                @include media(bp-gt-lg) {
                    text-align: left;
                    padding-left: 0;
                    margin-bottom: 10px;
                }
            }

            &__title,
            &__subtitle {
                font-size: 19px;
            }

            &__subtitle1,
            &__subtitle2 {
                font-size: 14px;
            }

            &__title {
                padding-top: 15px;

                @include media(bp-gt-lg) {
                    padding-top: 40px;
                }
            }

            &__separator {
                background-size: 100% 2px;
            }

            &__summary {
                font-size: 14px;
                margin-top: 15px;
                padding-bottom: 5px;

                @include media(bp-gt-md) {
                    margin-top: 20px;
                    font-size: 16px;
                    border: 0;
                }
            }

            &__phone-cta,
            &__print-cta {
                width: 100%;
            }

            &__print-cta {
                margin-left: 15px;
            }

            &__offer-image {
                display: block;
                width: 100px;
                margin-top: 15px;
                margin-right: 0;

                @include media(bp-gt-lg) {
                    margin-top: 0;
                    margin-right: 10px;
                    width: 180px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    right: 32px;
                    top: 76px;
                    width: 120px;
                    height: 120px;
                    background: #e3e3e3;
                    border-radius: 50%;
                    z-index: -1;

                    @include media(bp-xs) {
                        left: 40%;
                        top: 17px;
                        width: 69px;
                        height: 73px;
                    }
                    @include media(bp-md) {
                        left: 40%;
                        top: 17px;
                        width: 69px;
                        height: 74px;
                    }
                    @include media(bp-lg) {
                        left: 40%;
                        top: 17px;
                        width: 69px;
                        height: 74px;
                    }
                }
            }

            &__footer-copy {
                @include media(bp-gt-lg) {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    padding: 0;
                }
            }

            &__expiry-wrap {
                @include media(bp-gt-md) {
                    padding-bottom: 0;
                }
            }
        }

        &[data-offer-category='tire offers'] {
            .offer-card-refresh {
                &__offer-image {
                    &:before {
                        right: 31px;
                    }
                }
            }
        }
        .product-price__offer-icon {
            position: absolute;
            top: 16px;
            left: 16px;
        }
    }
}

.offer-card-refresh {
    $self: &;
    position: relative;
    flex: auto;
    margin: 35px auto;
    max-width: 300px;
    min-width: 270px;

    &:not(.offer-sign-in-only) {
        cursor: pointer;
    }

    &__offer-image {
        display: none;
    }

    &__icon {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 7px;
        filter: invert(100%);

        &-ribbon {
            background-color: $brand-color-red;
            position: absolute;
            width: 100%;
            transform: scale(0.85);
            border-radius: 10px;
            height: 70px;
            z-index: 0;
            top: -50px;
            text-align: center;
        }

        &-arc {
            position: absolute;
            width: 80px;
            height: 60px;
            background: $brand-color-red;
            left: 50%;
            transform: translateX(-50%);
            top: -37px;
            z-index: 1;
            border-radius: 50%;
        }
    }

    &__body-wrap,
    &__footer-wrap {
        background-color: $white;
        border-radius: 7px;
        text-align: center;
        border: 1px solid #d6d6d6;
        z-index: 0;
        padding: 0 15px;
        box-shadow: 1px 6px 10px -1px #e8e8e8;

        &--no-shadow {
            box-shadow: none;
        }
    }

    &__body-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 120px;
        border-bottom-color: $white;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &__body-copy {
        padding-top: 40px;
        .local-seo-page & {
            h3 {
                text-transform: none;
                font-family: 'Avenir', Arial, sans-serif !important;
            }
        }
    }

    &__discount-copy {
        color: $brand-color-red;
        margin: 0;
        margin-bottom: 5px;
        font-size: 30px;
        padding: 0 10px;
        overflow: hidden;
        word-break: break-word;
        display: -webkit-box;
        text-overflow: ellipsis;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__title,
    &__subtitle,
    &__subtitle1,
    &__subtitle2 {
        margin: 0;
        font-size: 14px;
        margin-bottom: 5px;
        text-align: center;
        line-height: 20px;
        padding: 0 10px;
    }

    &__summary {
        margin: 0;
        padding-bottom: 20px;
        font-size: 14px;
        text-transform: none;
        @include font-body;
        margin-top: 10px;
    }

    &__footer-wrap {
        border-top-color: $white;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &__ctas {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    &__phone-cta,
    &__print-cta,
    .signin-button {
        background: $brand-color-red;
        color: $white;
        text-decoration: none;
        padding: 17px 5px;
        border-radius: 5px;
        text-transform: uppercase;
        @include font-title;
        border: 2px solid $brand-color-red;
        display: inline-block;
        width: 100%;
        font-size: 12px;
        cursor: pointer;

        @include media(bp-gt-nav) {
            font-size: 10px;
        }

        @include media(bp-gt-sm) {
            font-size: 12px;
        }
    }

    &__phone-cta,
    .signin-button {
        &:hover,
        &:active {
            background-color: darken($brand-color-red, 10%);
            border-color: darken($brand-color-red, 10%);
            color: $white !important;
            text-decoration: none !important;
        }
    }

    .signin-button {
        max-width: 360px;
        margin-top: 16px;
    }

    &__print-cta {
        border: 2px solid #d6d6d6;
        background: $white;
        margin-left: 8px;
        color: $black;

        &:hover,
        &:active {
            background-color: darken($white, 10%);
            border-color: darken($white, 10%);
            color: $black !important;
            text-decoration: none !important;
        }
    }

    &__details-cta {
        text-transform: uppercase;
        color: $black;
        font-size: 12px;
        @include font-body-bold;
        margin-top: 10px;
        display: inline-block;
        margin-bottom: 13px;

        &:hover {
            color: $brand-color-red !important;
        }
    }

    &__expiry {
        &-wrap {
            font-size: 12px;
            font-style: italic;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 10px;
        }

        &-icon {
            margin-right: 5px;
        }

        &-days {
            font-weight: 600;
            margin-left: 5px;
        }

        &-date span {
            @include font-body-bold;
        }
    }

    &__separator {
        margin: -1px 10px;
        height: 20px;
        background: repeating-linear-gradient(to right, #cccccc 0, #cccccc 10px, transparent 10px, transparent 15px)
            bottom;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        position: relative;
        background-position: 50%;
        background-color: $white;

        &::before,
        &::after {
            box-sizing: border-box;
            content: '';
            position: absolute;
            width: 30px;
            height: 30px;
            border: 5px solid $white;
            border-radius: 50%;
            background: transparent;
            box-shadow: inset 0 0 0 1.3px #cccccc;
            z-index: 2;
        }

        &::before {
            left: -24px;
            clip: rect(0px, auto, auto, 15px);
            top: -5px;
        }

        &::after {
            right: -24px;
            clip: rect(15px, auto, auto, 0px);
            top: -5px;
            transform: rotate(90deg);
        }
    }
}
.offers-refresh__wrapper {
    .offer-card-refresh {
        .offer-card-refresh__ctas {
            display: flex;
        }
        .signin-button-wrapper {
            display: none;
        }
        &.offer-sign-in-only {
            .offer-card-refresh__ctas {
                display: none;
            }
            .signin-button-wrapper {
                display: block;
            }
        }
    }

    &.signed-in {
        .offer-card-refresh {
            .offer-card-refresh__ctas {
                display: flex;
            }
            .signin-button-wrapper {
                display: none;
            }
        }
    }
}
