@import '~common/scss/shared/shared';

.blog-hero {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    flex-direction: column;

    @include media(bp-gt-sm) {
        flex-direction: row;
    }

    @include media(bp-gt-md) {
        padding-top: 40px;
    }

    &__primary {
        @include media(bp-gt-sm) {
            min-width: 69%;
            width: calc((690px - 100%) * 1000);
            max-width: 100%;
        }

        @include media(bp-gt-md) {
            width: calc((700px - 100%) * 1000);
        }
    }

    .blog-sidebar {
        @include media(bp-gt-sm) {
            min-width: 30%;
            width: calc((48em - 100%) * 1000);
            max-width: 100%;
        }
    }

    .primary-card {
        position: sticky;
        top: 95px;
        width: 100%;
        max-width: 900px;
        height: auto;
        margin-bottom: 10px;
        overflow: hidden;
        border-bottom: none;
        cursor: pointer;
        $parent: &;

        @include media(bp-md) {
            top: 70px;
        }

        @include media(bp-gt-sm) {
            margin-bottom: 20px;
            margin-right: 20px;
            border-bottom: none;
        }

        &__top-bar {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40px;
            background: #ef3a33;
            mix-blend-mode: multiply;
            z-index: 1;

            @include media(bp-gt-sm) {
                height: 55px;
            }

            @include media(bp-gt-lg) {
                height: 70px;
            }
        }

        &__title {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            z-index: 2;
            width: 100%;
            z-index: 2;
            align-items: center;
            transition: 0.3s opacity;

            h2 {
                @include font-body-bold;
                text-transform: capitalize;
                color: $white;
                font-size: 20px;
                margin: 10px 0;

                @include media(bp-gt-sm) {
                    margin: 13px 0;
                    font-size: 27px;
                }

                @include media(bp-gt-lg) {
                    margin: 18px 0;
                    font-size: 32px;
                }
            }
        }

        &__brand-badge {
            height: 25px;
            width: 30px;
            margin: 0 5px 0 10px;

            @include media(bp-gt-sm) {
                height: 40px;
                width: 40px;
                margin: 0 10px 0 25px;
                display: inline-block;
            }
        }

        &:hover {
            & #{$parent}__image {
                img {
                    transform: scale(1.04);
                }
            }
        }

        &__image {
            overflow: hidden;
            height: 255px;
            background-color: #ebebeb;

            @include media(bp-gt-sm) {
                height: 420px;
            }

            @include media(bp-gt-lg) {
                height: 530px;
            }

            img {
                transform: scale(1);
                transition: 0.2s all;
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
        }

        &__copy {
            padding: 10px;

            @include media(bp-gt-sm) {
                padding: 15px;
            }
        }

        &__category {
            margin: 0;
            text-transform: uppercase;
            color: #e00000;
            font-size: 10px;
            letter-spacing: 1px;
            font-weight: 700;

            @include media(bp-gt-sm) {
                font-size: 12px;
            }
        }

        &__headline {
            margin: 0;
            font-size: 21px;
            color: $black;
            @include font-body-bold;
            text-transform: capitalize;
            width: 90%;
            padding: 5px 0;

            @include media(bp-gt-sm) {
                width: 100%;
                padding: 0;
                font-size: 28px;
                margin-top: 3px;
                margin-bottom: 5px;
            }

            @include media(bp-gt-lg) {
                font-size: 38px;
            }
        }

        &__publicationDate,
        &__readTime {
            margin: 0;
            display: flex;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            margin-bottom: 5px;
            display: inline-block;

            @include media(bp-gt-sm) {
                font-size: 14px;
                letter-spacing: 2px;
            }
        }

        &__publicationDate {
            font-weight: 500;
            color: $black;
        }

        &__readTime {
            color: #757575;
            display: inline-block;
        }

        &__separator {
            margin: 0;
            padding: 0 5px;
            color: $black;
            font-size: 5px;
            display: inline-block;
            position: relative;
            top: -2px;

            @include media(bp-gt-sm) {
                padding: 0 10px;
                font-size: 10px;
                top: 0;
            }
        }

        &__btn {
            text-decoration: none;
            text-transform: uppercase;
            border-radius: 5px;
            color: $white;
            display: inline-block;
            background-color: #e10c27;
            border: 3px solid #e10c27;
            border-radius: 5px;
            @include font-body-bold;
            font-size: 12px;
            padding: 8px 20px;

            @include media(bp-gt-sm) {
                font-size: 15px;
                padding: 10px 42px;
            }

            &:focus,
            &:active,
            &:hover {
                background-color: $white;
                text-decoration: none !important;
                color: #e10c27 !important;
            }
        }

        &__description {
            margin: 0;
            display: block;
            color: #575757;
            line-height: 23px;
            font-size: 14px;

            p {
                margin: 0;
                padding: 0;
            }

            &--text-ellipsis {
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    overflow: hidden;
                    position: relative;
                    max-height: 120px;
                    text-align: justify;
                    margin-right: -1em;
                    padding-right: 1em;

                    &:before {
                        content: '...';
                        position: absolute;
                        right: 12px;
                        bottom: 5px;
                        background: white;
                        box-shadow: -10px 2px 9px 0px $white;

                        @include media(bp-gt-sm) {
                            right: 15px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        right: 12px;
                        height: 1em;
                        background-color: $white;
                        width: 30px;

                        @include media(bp-gt-sm) {
                            right: 15px;
                        }
                    }
                }
            }

            @include media(bp-gt-sm) {
                margin-bottom: 10px;
                font-size: 16px;
            }

            p {
                padding: 0;
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }
}
