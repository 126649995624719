@import '~common/scss/shared/shared';

.product-carousel {
    padding: 40px 0;

    .carousel {
        width: 90%;
        margin: 40px auto;
    }

    .title {
        margin: 0.25em 0;
        text-align: center;
        line-height: 1.2em;
    }

    .swiper-container {
        & .swiper-wrapper {
            padding-bottom: 20px;
        }
        & .swiper-pagination {
            display: none;
        }

        & .swiper-button-prev,
        & .swiper-button-next {
            display: block;
            top: 60%;
        }

        & .swiper-button-prev::after,
        & .swiper-button-next::after {
            @include font-title;
            font-size: 36px;
            font-weight: normal;
        }

        & .swiper-button-prev {
            left: -10px;
        }

        & .swiper-button-next {
            right: -20px;
        }

        & .swiper-button-prev::after {
            content: '<';
        }

        & .swiper-button-next::after {
            content: '>';
        }

        @include media(bp-lt-lg) {
            & .swiper-pagination {
                display: block;
                bottom: 0;
            }

            & .swiper-button-prev,
            & .swiper-button-next {
                display: none;
            }
        }
    }

    .subtitle {
        margin: 0.5em 0;
        text-align: center;
        line-height: 1.2em;
    }

    .slide-content {
        margin-bottom: 10px;
        padding: 0 8%;
        text-align: center;
    }

    img {
        margin: 0 auto;
    }

    p {
        margin: 0.4em 0;
    }

    .img-container {
        img {
            max-height: 175px;
        }
    }

    .brand {
        margin-top: 20px;
    }

    .slide-content {
        padding: 20px 10px 10px;
        margin: 10px;
        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
            .product-name a {
                text-decoration: underline;
            }
        }
    }

    .product-category {
        text-transform: uppercase;
        text-align: center;
    }

    .product-name {
        text-transform: uppercase;
        text-align: center;
        a {
            text-decoration: none;
            &:hover {
                color: $brand-color-red;
            }
        }
    }

    .btn-container {
        text-align: center;
    }

    .tile-link {
        text-decoration: none;
    }

    @include media(bp-lt-lg) {
        .carousel {
            margin: 30px auto 75px;
        }

        .swiper-pagination-horizontal {
            position: relative;
        }
    }

    @include media(bp-lt-md) {
        padding: 30px 30px 20px;

        &:not(.no-gradient) {
            @include gradient-bottom();
        }
    }
}

.product-carousel:not(.no-gradient) {
    @include gradient-top();
}
