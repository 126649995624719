@import '~common/scss/shared/shared';

.horizontal-rule {
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: #dcdcdb;
    margin-bottom: 25px;
    margin-top: 10px;
}

.horizontal-rule.hrule-margin-collapsed {
    margin-bottom: 0;
}

.horizontal-rule.hrule-margin-narrow {
    margin-top: 10px;
    margin-bottom: 10px;
}

.horizontal-rule.hrule-margin-wide {
    margin-top: 30px;
    margin-bottom: 30px;
}

.horizontal-rule.hrule-black-plus {
    background-color: transparent;
    margin-bottom: 85px;
}
.horizontal-rule.hrule-black-plus-right {
    background-color: transparent;
    margin-bottom: 85px;
}
.horizontal-rule.hrule-black-plus > div {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    @include font-body;
    font-size: 70px;
    font-weight: bold;
    color: $black;

    &:before,
    &:after {
        content: '';
        flex-grow: 1;
        background: $black;
        height: 7px;
        font-size: 0px;
        line-height: 0px;
    }
    &:before {
        margin: 5px 10px 5px 20px;
    }
    &:after {
        margin: 5px 20px 5px 10px;
    }
}

.horizontal-rule.hrule-black-plus-right > div {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    @include font-body;
    font-size: 70px;
    font-weight: bold;
    color: $black;

    &:before {
        content: '';
        flex-grow: 1;
        background: $black;
        height: 7px;
        font-size: 0px;
        line-height: 0px;
    }
    &:before {
        margin: 5px 10px 5px 20px;
    }
}

@include media(bp-lt-sm) {
    .horizontal-rule.hrule-black-plus > div {
        font-size: 9.11vw;

        &:before {
            margin: 1.95vw 5.2vw 0 0;
        }
        &:after {
            margin: 1.95vw 0 0 5.2vw;
        }
    }

    .horizontal-rule.hrule-black-plus-right > div {
        font-size: 9.11vw;

        &:before {
            margin: 1.95vw 5.2vw 0 0;
        }
    }
}

.dotted-space {
    height: 50px;
}

.dotted-tight {
    height: 50px;
}

.diagonal,
.diagonal-lg {
    -webkit-background-size: 14px 14px;
    -moz-background-size: 14px 14px;
    background-size: 14px 14px;
    position: relative;
    height: 50px;
}

.diagonal:after,
.diagonal-lg:after {
    position: absolute;
    content: '';
    right: 0;
    background-color: $white;
    height: 50px;
    width: 15px;
}

.diagonal-lg:after {
    width: 40px;
}
