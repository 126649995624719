.icon-all-season {
    @include icon('all-season', #245b91, 16px);
    margin-top: -2px;
}

.icon-performance {
    @include icon('performance', #940000, 16px);
    margin-top: -4px;
}

.icon-winter {
    @include icon('winter', #45a9cb, 16px);
    margin-top: -4px;
}

.icon-summer {
    @include icon('summer', #ff9900, 16px);
    margin-top: -2px;
}

.icon-eco-friendly {
    @include icon('eco-friendly', #009900, 16px);
    margin-top: -2px;
}

.icon-all-terrain {
    @include icon('all-terrain', #663366, 16px);
    margin-top: -2px;
}

.icon-close-button {
    @include icon('close-button');
}

.icon-tach {
    @include icon('tach', #6d6d6d, 32px);
}

.icon-needle-gauge {
    @include icon('needle-gauge', #6d6d6d, 32px);
}

.icon-tire-sidewall {
    @include icon('tire-sidewall', #6d6d6d, 32px);
}

.icon-ruler {
    @include icon('ruler', #6d6d6d, 32px);
}

.icon-phone {
    @include icon('phone', #6d6d6d, 32px);
}

.icon-lock-circled {
    @include icon('lock-circled', #6d6d6d, 64px);
}

.icon-tire {
    @include icon('tire', $white, 64px);
}

.icon-oil {
    @include icon('oil', $white, 64px);
}

.icon-batteries {
    @include icon('batteries', $white, 64px);
}

.icon-brakes {
    @include icon('brakes', $white, 64px);
}

.icon-alignment {
    @include icon('alignment', $white, 64px);
}

.icon-tire-v3 {
    @include icon('tire', $black, 64px);
}

.icon-oil-v3 {
    @include icon('oil', $black, 64px);
}

.icon-batteries-v3 {
    @include icon('batteries', $black, 64px);
}

.icon-brakes-v3 {
    @include icon('brakes', $black, 64px);
}

.icon-alignment-v3 {
    @include icon('alignment', $black, 64px);
}

.icon-pencil {
    @include icon('pencil', $brand-color-red, 18px);
    margin-top: -2px;
}

.icon-map-marker {
    @include icon('map-marker', $brand-color-red, 16px);
    margin: -3px 4px 0 0;
}

.icon-question-mark {
    @include icon('question-mark', $black, 16px);
}

.icon-green-check {
    @include icon('green-check', $black, 16px);
}
