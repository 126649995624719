@import '~common/scss/shared/shared';

.blog-layout {
    .container-component {
        width: 100%;
        min-height: 100px;

        &.background-light-gray {
            background: #fafbfd;
        }

        &.background-gray {
            background: #110404;
        }
    }

    .container {
        max-width: 1584px; // 1440px + 5% margins;
        margin: 0 auto;
        padding: 0 4%;

        .blog-article-page & {
            overflow-x: hidden;
        }

        // to prevent padding from growing
        @include media(bp-gt-xl) {
            padding: 0 130px;
        }
    }

    // WordPress Migration Alignment Classes

    .alignright {
        display: inline;
        float: right;
    }

    .alignleft {
        display: inline;
        float: left;
    }

    .aligncenter {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    img {
        &.alignright {
            margin: 0.5% 0 1% 1.5%;
            max-width: 50%;
        }

        &.alignleft {
            margin: 0% 1.5% 1% 0%;
            max-width: 50%;
        }

        &.aligncenter {
            clear: both;
            margin-top: 0.4rem;
            margin-bottom: 1.6rem;
        }
    }
}
