/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

body.BSRO-UI-CompOn .tsr-card__action {
  display: block !important;
  border-radius: 6px;
  color: #fff !important;
  background-color: #d81e05 !important;
}

.tsr-card {
  width: 310px;
  min-height: 400px;
  padding: 40px 20px 0;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  border: 2px solid #f6f6f6;
  margin-bottom: 28px;
  color: #282828;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none !important;
  display: block;
}
.tsr-card__offer-tag {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  background-color: #dfe8ff;
  font-size: 12px;
  width: 115px;
  height: 27px;
  text-align: center;
  line-height: 27px;
  color: #005db6;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  z-index: 1;
}
.tsr-card__rebate-msg {
  display: none;
  font-size: 14px;
}
.tsr-card__offers-icon {
  background-image: url(https://s7d1.scene7.com/is/content/bridgestone/tag-badge-outline-icon-web-global-bsro);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center;
  margin-right: 10px;
  height: 16px;
  width: 16px;
  display: none;
  position: relative;
  top: 4px;
}
.tsr-card__both-offers {
  display: none;
}
.tsr-card__special-offer-icon {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  color: #005db6;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  padding: 4px 8px;
  border-radius: 4px;
  background: #dfe8ff;
  margin-bottom: 10px;
  width: 94px;
}
.tsr-card__brand-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 13px;
  left: 13px;
}
.tsr-card__brand-icon--primewell {
  width: 68px;
  height: 7px;
}
.tsr-card__brand-icon--toyo {
  width: 68px;
  height: 10px;
}
.tsr-card__brand-icon--suredrive {
  width: 107px;
  height: 10px;
}
.tsr-card__brand-icon--prinx {
  width: 89px;
  height: 13px;
}
.tsr-card__brand-icon img {
  width: 100%;
}
.tsr-card__brand-tag-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 150px;
  height: 100px;
}
.tsr-card__brand-tag {
  background-color: #d81e05;
  color: #fff;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  transform: rotate(-30deg);
  text-align: center;
  position: absolute;
  top: 26px;
  left: -37px;
  padding: 5px 45px;
  white-space: pre;
}
.tsr-card__brand-tag--special-offer {
  left: -45px;
}
.tsr-card__brand {
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3px;
  margin-top: 16px;
}
.tsr-card__tire-name {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 21px;
  text-align: center;
  margin-bottom: 5px;
  line-height: 30px;
}
.tsr-card__tire-image {
  text-align: center;
  height: 90px;
  overflow: hidden;
}
.tsr-card__tire-image img {
  width: 211px;
}
.tsr-card__tire-info {
  font-size: 13px;
  text-align: center;
  width: 100%;
  border-top: 1px solid #d4d4d4;
  padding-top: 15px;
  margin-bottom: 10px;
}
.tsr-card__reviews {
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 20px;
  text-align: center;
}
.tsr-card__reviews.reviews .reviews-inline-rating {
  justify-content: center;
}
.tsr-card__reviews.reviews .stars svg {
  width: 16px;
  height: 16px;
}
.tsr-card__inventory-tool {
  margin-left: 10px;
}
.tsr-card__mileage {
  font-size: 14px;
  margin-bottom: 10px;
}
.tsr-card__mileage strong {
  font-size: 16px;
}
.tsr-card__price {
  margin-left: 0;
  margin-bottom: 30px;
  height: auto;
  max-height: 100%;
}
.tsr-card__price strong {
  font-size: 21px;
}
.tsr-card__price .grid {
  display: block;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 16px;
}
.tsr-card__price .grid > div {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 36px;
  line-height: 1;
}
.tsr-card__price .grid > div span {
  font-size: 20px;
}
.tsr-card__price .list {
  display: none;
}
.tsr-card__price .no-price {
  font-size: 14px;
  line-height: 1;
  display: block;
}
.tsr-card__price .tag {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
.tsr-card__price .tag svg path {
  fill: #d31a0b;
}
.tsr-card__price .hide-price {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  display: block;
  font-size: 20px;
  line-height: 29px;
  font-weight: 400;
  border-top: 1px solid #d4d4d4;
  padding-top: 16px;
}
.tsr-card__prices {
  margin-left: 0;
  margin-bottom: 30px;
  height: auto;
  max-height: 100%;
}
.tsr-card__prices .grid {
  display: block;
  font-size: 14px;
  line-height: 1;
}
.tsr-card__prices .grid > div {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 21px;
  line-height: 30px;
  margin-left: 10px;
  display: inline-block;
}
.tsr-card__prices .list {
  display: none;
}
.tsr-card__prices .no-price {
  font-size: 14px;
  line-height: 1;
  display: block;
}
.tsr-card__prices .tag {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 9px;
}
.tsr-card__prices .tag svg path {
  fill: #d31a0b;
}
.tsr-card__prices .hide-price {
  display: block;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  border-top: 1px solid #d4d4d4;
  margin-top: 10px;
  padding-top: 10px;
}
.tsr-card__price .grid .single-product, .tsr-card__prices .grid .single-product {
  font-size: 16px;
  margin-left: 3px;
}
.tsr-card__price .grid sup, .tsr-card__prices .grid sup {
  font-size: 15px;
}
.tsr-card__total-price {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 60px;
}
.tsr-card__offer {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.tsr-card__action {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  border-top: 1px solid #d81e05;
  text-align: center;
  padding: 10px 0 13px;
  text-align: center;
  border-radius: 6px;
  margin: 0 -20px;
  color: #d81e05;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 20px;
  display: none;
  height: 44px;
  /* Customize the label (the container) */
  /* Create a custom checkbox */
}
.tsr-card__action svg {
  width: 14px;
  height: 14px;
  fill: #d81e05;
  position: relative;
  top: 4px;
}
.tsr-card__action .container {
  display: inline-block;
  position: absolute;
  padding-left: 28px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 44%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  /* Hide the browser's default checkbox */
  /* On mouse-over, add a grey background color */
  /* Style the checkmark/indicator */
}
.tsr-card__action .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  /* When the checkbox is checked, add a blue background */
}
.tsr-card__action .container input:checked ~ .checkmark {
  background-color: #fff;
  /* Show the checkmark when checked */
}
.tsr-card__action .container input:checked ~ .checkmark:after {
  display: block;
}
.tsr-card__action .container:hover input ~ .checkmark {
  background-color: #fff;
}
.tsr-card__action .container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #282828;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.tsr-card__action .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #979797;
  /* Create the checkmark/indicator (hidden when not checked) */
}
.tsr-card__action .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.tsr-card__action .wait {
  pointer-events: none;
}
.tsr-card__action.mobile {
  display: block;
  color: #fff;
  background-color: #d81e05;
  position: relative;
  left: 0;
  width: calc(100% + 40px);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.tsr-card__action.mobile svg {
  fill: #fff;
}
.tsr-card__action.mobile .checkmark {
  border-color: #d81e05;
}
@media only screen and (min-width: 1280px) {
  .tsr-card__action.mobile .container {
    left: 20%;
    transform: translateY(-50%);
    width: 60%;
  }
}
.tsr-card__action.mobile.show {
  width: calc(100% + 40px);
  display: block !important;
  left: 0;
}
.tsr-card__action.mobile .tsr-card__total-price {
  margin-bottom: 10px;
}
.tsr-card__action.mobile.disabled {
  color: #666;
  background-color: #fff;
}
.tsr-card__action.mobile.disabled .checkmark {
  background-color: #ccc;
  border-color: #666;
}
.tsr-card__action.show {
  width: calc(100% + 4px);
  left: 18px;
  color: #fff;
  background-color: #d81e05;
  display: none !important;
}
.tsr-card__action.show svg {
  fill: #fff;
}
.tsr-card__action.show:not(.list) .container {
  width: 49%;
}
.tsr-card__action.show .checkmark {
  border-color: #d81e05;
}
@media only screen and (min-width: 1280px) {
  .tsr-card__action.show {
    display: block !important;
  }
}
.tsr-card__action.disabled {
  color: #666;
}
.tsr-card__action.disabled .checkmark {
  background-color: #ccc;
}
.tsr-card__action:hover {
  background-color: #c11b03 !important;
}
@media only screen and (min-width: 1280px) {
  .tsr-card__action {
    display: block;
  }
}
.tsr-card__info-action {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  font-size: 14px;
  border: none;
  outline: none;
  background: transparent;
  border-top: 1px solid #d4d4d4;
  width: 100%;
  text-align: left;
  margin-left: 10px;
  padding: 16px 0;
  cursor: pointer;
  text-transform: uppercase;
  color: #282828;
}
.tsr-card__info-action svg {
  width: 20px;
  height: 8px;
  margin-left: 10px;
}
.tsr-card__info-action.open svg {
  transform: rotate(-180deg);
}
@media only screen and (min-width: 1280px) {
  .tsr-card__info-action {
    display: none;
  }
}
.tsr-card__info-wrapper {
  display: none;
}
.tsr-card__info-wrapper ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 20px;
}
.tsr-card__info-wrapper li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tsr-card__info-wrapper li span {
  font-size: 12px;
}
.tsr-card__info-wrapper.open {
  display: block;
}
@media only screen and (min-width: 1280px) {
  .tsr-card__info-wrapper.open {
    display: none;
  }
}
.tsr-card__stat-name {
  font-size: 12px;
  line-height: 1;
  width: 60px;
  text-align: right;
  margin-right: 10px;
}
.tsr-card__stat-bar {
  width: 176px;
  height: 3px;
  background-color: #cacaca;
  border-radius: 2px;
  margin-right: 10px;
}
.tsr-card__stat-graph {
  width: 0;
  height: 3px;
  background-color: #d81e05;
  border-radius: 2px;
}
.tsr-card__other-stat {
  font-size: 14px;
  margin-bottom: 20px;
}
.tsr-card:hover {
  border: 2px solid #282828;
  text-decoration: none !important;
  color: inherit !important;
}
.tsr-card:hover .tsr-card__action {
  color: #fff;
  background-color: #d81e05;
}
.tsr-card:hover .tsr-card__action svg {
  fill: #fff;
}
.tsr-card:hover .tsr-card__action .checkmark {
  border-color: #d81e05;
}
.tsr-card:hover .tsr-card__action.disabled {
  color: #666;
  background-color: white !important;
}
.tsr-card:hover .tsr-card__action.disabled .checkmark {
  background-color: #ccc;
  border-color: #666;
}
.tsr-card:hover .tsr-card__action.mobile {
  width: calc(100% + 44px);
  left: -2px;
}
.tsr-card:focus {
  color: #000 !important;
}
.tsr-card .spacer {
  width: 7px;
  display: inline-block;
}
.tsr-card .strike-pricing {
  font-size: 20px !important;
  display: inline-block;
  margin-left: 10px;
  color: rgba(74, 74, 74, 0.7);
  position: relative;
}
.tsr-card .strike-pricing:after {
  content: "";
  width: 110%;
  height: 2px;
  background-color: #a5a5a5;
  display: block;
  transform: rotate(-25deg);
  position: absolute;
  top: 50%;
}
.tsr-card .strike-pricing sup {
  font-size: 12px !important;
}
.tsr-card.boosted {
  border: 1px solid #000 !important;
}
.tsr-card.list {
  width: 100%;
  display: flex;
  min-height: 0;
  padding: 22px 20px 21px 0;
  border-radius: 10px;
  border: 2px solid #f6f6f6;
  margin-bottom: 18px;
}
.tsr-card.list.open {
  width: 100%;
}
.tsr-card.list.open .tsr-card__tire-info {
  width: 20%;
}
.tsr-card.list.open .tsr-card__offer-tag {
  width: 130px;
  height: 29px;
}
.tsr-card.list .tsr-card__boosted {
  border-radius: 0 6px 0 12px;
  background: #000;
  width: 129px;
  height: 29px;
  color: #fff;
  text-align: center;
  align-content: center;
  position: absolute;
  right: 0;
  top: 0;
}
.tsr-card.list .tsr-card__image-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 19.75%;
}
.tsr-card.list .tsr-card__tire-image {
  width: 100%;
  height: 141px;
}
.tsr-card.list .tsr-card__tire-image img {
  width: calc(100% - 20px);
}
.tsr-card.list .tsr-card__name-wrapper {
  padding-top: 5px;
  margin-left: 21.5%;
  width: 18.4%;
  padding-right: 3px;
  border-right: 1px solid #d4d4d4;
}
.tsr-card.list .tsr-card__brand-tag {
  background-color: #d81e05;
  color: #fff;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  transform: rotate(-30deg);
  text-align: center;
  position: absolute;
  top: 26px;
  left: -37px;
  padding: 5px 45px;
  white-space: pre;
}
.tsr-card.list .tsr-card__brand-tag--special-offer {
  left: -37px;
}
.tsr-card.list .tsr-card__brand {
  text-align: left;
}
.tsr-card.list .tsr-card__tire-name {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  text-align: left;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.tsr-card.list .tsr-card__reviews {
  padding: 0;
  border: none;
  margin-bottom: 11px;
  text-align: left;
}
.tsr-card.list .tsr-card__reviews.reviews .reviews-inline-rating {
  justify-content: start;
}
.tsr-card.list .tsr-card__reviews .write-a-review-button p {
  margin: 5px 0px 0px 0px;
}
.tsr-card.list .tsr-card__reviews .write-a-review-button strong {
  font-family: unset !important;
}
.tsr-card.list .tsr-card__size-info p {
  margin: 0;
}
.tsr-card.list .tsr-card__tire-info {
  width: 15%;
  margin: 11px 4px 0 25px;
  border: none;
  padding: 0;
  text-align: left;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tsr-card.list .tsr-card__tire-type {
  margin-bottom: 0.5em;
}
.tsr-card.list .tsr-card__inventory-tool {
  margin-left: 0;
}
.tsr-card.list .tsr-card__inventory-tool > div {
  margin-bottom: 0.5em;
}
.tsr-card.list .tsr-card__mileage, .tsr-card.list .tsr-card__other-stat {
  margin-bottom: 0.5em;
  margin-left: 0;
}
.tsr-card.list .tsr-card__mileage strong, .tsr-card.list .tsr-card__other-stat strong {
  font-size: 14px;
}
.tsr-card.list .tsr-card__info-wrapper {
  width: 25%;
  padding-top: 16px;
  padding-right: 26px;
  border-right: 1px solid #d4d4d4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tsr-card.list .tsr-card__info-wrapper ul {
  margin: 0;
  padding: 0;
  border: none;
}
.tsr-card.list .tsr-card__info-wrapper li {
  margin-bottom: 0;
}
.tsr-card.list .tsr-card__info-wrapper li span {
  line-height: 20px;
}
.tsr-card.list .tsr-card__stat-bar {
  width: 65%;
}
.tsr-card.list .tsr-card__price-wrapper {
  margin-left: 27px;
  width: 22%;
  margin-top: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tsr-card.list .tsr-card__price {
  font-size: 22px;
  margin: 0;
}
.tsr-card.list .tsr-card__price div {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 36px;
  line-height: 1;
}
.tsr-card.list .tsr-card__price div span {
  font-size: 20px;
}
.tsr-card.list .tsr-card__price div sup {
  font-size: 15px;
}
.tsr-card.list .tsr-card__price div.hide-price {
  display: block;
  font-size: 20px;
  line-height: 29px;
  font-weight: 400;
}
.tsr-card.list .tsr-card__price .grid {
  display: none;
}
.tsr-card.list .tsr-card__price .list {
  display: block;
  margin-bottom: 16px;
}
.tsr-card.list .tsr-card__price .no-price {
  font-size: 14px;
  line-height: 1;
  display: block;
}
.tsr-card.list .tsr-card__prices {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 11px;
  margin: 0;
  margin-bottom: 12px;
}
.tsr-card.list .tsr-card__prices > div {
  display: flex;
  align-items: center;
}
.tsr-card.list .tsr-card__prices > div > div {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 22px;
}
.tsr-card.list .tsr-card__prices > div > div sup {
  font-size: 13px;
}
.tsr-card.list .tsr-card__prices > div > div span {
  font-size: 15px;
  margin-left: 0;
}
.tsr-card.list .tsr-card__prices > div > div.hide-price {
  display: block;
  font-size: 20px;
  line-height: 29px;
  font-weight: 400;
  border-top: 1px solid #d4d4d4;
  margin-top: 10px;
  padding-top: 10px;
}
.tsr-card.list .tsr-card__prices .grid {
  display: none;
}
.tsr-card.list .tsr-card__prices .list {
  display: flex;
}
.tsr-card.list .tsr-card__prices .no-price {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 14px;
  line-height: 1;
  display: block;
}
.tsr-card.list .tsr-card__total-price {
  margin-left: 0;
  border-top: 1px solid #d4d4d4;
  padding-top: 16px;
}
.tsr-card.list .tsr-card__offer {
  margin-bottom: 0;
  margin-left: 0;
  display: inline-block;
}
.tsr-card.list .tsr-card__offer a {
  color: #000;
}
.tsr-card.list .tsr-card__offer a:hover {
  color: #000;
}
.tsr-card.list .tsr-card__offer-tag {
  width: 184px;
  height: 30px;
  line-height: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 6px;
  left: auto;
  right: 0;
  transform: translateX(0);
}
.tsr-card.list .tsr-card__action {
  left: 33px;
  bottom: auto;
  top: 23%;
  transform: translateY(-50%);
  width: 167px;
  height: 38px;
  display: none;
}
.tsr-card.list .tsr-card__action .container {
  line-height: 18px;
  width: calc(100% - 15px);
  left: 4%;
  top: 50%;
  transform: translateY(-50%);
}
.tsr-card.list .tsr-card__action.show {
  display: block;
  border-radius: 6px;
  color: #fff;
  background-color: #d81e05;
}
.tsr-card.list .tsr-card__action.show svg {
  fill: #fff;
}
.tsr-card.list .tsr-card__action.show .checkmark {
  border-color: #d81e05;
}
.tsr-card.list .tsr-card__action.disabled {
  background-color: #666;
  border-color: #666;
  color: #ccc;
}
.tsr-card.list .tsr-card__action.disabled .checkmark {
  border-color: #666;
  background-color: #ccc;
}
.tsr-card.list:hover {
  border: 2px solid #282828;
}
.tsr-card.list:hover .tsr-card__action {
  display: block;
  border-radius: 6px;
}
.tsr-card.list:hover .tsr-card__action.disabled {
  background-color: #666 !important;
  border-color: #666;
  color: #ccc;
}
.tsr-card.list:hover .tsr-card__action.disabled .checkmark {
  border-color: #666;
  background-color: #ccc;
}
.tsr-card.list:hover .tsr-card__tire-image {
  opacity: 0.41;
}
.tsr-card.grid .tsr-card__boosted {
  border-radius: 0px 0px 12px 12px;
  background: #000;
  display: flex;
  width: 90px;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: calc(50% - 45px);
  height: 29px;
}
.tsr-card.grid .tsr-card__special-offer-icon {
  position: absolute;
  right: 13px;
  top: 13px;
}
.tsr-card.grid .tsr-card__offer {
  display: inline;
  margin-left: 0;
}
.tsr-card.grid .tsr-card__offer a {
  color: #000;
}
.tsr-card.grid .tsr-card__offer a:hover {
  color: #000;
}
.tsr-card.grid .tsr-card__hide-offer {
  margin: 0 0 60px 10px;
}
.tsr-card__total-price {
  margin-bottom: 15px;
  border-top: 1px solid #d4d4d4;
  padding-top: 16px;
}
.tsr-card .btn.otd-price {
  display: block;
  font-size: 14px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 12px;
  max-width: 231px;
}
.tsr-card .btn.otd-price.grid {
  margin: 0 auto 60px auto;
}
.tsr-card .btn.otd-price.grid.mobile {
  margin-bottom: 20px;
}

.product-results .compare-checkbox label {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.tsr-card .no-price {
  display: none;
}
.tsr-card .strike-pricing {
  display: none;
}
.tsr-card__offer-tag {
  display: none;
}
.tsr-card__total-price {
  display: block;
}
.tsr-card .tag {
  display: none;
}
.tsr-card__offer.rebate, .tsr-card__offer.coupon {
  display: none;
}
.tsr-card.without-price .tsr-card__price .grid,
.tsr-card.without-price .tsr-card__prices .grid {
  display: none;
}
.tsr-card.without-price .tsr-card__price .no-price,
.tsr-card.without-price .tsr-card__prices .no-price {
  display: block;
}
.tsr-card.without-price .tsr-card__price .no-price button.store-phone,
.tsr-card.without-price .tsr-card__prices .no-price button.store-phone {
  border: none;
  background: none;
  color: #d81e05;
  cursor: pointer;
}
.tsr-card.without-price .tsr-card__total-price {
  display: none;
}
.tsr-card.price-sale .tsr-card__offer-tag {
  display: block;
}
.tsr-card.price-sale .strike-pricing {
  display: inline-block;
}
.tsr-card.with-hidden-price .tag {
  display: inline-block;
}
.tsr-card.with-rebate .tsr-card__offer.rebate {
  display: inline;
}
.tsr-card.with-rebate .tsr-card__offer.rebate button {
  border: none;
  background: none;
  color: #d81e05;
  cursor: pointer;
}
.tsr-card.with-rebate .tsr-card__rebate-msg {
  display: inline-block;
}
.tsr-card.with-rebate .tsr-card__offers-icon {
  display: inline-block;
}
.tsr-card.with-coupon .tsr-card__offer.coupon {
  display: inline;
}
.tsr-card.with-coupon .tsr-card__offer.coupon button {
  border: none;
  background: none;
  color: #d81e05;
  cursor: pointer;
}
.tsr-card.with-coupon .tsr-card__offers-icon {
  display: inline-block;
}
.tsr-card.with-offers .tsr-card__no-both-offers {
  display: none;
}
.tsr-card.with-offers .tsr-card__both-offers {
  display: inline-block;
}

.tsr-card__inventory {
  display: none;
}

/* Hide section headings */
.tsr-results__heading {
  display: none;
}

/* remove the gap between sections */
.tsr-card-deck {
  margin-bottom: 0;
}

/* add back the margin after last section */
.tsr-results {
  margin-bottom: 35px;
}

/* display inventory for mobile */
.tsr-card__inventory {
  display: block;
  margin: 0px 0px 10px 10px;
}
.tsr-card__inventory .tsr-card__inventory__message {
  margin-left: -10px;
}
.tsr-card__inventory .tsr-card__inventory__message a {
  font-size: 14px;
}

/* display inventory for desktop */
.tsr-card.list .tsr-card__inventory {
  margin-bottom: 0.5em;
  margin-left: 0px;
}
.tsr-card.list .tsr-card__inventory .tsr-card__inventory__message {
  font-size: 14px;
  line-height: 20px;
  font-family: "Avenir", Arial, sans-serif !important;
}
.tsr-card.list .tsr-card__inventory .tsr-card__inventory__message a {
  font-size: 14px;
}

#tire_search_results .tsr-card.list {
  min-height: 283px;
  padding: 16px 0;
}
#tire_search_results .tsr-card.list .tsr-card__price-wrapper {
  min-width: 314px;
  margin-top: 34px;
  margin-left: 19px;
}
#tire_search_results .tsr-card.list .tsr-card__price-wrapper .product-price.lidi, #tire_search_results .tsr-card.list .tsr-card__price-wrapper .product-price.map, #tire_search_results .tsr-card.list .tsr-card__price-wrapper .product-price.signin-only {
  margin-top: -36px;
}
#tire_search_results .tsr-card.list .tsr-card__price-wrapper .product-price__offer-copy {
  padding-right: 20px;
}
#tire_search_results .tsr-card.list .otd-price {
  margin-bottom: 0;
}
#tire_search_results .tsr-card.grid .tsr-card__price {
  margin-left: 0;
  margin-bottom: 30px;
  height: auto;
  max-height: 100%;
}
#tire_search_results .tsr-card.grid .product-price__price-dollar {
  font-size: 30px;
  line-height: 30px;
}

#tire_search_results .product-price__price {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}
#tire_search_results .product-price__price-dollar {
  font-size: 36px;
  line-height: 36px;
}
#tire_search_results .product-price__price-or {
  align-self: center;
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0 10px;
}
#tire_search_results .product-price__price sup {
  align-self: normal;
  font-size: 20px;
  margin-top: 1px;
  margin-left: 2px;
}
#tire_search_results .product-price__price-ea {
  font-size: 20px;
  line-height: 20px;
}
#tire_search_results .product-price__text {
  flex-grow: 1;
}
#tire_search_results .product-price__four-tires {
  display: block;
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: black;
  margin-bottom: 0;
}
#tire_search_results .product-price__four-tires strong {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
}
#tire_search_results .product-price__offer-icon {
  margin-bottom: 10px;
}
#tire_search_results .product-price__tag-icon {
  margin-right: 6px;
  min-width: 16px;
}
#tire_search_results .product-price__offer-detail {
  color: black;
}
#tire_search_results .product-price__offer-detail:hover {
  color: black !important;
}
#tire_search_results .product-price__offer-copy-text, #tire_search_results .product-price__signin-only-description {
  display: flex;
  gap: 1px;
}
#tire_search_results .product-price__offer-copy-text span, #tire_search_results .product-price__signin-only-description span {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: black;
}
#tire_search_results .product-price__offer-copy-text strong, #tire_search_results .product-price__signin-only-description strong {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}
#tire_search_results .product-price__separator {
  border: 0;
  border-top: 1px solid #d4d4d4;
  margin: 16px 0;
}
#tire_search_results .product-price__signin-only-description, #tire_search_results .product-price__signin-only-link, #tire_search_results .product-price__signin-only-links, #tire_search_results .product-price__fitment {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: black;
}
#tire_search_results .product-price__signin-only-description {
  margin-top: 8px;
}
#tire_search_results .product-price__fitment {
  margin-bottom: 8px;
}
#tire_search_results .product-price__signin-only-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
  color: black;
}
#tire_search_results .grid .product-price__offer-icon {
  position: absolute;
  top: 13px;
  right: 13px;
}

.tsr-card-deck {
  width: 100vw;
  margin-bottom: 15px;
  display: block;
}
@media only screen and (min-width: 768px) {
  .tsr-card-deck {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    margin-bottom: 35px;
  }
}
.tsr-card-deck .swiper-slide {
  width: 320px;
}

.tsr-signup {
  width: 100%;
  background-color: #fff;
  color: #282828;
  padding: 65px 15px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 24px;
}
.tsr-signup__title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.tsr-signup__info {
  font-size: 16px;
  line-height: 35px;
  margin-bottom: 18px;
}
.tsr-signup__underline {
  text-decoration: underline;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.tsr-signup__field {
  display: inline-flex;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  width: 100%;
  max-width: 360px;
}
.tsr-signup__field input {
  width: 80%;
  border: 1px solid #e7e7e7;
}
@media only screen and (min-width: 768px) {
  .tsr-signup__field input {
    width: 280px;
  }
}
.tsr-signup__field button {
  width: 20%;
  border-radius: 5px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  left: -4px;
  height: 50px;
  font-size: 14px;
}
.tsr-signup__field button:focus {
  outline: none;
}
@media only screen and (min-width: 768px) {
  .tsr-signup__field button {
    width: 80px;
  }
}
@media only screen and (min-width: 768px) {
  .tsr-signup__field {
    width: 360px;
  }
}
.tsr-signup__error {
  color: #d81e05;
  font-size: 16px;
  margin: 5px 0;
}
.tsr-signup__action {
  color: #d81e05;
  text-decoration: underline;
  font-size: 16px;
  line-height: 35px;
}
@media only screen and (min-width: 768px) {
  .tsr-signup {
    margin-bottom: 100px;
  }
}

.tsr-no-results {
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 25px 0;
  color: #282828;
  border-radius: 8px;
  margin-bottom: 24px;
}
.tsr-no-results svg {
  width: 40px;
  height: 40px;
  fill: #282828;
  margin-bottom: 10px;
}
.tsr-no-results__title {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
}
.tsr-no-results__info {
  font-size: 18px;
  line-height: 25px;
  margin: 0 auto 16px;
  max-width: 80%;
}
@media only screen and (min-width: 768px) {
  .tsr-no-results__info {
    max-width: 700px;
  }
}
.tsr-no-results__info button {
  border: none;
  text-decoration: underline;
  color: #d01c05;
  background: transparent;
}
.tsr-no-results__info button:hover {
  color: #6c0f03;
}
@media only screen and (min-width: 768px) {
  .tsr-no-results {
    padding: 35px 0;
    margin-bottom: 100px;
  }
}
.tsr-no-results__action.btn-rounded {
  width: auto;
}

.tsr-stage__container {
  width: 100%;
}

.result-card {
  font-family: "Avenir", Arial, sans-serif !important;
}
.result-card .empty {
  display: none;
}
.result-card.clearance-only .corner {
  border-width: 0 100px 100px 0;
  border-color: transparent #03a51e transparent transparent;
}
.result-card.clearance-only .corner + .empty {
  display: block;
  position: absolute;
  right: -22px;
  top: -22px;
  width: 10px;
  background: white;
  z-index: 9;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent transparent #03a51e transparent;
}
.result-card.clearance-only .top {
  position: relative;
}
.result-card.clearance-only .top .corner .corner-text {
  top: 28px;
  z-index: 9190;
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-weight: normal;
  font-size: 16px;
}
.result-card .brand-info h4.product-name a {
  vertical-align: text-top;
}
.result-card .brand-info h4.product-id, .result-card .brand-info h4.size {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
}
.result-card .brand-info h4.product-id:first-child {
  margin-bottom: 8px;
}
.result-card .brand-info .brand-img .brand-image.toyo {
  margin-bottom: -5px;
}
.result-card .details {
  position: relative;
}
.result-card .details .tire-image {
  margin: 10px 34px 30px 10px;
  max-width: 123px;
}
.result-card .details .compare-checkbox {
  position: absolute;
  bottom: 11px;
}
.result-card .details .pricing-specs .pricing .front-rear-text {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
}
.result-card .price .retail-price,
.result-card .price .discounted-price {
  line-height: normal;
}

.center {
  text-align: center !important;
}

@media only screen and (max-width: 767px) {
  .top-result-container .mobile-only-filter .filter-btn a,
  .top-result-container .mobile-only-filter .sorting-btn a {
    font-family: "Avenir", Arial, sans-serif !important;
  }
  .top-result-container .mobile-only-filter .filter-btn a:after,
  .top-result-container .mobile-only-filter .sorting-btn a:after {
    top: 1px;
  }
  .top-result-container .mobile-only-filter .sorting-btn {
    margin: 0 20px 0 0;
  }
}
.product-results-grid--v1 .container .results-container .tire-sizing-options button {
  font-family: "Ultramagnetic", Arial, sans-serif !important;
  font-weight: normal;
}

.product-results-interstitial p {
  color: #4a4a4a;
  font-size: 16px;
}
.product-results-interstitial .tooltip {
  color: #4a4a4a;
  font-size: 16px;
}
.product-results-interstitial .tooltip-icon {
  background-color: #4a4a4a;
  color: #fff;
  font-size: 14px;
}
@media only screen and (max-width: 1024px) {
  .product-results-interstitial .btn {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .product-results-interstitial {
    font-size: 15px;
  }
}