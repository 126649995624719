@import '~common/scss/shared/shared';

/*
 * TABS
 *
 * STANDARD FUNCTIONAL LAYOUT
 *
 * DO NOT TOUCH
 *
 * Notes:
 * - z-indexes are not specified on purpose. add z-indexes as you see fit.
 * - elements such as <a>, <p> if present are left generic. overriding these should be done in your component.
 *
  *
 */

// Tabs Variables
$tabsDarkGrey: #454545;
$tabsLightGrey: #dfdfdf;
$tabsBorderBottom: 1px solid white;
$tabsActiveColor: #ebebeb;
$tabsActiveLinkColor: #d81e0c;
$tabActivebackgroundColor: #595959;

//
// BEGIN TAB-NAV
//
// Tab Nav Base
%tab-nav-table {
    > ul {
        display: table;
        height: 100%;

        > li {
            display: table-cell;
        }
    }
}

%tab-nav-block {
    > ul {
        display: block;
        height: 100%;

        > li {
            position: relative;
            display: block;
            background: $tabsLightGrey;
            margin-bottom: 1px;

            // default size in comps --- ONLY APPLIES IN RAIL/STACK Configuration
            // once in horizontal "top" mode the <li> height conforms to <ul> and .tab-nav height
            height: 40px;

            > a {
                color: $tabsDarkGrey;
                text-decoration: none;

                &:hover {
                    color: $tabsActiveColor;
                }
            }

            &.active {
                background: $tabsActiveColor !important;

                a,
                a:hover {
                    color: $tabsActiveLinkColor;
                    cursor: default;
                }
            }
        }
    }
}

%tab-top {
    @extend %tab-nav-table;
    > ul {
        > li {
            min-height: 29px; //IEfix
            position: relative;

            &.active {
                display: table-cell !important; //IEfix

                span.indicator {
                    margin: 6px 0 0;
                }
            }
        }
        //only for firefox to manage indicator , will replace if found better solution
        > li.active,
        x:-moz-any-link {
            display: block !important;
        }
    }
}

%tab-right {
    @extend %tab-nav-block;
    > ul {
        > li.active {
            span.indicator {
                margin: 0 6px 0 0;
                background: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxOTMxRjA4NzlCOEYxMUU1QjUyRkI5MkY4RDE4NDY4MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxOTMxRjA4ODlCOEYxMUU1QjUyRkI5MkY4RDE4NDY4MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjE5MzFGMDg1OUI4RjExRTVCNTJGQjkyRjhEMTg0NjgxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjE5MzFGMDg2OUI4RjExRTVCNTJGQjkyRjhEMTg0NjgxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+uh3mHwAAAHBJREFUeNpiuCHHKgjEq0D0////GZAxEwMEKAHx7pvybIIMSIBJ/eGv90DaFcpHUQDWiUsBI8hsGIAK7oZyXZmQ7YCacBaIjYHYBcV1QBfPBOL/QJwGNhGXBFwSJIAuAZYECihhk0DWqYQuAcIAAQYAKpR61U4yJD4AAAAASUVORK5CYII=)
                    transparent no-repeat left center;
            }
        }
    }
}

%tab-bottom {
    @extend %tab-nav-table;
    > ul {
        > li {
            position: relative;
            &.active {
                span.indicator {
                    margin: 0 0 6px 0;
                }
            }
        }
    }
}

%tab-left {
    @extend %tab-nav-block;
    > ul {
        > li.active {
            span.indicator {
                margin: 0 0 0 6px;
                background: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAALCAYAAACzkJeoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2OUE4RTk2RTk1MDQxMUU1OEUwNURGQUFDNjAyRDY1MiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2OUE4RTk2Rjk1MDQxMUU1OEUwNURGQUFDNjAyRDY1MiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY5QThFOTZDOTUwNDExRTU4RTA1REZBQUM2MDJENjUyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY5QThFOTZEOTUwNDExRTU4RTA1REZBQUM2MDJENjUyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+oIGkCwAAAGpJREFUeNpiuCHHKgjEM0H0////GZAxEwMEGAPx7pvybIIMSIBJ/eGv90DaFcpHUQDWiUsBI8hsGIAK7oZyXZmQ7YCacBbqBhcU10Fd/R+I08Am4pKAS2KTAEsCBYyxSSDrVEKXAGGAAAMAO/p7GVs+ppgAAAAASUVORK5CYII=)
                    transparent no-repeat right center;
            }
        }
    }
}

/*
* TAB-NAV CLASS MODIFIERS
* Breaking out each individual class modifier because
* SASS does not support extend without child selectors.
*/

// Default Horizontal Tab Nav
.tab-nav,
%tab-nav {
    @extend %tab-nav-table;

    height: 100%;

    > ul,
    > ul > li {
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    > ul {
        // Hover child within <li> will always conform with <ul> parent width
        // For <li> dropdowns set <li> to relative
        position: relative;
        width: 100%;

        > li {
            // Allows for responsive height and width while wrapping text within span from the center out
            // Don't have to worry about padding. Span maintains height and width to 100% automatically to the
            // parent container. Everything is automatic. Slight top padding adjustments can be made within
            // span to offset line-height;
            > a {
                display: table;
                font-size: 16px;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                height: 100%;

                span {
                    display: table-cell;
                    // NOT NECESSARY but visually tweaks the vertical centering due to the extra 5px margin/padding? on the bottom of the span.
                    padding-top: 0; // roboto fix. was... 5px;
                    vertical-align: middle;
                }
            }

            span.indicator {
                position: absolute;
                display: none;
                width: 100%;
                height: 100%;
                pointer-events: none;
                z-index: 888;
            }

            i.no-transform {
                text-transform: none;
            }

            &.active {
                span.indicator {
                    display: block;
                }
            }
        }
    }

    // Horizontal Style Tabs
    &.tab-top {
        @extend %tab-top;
    }

    &.tab-bottom {
        @extend %tab-bottom;
    }

    // Stacked, Single Column Style Tabs
    &.tab-left {
        @extend %tab-left;
    }

    &.tab-right {
        @extend %tab-right;
    }

    &.tab-catalog {
        > ul {
            > li {
                > a {
                    line-height: 1.2em;

                    &:hover {
                        color: $black;
                        background-color: #ededed;
                    }

                    @include media(bp-lt-md) {
                        font-size: 12px;

                        span {
                            padding-left: 8px !important;
                            padding-right: 8px !important;
                        }
                    }

                    @include media(bp-lt-sm) {
                        font-size: 9px;

                        span {
                            padding-left: 5px !important;
                            padding-right: 5px !important;
                        }
                    }
                }
                &.active {
                    a,
                    a:hover {
                        color: $white;
                        background-color: $tabActivebackgroundColor;
                    }
                }
            }
        }
    }
}
//
// END TAB-NAV
//

//
// BEGIN TAB-CONTENT
//
.tab-content,
%tab-content {
    position: relative;
    height: 100%;

    &.top-margin {
        margin-top: 20px;
    }

    &.hide {
        display: none !important;
    }

    &.active {
        display: block !important;
    }
}
//
// END TAB-CONTENT
//

//
// BEGIN TABS
//
.tabs {
    width: 100%;
    height: 100%;

    .tab-nav {
        // Extend and inherit tab-nav and tab-nav.left
        // Default tab style is now a stack left rail.
        @extend %tab-nav, %tab-left;

        display: inline-block;
        margin-right: -3px; // fix inline-block spacing bug
        width: 30%;

        > ul {
            > li {
                > a {
                    text-align: left;
                    span {
                        // Ensures that the parent <a> always has
                        // the full hover state without sacrificing
                        // its own padding.
                        padding-left: 8.33%; // 14/168 from comps
                    }
                }
            }
        }
    }

    .tab-content {
        @extend %tab-content;

        display: inline-block;
        width: 70%;
    }

    // TO DO
    &.tab-top {
        .tab-nav {
            @extend %tab-nav, %tab-top;

            display: block;
            height: 30%;
            width: 100%;

            > ul {
                > li {
                    height: 100%;
                    > a {
                        text-align: center;

                        span {
                            padding-left: 0;
                        }
                    }
                    &.active {
                        // Fix to Firefox Bug
                        // https://bugzilla.mozilla.org/show_bug.cgi?id=63895
                        //
                        // TP-686
                        // so instead of a table-cell. make it a block element.
                        // since the issue pops up because of FF relative table-cell to absolute
                        // child position. Since UL is a table, a child element that is a block
                        // still acts as a table-cell.
                        display: block;
                        cursor: default !important;
                    }
                }
            }
        }

        .tab-content {
            display: block;
            height: 70%;
            width: 100%;

            > div {
                position: relative;
                padding-top: 6px;
                overflow: visible;
            }
        }

        &.left,
        &.right {
            float: none;

            .tab-nav {
                border-bottom: 1px solid #d5d5d5;
                height: 40px;

                > ul {
                    width: auto;

                    > li {
                        border-left: 2px solid white;

                        @include media(bp-lt-md) {
                            border-left: 1px solid white;
                        }

                        a {
                            span {
                                padding-left: 25px; // THIS NESTING IS EXCESSIVE
                                padding-right: 25px;
                            }
                        }
                        &:first-child {
                            border-left: 0px;
                        }
                    }
                }
            }
        }

        &.left {
            .tab-nav > ul {
                float: left;
            }
        }

        &.right {
            .tab-nav > ul {
                float: right;
            }
        }

        &.full {
            .tab-nav > ul {
                width: 100%;
            }
        }
    }
}
// END STANDARD FUNCTIONAL LAYOUT //

@media only screen and (max-width: 1024px) {
    .tabs {
        &.tab-top {
            &.left,
            &.right {
                .tab-nav {
                    > ul {
                        > li {
                            a {
                                span {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.coral-Modal-body {
    .tire-search-widget {
        .tabs.tab-top {
            .tab-nav > ul > li {
                display: table-cell !important;
            }
        }
    }
}
