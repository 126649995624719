@use 'sass:math';

$gradient-color: #ebebeb;

.articles-carousel {
    @include gradient-top();

    padding: 40px 0;

    @include media(bp-lt-md) {
        padding: 40px 40px 0 40px;
    }

    .lead-img {
        margin: 10px 0 30px;
        text-align: center;
    }

    .img-container {
        position: relative;
        overflow: hidden;
        max-height: 150px;

        img {
            width: 100%;
        }
    }

    .video-icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        > div {
            width: 20%;
            margin: 15% auto 0;
        }
    }

    .title {
        margin: 0.5em 0 0.2em;
        line-height: 1.2em;
        text-transform: uppercase;

        @include media(bp-gt-sm) {
            text-align: center;

            &.align-left {
                width: 90%;
                margin: 0 auto;
                text-align: left;
            }
        }

        @include media(bp-gt-md) {
            &.align-left {
                padding-left: percentage(math.div(8, 300));

                &[data-slides-desktop='4'] {
                    padding-left: percentage(math.div(8, 400));
                }
            }
        }

        @include media(bp-lt-lg) {
            &.align-left {
                padding-left: percentage(math.div(8, 200));

                &[data-slides-tablet='3'] {
                    padding-left: percentage(math.div(8, 300));
                }
            }
        }
    }

    > p {
        width: 60%;
        margin: 1em 20%;
    }

    .carousel {
        width: 96%;
        margin: 40px auto 0;
    }

    .slide-content {
        padding: 0 8%;
    }

    .slide-title {
        margin: 0.5em 0;
        a {
            overflow: hidden;
            /** limit to 2 lines so it doesn't break the slide layouts
            /** widely supported with the prefix 97.66%
            /** https://caniuse.com/?search=line-clamp */
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            /** 96.65%
            /** https://caniuse.com/?search=box-orient */
            -webkit-box-orient: vertical;

            &:hover {
                text-decoration: none !important;
                color: $text-color !important;
            }
        }
    }

    @include media(bp-lt-md) {
        .lead-img {
            margin-bottom: 20px;
        }

        > p {
            width: 100%;
            margin: 1em 0;
        }

        .carousel {
            width: 100%;
            margin-top: 20px;
        }

        .slide-content {
            padding: 0;
        }

        .title {
            line-height: 1em;
            text-align: center;
        }

        .slide-title {
            margin: 0.5em 0;
            a {
                text-decoration: none;
                color: $text-color;
            }
        }
    }
    .slide-content {
        p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            overflow: hidden;
            -webkit-box-orient: vertical;
        }
    }
    .swiper {
        .swiper-button-next {
            right: 0;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
            &:after {
                @include font-title;
                font-size: 36px;
                font-weight: normal;
                color: $black;
            }
        }

        .swiper-button-prev:after {
            content: '<';
        }

        .swiper-button-next:after {
            content: '>';
        }
    }
    .swiper-wrapper {
        @include media(bp-lt-md) {
            padding-bottom: 30px;
        }
    }
    .swiper-pagination {
        position: relative;
        margin-top: 20px;
    }
}
