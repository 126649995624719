@import '~common/scss/shared/shared';
@import '~common/components/content/tabs/v1/scss/tabs';

.tire-service-widget {
    width: 444px;
    height: 522px;

    th,
    td {
        padding: 0;
    }

    .top-nav.tab-top {
        display: none;
    }

    .mobile-tab-nav {
        color: $dark-gray;
        display: none;
        text-align: center;
        height: 75px;
        width: 100%;
        padding-top: 15px;
        font-size: 24px;

        text-transform: uppercase;

        a {
            color: $dark-gray;
            display: block;
            text-decoration: none;
        }
    }

    > .tab-nav {
        width: 117px;
        float: left;
        margin: 0;
        display: block;

        > ul > li {
            height: 20%;

            > a {
                text-align: center;
            }
        }
    }

    .tab-content {
        width: 327px;
        float: left;

        section {
            display: none;
            background-color: #dfdfdf;
            height: 100%;
            padding: 20px;

            &.active {
                display: block;
                background-color: #ebebeb;
            }

            .store {
                .holiday {
                    color: $brand-color-red;
                }
            }

            .btn {
                display: block;
                width: auto;
                margin: 0 auto;
            }
        }
    }

    form {
        .col {
            width: 100%;
            margin-bottom: 12px;

            &.last {
                margin: -10px 0 !important;
            }

            a {
                color: white;
                line-height: 2;
            }
        }

        .custom-select select,
        input {
            height: 35px;
        }

        input {
            width: 100%;
        }

        .custom-select select {
            width: 110%;
        }

        fieldset .custom-select,
        fieldset input {
            float: left;
            margin-right: 10px;
            width: 60%;
        }
    }

    .icon {
        display: block;
        margin: 0 auto;
    }

    a.directions:before {
        @include icon('map-signs', $white, 32px);

        display: block;
        margin: 0 auto;
    }

    .coral-Modal & {
        height: auto !important;

        .tab-nav li {
            width: 50%;
        }

        .tooltip {
            color: $brand-color-red;
        }

        .tire-search-widget {
            display: inline-block !important;
            height: auto !important;
        }

        .tab-content {
            color: black !important;
        }

        .coral-Modal-body {
            height: auto !important;
        }
    }

    .vehicle-lookup {
        .close-tab-content {
            display: none;
        }
        .active {
            .heading {
                margin-bottom: 10px !important;
            }

            .store {
                margin-bottom: 5px !important;
            }
        }
    }

    // .widget-tab-content-wrapper {
    //     .set-zip-form {
    //         .heading {
    //             margin-bottom: 25px !important;
    //         }

    //         .label {
    //             font-size: 18px;
    //         }

    //         .bar {
    //             .widget-zip-by-vehicle-type-main {
    //                 width: 70%;
    //                 font-size: 16px;
    //             }

    //             .btn {
    //                 display: inline-block;
    //                 width: auto;
    //                 padding: 6px 15px;
    //                 border: solid 1px #034e85 !important;
    //                 margin-left: 5px;
    //                 vertical-align: bottom;
    //                 background: $brand-color-blue;
    //             }
    //         }
    //     }
    // }

    .top-nav.tab-top,
    .close-tab-content {
        display: none;
    }

    .tab-content section {
        .btn {
            width: 100%;
            border: 2px solid #666666 !important;
        }
    }

    .close-tab-content {
        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }
    }

    // Begin Reskin
    .reskin & {
        position: relative;
        width: 100%;

        .mobile-tab-nav {
            display: none !important;
        }

        > .tab-nav {
            position: absolute;
            width: 100%;
            float: none;
            height: 100%;
            top: 0;

            > ul {
                > li {
                    height: 25%;
                    width: 100%;
                    border-left: 0;
                    border-right: 0;

                    &:first-child {
                        display: none;
                    }

                    > a {
                        text-align: left;
                        font-size: 20px;
                        padding-left: 0;

                        span {
                            padding-left: 104px;
                            user-select: none;

                            &:before {
                                display: none !important;
                            }

                            .icon {
                                display: block !important;
                                padding: 0;
                            }
                        }

                        .icon {
                            position: absolute;
                            top: 50%;
                            transform: translate(0, -50%);
                            left: 20px;
                        }
                    }
                }
            }

            &.reskin {
                padding: 55px 15px 0 15px;
                position: absolute !important;

                li {
                    border-bottom: 1px solid $white;

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        &:after {
                            position: relative;
                            display: table-cell;
                            color: $white;
                            content: '\203A';
                            top: -2px;
                            font-size: 40px;
                            vertical-align: middle;
                        }
                    }
                }

                @include media(bp-lt-md) {
                    li {
                        height: 24.9% !important;
                    }
                }

                @media only screen and (max-width: 700px) {
                    display: none;
                    height: 522px;
                    padding-top: 55px;
                }
            }
        }

        .top-nav {
            @extend %tab-nav;
            display: block;

            &.tab-top {
                @extend %tab-nav, %tab-top;
                @include font-title;
                position: absolute !important;
                width: 100%;
                height: 55px;
                z-index: 2;
                top: 0;

                ul {
                    li {
                        border-top: 0 !important;
                        display: table-cell;
                        width: 50%;
                        height: 55px !important;

                        &.active {
                            border-top-color: $white;
                            display: table-cell !important;
                        }

                        a {
                            text-decoration: none;
                            text-align: center;
                            padding: 0;
                            font-size: 20px;
                            color: white !important;
                            height: 55px !important;

                            span {
                                padding-left: 0;
                                height: 55px !important;
                            }
                        }

                        &:first-child {
                            border-right: 3px solid #656665 !important;

                            @media only screen and (max-width: 700px) {
                                border-right: 3px solid $white !important;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 700px) {
                    /* position: absolute !important; */
                }
            }
        }

        > .tab-content {
            width: 100%;
            float: none;
            padding: 15px;

            &.vehicle-lookup {
                height: 100%;
            }

            section {
                padding: 0;
                z-index: 3;

                .heading {
                    border-bottom: 1px solid $dark-gray;
                    font-size: 24px;
                    margin-bottom: 15px !important;
                    padding-bottom: 15px;
                }
            }

            @include media(bp-lt-md) {
                height: 522px !important;
            }

            @media only screen and (max-width: 700px) {
                display: none;
            }
        }

        .tire-search-widget {
            padding-top: 55px !important;

            .heading {
                display: none;
            }

            .tab-nav {
                li.active {
                    display: table-cell !important;
                }
            }
            .tab-nav {
                width: 75%;
                margin: 0 auto;

                > ul {
                    > li {
                        width: 50%;

                        a {
                            color: $white !important;
                            font-size: 16px;
                        }

                        &.active {
                            background: $white !important;

                            a {
                                color: $brand-color-red !important;
                            }
                        }
                    }
                }
            }
        }

        &.shop-tires {
            .top-nav {
                ul {
                    li.shop-tires {
                        &.active {
                            background: #ebebeb !important;
                        }
                    }
                }
            }

            > .tab-nav.reskin {
                z-index: 0;
                display: none;
            }

            > .tab-content {
                display: block;

                .tire-search-widget {
                    display: block;
                    background: #ebebeb;
                }
            }
        }

        &.get-services {
            .top-nav {
                ul {
                    li.get-services {
                        &.active {
                            background: #ebebeb !important;
                        }
                    }
                }
            }

            > .tab-nav.reskin {
                z-index: 1;
                display: block;
            }

            > .tab-content {
                display: block;
            }
        }

        &.show-tab-content {
            > .tab-content {
                background: #f6f4f4;
                // background: -moz-linear-gradient(top, #f6f4f4 50%, #e0dfdf 100%);
                // background: -webkit-linear-gradient(top, #f6f4f4 50%, #e0dfdf 100%);
                background: linear-gradient(to bottom, #f6f4f4 50%, #e0dfdf 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f4f4', endColorstr='#e0dfdf',GradientType=0 );
                z-index: 3;
                color: $dark-gray;
                display: block;

                .close-tab-content {
                    position: absolute;
                    display: block;
                    top: 4px;
                    right: 13px;
                    /* content: "\00D7"; */
                    font-size: 35px;
                    font-weight: 100;
                    color: $brand-color-red;
                    text-align: center;
                    line-height: 1;
                    text-decoration: none;

                    @include media(bp-lt-lg) {
                        width: 42px;
                        height: 42px;
                        font-size: 42px;
                        left: 15px;
                        top: 5px;
                        text-align: left;

                        .desktop {
                            display: none;
                        }

                        .mobile {
                            display: block;
                        }
                    }
                }

                section {
                    background: transparent;

                    a {
                        color: $brand-color-red;
                    }

                    p {
                        color: $dark-gray;
                    }

                    .btn {
                        &.blue {
                            background: $brand-color-blue !important;
                            border: solid 2px #034e85 !important;
                            color: white;
                        }
                    }

                    form {
                        .btn {
                            &.blue {
                                background: #f8f8f8 !important;
                                color: #666666;
                                border: 2px solid #666666 !important;
                            }
                        }
                    }
                }

                @include media(bp-lt-lg) {
                    h3 {
                        padding-left: 20px;
                    }
                }
            }
        }

        .widget-tab-content-wrapper {
            a {
                &.default {
                    display: inline-block;
                    cursor: pointer;
                    width: 100%;
                }

                &.btn {
                    &.blue {
                        margin-bottom: 20px;
                    }
                }
            }

            .store {
                position: relative;
                color: $dark-gray;
                font-size: 18px;
                width: 100%;

                a {
                    text-decoration: none;
                }

                h4 {
                    font-size: 20px;
                    margin: 0 0 12px 0;
                }

                .has-store,
                .no-store {
                    margin-top: 0;
                    line-height: normal;
                }

                .has-store {
                    display: block;

                    .phone {
                        display: inline-block;
                        margin-top: 12px;
                        font-size: 20px;
                    }
                }

                .no-store {
                    display: none !important;
                }

                .phone,
                .store-directions {
                    text-transform: uppercase;
                }

                .store-location {
                    .has-store {
                        width: 57%;
                    }
                }

                .store-directions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    max-width: 116px;
                    text-align: center;
                    font-size: 18px;

                    img {
                        width: 116px;
                        height: 83px;
                        min-width: 116px;
                        min-height: 83px;
                        margin-bottom: 8px;
                        background-color: #ddd;
                    }
                }

                .store-hours {
                    margin-bottom: 0;
                    font-size: 15px;

                    table {
                        width: 100%;
                    }

                    .holiday {
                        color: $brand-color-red !important;
                    }
                }

                .store-ctas {
                    display: table;
                    width: 100%;
                    margin: 20px 0;

                    span {
                        display: table-cell;
                        width: 50%;
                        padding: 0 0 0 15px;

                        &:first-child {
                            padding: 0;
                        }
                    }

                    .btn {
                        border: 1px solid $brand-color-red !important;
                        background: transparent;
                        font-size: 17px;
                    }
                }
            }
        }

        .icon-phone {
            @include icon('phone-grid', $brand-color-red, 16px);
            margin: -2px 4px 0 0;
        }

        .has-local-store & {
            .has-store {
                display: block !important;
            }

            .no-store {
                display: none !important;
            }

            .store-ctas {
                .has-store {
                    display: table-cell !important;
                }
            }
        }

        .no-local-store & {
            // .set-zip-form {
            //     display: none !important;
            // }

            .has-store {
                display: none !important;
            }

            .no-store {
                display: block !important;
            }

            .widget-tab-content {
                display: block;
            }

            .store-location {
                .no-store {
                    display: block !important;
                }
            }

            .store-directions {
                display: none;
            }

            .store-hours {
                display: none;
            }

            .store-ctas {
                margin-bottom: 10px !important;

                .no-store {
                    display: table-cell !important;
                    padding: 0 !important;

                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include media(bp-lt-md) {
            height: 522px;
        }
    }
    // End Reskin
}

.no-local-store {
    // .set-zip-form {
    //     display: block;
    // }

    .widget-tab-content {
        display: none;
    }
}

.has-local-store {
    // .set-zip-form {
    //     display: none;
    // }

    .widget-tab-content {
        display: block;
    }
}

.widget-wrap {
    &.hideTabs {
        .tire-service-widget > .tab-nav {
            display: none !important;
        }

        .reskin & {
            .tire-service-widget {
                > .tab-content {
                    display: block;
                }

                .tire-search-widget {
                    padding: 0 !important;

                    h3.heading {
                        display: inline-block !important;
                        font-size: 26px;
                        padding-bottom: 5px;
                        border-bottom: 0;
                    }

                    .tabs.tab-top {
                        .tab-nav {
                            margin: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.widget-wrap.hideTabs + div.copy {
    left: 32%;

    @include media(bp-lt-lg) {
        left: 36%;
    }
}

.tire-search-widget {
    .tabs.tab-top .tab-nav {
        height: 30px;
    }

    .tab-content {
        height: auto;

        section {
            padding: 0;
        }
    }
}

.tire-service-widget .custom-select,
.tire-service-widget input[name='zip'] {
    opacity: 0.7;

    &.enable {
        opacity: 1;
    }
}

@media only screen and (max-width: 700px) {
    .tire-service-widget {
        .mobile-tab-nav {
            height: 60px !important;
            padding-top: 14px !important;
            padding-right: 6%;
        }

        > .tab-nav {
            position: relative !important;
            top: 0 !important;

            > ul > li {
                height: 70px !important;
            }
        }
    }

    .widget-wrap.hideTabs + div.copy {
        left: 0;
    }

    .reskin .hero-flag-right .tire-service-widget > .top-nav.tab-top {
        position: relative !important;
    }
}

@media only screen and (max-width: 900px) {
    .tire-service-widget {
        width: 275px;
        height: 550px;
        position: relative;

        .mobile-tab-nav {
            display: block;
            height: 40px;
            padding-top: 3px;
            background-color: white;
            position: relative;

            &:before {
                content: '';
                background: 68% 52% no-repeat
                    url("data:image/svg+xml;charset=utf8,<svg>xmlns='//www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 16px 16px'><polygon style='fill:$dark-gray;' points='13.4,3.5 7.5,9.5 1.7,3.5 0,5 7.5,12.5 15.1,5 '/></svg>");
                width: 16px;
                height: 16px;
                display: block;
                position: absolute;
                left: 60%;
                top: 50%;
                /* TODO: RE-IMPLEMENT BELOW */
                // @include transform(translate(40px,-50%));
                padding-right: 30px;
            }
        }

        > .tab-nav {
            display: none;
            width: 100%;
            height: auto;
            position: absolute;
            top: 40px;
            z-index: 999;

            > ul > li {
                display: inline-block;
                height: 102px;
                width: 100%;

                > a {
                    text-align: left;
                    padding-left: 85px;
                    font-size: 30px;

                    .icon {
                        position: absolute;
                        top: 21px;
                        left: 15px;
                    }
                }
            }
        }

        .tab-content {
            display: block;
            height: 510px;
            width: 100%;
        }
    }
}

@media only screen and (min-width: 901px) {
    .tire-service-widget > .tab-nav {
        display: block !important;
    }
}

.tire-service-widget.coral-Modal-body {
    .zipcodeError {
        display: inline-block;
        width: 100%;
        color: $brand-color-red;
    }
}

.custom-select {
    @media screen and (min-width: 0\0) {
        select {
            width: 100% !important;
        }
    }

    select::-ms-expand {
        display: none;
    }
}

.hero.hero-widget {
    .reskin & {
        .widget-wrap {
            min-width: initial;
            min-height: 55px;
            width: 375px;

            @media only screen and (max-width: 900px) {
                width: 300px;
            }

            @media only screen and (max-width: 700px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                &:has(+ .offer-details-v2) {
                    flex-direction: column-reverse;
                }
            }
        }

        .copy {
            img {
                // BSROAEM-7314: for CLS. expecting a particular brand logo. if there are others we should modify this approach
                min-width: 261px;
                min-height: 76px;
            }

            @media only screen and (max-width: 700px) {
                left: auto !important;
            }
        }
    }
}
