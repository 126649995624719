@import '~common/scss/shared/shared';


.heading-with-background-image {
    @include clearfix;
    position: relative;
    
    .content {
        position: absolute;
        top: 50%;
        width: 100%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        text-align: center;
    }

    .image-wrap {
        @include media(bp-lt-sm) {
            overflow: hidden;
        }

        > img {
            max-width: none;
            width: 100%;
        }
    }

    .content {
        padding: 5px;
    }

    .heading,
    .subhead {
        margin: 0 auto;
        color: $white;
        text-transform: uppercase;
        line-height: 1;
    }

    .heading {
        font-size: 56px;

        @include media(bp-lt-sm) {
            font-size: 25px;
        }
    }

    .subhead {
        font-size: 20px;

        @include media(bp-lt-lg) {
            font-size: 18px;
        }

        @include media(bp-lt-sm) {
            font-size: 12px;
        }
    }
}

.heading-with-background-image-small-heading {
    .heading {
        font-size: 44px;

        @include media(bp-lt-lg) {
            font-size: 30px;
        }

        @include media(bp-lt-sm) {
            font-size: 25px;
        }
    }
}
