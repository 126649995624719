@import '~common/scss/shared/shared';

.article-list {
    &__heading {
        h2 {
            @include font-body-bold;
            width: 100%;
            margin: 0 auto 15px;
            border-top: 2px solid $brand-color-red;
            padding-top: 4px;
            font-size: 15px;
            letter-spacing: 2px;
            text-transform: uppercase;

            @include media(bp-gt-lg) {
                border-top: 4px solid $brand-color-red;
                padding-top: 7px;
                font-size: 20px;
            }
        }
    }

    &__filter-section {
        display: none;
    }

    &__load-more-section {
        width: 100%;
        text-align: center;
        display: none;

        @include media(bp-gt-sm) {
            margin-top: 0px;
            padding-bottom: 10px;
        }

        a.load-more-cta {
            @include font-body-bold;
            display: inline-block;
            border: 3px solid #e10c27;
            padding: 7px 12px;
            background: $white;
            font-size: 19px;
            color: $black;
            text-decoration: none;

            @include media(bp-gt-sm) {
                padding: 7px 20px;
            }

            &:active,
            &:focus {
                color: $black;
                text-decoration: none;
            }

            &:hover {
                background: #e10c27;
                color: $white;
                text-decoration: none;
            }
        }

        div.content-pagination {
            display: none;
            margin-top: 8px;
            color: $black;
            letter-spacing: 1px;
            text-transform: uppercase;

            @include media(bp-gt-sm) {
                display: block;
            }
        }
    }

    &__list {
        padding-bottom: 40px;
        justify-content: space-between;
        flex-wrap: wrap;
        display: flex;

        @include media(bp-gt-sm) {
            &::after {
                content: '';
                flex: auto;
            }
        }

        // @import './blog-article-card'; shouldn't need to import this since it's loaded prior.

        // Vertical Variation
        aside & {
            .article-card {
                flex: 0 1 calc(50% - 8px);
                margin-bottom: 10px;

                @include media(bp-gt-sm) {
                    height: 230px;
                    flex: 0 1 100%;
                }

                @include media(bp-gt-lg) {
                    height: 340px;
                    flex: 0 0 100%;
                    margin: 0;
                    margin-bottom: 25px;
                }

                &__image {
                    height: 125px;
                    background-color: #ebebeb;

                    img {
                        height: 100%;
                    }

                    @include media(bp-gt-sm) {
                        height: 160px;
                    }

                    @include media(bp-gt-lg) {
                        height: 240px;
                    }
                }

                &__copy {
                    @include media(bp-gt-sm) {
                        position: absolute;
                        padding: 10px;
                        bottom: -95px;
                        width: 100%;
                        min-height: 185px;
                    }
                    @include media(bp-gt-lg) {
                        padding: 13px;
                        bottom: -85px;
                    }
                }

                &__headline {
                    @include media(bp-gt-sm) {
                        font-size: 15px;
                    }
                    @include media(bp-gt-lg) {
                        font-size: 22px;
                    }
                }

                &__publicationDate,
                &__readTime {
                    @include media(bp-gt-sm) {
                        font-size: 10px;
                    }
                    @include media(bp-gt-lg) {
                        font-size: 13px;
                    }
                }

                &__separator {
                    @include media(bp-gt-sm) {
                        padding: 0 2px;
                        top: 2px;
                        font-size: 7px;
                    }
                    @include media(bp-gt-lg) {
                        padding: 0 4px;
                        top: 0;
                        font-size: 9px;
                    }
                }

                &__btn {
                    @include media(bp-gt-sm) {
                        display: inline-block;
                        transition: 0.2s 0.1s;
                        opacity: 0;
                    }
                }
            }
        }
    }
}

// This is tempororary until we can make this the default styling
.blog__article-list--horizontal {
    .article-list__list {
        padding-bottom: 10px;

        @include media(bp-gt-sm) {
            margin: 0 -10px;
        }

        .article-card {
            @include media(bp-xs) {
                display: flex;
                justify-content: space-between;
            }

            @include media(bp-gt-xs) {
                flex: 0 1 calc(50% - 8px);
            }

            @include media(bp-gt-sm) {
                flex: 0 0 calc(33.33% - 20px);
                height: 240px;
                margin: 10px;
            }

            @include media(bp-gt-lg) {
                flex: 0 0 calc(25% - 20px);
                height: auto;
                min-height: 335px;
            }

            &__headline {
                @include media(bp-gt-sm) {
                    font-size: 15px;
                }

                @include media(bp-gt-lg) {
                    font-size: 22px;
                }
            }

            &__category {
                font-size: 10px;
            }

            &__separator {
                padding: 0 2px;

                @include media(bp-gt-sm) {
                    top: -1px;
                    font-size: 7px;
                }

                @include media(bp-gt-lg) {
                    top: 3px;
                    padding: 0 4px;
                    font-size: 9px;
                }
            }

            &__image {
                height: 125px;
                background-color: #ebebeb;

                @include media(bp-xs) {
                    width: 50%;
                }

                img {
                    height: 100%;
                }

                @include media(bp-gt-sm) {
                    height: 130px;
                }

                @include media(bp-gt-lg) {
                    height: 219px;
                }
            }

            &__copy {
                @include media(bp-xs) {
                    padding: 5px 0 10px 0;
                    width: 46%;
                }

                @include media(bp-gt-sm) {
                    padding: 10px;
                }

                @include media(bp-gt-lg) {
                    padding: 13px;
                }
            }

            &__category {
                @include media(bp-xs) {
                    margin-top: 5px;
                }
            }

            &__publicationDate,
            &__readTime {
                @include media(bp-xs) {
                    margin-bottom: 6px;
                }

                @include media(bp-gt-sm) {
                    font-size: 10px;
                }

                @include media(bp-gt-lg) {
                    font-size: 13px;
                }
            }

            &__description {
                @include media(bp-gt-sm) {
                    display: none;
                }
            }

            &__btn {
                @include media(bp-xs) {
                    display: inline-block;
                }
            }
        }
    }
}

.blog__article-list--filter-infinite {
    .article-list__filter {
        &-section {
            display: block;
        }
    }
}

.blog__article-list--infinite,
.blog__article-list--filter-infinite {
    .article-list__load-more-section {
        display: block;
    }

    .article-list__heading {
        padding-top: 20px;
        @include media(bp-gt-sm) {
            padding-top: 40px;
        }
    }

    .article-list__filter {
        &-section {
            h2 {
                font-size: 20px;
                letter-spacing: 1px;
                margin: 0;
                padding: 20px 0;

                @include media(bp-xs) {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }

        &-count {
            text-align: center;
            color: #575757;
            padding: 20px 0 20px;
            letter-spacing: 1px;
        }

        &-group {
            @include media(bp-xs) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &-cta {
            background: $white;
            border: 2px solid $black;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            @include font-body-bold;
            outline: none;
            text-transform: uppercase;

            @include media(bp-gt-sm) {
                border: 3px solid $black;
                padding: 10px 15px;
                border-radius: 5px;
                font-size: 15px;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            &:hover,
            &:focus,
            &:active {
                border-color: #e10c27;
            }

            span {
                display: none;
            }

            @include media(bp-gt-sm) {
                padding: 10px 25px;
                font-size: 18px;
            }

            &:disabled,
            &[disabled] {
                border: 3px solid #bdbdbd !important;
                background: #dfdfdf !important;
                color: #848484 !important;
                cursor: not-allowed !important;
            }

            &--selected {
                background-color: #e10c27;
                border-color: #e10c27;
                color: $white;
                position: relative;
                padding-right: 30px;

                @include media(bp-gt-sm) {
                    padding-right: 50px;
                }

                span {
                    display: block;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    font-size: 10px;
                    background: #c75160;
                    border-radius: 50px;
                    height: 10px;
                    width: 10px;
                    padding: 8px;

                    @include media(bp-gt-sm) {
                        top: 13px;
                        right: 10px;
                        height: 15px;
                        width: 15px;
                        padding: 10px;
                    }

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        top: 7px;
                        right: 3px;
                        width: 10px;
                        height: 2px;
                        background-color: $white;

                        @include media(bp-gt-sm) {
                            top: 9px;
                            right: 3px;
                            width: 13px;
                        }
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .article-list__list {
        padding-bottom: 0;

        @include media(bp-gt-sm) {
            padding-bottom: 10px;
            margin: 0 -10px;
        }

        .article-card {
            flex: 0 1 calc(50% - 8px);
            border-bottom: none;

            &.blog__article-list--infinite--hide {
                display: none;
            }

            @include media(bp-lt-sm) {
                &:nth-of-type(n + 5) {
                    display: none;
                }
            }

            @include media(bp-gt-sm) {
                flex: 0 0 calc(50% - 20px);
                margin: 10px;

                &:nth-of-type(n + 7) {
                    display: none;
                }
            }

            @include media(bp-gt-lg) {
                flex: 0 0 calc(33.33% - 20px);
                height: 400px;
                margin-bottom: 20px;
            }

            &__image {
                height: 125px;
                background-color: #ebebeb;

                img {
                    height: 100%;
                }

                @include media(bp-gt-sm) {
                    height: 219px;
                }

                @include media(bp-gt-lg) {
                    height: 270px;
                }
            }

            &__copy {
                @include media(bp-gt-sm) {
                    padding: 13px;
                }
            }

            &__category {
                @include media(bp-gt-sm) {
                    font-size: 11px;
                }
            }

            &__headline {
                @include media(bp-gt-sm) {
                    font-size: 18px;
                }

                @include media(bp-gt-lg) {
                    font-size: 22px;
                    width: 100%;
                    padding: 0;
                }
            }

            &__description {
                display: none;
            }

            &__publicationDate,
            &__readTime {
                @include media(bp-gt-sm) {
                    font-size: 13px;
                }
            }

            &__separator {
                @include media(bp-gt-sm) {
                    padding: 0 10px;
                }
            }
        }
    }
}
