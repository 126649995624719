/* Base Card */

// TODO: this class name is too general and shouldn't be in the base styles since it's meant for TSR v1

.card {
    position: relative;
    background-color: white;
    padding: 22px;

    /* top right corner marker */
    .corner {
        display: none;
        border-style: solid;
        border-width: 0 70px 70px 0;
        border-color: transparent $brand-color-blue transparent transparent;
        position: relative;
        float: right;
        margin: -22px;

        .corner-text {
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: 18px;
            left: 25px;
            color: white;
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .top {
        margin: 0 0 20px 0;
    }

    .details {
        margin: 5px 0 0;
        width: 100%;
        display: inline-block;
    }

    .bottom {
        text-align: center
    }
}