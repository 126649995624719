@use 'sass:math';
@import '~common/scss/shared/shared';

.fleetcare-registration {
    margin-bottom: 50px;

    .container {
        @include media(bp-lt-sm) {
            margin: 0;
        }
    }

    .heading {
        margin-bottom: 15px;

        @include media(bp-lt-sm) {
            text-align: center;
            font-size: 25px;
        }
    }

    .wrap {
        padding: 35px 0;

        @include media(bp-lt-sm) {
            padding: 0;
        }

        .inner {
            .register-btn {
                width: 138px;

                &.email {
                    float: left;
                }
            }

            @include media(bp-lt-sm) {
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
            }
        }
    }

    .wrap-inner {
        @include clearfix;
        width: percentage(math.div(1150px, 1300px));
        margin: 0 auto;

        @include media(bp-lt-sm) {
            width: 100%;
        }
    }

    .desc {
        overflow: hidden;
        margin-bottom: 20px;

        > div {
            float: left;
            width: 70%;
        }

        .icn {
            width: 70px;
        }
    }

    .fields-section {
        @include clearfix;

        .fields {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 15px;
            > div {
                float: left;
                width: 30%;
                margin: 0 26px 26px 0;

                @include media(bp-gt-sm) {
                    width: 28%;
                }

                @include media(bp-lt-sm) {
                    float: none;
                    width: 100%;
                }

                &:nth-child(even) {
                    float: left;

                    @include media(bp-lt-sm) {
                        float: none;
                    }
                }
            }

            .radio-list {
                width: 100%;

                .radio-section {
                    float: left;
                    width: 30%;
                    margin: 0 26px 26px 0;

                    @include media(bp-gt-sm) {
                        width: 28%;
                    }

                    @include media(bp-lt-sm) {
                        width: 100%;
                    }

                    label {
                        @include media(bp-lt-sm) {
                            display: block;
                        }
                    }
                    .radio-group {
                        input {
                            width: 10%;
                            margin: 20px 0 20px;
                        }

                        label {
                            display: inline;
                        }
                    }
                }
            }
            .option-list {
                display: none;
                width: 100%;

                textarea {
                    width: 100%;
                    height: 120px;
                    resize: none;
                }
                span {
                    font-style: italic;
                    font-size: 12px;
                }

                label {
                    @include media(bp-lt-sm) {
                        display: none;
                    }
                }
            }

            &.purchase {
                div {
                    position: relative;

                    span {
                        position: absolute;
                        top: 50%;
                        left: 2%;

                        @include media(bp-lt-md) {
                            top: 25%;
                        }
                    }

                    input {
                        padding-left: 20px;
                    }
                }
            }
        }
    }

    .btn[disabled] {
        cursor: default !important;
        opacity: .5;
    }

    label {
        display: block;

        @include media(bp-lt-sm) {
            display: none;
        }
    }

    input {
        width: 100%;
    }
}


.emailError {
    color: $brand-color-red;
}

.zipError {
    color: $brand-color-red;
}
