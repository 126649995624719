// .default is used to show original styles and .no-styles is an option to hide styles
.consumer-rights-form-container:not(.default):not(.no-styles) {
    .consumer-rights-title {
        background-color: #484545;
        padding: 104px 120px 104px 340px;

        h3 {
            font-size: 48px;
            letter-spacing: 0;
        }
    }

    .consumer-rights-info {
        padding: 60px 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
        width: 90%;

        .consumer-rights-info__text {
            color: #343434;
            font-size: 30px;
        }

        p {
            width: 100%;
            margin-bottom: 1em;
            margin-top: 1em;

            @include media(bp-gt-sm) {
                width: 60%;
            }
        }

        h2 {
            color: #343434;
            text-transform: uppercase;
        }
    }

    .consumer-rights-state {
        background-color: rgb(238, 238, 238);
        padding: 16px;

        @include media(bp-gt-sm) {
            padding: 32px;
        }

        .consumer-rights-state-radio {
            width: 90%;
            max-width: 1024px;
            margin-left: auto;
            margin-right: auto;

            > div {
                display: flex;
                align-items: center;
            }
        }

        .consumer-rights-state-radio__title {
            color: #343434;
            font-size: 16px;
            margin: 20px 0 15px;
            font-weight: bold;
            text-transform: uppercase;
        }

        input[type='radio'] {
            margin-right: 15px; // 10px;
            // float: left;
            height: 20px; //25px;
        }

        .consumer-rights-state-next_button {
            // @include font-body-bold;
            text-transform: uppercase;
            background-color: $brand-color-red;
            border: 2px solid $brand-color-red;
            position: relative;
            text-align: center;
            color: #f0f0f0;
            padding: 16px;
            white-space: nowrap;
            min-width: 150px;
            margin-bottom: 32px;
            margin-top: 32px;
        }
    }

    .consumer-rights-form-section {
        background-color: rgb(238, 238, 238);
        display: none;
        padding: 0;

        @include media(bp-gt-sm) {
            padding: 32px;
        }

        .consumer-rights-form {
            margin-left: auto;
            margin-right: auto;
            max-width: 1024px;
            width: 90%;

            section {
                clear: both;
                padding: 12px 0;

                @include media(bp-gt-sm) {
                    width: 50%;
                    float: left;
                    clear: none;
                }
            }

            .consumer-rights-request-checkbox,
            .consumer-rights-request-radio {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 90%;
            }

            // .consumer-rights-request-checkbox {
            // float: left;
            // }

            .consumer-rights-request-radio {
                // width: 90%;
                // float: left;

                @include media(bp-gt-sm) {
                    margin-top: 28px;
                }
            }

            input[type='checkbox'],
            input[type='radio'] {
                margin-right: 15px;
                // float: left;
                height: 16px; //25px;
            }

            #agree-chkbox {
                float: left;
            }

            .customer_request_label {
                margin-bottom: 16px;
            }

            label {
                float: left;
                width: calc(100% - 32px); //88%;
            }

            .consumer-rights-form__input {
                border: 1px solid #ccc;
                font-size: 16px;
                margin-bottom: 10px;
                padding: 12px 0 12px 10px;
                width: 100%;
            }

            .consumer-rights-form__textarea {
                padding-bottom: 0;
                margin-bottom: 0;
                resize: none;
            }

            .customer_request_label {
                width: 100%;
                text-align: right;
            }

            .required {
                padding-top: 32px;
                clear: both;
            }

            .consumer-rights-form__title {
                color: #343434;
                font-size: 16px;
                margin: 20px 0 15px;
                font-weight: bold;
                text-transform: uppercase;
                width: 100%;
            }

            .consumer-rights-form-submit_button {
                @include font-body-bold;
                text-transform: uppercase;
                background-color: $brand-color-red;
                border: 2px solid $brand-color-red;
                position: relative;
                text-align: center;
                color: #f0f0f0;
                padding: 16px;
                white-space: nowrap;
                min-width: 150px;
                margin-bottom: 40px;
                margin-top: 16px;
            }

            .clear-both {
                clear: both;
            }

            .agent_email {
                display: none;
                width: 88%;
                margin-left: 6%;
            }
        }

        .validate-errors-list {
            width: 100%;
            margin: 0 0 0.5em;
            padding: 0 0 12px 12px;
            list-style-type: none;
            color: $brand-color-red;
            font-size: 13px;
            clear: both;

            &.agent_email {
                margin-left: 6%;
                display: block;
            }

            &.agent_request {
                width: 88%;
                margin-left: 4%;
            }
        }
    }

    .consumer-rights-form-message-section,
    .consumer-rights-privacy-section {
        display: none;
        background-color: rgb(238, 238, 238);
        padding: 32px 16px;
        min-height: 120px;

        @include media(bp-gt-sm) {
            padding: 32px;
            min-height: 220px;
        }

        .consumer-rights-form-message {
            margin-left: auto;
            margin-right: auto;
            max-width: 1024px;
            width: 90%;
        }

        .success-message,
        .error-message,
        .general-error-message {
            display: none;
        }

        .error-message__text {
            color: $brand-color-red;
        }
    }
}

// provided to us. not used.
.consumer-rights-form-container.default {
    .consumer-rights-title {
        background-color: #484545;
        padding: 104px 120px 104px 340px;

        h3 {
            font-size: 3rem;
            letter-spacing: 0;
        }
    }

    .consumer-rights-info {
        padding: 3.75rem 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 64rem;
        width: 90%;

        .consumer-rights-info__text {
            color: #343434;
            font-size: 1.875rem;
        }

        p {
            width: 100%;
            margin-bottom: 1em;
            margin-top: 1em;

            @media (min-width: 768px) {
                width: 60%;
            }
        }

        h2 {
            color: #343434;
            text-transform: uppercase;
        }
    }

    .consumer-rights-state {
        background-color: rgb(238, 238, 238);
        padding: 16px;

        @media (min-width: 768px) {
            padding: 32px;
        }

        .consumer-rights-state-radio {
            margin-left: auto;
            margin-right: auto;
            max-width: 1024px;
            max-width: 64rem;
            width: 90%;
        }

        .consumer-rights-state-radio__title {
            color: #343434;
            font-size: 1rem;
            margin: 20px 0 15px;
            font-weight: bold;
            text-transform: uppercase;
        }

        input[type='radio'] {
            margin-right: 10px;
            float: left;
            height: 25px;
        }

        .consumer-rights-state-next_button {
            @include font-body-bold;
            text-transform: uppercase;
            background-color: $brand-color-red;
            border: 2px solid $brand-color-red;
            position: relative;
            text-align: center;
            color: #f0f0f0;
            padding: 1rem;
            white-space: nowrap;
            min-width: 150px;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }
    }

    .consumer-rights-form-section {
        background-color: rgb(238, 238, 238);
        display: none;
        padding: 0;

        @media (min-width: 768px) {
            padding: 32px;
        }

        .consumer-rights-form {
            margin-left: auto;
            margin-right: auto;
            max-width: 64rem;
            width: 90%;

            section {
                clear: both;
                padding: 12px 0;

                @media (min-width: 768px) {
                    width: 50%;
                    float: left;
                    clear: none;
                }
            }

            .consumer-rights-request-checkbox {
                width: 90%;
                float: left;
            }

            .consumer-rights-request-radio {
                width: 90%;
                float: left;

                @media (min-width: 768px) {
                    margin-top: 28px;
                }
            }

            input[type='checkbox'],
            input[type='radio'] {
                margin-right: 15px;
                float: left;
                height: 25px;
            }

            label {
                float: left;
                width: 88%;
            }

            .consumer-rights-form__input {
                border: 1px solid #ccc;
                font-size: 1rem;
                margin-bottom: 10px;
                padding: 12px 0 12px 10px;
                width: 100%;
            }

            .consumer-rights-form__textarea {
                padding-bottom: 0;
                margin-bottom: 0;
                resize: none;
            }

            .customer_request_label {
                width: 100%;
                text-align: right;
            }

            .required {
                margin-top: 32px;
            }

            .consumer-rights-form__title {
                color: #343434;
                font-size: 1rem;
                margin: 20px 0 15px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .consumer-rights-form-submit_button {
                @include font-body-bold;
                text-transform: uppercase;
                background-color: $brand-color-red;
                border: 2px solid $brand-color-red;
                position: relative;
                text-align: center;
                color: #f0f0f0;
                padding: 1rem;
                white-space: nowrap;
                min-width: 150px;
                margin-bottom: 40px;
                margin-top: 1rem;
            }

            .clear-both {
                clear: both;
            }

            .agent_email {
                display: none;
                width: 88%;
                margin-left: 6%;
            }
        }

        .validate-errors-list {
            color: $brand-color-red;
            font-size: 0.8rem;
            padding: 0 0 12px 12px;
            width: 100%;
            clear: both;

            &.agent_email {
                margin-left: 6%;
                display: block;
            }

            &.agent_request {
                width: 88%;
                margin-left: 4%;
            }
        }
    }

    .consumer-rights-form-message-section,
    .consumer-rights-privacy-section {
        display: none;
        background-color: rgb(238, 238, 238);
        padding: 32px 16px;
        min-height: 120px;

        @media (min-width: 768px) {
            padding: 32px;
            min-height: 220px;
        }

        .consumer-rights-form-message {
            margin-left: auto;
            margin-right: auto;
            max-width: 64rem;
            width: 90%;
        }

        .success-message,
        .error-message,
        .general-error-message {
            display: none;
        }

        .error-message__text {
            color: $brand-color-red;
        }
    }
}
