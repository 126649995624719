@import '~common/scss/shared/shared';
.article-card {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    height: auto;
    background-color: $white;
    position: relative;
    $parent: &;

    &--linkable {
        cursor: pointer;
    }

    &--is-animate {
        @include media(bp-gt-lg) {
            &:hover {
                & #{$parent}__copy {
                    transform: translateY(-90px);
                }
                & #{$parent}__description {
                    opacity: 1;
                }
                & #{$parent}__btn {
                    opacity: 1;
                }
            }
        }
    }

    &--is-border-rollover {
        &:hover {
            outline: 2px solid $gray;
        }
    }

    @include media(bp-gt-sm) {
        margin-bottom: 20px;
        border-bottom: none;

        &--drop-shadow {
            box-shadow: 0px 0px 14px 1px #e6e6e6;
        }
    }

    &__image {
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &__copy {
        padding: 10px;
        background-color: $white;
        transition: 0.3s ease-in-out;

        @include media(bp-gt-sm) {
            padding: 16px;
        }
    }

    &__category {
        margin: 0;
        text-transform: uppercase;
        color: #e00000;
        font-size: 10px;
        letter-spacing: 1px;
        font-weight: 700;

        @include media(bp-gt-sm) {
            font-size: 11px;
        }
    }

    &__headline {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        color: $black;
        @include font-body-bold;
        padding: 2px 0;
        text-transform: capitalize;

        @include media(bp-gt-sm) {
            font-size: 18px;
            line-height: 18px;
        }

        @include media(bp-gt-lg) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    &__publicationDate {
        margin: 0;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        font-weight: 500;
        color: $black;
        display: inline-block;

        @include media(bp-gt-sm) {
            font-size: 13px;
            letter-spacing: 2px;
        }
    }

    &__separator {
        padding: 0 5px;
        color: $black;
        font-size: 7px;

        @include media(bp-gt-sm) {
            padding: 0 10px;
            font-size: 10px;
        }
    }

    &__readTime {
        margin: 0;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        font-weight: 500;
        color: #757575;
        display: inline-block;

        @include media(bp-gt-sm) {
            font-size: 13px;
            letter-spacing: 2px;
        }
    }

    &__description {
        margin: 0;
        opacity: 0;
        display: none;
        color: $black;
        margin-bottom: 5px;
        min-height: 40px;
        overflow: hidden;
        transition: 0.2s 0.1s;

        p {
            margin: 0;
            padding: 0;
        }

        @include media(bp-xs) {
            display: none;
        }

        @include media(bp-gt-sm) {
            display: inline-block;
        }
    }

    &__btn {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        padding: 5px 15px;
        border-radius: 5px;
        color: $white;
        font-weight: 600;
        margin: 0px 0 2px 0;
        display: inline-block;
        background-color: #e10c27;
        border: 2px solid #e10c27;
        border-radius: 4px;
        letter-spacing: 1px;

        &:focus,
        &:active,
        &:hover {
            background-color: $white;
            text-decoration: none !important;
            color: #e10c27 !important;
        }

        @include media(bp-gt-sm) {
            margin: 0 0 7px 0;
            display: none;
        }
    }
}
