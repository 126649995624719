header {
    nav.top-nav {
        .mobile-account-menu-wrap {
            a {
                background-color: #444;
                border: none;
                color: $white;
                @include font-body;
                font-weight: normal;
                padding: 12px 15px;
                margin-bottom: 17px;
                min-height: 30px;
            }
        }
    }
}

.desktop-account-menu-wrap {
    display: inline-block;
    height: 30px;
    position: relative;

    & > div {
        display: flex;
    }

    .nav-account-toggle {
        // border-left: 1px solid $dark-gray !important;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &:hover {
        .links & {
            .nav-account-panel {
                display: block;
            }
        }
    }

    .nav-account-panel {
        position: absolute;
        z-index: 1;
        top: auto;
        right: -10px;
        min-width: 275px;
        margin-top: 28px;
        padding: 5px 15px 8px 15px;
        background-color: #fafafa;
        border: solid thin #ccc;
        display: none;

        @include media(bp-gt-md) {
            right: -15px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            height: 50px;
            text-align: left;

            &:not(:first-child) {
                border-top: 1px solid #e0e0e0;
            }

            span {
                &.notification {
                    position: absolute;
                    visibility: hidden;
                }
            }
        }

        a {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            border-left: none !important;
            padding: 0 10px !important;
            font-size: 18px;
            color: #4a4a4a !important;
        }
    }
}

/* start background colors */
.bg-grey-color {
    background-color: rgb(245, 245, 245);
}
/* end background colors */

.bg-grey {
    padding: 35px 5%;
    background-color: #fafafa;

    &.vehicle-card {
        padding: 35px 0;
    }
}

.bg-map-grey {
    background-color: #ebebeb;
}

.secondary-nav .links .desktop-account-menu-wrap a:first-child {
    border-left: 1px solid $dark-gray;
}

.secondary-nav .links .desktop-account-menu-wrap .nav-account-toggle {
    border: 0;
    background: none;
    border-left: 1px solid $dark-gray;
    padding: 0 6px;
}
