@import '~fcac/scss/shared/shared';
.more-products-carousel {
    margin: 50px 0 75px;

    .carousel {
        width: 90%;
        margin: 0 auto;
    }

    .slide-content {
        display: table;
        padding: 15px 20px 20px;

        > div {
            display: table-cell;
            vertical-align: top;
        }

        > .btn {
            display: block;
            width: 100%;
            margin-top: 1em;

            @include media(bp-gt-sm) {
                display: none;
            }
        }
    }

    .img-container {
        box-sizing: content-box;
        width: 125px;
        padding-right: 20px;
    }

    .copy-container {
        .btn {
            margin-top: 1em;
        }
    }

    .title {
        margin-left: 5%;
    }

    .product-name {
        margin-top: 0;
        line-height: 1em;
    }

    .product-id {
        margin: 0.5em 0;
        line-height: 1em;
    }

    .price {
        margin: 0.3em 0 0.1em;
        line-height: 0.8em;

        & .cents {
            vertical-align: top;
            line-height: 0.8em;
        }
    }

    .disclaimer {
        margin: 0;
        letter-spacing: 0.05em;
    }

    p {
        margin: 0.4em 0;
    }

    .btn-mobile {
        display: none;
    }

    @include media(bp-lt-md) {
        padding: 0 30px;

        .btn-mobile {
            margin-top: 20px;
            display: block;
        }

        .slide-content {
            padding: 0;
        }

        .copy-container {
            .btn {
                display: none;
            }
        }

        .title {
            margin-left: 0;
        }
    }
}

.more-products-carousel-reskin {
    .container {
        margin: 0 auto;

        h2.title {
            text-align: center;
        }
    }

    .slick-dots {
        position: relative;
        margin-top: 0;
        bottom: initial;
    }

    @media print {
        display: none;
    }

    &:not(.v2) {
        .product-price {
            .product-price__offer-icon {
                display: none;
            }

            .product-price__tag-icon {
                padding-right: 5px;
            }

            .product-price__offer-copy-text {
                color: #666;
            }

            .product-price__offer-copy {
                line-height: 20px;
                margin-bottom: 5px;
            }

            .product-price__price-or {
                font-size: 12px;
                margin: 0 5px;
                color: #666;
                line-height: 30px;
                vertical-align: middle;
            }

            .product-price__price {
                @include font-title;
                font-size: 28px;
                color: $brand-color-red;
                margin: 0;

                sup,
                .product-price__price-ea {
                    font-size: 20px;
                }
            }

            &__signin-only-description {
                button.product-price__signin-only-link {
                    color: $brand-color-red;
                    background: none;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    padding: 0;
                    text-decoration: underline;
                }
            }
        }
    }
}

.tires-for-you {
    background-color: #f6f6f6;

    h2 {
        background: $white;
        margin: 0;
        padding: 60px 0 10px 0;
        text-align: center;

        @include media(bp-lt-md) {
            padding-top: 0;
        }
    }

    > p {
        background: white;
        margin: 0;
        padding: 0 0 40px 0;

        @include media(bp-lt-md) {
            padding-bottom: 20px;
        }
    }

    .results-container {
        text-align: center;
        padding-top: 40px !important;
        padding-bottom: 60px !important;
        > button {
            @include font-title();
            padding: 14px 16px;
            border: 2px solid $brand-color-red;
            border-radius: 4px;
            color: $brand-color-red;
            font-size: 20px;
            line-height: 110%;
            text-align: center;
            background: none;
            cursor: pointer;

            @include media(bp-lt-md) {
                display: none;
            }
        }

        .tsr-card {
            text-align: left;
            max-width: 310px;
            padding: 0;
            cursor: default;

            &__tire-image img {
                display: inline-block;
            }

            .reviews-v2 .reviews-inline-rating {
                flex-direction: row;

                .review-count {
                    margin-left: 5px;
                }
            }

            .btn.btn-rounded.btn-standard {
                padding-left: 0;
                padding-right: 0;
                font-size: 13px;
            }
            &__pricing {
                margin: 16px 0;
            }
            .no-price {
                margin: 16px 0;
            }
            .product-price {
                &__price {
                    @include font-title;
                    font-size: 20px;
                    line-height: 20px;
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 4px;

                    &-dollar {
                        font-size: 36px;
                        line-height: 36px;
                    }

                    &-or {
                        align-self: center;
                        @include font-body;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        margin: 0 10px;
                    }

                    sup {
                        align-self: normal;
                        font-size: 20px;
                        margin-top: 1px;
                        margin-left: 2px;
                    }

                    &-ea {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }

                &__text {
                    flex-grow: 1;
                }

                &__four-tires {
                    display: block;
                    @include font-body;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    color: black;
                    margin-bottom: 0;

                    strong {
                        @include font-body-bold;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 900;
                    }
                }

                &__offer-icon {
                    position: absolute;
                    top: 12px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                }

                &__tag-icon {
                    margin-right: 6px;
                    min-width: 16px;
                }

                &__offer-detail {
                    color: black;

                    &:hover {
                        color: black !important;
                    }
                }

                &__offer-copy-text,
                &__signin-only-description {
                    display: flex;
                    gap: 1px;

                    span {
                        @include font-body;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 400;
                        color: black;
                    }

                    strong {
                        @include font-body-bold;
                        font-weight: 900;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }

                &__separator {
                    border: 0;
                    border-top: 1px solid #d4d4d4;
                    margin: 16px 0;
                }

                // Grouping to override specificity in app styles.
                &__signin-only-description,
                &__signin-only-link,
                &__signin-only-links,
                &__fitment {
                    @include font-body;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    color: black;
                }

                &__signin-only-description {
                    margin-top: 8px;
                }

                &__fitment {
                    margin-bottom: 8px;
                }

                &__signin-only-link {
                    background: none !important;
                    border: none;
                    padding: 0 !important;
                    text-decoration: underline;
                    cursor: pointer;
                    color: black;
                }
            }
        }

        .slick-dots {
            display: none !important;
        }

        .slick-initialized .slick-slide {
            max-width: 350px;
        }

        .product-results .results.ecom .without-price .no-price {
            @include media(bp-lt-md) {
                left: 0;
            }
        }
    }
}
