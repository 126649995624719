h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $heading-color;
    font-family: $heading-font;
}

h1,
.h1 {
    font-size: 37px;
}

h2,
.h2 {
    font-size: 32px;
}

h3,
.h3 {
    font-size: 26px;
}

.text-align-left,
.left {
    text-align: left !important;
}

.text-align-center,
.center {
    text-align: center !important;
}

.text-align-right,
.right {
    text-align: right !important;
}

.disclaimer {
    font-size: 12px;
}