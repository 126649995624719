$nav-main-height: 82px;
$wrapper-max-width: 1440px;
$subnav-max-width: 1920px;

$nav-secondary-height: 48px;
$nav-action-height: 31px;
$z-nav: 9000;

$nav-margin: 60px;
$nav-padding: 40px;

$nav-height: 131px;

$nav-main-sub-nav-div-width: 100%;
$nav-main-sub-nav-div-height: 370px;

$z-nav: 9000;

$font-family-base: 'Avenir', Arial, sans-serif;
$colorBlack: $black;
$colorWhite: $white;
$colorRed1: #ee3d42;
$colorRed2: $brand-color-red;
$colorRed3: #d62118;
$colorRed4: #ff0000;
$colorRed5: #d81d05;
$colorRed6: #951200;
$colorGrey: #c4c7c7;
$colorGrey1: #808080;
$colorGrey2: #dbdbdb;
$colorGrey3: #cccccc;
$colorGrey4: $dark-gray;
$colorGrey5: #4a4a4a;
$colorGrey6: #767676;
$colorGrey7: #d9d9d9;
$colorGrey8: #e6e6e6;
$colorDarkGrey: #5c5f5f;
$colorGreen: #75bb3e;

$nav-link-color: $colorGrey4;
$nav-link-color-hover: $colorRed5;
