// .default is used to show original styles and .no-styles is an option to hide styles
.cmp-contentfragment:not(.default):not(.no-styles) {
    // margin: 50px percentage(70px / $wrapper-max-width);

    .cmp-contentfragment__elements {
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
        padding: 50px 0;

        h2 {
            font-size: 30px;
            letter-spacing: 3px;
            padding-bottom: 30px;
            text-align: center;
            color: $black;
            text-transform: uppercase;
        }
    }

    .cmp-contentfragment__title {
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
        padding: 50px 0;
        letter-spacing: 3px;
        padding-bottom: 30px;
        text-align: center;
        color: $black;
        text-transform: uppercase;
    }
}

// provided to us. not used.
.cmp-contentfragment.default {
    background-color: #f5f5f5;
    @include font-body;

    .cmp-contentfragment__elements {
        margin-left: auto;
        margin-right: auto;
        max-width: 64rem;
        padding: 50px 0;

        h2 {
            font-size: 1.875rem;
            letter-spacing: 3px;
            padding-bottom: 30px;
            text-align: center;
            color: $black;
            text-transform: uppercase;
        }
    }

    .cmp-contentfragment__title {
        margin-left: auto;
        margin-right: auto;
        max-width: 64rem;
        padding: 50px 0;
        letter-spacing: 3px;
        padding-bottom: 30px;
        text-align: center;
        color: $black;
        text-transform: uppercase;
    }
}
