//General
//Colors

$white: #fff; // 255,255,255
// Scaffolding
$font-family-base: 'Avenir', Arial, sans-serif !default;
$font-size-base: 14px !default;
$line-height-base: 1.42857 !default;
$font-weight-base: normal !default;

// Layouts
$wrapper-max-width: 1440px !default;
$container-max-width: 1300px !default;

// Type
$font-family-regular: 'Avenir', Arial, sans-serif !default;
$heading-font: 'Ultramagnetic', Arial, sans-serif !default;
$font-body-bold: 'AvenirHeavy', Arial, sans-serif !default;

$blue-50: #dfe8ff;
$service-blue: #005eb8;
$text-color-dark: #1a1a1a;
