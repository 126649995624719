@import '~common/scss/shared/shared';

.fifty-fifty {
    @include outer-container;
    @include cta-link();

    width: 100%;

    @include media(bp-gt-sm) {
        @include gradient-top();
    }

    &.bg-gray-gradient {
        @include media(bp-gt-sm) {
            @include gradient-top();
        }
    }

    h2,
    .h2 {
        @include media(bp-md) {
            font-size: 3vw;
        }
    }

    h3,
    .h3 {
        @include media(bp-md) {
            font-size: 1.5vw;
        }
    }

    &.bg-color-white {
        background: transparent;
    }

    .title {
        text-align: left;
        line-height: 1em;

        @include media(bp-lt-md) {
            text-align: center;
        }

        @include media(bp-gt-sm) {
            margin-bottom: 0;
        }
    }

    .subtitle {
        font-size: 18px;

        @include media(bp-gt-sm) {
            margin-top: 0;
        }
    }

    > div {
        box-sizing: border-box;
        color: $dark-gray;

        @include media(bp-lt-md) {
            height: auto !important;
        }

        @include media(bp-gt-sm) {
            width: 50%;
            float: left;
        }
    }

    .img-container {
        img {
            display: block;
            width: 100%;

            @include media(bp-md) {
                object-fit: cover;
                height: 40vw;
            }
        }

        @include media(bp-gt-sm) {
            &.right {
                float: right;
            }
        }
    }

    .copy-container {
        position: relative;

        @include media(bp-lt-md) {
            padding: 50px 30px 75px;
        }
    }

    .copy {
        ul {
            @include bulleted-list();
        }

        @include media(bp-gt-sm) {
            width: 80%;
            padding: 5% 0 5% 5%; // no-js padding

            &.center-block {
                visibility: visible;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                padding: 0;
            }
        }
    }

    ol {
        counter-reset: li;

        li {
            position: relative; /* Create a positioning context */
            margin: 0; /* Give each list item a left margin to make room for the numbers */
            padding: 2px 10px; /* Add some spacing around the content */
            list-style: none; /* Disable the normal item numbering */

            &:before {
                content: counter(li);
                counter-increment: li;
                position: absolute;
                top: 27px;
                left: -1em;
                padding: 4px;
                color: $text-color;
                text-align: center;
                font-size: 32px;
            }
        }

        padding: 0 0 0 20px;

        li {
            min-height: 50px;

            &:before {
                top: -3px;
                left: -20px;
                padding: 0;
            }
        }
    }
}
