%btn {
    @include btn;
}

.btn,
.experiencefragment button {
    @extend %btn;

    &--offers {
        font-size: 16px;
        margin: 0 auto;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin-bottom: 60px;
        padding: 15px 25px;
        border-radius: 4px;

        @include media(bp-xs) {
            width: 240px;
        }
    }
}

.btn-group {
    @include media(bp-gt-sm) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;

        .btn {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 5px;
            }

            &.sm {
                width: 160px;
            }

            &.md {
                width: 200px;
            }

            &.lg {
                width: 240px;
            }
        }
    }
}

@include media(bp-gt-nav) {
    .btn.btn-white {
        background-color: $white;
        color: $black;

        &:hover,
        &:active {
            background-color: darken($white, 10%);
            color: $black;
        }
    }
}

@include media(bp-lt-md) {
    .btn.desktop-only {
        display: none;
    }
}

button[disabled] {
    background: $default-button-background-color !important;
    cursor: default !important;
    opacity: 0.5;
    border: 2px solid #6f3f3f !important;

    &:hover {
        background: $default-button-background-color !important;
    }
}

.cta-link,
.cta-txtLink,
.btn.cta-txtLink,
.btn.cta-link {
    background: none;
    color: black;
    border: none;
    text-decoration: underline;

    @include font-title;

    font-size: 18px;
    color: black;
    text-decoration: none;

    &:after {
        content: '>';
        margin-left: 6px;
        color: $brand-color-red;
    }

    &:hover {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        color: #6c0f03;
    }

    @include media(bp-lt-md) {
        font-size: 16px;
    }
}
