@import '~common/scss/shared/shared';

$colorBlack: $black;
$colorWhite: $white;
$colorGrey1: #4a4a4a;
$colorGrey2: #cccccc;
$colorGrey3: #999999;
$colorGrey4: #656565;

.site-footer,
.experience-fragment {
    &:has(.footer-navigation) {
        background: none;
        border-top: none;
    }
    .footer-v2,
    .footer-v2-author {
        display: none;
        .bsro-ui-global-nav-v2 & {
            display: block;
        }
        &.author {
            display: block;
        }
        .footer-navigation {
            background: linear-gradient(180deg, #ebebeb 0%, #f6f6f6 25.38%, $white 35.27%, $white 100%);
            .container {
                padding: 35px 32px 36px 32px;
                display: flex;
                flex-direction: column;
                @include media(bp-gt-sm) {
                    padding-top: 74px;
                    padding-bottom: 117px;
                    display: block;
                }
                .tools {
                    display: flex;
                    flex-direction: column;
                    order: 2;
                    @include media(bp-gt-sm) {
                        flex-direction: row;
                        gap: 8px;
                        padding-bottom: 40px;
                        order: unset;
                        border-bottom: 1px solid $colorGrey2;
                    }
                    @include media(bp-gt-md) {
                        border-bottom: none;
                    }
                    .column {
                        width: 100%;
                        @include media(bp-gt-sm) {
                            width: 20%;
                        }
                        .title-text-cta {
                            .container {
                                padding: 0;
                                .copy {
                                    padding-left: 0;
                                    h2 {
                                        &.title {
                                            color: $colorBlack !important;
                                            @include font-body;
                                            font-size: 12px !important;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 16px;
                                            margin-top: 0;
                                            margin-bottom: 0;
                                            text-transform: capitalize;
                                            @include media(bp-gt-sm) {
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                    .cta {
                                        a {
                                            &.cta-link {
                                                color: $colorBlack;
                                                @include font-title;
                                                font-size: 20px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                text-decoration: none;
                                                text-transform: uppercase;
                                                &:after {
                                                    display: none;
                                                }
                                                @include media(bp-gt-sm) {
                                                    font-size: 12px;
                                                    line-height: 16px;
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .offers-container {
                            .offers-signup {
                                margin: 0;
                                .signup-container {
                                    display: flex;
                                    flex-direction: column;
                                    h3 {
                                        color: $colorBlack;
                                        @include font-body;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 900;
                                        line-height: 16px;
                                        margin-top: 0;
                                        margin-bottom: 10px;
                                        @include media(bp-gt-sm) {
                                            font-weight: 400;
                                            margin-bottom: 4px;
                                        }
                                    }
                                    .form {
                                        form {
                                            display: flex;
                                            flex-direction: row;
                                            width: 100%;
                                            input {
                                                &.offers-signup-email {
                                                    color: $colorGrey1;
                                                    border: 1px solid $colorBlack;
                                                    @include font-body;
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 16px;
                                                    width: 100%;
                                                    height: 33px;
                                                    max-width: 274px;
                                                    padding: 6px 6px 4px 6px;
                                                    margin-right: 4px;
                                                    @include media(bp-gt-sm) {
                                                        max-width: 216px;
                                                        height: 26px;
                                                    }
                                                }
                                            }
                                            button {
                                                &.btn {
                                                    &.offer-signup-btn {
                                                        line-height: 12px;
                                                        display: block;
                                                        width: 33px;
                                                        height: 33px;
                                                        @include media(bp-gt-sm) {
                                                            width: 26px;
                                                            height: 26px;
                                                            line-height: 16px;
                                                        }
                                                        &:hover,
                                                        &:focus {
                                                            border: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .footer-tool {
                            display: flex;
                            flex-direction: column;
                            margin: 0;
                            width: 100%;
                            padding-top: 18px;
                            padding-bottom: 18px;
                            border-bottom: none;
                            @include media(bp-gt-sm) {
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                            .footer-heading {
                                color: $colorBlack;
                                @include font-body;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                                display: inline-block;
                                padding-top: 0;
                                padding-bottom: 0;
                                @include media(bp-gt-sm) {
                                    margin-bottom: 5px;
                                }
                                .toggle {
                                    display: none;
                                }
                                &:after {
                                    display: block;
                                    content: '\002B';
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    @include media(bp-gt-sm) {
                                        display: none;
                                    }
                                }
                                &.is-active {
                                    margin-bottom: 18px;
                                    @include media(bp-gt-sm) {
                                        margin-bottom: 5px;
                                    }
                                    &:after {
                                        content: '\2013';
                                    }
                                }
                            }
                            .wrap {
                                display: flex;
                                flex-direction: row;
                                max-height: 0;
                                overflow: hidden;
                                transition: max-height 0.2s ease-out;
                                @include media(bp-gt-sm) {
                                    max-height: unset;
                                }
                                .image {
                                    margin-right: 9px;
                                    margin-bottom: 0;
                                    width: calc(50% - 9px);
                                }
                                .content {
                                    display: flex;
                                    flex-direction: column;
                                    width: auto;
                                    p {
                                        color: $colorBlack;
                                        @include font-body;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 16px;
                                        margin-top: 5px;
                                        margin-bottom: 5px;
                                        a {
                                            color: $colorGrey1;
                                            @include font-body;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 16px;
                                            text-decoration: underline;
                                            &:hover,
                                            &:focus {
                                                color: $colorGrey1;
                                            }
                                        }
                                        &:last-child {
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .container-component {
                            &.footer-social-links {
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                white-space: nowrap;
                                margin-bottom: 22px;
                                @include media(bp-gt-sm) {
                                    align-items: flex-end;
                                    margin-bottom: 0;
                                }
                                .container {
                                    padding: 0;
                                    max-width: 100%;
                                    margin: 0;
                                    flex-direction: row;
                                    @include media(bp-gt-sm) {
                                        flex-direction: column;
                                    }
                                    .text {
                                        display: none;
                                        @include media(bp-gt-sm) {
                                            display: block;
                                        }
                                        p {
                                            color: $colorBlack;
                                            @include font-body;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 16px;
                                            margin-top: 0;
                                            margin-bottom: 28px;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .image-lazy-load {
                                        &.image {
                                            margin-bottom: 0;
                                            display: inline-block;
                                            margin-right: 51px;
                                            @include media(bp-gt-sm) {
                                                margin-right: 2%;
                                            }
                                            @include media(bp-gt-md) {
                                                margin-right: 10%;
                                            }
                                            @include media(bp-gt-lg) {
                                                margin-right: 25px;
                                            }
                                            a {
                                                display: inline-block;
                                                img {
                                                    &.lazyload {
                                                        &.cq-dd-image {
                                                        }
                                                    }
                                                }
                                            }
                                            &:last-child {
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.have-questions {
                            position: absolute;
                            top: 35px;
                            max-width: calc(100vw - 32px);
                            @include media(bp-gt-sm) {
                                margin-bottom: 0;
                                margin-bottom: 36px;
                                position: relative;
                                top: unset;
                            }
                            .container {
                                width: 100%;
                                .copy {
                                    .title {
                                        @include font-body;
                                    }
                                    .cta {
                                        @include font-title;

                                        @include media(bp-gt-sm) {
                                            @include font-body;
                                        }

                                        a {
                                            &.cta-link {
                                                display: block;
                                                font-family: inherit !important;

                                                @include media(bp-gt-sm) {
                                                    display: inline-block;
                                                }
                                                &:after {
                                                    border: solid $colorBlack;
                                                    border-width: 0 2px 2px 0;
                                                    content: '';
                                                    display: block;
                                                    height: 4px;
                                                    left: calc(100% - 52px);
                                                    padding: 1px;
                                                    position: relative;
                                                    top: -20px;
                                                    transform: rotate(315deg);
                                                    width: 4px;
                                                    @include media(bp-gt-sm) {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.get-coupons {
                            width: 100%;
                            order: 6;
                            padding-top: 18px;
                            padding-bottom: 26px;
                            @include media(bp-gt-sm) {
                                width: 27%;
                                order: unset;
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                        &.credit-card {
                            width: 100%;
                            border-bottom: 1px solid $colorGrey2;
                            order: 4;
                            @include media(bp-gt-sm) {
                                width: auto;
                                border-bottom: none;
                                order: unset;
                                position: absolute;
                                top: 220px;
                                right: 58px;
                            }
                            @include media(bp-gt-md) {
                                width: 37%;
                                position: unset;
                                top: unset;
                                right: unset;
                            }
                            .footer-tool {
                                @include media(bp-gt-md) {
                                    padding-left: 17%;
                                }
                                .wrap {
                                    .image {
                                        max-width: 70px;
                                    }
                                }
                            }
                        }
                        &.firestone-app {
                            width: 100%;
                            border-bottom: 1px solid $colorGrey2;
                            order: 5;
                            @include media(bp-gt-sm) {
                                width: auto;
                                border-bottom: none;
                                order: unset;
                                position: absolute;
                                top: 320px;
                                right: 32px;
                            }
                            @include media(bp-gt-md) {
                                width: 32%;
                                position: unset;
                                top: unset;
                                right: unset;
                            }
                            .footer-tool {
                                @include media(bp-gt-md) {
                                    padding-left: 12%;
                                }
                                .wrap {
                                    .image {
                                        max-width: 54px;
                                    }
                                    .content {
                                        p {
                                            display: none;
                                            @include media(bp-gt-sm) {
                                                display: block;
                                            }
                                            &.mobile-apps {
                                                display: block;
                                                text-decoration: none;
                                                margin-bottom: 0;
                                                margin-left: 15px;
                                                @include media(bp-gt-sm) {
                                                    display: none;
                                                }
                                                span {
                                                    text-transform: uppercase;
                                                    font-weight: 600;
                                                    font-size: 10px;
                                                    text-align: center;
                                                    width: 100%;
                                                    display: inline-block;
                                                    margin-bottom: 8px;
                                                }
                                                a {
                                                    display: inline-block;
                                                    margin-right: 8px;
                                                    img {
                                                        width: 95px;
                                                        height: auto;
                                                    }
                                                    &:last-child {
                                                        margin-right: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.social-media {
                            order: 7;
                            @include media(bp-gt-sm) {
                                order: unset;
                                position: absolute;
                                top: 450px;
                                right: 58px;
                                width: 215px;
                                .container-component {
                                    &.footer-social-links {
                                        align-items: flex-start;
                                    }
                                }
                            }
                            @include media(bp-gt-md) {
                                position: unset;
                                top: unset;
                                right: unset;
                                width: 20%;
                                .container-component {
                                    &.footer-social-links {
                                        align-items: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }
                .links {
                    display: flex;
                    flex-direction: column;
                    order: 1;
                    margin-top: 90px;
                    @include media(bp-gt-sm) {
                        flex-direction: row;
                        padding-bottom: 16px;
                        order: unset;
                        margin-top: 0;
                        width: 66%;
                        flex-wrap: wrap;
                        position: relative;
                    }
                    @include media(bp-gt-md) {
                        width: 100%;
                        flex-wrap: unset;
                        border-top: 1px solid $colorGrey2;
                        position: unset;
                    }
                    .column {
                        width: 100%;
                        border-bottom: 1px solid $colorGrey2;
                        @include media(bp-gt-sm) {
                            width: 25%;
                            margin-top: 0;
                            padding-top: 40px;
                            border-bottom: none;
                        }
                        .simple-text {
                            position: relative;
                            margin-top: 18px;
                            margin-bottom: 18px;
                            @include media(bp-gt-sm) {
                                margin-top: 0;
                                margin-bottom: 14px;
                            }
                            span {
                                color: $colorBlack;
                                @include font-body;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                text-transform: uppercase;
                                margin-bottom: 14px;
                                @include media(bp-gt-sm) {
                                    font-weight: 700;
                                }
                            }
                            &:after {
                                display: block;
                                content: '\002B';
                                width: 10px;
                                height: 10px;
                                position: absolute;
                                right: 0;
                                top: 0;
                                @include media(bp-gt-sm) {
                                    display: none;
                                }
                            }
                            &.is-active {
                                &:after {
                                    content: '\2013';
                                    @include media(bp-gt-sm) {
                                        display: none;
                                    }
                                }
                            }
                        }
                        .footerlinks {
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height 0.2s ease-out;
                            @include media(bp-gt-sm) {
                                max-height: unset;
                            }
                            .footer-heading {
                                color: $colorGrey1;
                                @include font-body;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 900;
                                line-height: 20px;
                                text-transform: capitalize;
                                .toggle {
                                    display: none;
                                }
                                @include media(bp-gt-sm) {
                                    margin-bottom: 16px;
                                }
                            }
                            .wrap {
                                ul {
                                    &.foundation-ordered-list-container {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 16px;
                                        padding: 0;
                                        margin-top: 16px;
                                        margin-bottom: 16px;
                                        @include media(bp-gt-sm) {
                                            margin-top: 0;
                                        }
                                        li {
                                            display: block;
                                            &.foundation-list-item {
                                                a {
                                                    color: $colorGrey1;
                                                    @include font-body;
                                                    font-size: 16px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 20px;
                                                    &:hover,
                                                    &:focus {
                                                        color: $colorGrey1;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:nth-child(1) {
                            @include media(bp-gt-sm) {
                                order: 1;
                                flex: 1 0 50%;
                            }
                            @include media(bp-gt-md) {
                                order: unset;
                                width: 25%;
                                flex: none;
                            }
                        }
                        &:nth-child(2) {
                            @include media(bp-gt-sm) {
                                order: 2;
                                flex: 1 0 50%;
                            }
                            @include media(bp-gt-md) {
                                order: unset;
                                width: 25%;
                                flex: none;
                            }
                        }
                        &:nth-child(3) {
                            @include media(bp-gt-sm) {
                                order: 3;
                                flex: 1 0 50%;
                            }
                            @include media(bp-gt-md) {
                                order: unset;
                                width: 25%;
                                flex: none;
                            }
                        }
                        &:nth-child(4) {
                            @include media(bp-gt-sm) {
                                order: 4;
                                flex: 1 0 50%;
                                position: absolute;
                                top: 780px;
                                left: 50%;
                            }
                            @include media(bp-gt-md) {
                                order: unset;
                                width: 25%;
                                flex: none;
                                position: relative;
                                top: unset;
                                left: unset;
                            }
                            .footerlinks {
                                &:not(.footerlinks ~ .footerlinks) {
                                    border-bottom: 1px solid $colorGrey2;
                                    @include media(bp-gt-sm) {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottom-list {
                    border-top: 1px solid $colorGrey2;
                    @include font-body;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    position: relative;
                    padding-top: 25px;
                    order: 3;
                    @include media(bp-gt-sm) {
                        margin-top: 0;
                        padding-top: 28px;
                        order: unset;
                    }
                    .inline-list {
                        margin-bottom: 8px;
                        .footerlinks {
                            .wrap {
                                ul {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    width: 100%;
                                    @include media(bp-gt-sm) {
                                        display: block;
                                    }
                                    &.foundation-ordered-list-container {
                                        li {
                                            &.foundation-list-item {
                                                margin-bottom: 8px;
                                                display: inline-block;
                                                width: 50%;
                                                @include media(bp-gt-sm) {
                                                    margin-right: 25px;
                                                    width: auto;
                                                }
                                                a {
                                                    color: $colorGrey3;
                                                    @include font-body;
                                                    font-size: 12px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 16px;
                                                    text-decoration: underline;
                                                    &:hover,
                                                    &:focus {
                                                        color: $colorGrey3;
                                                    }
                                                    @include media(bp-gt-sm) {
                                                        color: $colorGrey1;
                                                        &:hover,
                                                        &:focus {
                                                            color: $colorGrey1;
                                                        }
                                                    }
                                                }
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                                &:nth-child(odd) {
                                                    padding-right: 35px;
                                                    @include media(bp-gt-sm) {
                                                        padding-right: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .copyright {
                        .simple-text {
                            span {
                                color: $colorGrey4;
                                font-size: 11px;
                                @include media(bp-gt-sm) {
                                    color: $colorGrey1;
                                    font-size: inherit;
                                }
                            }
                        }
                    }
                    .footer-logo {
                        position: absolute;
                        top: -80px;
                        right: 0;
                        display: none;
                        @include media(bp-gt-sm) {
                            display: block;
                        }
                        a {
                            img {
                                width: 49px;
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-v2-author {
        display: block;
    }
}
