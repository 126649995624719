.more-or-less-controls {
    display: none;
}

@include media(bp-lt-md) {
    .more-or-less-controls {
        display: block;

        a {
            font-weight: bold;
            text-decoration: none;
        }

        .less-btn {
            display: none;
        }
    }

    .more-or-less-to-hide {
        display: none;
    }
}