@use 'sass:math';

.overview {
    &.mobile-only {
        display: block !important;
    }

    &.mod-disclaimer {
        h2 {
            margin-bottom: 0px;
        }
        .overview-body {
            text-align: center !important;
            margin-top: 18px;
        }
        .disclaimer {
            font-size: 14px;
        }
        .outer-container {
            .item-wrapper {
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }

    .outer-container {
        @include outer-container;
    }

    width: 100%;

    .img-container {
        display: flex;
        width: percentage(math.div(680, 1440));
        padding: 0 percentage(math.div(70, 1440)) 0 0;
        float: left;
        &.left {
            justify-content: left;
        }
        &.right {
            justify-content: right;
        }
        img {
            display: block;
        }
    }

    .copy-container {
        width: percentage(math.div(760, 1440));
        padding-right: percentage(math.div(70, 1440));
        float: left;
    }

    .btn-container {
        margin: 20px 0;
        text-align: center;
    }

    .title {
        margin: 0 0 50px 0 !important;
        padding-top: 50px;
        font-weight: normal;
        line-height: 1.1em;
        text-align: center;
        text-transform: uppercase;
    }

    .item,
    .item-wrapper {
        width: 100%;

        .mobile-only {
            display: none;
        }
    }

    .item-wrapper {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }

    .item-title {
        font-weight: normal;
    }

    .overview-body {
        margin: 2em 0;
        line-height: 1.1em;

        b,
        strong {
            text-transform: uppercase;
            font-weight: normal;
        }

        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }
    }

    @include media(bp-lt-md) {
        padding: 0 15px;

        .img-container {
            display: none;
        }

        .copy-container {
            width: 100%;
            margin-bottom: 40px;
            padding-right: 0;
        }

        .title {
            margin: 0 0 40px;
            padding-top: 40px;
        }

        .carousel {
            padding-bottom: 10px;
        }

        .item {
            margin-bottom: 0;
            padding-bottom: 0;

            .mobile-only {
                display: inline-block;
            }
        }

        .item-wrapper {
            margin-bottom: 0;
            padding-bottom: 0;

            .mobile-only {
                display: block;
            }
        }

        .overview-body {
            display: none;
        }

        .desktop-only {
            display: none;
        }
    }
}

.overview-carousel {
    .item-wrapper {
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}
