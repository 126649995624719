@use 'sass:math';
$vehicle-info-bar-bg: $brand-color-blue;

.vehicle-info-bar {
    position: relative;
    min-height: 50px;
    height: auto;
    font-size: 20px;
    width: 100%;
    color: $white;
    line-height: 50px;

    @include media(bp-lt-md) {
        min-height: auto;
        line-height: 1em;
        font-size: 15px;
        text-align: center;
    }

    > .container {
        padding: 0;

        @include media(bp-lt-md) {
            padding: 8px 0;
        }
    }

    .container {
        position: relative;
        background: $vehicle-info-bar-bg;

        @include media(bp-lt-md) {
            margin: 0;
            text-align: center;
        }
    }

    .vehicle,
    dt,
    dd {
        display: inline-block;
        padding: 0;
        margin: 0;
    }

    dt {
        padding: 0 5px 0 12px;

        @include media(bp-lt-md) {
            display: none;
        }
    }

    dd {
        @include media(bp-lt-md) {
            font-size: 15px;
            line-height: 1em;
            padding: 0 8px 5px;
        }
    }

    .vehicle {
        @include media(bp-lt-md) {
            padding-top: 0;
        }
    }

    .change-vehicle {
        font-size: 16px;
        padding-right: 12px;
        float: right;
        color: $white;
        text-decoration: underline;

        &:hover {
            color: #ededed;
        }

        @include media(bp-lt-md) {
            padding-right: 0;
            font-size: 15px;
            float: none;
        }
    }

    .horizontal-vehicle-filter {
        background: transparent;
        font-size: 14px;
        line-height: 1.45em;
        padding: 0;

        .container {
            padding: 0;
            margin: 0;
        }

        .filters-wrap {
            background: transparent;
        }

        .filters-wrap-inner {
            width: 100%;
            padding: 15px 10px 0;
        }

        fieldset {
            a {
                &.tooltop {
                    color: $white;
                }
            }
        }
    }

    &.grid {
        color: black;
        padding: 0;
        overflow: visible;

        dt {
            font-size: 26px;
            padding: 0;
            text-transform: uppercase;

            @media screen and (max-width: 768px) {
                display: inline-block;
            }
        }

        .container {
            background: transparent;
            border-bottom: 1px solid #ededed;

            .coral-Popover-content {
                .filters-wrap {
                    margin: 0;
                }

                .container {
                    .filters-wrap {
                        background: #f6f6f6;
                    }
                }
            }

            .vehicle {
                width: 49%;
                display: inline-block;
                line-height: normal;

                @include media(bp-lt-md) {
                    width: 100%;
                    padding: 0 5px;
                }
            }

            .triple-guarantee-desktop {
                position: relative;
                display: inline-block;
                width: 50%;
                height: auto;
                text-align: right;
                vertical-align: top;
                top: -13px;

                @media screen and (max-width: 824px) {
                    top: -10px;

                    img {
                        width: 90%;
                    }
                }

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }

        .change-vehicle {
            color: black;
            float: none;
            font-size: 24px;
            padding: 3px 0 0 11px;
        }
    }

    &.popOver {
        .coral-Popover {
            display: block;
        }
    }
}

.vehicle-info-bar--v2 {
    .vehicle-lookup {
        margin: 20px auto;
    }

    &.grid {
        .vehicle-name {
            color: $brand-color-red;
            font-size: 25px;
        }

        span {
            @include media(bp-lt-md) {
                padding: 0;
            }
        }

        .container {
            border-bottom: none;

            .vehicle {
                width: 100%;
                display: flex;
            }

            @include media(bp-lt-md) {
                margin: 0 percentage(math.div(70px, $wrapper-max-width));
                text-align: left;
            }
        }
    }
    a:hover {
        text-decoration: none !important;
    }
    .icon-edit {
        position: relative;
        top: -3px;
        display: inline-block;
        width: 17px;
        height: 17px;
        mask: url(https://s7d1.scene7.com/is/content/bridgestone/edit-2021-icon-global-web-bsro);
        background-color: $brand-color-red;
        mask-size: cover;

        &:hover,
        &:focus {
            background-color: darken($brand-color-red, 10%);
        }
    }

    .vehicle-info-bar__tsr {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h1 {
            margin: 0px;
            line-height: 0;
        }
    }

    .results-count {
        @include font-body-bold;
        font-size: 16px;
        line-height: 1.2em;
        text-transform: uppercase;

        span {
            @include font-body;
            text-transform: lowercase;
        }
    }

    .triple-guarantee {
        display: flex;
        width: 350px;
        align-items: center;
        background-color: $brand-color-red;
        border-radius: 5px;
        padding: 10px;

        @include media(bp-lt-lg) {
            display: none;
        }

        &__logo {
            min-width: 30px;
            font-size: 12px;
            line-height: 1.2em;

            img {
                width: 30px;
                filter: invert(1);
            }
        }

        &__message {
            @include font-body;
            padding-left: 10px;
            font-size: 14px;
            color: $white;
            line-height: 1.2em;

            > div:first-child {
                @include font-body-bold;
                margin-bottom: 6px;
                padding-bottom: 6px;
                border-bottom: 1px solid $white;
                text-transform: uppercase;
            }
        }
    }

    .cfna-finance {
        display: flex;
        width: 350px;
        align-items: center;
        background-color: #d81e05;
        border-radius: 5px;
        padding: 10px;
        margin-top: 6px;

        &:hover {
            text-decoration: none;
        }

        @include media(bp-lt-lg) {
            display: none;
        }

        &__logo {
            min-width: 30px;
            font-size: 12px;
            line-height: 1.2em;

            img {
                width: 30px;
                filter: invert(1);
            }
        }

        &__message {
            @include font-body;
            padding-left: 10px;
            font-size: 13px;
            color: $white;
            line-height: 1.2em;
        }
    }
}
