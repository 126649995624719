@import '~common/scss/shared/shared';

.maintenance-schedule {
    .header {
        padding-bottom: 10px;
        border-bottom: solid 1px #ccc;
    }

    .ctas {
        overflow: hidden;
        a {
            float: right;
        }

        .call-store-message {
            display: inline-block;
            float: right;
        }
    }

    .toolbar {
        overflow: hidden;
        padding: 10px 0;

        .copy {
            float: left;
            line-height: 2.2;
        }
    }

    .conditions {
        width: 35%;
        float: right;
        text-align: right;

        .custom-select {
            display: inline-flex;
            width: 220px;
        }

        @include media(bp-lt-sm) {
            width: 100%;
            float: left;
            text-align: right;
            margin-top: 10px;
        }
    }

    .slick-prev,
    .slick-next {
        z-index: 500;
    }

    .slick-prev {
        @include media(bp-lt-lg) {
            left: 0;
        }
    }

    .slick-next {
        @include media(bp-lt-lg) {
            right: 0;
        }
    }

    .miles-swiper {
        margin: 0;
        background: #999;

        .slide {
            text-align: center;

            a {
                display: block;
                padding: 5px 0;
                background: #999;
                font-weight: bold;
                color: $white;
                text-decoration: none;

                &:hover {
                    background: #ccc;
                }
            }

            &.active {
                a {
                    background: red;
                }
            }
        }

        .slick-prev,
        .slick-next {
            top: 20%;
        }
    }

    .details-swiper {
        margin: 30px 0;

        .slide-content {
            width: 80%;
            margin: 0 auto;
            background: #999;
            color: $white;
        }

        .title {
            margin: 0;
            padding: 10px 0;
            color: $white;
            text-align: center;
            border-bottom: solid 1px #ccc;
        }

        .content {
            padding: 10px 30px;

            span {
                text-transform: uppercase;
            }
        }

        .slide {
            &.active {
                .title {
                    background: $text-color;
                }

                .content {
                    background: red;
                }
            }
        }

        .slick-prev,
        .slick-next {
            top: 150px;

            @include media(bp-lt-sm) {
                top: 50%;
            }
        }
    }

    .additional {
        border-top: solid 1px #ccc;
    }

    .periodic-swiper,
    .miles-swiper,
    .details-swiper {
        margin: 0 0 30px;

        .slide-content {
            width: 80%;
            margin: 0 auto;
        }

        .title {
            margin: 0;
            padding: 10px 0;
            color: $white;
            text-align: center;
            background: #999;
        }

        .content {
            padding: 10px 30px;
            border-right: solid 1px #999;
            border-bottom: solid 1px #999;
            border-left: solid 1px #999;

            span {
                text-transform: uppercase;
            }
        }

        .swiper-button-next {
            right: -6px;
        }

        .swiper-button-prev {
            left: -6px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            &:after {
                content: '<';
                @include font-title;
                font-size: 36px;
                font-weight: normal;
                color: $black;
            }
        }

        .swiper-button-prev:after {
            content: '<';
        }

        .swiper-button-next:after {
            content: '>';
        }
    }

    .footer {
        clear: both;
        border-top: solid 1px #ccc;
    }
}
