@import '~common/scss/shared/shared';

.brand-specs {
    @include gradient-top();
    @include outer-container;

    padding: 50px 0 0;

    .title {
        width: 80%;
        margin: 0.5em auto;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.2em;
    }

    .copy-container {
        width: 70%;
        margin: 0 auto 40px;
        text-align: center;
    }

    .img-container,
    .content,
    .ratings-container {
        float: left;
    }

    .img-container {
        position: relative;
        width: 36%; //745px;//60%;
        padding-right: 20px;
    }

    .content {
        width: 30%;
    }

    li {
        position: relative; /* Create a positioning context */
        margin: 0 0 25px 0; /* Give each list item a left margin to make room for the numbers */
        padding: 2px 10px; /* Add some spacing around the content */
        list-style: none; /* Disable the normal item numbering */

        &:before {
            content: counter(li);
            counter-increment: li;
            position: absolute;
            top: -2px;
            left: -2em;
            width: 32px;
            margin-right: 8px;
            padding: 4px;
            color: $white;
            text-align: center;
            border-radius: 50%;
        }
    }

    .ratings-container {
        width: 34%;

        .title {
            text-align: center;
            font-size: 26px !important;
        }

        .speed-rating {
            text-align: center;
        }
    }

    @include media(bp-lt-lg) {
        .img-container {
            width: 50%;
        }

        .content {
            width: 50%;
        }

        .ratings-container {
            width: 100%;
        }
    }

    @include media(bp-lt-md) {
        padding-top: 20px;

        .title {
            width: 100%;
            padding: 0 15px;
        }

        .copy-container {
            width: 100%;
            margin-bottom: 20px;
            padding: 0 15px;
        }

        .img-container,
        .content {
            width: 100%;
        }

        ol {
            margin-left: 5%;
        }
    }
}
